import React, { FC } from 'react';
import classnames from 'classnames/bind';
import styles from './FacilityTabNavigator.module.css';
import { UseTabNavigator } from './useTabNavigator';

const cx = classnames.bind(styles);

export type FacilityTabNavigatorProps = {
  tabNavigator: UseTabNavigator;
  clickToNavigate?: boolean;
};

export const FacilityTabNavigator: FC<FacilityTabNavigatorProps> = ({
  tabNavigator,
  clickToNavigate = true,
}) => {
  const { activeTabIndex, setActiveTabIndex, tabs } = tabNavigator;

  return (
    <nav className={cx('tabs')}>
      <div className={cx('shadow')}></div>
      {tabs.map((tab, index) => {
        const text = typeof tab === 'string' ? tab : tab.key;

        return (
          <div
            key={text}
            className={cx('tab', {
              active: activeTabIndex === index,
              clickable: clickToNavigate,
            })}
            onClick={() => clickToNavigate && setActiveTabIndex(index)}
          >
            {typeof tab === 'string' ? tab : tab.component}
          </div>
        );
      })}
    </nav>
  );
};

FacilityTabNavigator.displayName = 'FacilityTabNavigator';
