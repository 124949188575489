import React, { FC, useEffect } from 'react';
import classnames from 'classnames/bind';
import { BrowserRouter, Switch } from 'react-router-dom';
import Modal from 'react-modal';

import { Route } from 'src/components/Route';
import styles from './App.module.css';

import SWN from '../SmartWaterNavigator';
import WRM from '../WaterRiskMonetizer';
import WAA from '../WaterActionAssessment';

import { SiteHeader } from 'src/components/SiteHeader';
import { eventBus } from 'src/utils/eventBus';
import { useAuth } from 'src/hooks/useAuth';
import { SESSION_INVALID } from 'src/constants/events';

Modal.setAppElement('#root');

const cx = classnames.bind(styles);

interface AppProps {}

export const App: FC<AppProps> = () => {
  const { logout } = useAuth();

  useEffect(() => {
    const terminate = () => logout && logout('/login?r=sess_exp');

    eventBus.on(SESSION_INVALID, terminate);

    return () => eventBus.remove(SESSION_INVALID, terminate);
  }, [logout]);

  return (
    <BrowserRouter>
      <div className={cx('app')}>
        <SiteHeader />

        <Switch>
          <Route exact={false} key="wrm" path="/wrm" component={WRM} />
          <Route exact={false} key="waa" path="/waa" component={WAA} />
          <Route exact={false} key="swn" path="/" component={SWN} />
        </Switch>
      </div>
    </BrowserRouter>
  );
};
