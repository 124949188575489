import React, { FC, PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';

type FaqProps = Record<string, unknown>;

export const Faq: FC<PropsWithChildren<FaqProps>> = () => {
  return (
    <>
      <div
        style={{
          height: '48px',
          backgroundColor: '#005075',
          width: '100%',
          maxWidth: '1920px',
        }}
      />
      <div className="container" id="main-container">
        <div className="row">
          <div id="primary" className="content-area col-md-8">
            <main id="main" className="site-main" role="main">
              <article
                id="post-3755"
                className="post-3755 page type-page status-publish hentry"
              >
                <header className="entry-header">
                  <h1 className="entry-title">Frequently Asked Questions</h1>{' '}
                </header>

                <div className="entry-content">
                  <h4>What is the Ecolab Smart Water Navigator?</h4>
                  <p>
                    Water is essential to the health of our environment,
                    communities and our economy. By 2030, businesses water use
                    is expected to surpass supply by 56% (World Resources
                    Institute) a disparity that will continue to increase if
                    nothing changes. Businesses rely on water to operate and
                    grow and water scarcity presents a growing risk to
                    organizations. While most corporations have established
                    goals to reduce water use, it can be difficult to make
                    progress due to lack of tools and expertise at the facility
                    level.
                  </p>
                  <p>
                    The Ecolab Smart Water Navigator is a publicly available,
                    online tool that can help you protect your business and
                    ensure continued growth. It offers a proven methodology to
                    help organizations bridge the gap between corporate goals
                    and local action. Ecolab’s Smart Water Navigator can help
                    you by:
                  </p>
                  <ul>
                    <li>
                      Enhancing your understanding of local water risks and the
                      full value of water to your operations in financial terms
                    </li>
                    <li>
                      Providing water targets that are proportional to the local
                      water stress
                    </li>
                    <li>
                      Delivering an industry-specific smart water roadmap to
                      guide improved performance over time
                    </li>
                  </ul>
                  <hr />
                  <p>&nbsp;</p>
                  <h4>How does the Smart Water Navigator work?</h4>
                  <p>
                    The tool uses a proven, four-step process, to enhance
                    business resilience in your organization through smart water
                    management.
                  </p>
                  <p>
                    <span style={{ color: '#005775;' }}>
                      <strong>Step 1: Identify |&nbsp;</strong>
                    </span>
                    To get started on your smart water journey, the tool will
                    help you identify the role of water in your operations,
                    assess your current water use and quantify water-related
                    risks in financial terms
                  </p>
                  <p>
                    <span style={{ color: '#005775;' }}>
                      <strong>Step 2: Target |&nbsp;</strong>
                    </span>
                    Then, you’ll set sustainable water withdrawal targets for
                    your facility to manage water-use risk
                  </p>
                  <p>
                    <span style={{ color: '#005775;' }}>
                      <strong>Step 3: Implement |&nbsp;</strong>
                    </span>
                    Next, the Ecolab Smart Water Navigator will provide you with
                    a practical, industry-specific guide to implement solutions
                    that will improve water management practices at an
                    individual facility level
                  </p>
                  <p>
                    <span style={{ color: '#005775;' }}>
                      <strong>Step 4: Track |&nbsp;</strong>
                    </span>
                    In the final step, you can track your progress over time and
                    revisit your smart water action plan to make adjustments
                    based on changing circumstances.
                    <br />
                    Using the Smart Water Navigator, you’ll have the ability to:
                  </p>
                  <ul>
                    <li>
                      Set clear targets and drive internal accountability to
                      help reach&nbsp;those targets
                    </li>
                    <li>
                      Optimize water-use performance to help ensure more
                      resilient operations
                    </li>
                    <li>
                      Build trust and credibility with stakeholders to drive
                      collective action
                    </li>
                  </ul>
                  <p>
                    And, by addressing shared water challenges, you will
                    contribute to a world with enough water for all.
                  </p>
                  <hr />
                  <p>&nbsp;</p>
                  <h4>
                    How is the Smart Water Navigator different from other
                    publicly available water tools?
                  </h4>
                  <p>
                    Several water frameworks and tools provide a good starting
                    point for quantitative and systematic assessments of water
                    risk. These include the World Resource Institute’s Aqueduct
                    Tool and the World Wildlife Fund’s Water Risk Filter. The
                    Smart Water Navigator is the first tool to place a monetary
                    value on water quantity and water quality risks and provide
                    users with an action plan to help mitigate and adapt to
                    those risks. The Smart Water Navigator leverages two tools,
                    the Water Risk Monetizer and the Water Action Assessment, to
                    help users along their smart water journey.
                  </p>
                  <p>
                    The&nbsp;
                    <Link to="/about">Water Risk Monetizer</Link>, as part of
                    Ecolab’s Smart Water Navigator platform, is a publicly
                    available global water risk tool that uses best-in-class
                    local water basin datasets and scientific methodologies to
                    monetize business water risks. The Water Risk Monetizer
                    defines water quantity and water quality risks in financial
                    terms to estimate potential risk-related increases in
                    operating costs and possible loss of revenue their
                    businesses.
                  </p>
                  <p>
                    Meanwhile the&nbsp;
                    <Link to="/waa">Water Action Assessment</Link>
                    &nbsp;provides users with a high-level overview of where
                    they land on the&nbsp;
                    <Link to="/waa/about">Water Maturity Curve</Link>
                    &nbsp;and detailed action steps they can take to improve
                    their water management practices. One way to improve water
                    management practices is to make the business case for
                    projects or technologies that help them better manage water.
                    In other words, the Water Action Assessment is a tool that
                    can help users identify how they are doing in terms of smart
                    water management and the Water Risk Monetizer can help users
                    make the business case for change.
                  </p>
                  <hr />
                  <p>&nbsp;</p>
                  <h4>
                    Why is the Smart Water Navigator vital to addressing global
                    water challenges?
                  </h4>
                  <p>
                    Global water scarcity, a function of quantity (availability)
                    and quantity of clean, freshwater threatens business
                    vitality and demands action. The current cost of water does
                    not account for real and future risks, making it hard to
                    justify business decisions to reduce use. In addition,
                    decreasing water availability and declining water quality
                    factors make it harder for businesses to access the water
                    they need to operate, which puts revenue at risk. Through
                    the&nbsp;
                    <Link to="/about">Water Risk Monetizer</Link>, the Smart
                    Water Navigator provides valuable, actionable information
                    including risk-adjusted water prices for incoming and
                    outgoing water and calculation of potential revenue at risk.
                    This helps businesses better understand risks and potential
                    impacts so they can make more informed decisions around
                    strategies and investments to mitigate water risks and
                    enable growth.
                  </p>
                  <p>
                    And the Smart Water Navigator’s&nbsp;
                    <Link to="/waa">Water Action Assessment</Link>
                    &nbsp;can help guide corporate and facility managers to
                    reduce water use and utilize this valuable resource for
                    long-term revenue growth. Managing a site’s water resources
                    sustainably can result in reduced operating costs, reduced
                    utility bills, reduced liability risk and occasionally
                    increased reputational benefits. As competition for clean
                    water increases, so will water-related
                    expenditures.&nbsp;The Smart Water Navigator is designed to
                    support assessment of a site’s current state of water
                    performance, benchmark its performance over time, and help
                    improve water performance by providing customized resources.
                  </p>
                  <hr />
                  <p>&nbsp;</p>
                  <h4>
                    Who will benefit from use of the Smart Water Navigator?
                  </h4>
                  <p>
                    The tool is designed for use by a range of business
                    decision-makers, including:
                  </p>
                  <ul>
                    <li>
                      Corporate decision-makers – individuals and teams who set
                      enterprise business strategy (supply chain, operations,
                      quality, engineering, sustainability)
                    </li>
                    <li>
                      Facility and operations managers (site level) –
                      individuals and teams responsible for improving
                      performance at the local level
                    </li>
                    <li>
                      Business consultants – third-party experts who help shape
                      corporate strategy and decision-making
                    </li>
                    <li>
                      Nongovernmental organizations (NGOs) – organizations that
                      influence global water stewardship practices
                    </li>
                  </ul>
                  <p>
                    Whether you input data for one facility, or a portfolio of
                    facilities (using the Batch Upload Template Excel files),
                    the Ecolab Smart Water Navigator can help guide your
                    organization and facilities to consider the full value of
                    water, develop a plan to execute appropriate strategies to
                    mitigate risk, and link water management practices to
                    long-term revenue growth.
                  </p>
                  <hr />
                  <p>&nbsp;</p>
                  <h4>
                    How do I share outputs from the Smart Water Navigator?
                  </h4>
                  <p>
                    There are options to download outputs as reports in .csv
                    (comma separated values) or PDF formats. To share this
                    information with someone else, just download the .csv or PDF
                    report.
                  </p>
                  <p>
                    There is also the option to invite others to use the Ecolab
                    Smart Water Navigator. This will not enable them to view
                    your facility Dashboard. Other users in the same company
                    with the same company email address will be able to view
                    Water Action Assessment benchmarking data for the same
                    company, but will not be able to view data for individual
                    facilities.
                  </p>
                  <hr />
                  <p>&nbsp;</p>
                  <h4>
                    How do I know the data I input into the Smart Water
                    Navigator is kept confidential?
                  </h4>
                  <p>
                    All accounts are private and other users do not have access
                    to information in individual accounts. Please view
                    Ecolab’s&nbsp;
                    <a href="https://www.ecolab.com/privacy-policy">
                      Privacy Policy&nbsp;
                    </a>
                    for more information.
                  </p>
                  <hr />
                  <p>&nbsp;</p>
                  <h4>
                    Who can I reach out to if I have other questions or need
                    help?
                  </h4>
                  <p>
                    For more information, you can download complete
                    methodologies for both the&nbsp;
                    <Link to="/about">Water Risk Monetizer</Link>
                    &nbsp;and&nbsp;
                    <Link to="/waa">Water Action Assessment</Link>, including
                    data sources and key assumptions. And, Ecolab’s
                    sustainability team is here to help support you: please
                    reach out to&nbsp;SmartWaterNavigator@ecolab.com.
                  </p>
                  <hr />
                </div>
              </article>
            </main>
          </div>
        </div>
      </div>
    </>
  );
};

Faq.displayName = 'Faq';
