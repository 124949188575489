import React, { FC, SyntheticEvent, useState } from 'react';
import classnames from 'classnames/bind';
import styles from './Folders.module.css';
import { useQueryClient, useQuery } from 'react-query';
import { deleteFolder, getFolders } from 'src/clients/api/folder';
import { useDeepCompareMemo } from 'src/hooks/useDeepCompare';
import { LoadingSpinner } from 'src/components/LoadingSpinner';
import { BlockError } from 'src/components/BlockError';
import { Copy } from 'src/components/Copy';
import { Button } from 'src/components/Button';
import { Icon } from '@mdi/react';
import {
  mdiChevronDown,
  mdiFileEditOutline,
  mdiClose,
  mdiPlusCircleOutline,
} from '@mdi/js';
import { Folder } from 'src/types/folder';
import { ConfirmDialog } from '../../../../components/ConfirmDialog';
import { removeFacilityFolderAssociation } from 'src/clients/api/facilty';
import { FolderCreateDialog } from '../../components/FolderCreateDialog';
import { useHistory } from 'react-router-dom';
import { useFilterAndSort } from 'src/hooks/useFilterAndSort';
import { useAsyncState } from 'src/hooks/useAsyncState';
import { Alert } from 'src/components/Alert';

const cx = classnames.bind(styles);

type FoldersListItemProps = {
  folder: Folder;
};
const FoldersListItem: FC<FoldersListItemProps> = ({ folder }) => {
  const history = useHistory();
  const queryClient = useQueryClient();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [openDialog, setOpenDialog] = useState<string | undefined>();
  const [asyncState, setAsyncState] = useAsyncState();
  const { setFilterState } = useFilterAndSort();

  const handleSubmitDelete = async () => {
    try {
      setAsyncState({ status: 'loading' });
      const result = await deleteFolder(folder.folderId);

      if (result.status !== 'success') {
        throw Error(result.value.errors[0].message);
      }

      setAsyncState({
        status: 'success',
        message: 'Folder deleted successfully!',
      });

      queryClient.refetchQueries('folders');
      queryClient.refetchQueries('facilities');
    } catch (e) {
      setAsyncState({
        status: 'error',
        message: e.message ?? 'There was an error deleting your folder.',
      });
    }
  };

  const handleSubmitFolderRemove = async (facilityId: number) => {
    try {
      setAsyncState({ status: 'loading' });
      const result = await removeFacilityFolderAssociation(
        facilityId,
        folder.folderId
      );

      if (result.status !== 'success') {
        throw Error(result.value.errors[0].message);
      }

      setAsyncState({
        status: 'success',
        message: 'Facility removed successfully!',
      });

      queryClient.invalidateQueries('facilities');
      queryClient.invalidateQueries('folders');
    } catch (e) {
      setAsyncState({
        status: 'error',
        message: e.message ?? 'There was an error removing your folder.',
      });
    }
  };

  return (
    <li className={cx('listItem')}>
      <div className={cx('edit')}>
        <div className={cx('link')}>
          <Button
            className={cx('basicButton')}
            onClick={(e: SyntheticEvent) => {
              e.stopPropagation();
              setOpenDialog(`edit:${folder.folderId}`);
            }}
          >
            <Icon path={mdiFileEditOutline} size="18" />
          </Button>
          <FolderCreateDialog
            isOpen={openDialog === `edit:${folder.folderId}`}
            onSave={() => {
              queryClient.refetchQueries('folders');
              setOpenDialog(undefined);
            }}
            onClose={() => setOpenDialog(undefined)}
            folder={folder}
          />
        </div>
      </div>

      <div className={cx('content')}>
        <div className={cx('titleBar')} onClick={() => setIsOpen(!isOpen)}>
          <span className={cx('title')}>{folder.folderName}</span>
          <div className={cx('titleBarSecondary')}>
            <Icon
              path={mdiChevronDown}
              className={cx('icon', { open: isOpen })}
            />
            <Button
              className={cx('basicButton', 'folderDelete')}
              onClick={(e: SyntheticEvent) => {
                e.stopPropagation();
                setOpenDialog('delete');
              }}
            >
              <Icon path={mdiClose} className={cx('icon')} />
            </Button>
            <ConfirmDialog
              variant="danger"
              isOpen={openDialog === 'delete'}
              isLoading={asyncState.status === 'loading'}
              title="Delete Folder"
              description={`Are you sure you want to delete ${folder.folderName}?`}
              onClose={() => setOpenDialog(undefined)}
              onConfirm={handleSubmitDelete}
            >
              <Copy as="p">
                This will delete the folder. <br />
                Facilities will still be available in your dashboard.
              </Copy>
            </ConfirmDialog>
          </div>
        </div>
        {isOpen ? (
          <div className={cx('extended')}>
            {folder.folderFacilities.length > 0 ? (
              <>
                {folder.folderFacilities.map((f) => (
                  <div className={cx('sublistItem')} key={f.facilityId}>
                    <span>
                      <strong>{f.facilityName}</strong> - {f.facilityCity},{' '}
                      {f.facilityState}, {f.facilityCountry}
                      {f.facilityLabel ? ` | ${f.facilityLabel}` : ''}
                    </span>

                    <Button
                      className={cx('basicButton', 'facilityRemove')}
                      onClick={(e: SyntheticEvent) => {
                        e.stopPropagation();
                        setOpenDialog(`remove:${f.facilityId}`);
                      }}
                    >
                      <Icon path={mdiClose} className={cx('icon')} />
                    </Button>
                    <ConfirmDialog
                      variant="danger"
                      isOpen={openDialog === `remove:${f.facilityId}`}
                      isLoading={asyncState.status === 'loading'}
                      title="Remove facility from folder"
                      description="This action will remove the folder association from this facility"
                      onClose={() => setOpenDialog(undefined)}
                      onConfirm={() => handleSubmitFolderRemove(f.facilityId)}
                    />
                  </div>
                ))}
                <div className={cx('folderActions')}>
                  <Button
                    onClick={() => {
                      setFilterState!('folderId', `${folder.folderId}`);
                      history.push('/wrm/dashboard');
                    }}
                  >{`View all facilities in ${folder.folderName} on the dashboard`}</Button>
                </div>
              </>
            ) : (
              <Copy as="p" className={cx('emptySublist')}>
                No associated facilities found
              </Copy>
            )}
          </div>
        ) : null}
      </div>
    </li>
  );
};

type FoldersProps = {};
export const Folders: FC<FoldersProps> = () => {
  const queryClient = useQueryClient();
  const [isCreatingFolder, setIsCreatingFolder] = useState<boolean>(false);
  const [sortState, setSortState] = useState<'asc' | 'desc'>('asc');

  const { isLoading, isFetching, data, error, refetch } = useQuery(
    ['folders'],
    () => getFolders()
  );

  const folders =
    data?.status === 'success' && data?.value.result ? data?.value.result : [];

  const sortedFolders = useDeepCompareMemo(() => {
    return [...folders].sort((a, b) => {
      const aProp = a.folderName;
      const bProp = b.folderName;
      if (aProp && bProp) {
        if (aProp < bProp) {
          return -1;
        }
        if (aProp > bProp) {
          return 1;
        }
      }

      return 0;
    });
  }, [folders]);

  const resortedFolders =
    sortState === 'asc' ? sortedFolders : [...sortedFolders].reverse();

  if (isLoading) {
    return <LoadingSpinner block />;
  }

  if (error || !data || (data.status === 'error' && data.code !== 404)) {
    return (
      <BlockError
        retry={refetch}
        isLoading={isFetching}
        title="Error loading folders"
        message="We were unable to load your folders successfully"
      />
    );
  }

  const handleSortChange = () => {
    setSortState(sortState === 'asc' ? 'desc' : 'asc');
  };

  return (
    <div className={cx('folders')}>
      <Copy as="h2">Folders List</Copy>
      <button
        className={cx('basicButton', 'addFolder')}
        onClick={() => setIsCreatingFolder(true)}
      >
        <Icon
          path={mdiPlusCircleOutline}
          size="24"
          style={{ marginRight: '5px' }}
        />
        <span>ADD NEW FOLDER</span>
      </button>
      {isCreatingFolder ? (
        <FolderCreateDialog
          isOpen={isCreatingFolder}
          onSave={() => {
            queryClient.refetchQueries('folders');
            setIsCreatingFolder(false);
          }}
          onClose={() => setIsCreatingFolder(false)}
        />
      ) : null}

      <header className={cx('actions')}>
        <div className={cx('sort')}>
          <Button
            onClick={handleSortChange}
            className={cx('basicButton', 'sortButton')}
          >
            Name
            <Icon
              path={mdiChevronDown}
              className={cx('icon', { open: sortState === 'desc' })}
            />
          </Button>
        </div>
      </header>
      <ul className={cx('list')}>
        {resortedFolders.map((f) => (
          <FoldersListItem folder={f} key={f.folderId} />
        ))}
        {data.code === 404 ? (
          <Alert variant="info" message="No folders found." />
        ) : null}
      </ul>
    </div>
  );
};

Folders.displayName = 'Folders';
