import { yupResolver } from '@hookform/resolvers/yup';
import classnames from 'classnames/bind';
import React, { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Link, RouteComponentProps, useHistory } from 'react-router-dom';
import { animated, config, useSpring } from 'react-spring';
import {
  RegisterFormSchemaPayload,
  registrationFormSchema,
} from 'src/clients/auth';
import { Alert } from 'src/components/Alert';
import { Button } from 'src/components/Button';
import { CheckboxInput } from 'src/components/CheckboxInput';
import { Copy } from 'src/components/Copy';
import { RouteContentScrollable } from 'src/components/RouteContentScrollable';
import { SelectInput } from 'src/components/SelectInput';
import { TextInput } from 'src/components/TextInput';
import { COUNTRY_MAP } from 'src/constants/countries';
import { useAsyncState } from 'src/hooks/useAsyncState';
import { useAuth } from 'src/hooks/useAuth';
import styles from './Registration.module.css';

const cx = classnames.bind(styles);

const RESOLVER = yupResolver<RegisterFormSchemaPayload>(registrationFormSchema);

type RegistrationPropsType = RouteComponentProps<
  {},
  {},
  {
    referrer: string;
  }
>;

export const Registration: FC<RegistrationPropsType> = () => {
  const history = useHistory();
  const { register: registerUser } = useAuth();

  const { handleSubmit, errors, register, control } = useForm<
    RegisterFormSchemaPayload
  >({
    resolver: RESOLVER,
    mode: 'onChange',
  });

  const [state, setState] = useAsyncState();

  const onSubmit = async (form: RegisterFormSchemaPayload) => {
    if (registerUser) {
      try {
        setState({
          status: 'loading',
        });

        const result = await registerUser(form);

        if (result.status === 'error') {
          throw new Error(result.value.errors[0].message);
        }

        setState({
          status: 'success',
          message: 'Registration successful. Please log in.',
        });
      } catch (e) {
        setState({
          status: 'error',
          message: e.message ?? 'There was an error with your request.',
        });
      }
    }
  };

  const animate = useSpring({
    opacity: 1,
    transform: 'translate(0px, 0px)',
    from: { opacity: 0, transform: 'translate(0px, -10px)' },
    config: config.molasses,
  });

  return (
    <RouteContentScrollable className={cx('scrollable')}>
      <div>
        <article className={cx('registration')} data-testid="registration">
          {state.status !== 'success' && (
            <aside>
              <div className={cx('sidearm')}>
                <Copy color="white" as="h2">
                  Welcome to the Smart Water Navigator
                </Copy>
                <p>Already have an account?</p>
                <Button
                  light
                  variant="secondary"
                  onClick={() => history.push('/login')}
                >
                  Login
                </Button>
              </div>
            </aside>
          )}

          <section>
            <div className={cx('registrationBox')}>
              <Copy as="h2">Create account</Copy>

              {state.status === 'success' ? (
                <animated.div style={animate}>
                  <Alert
                    variant="success"
                    message="Your registration was successful. Please login."
                    className={cx('success')}
                  />

                  <Link to={'/login'}>
                    <Button>Login</Button>
                  </Link>
                </animated.div>
              ) : (
                <>
                  {state.status === 'error' && (
                    <div className={cx('error')}>
                      <animated.div style={animate}>
                        <Alert
                          variant="danger"
                          message={
                            state.message ??
                            'There was an error registering your account. Please try again.'
                          }
                        />
                      </animated.div>
                    </div>
                  )}
                  <form
                    onSubmit={handleSubmit(onSubmit)}
                    className={cx('registrationForm')}
                  >
                    <TextInput
                      type="text"
                      name="firstName"
                      ref={register}
                      placeholder="First name"
                      autoComplete="given-name"
                      error={errors.firstName}
                      className={cx('input')}
                    />

                    <TextInput
                      type="text"
                      name="lastName"
                      ref={register}
                      placeholder="Last name"
                      autoComplete="family-name"
                      error={errors.lastName}
                      className={cx('input')}
                    />

                    <TextInput
                      type="text"
                      name="companyName"
                      ref={register}
                      placeholder="Company name"
                      autoComplete="organization"
                      error={errors.companyName}
                      className={cx('input')}
                    />

                    <TextInput
                      type="text"
                      name="jobTitle"
                      ref={register}
                      placeholder="Job title"
                      autoComplete="organization-title"
                      error={errors.jobTitle}
                      className={cx('input')}
                    />

                    <Controller
                      name="country"
                      control={control}
                      render={(props) => (
                        <SelectInput
                          {...props}
                          error={errors.country}
                          className={cx('input')}
                          placeholder="Country"
                          items={Object.entries(COUNTRY_MAP).map(
                            ([abbr, name]) => ({
                              label: name,
                              value: abbr,
                            })
                          )}
                        />
                      )}
                    />

                    <TextInput
                      type="email"
                      name="email"
                      ref={register}
                      placeholder="Email"
                      autoComplete="email"
                      error={errors.email}
                      className={cx('input')}
                    />

                    <TextInput
                      type="password"
                      name="password"
                      ref={register}
                      placeholder="Password"
                      autoComplete="new-password"
                      error={errors.password}
                      className={cx('input')}
                    />

                    <TextInput
                      type="password"
                      name="confirmPassword"
                      ref={register}
                      placeholder="Confirm password"
                      autoComplete="new-password"
                      error={errors.confirmPassword}
                      className={cx('input')}
                    />

                    {/* @ts-ignore */}
                    <Controller
                      name="acceptTerms"
                      control={control}
                      render={(props) => (
                        <CheckboxInput
                          {...props}
                          className={cx('input')}
                          error={errors.acceptTerms}
                        >
                          I agree to the terms and conditions and acknowledge
                          that Ecolab will treat my personal data consistent
                          with the privacy policy.
                        </CheckboxInput>
                      )}
                    />

                    {/* @ts-ignore */}
                    <Controller
                      name="explicitOptIn"
                      control={control}
                      render={(props) => (
                        <CheckboxInput
                          {...props}
                          className={cx('input')}
                          error={errors.explicitOptIn}
                        >
                          By checking this box, you are agreeing that Ecolab may
                          contact you via email with promotional offers. For
                          more information about how we may use personal data to
                          send marketing communications, please refer to our
                          privacy policy.
                        </CheckboxInput>
                      )}
                    />

                    <Button
                      type="submit"
                      className={cx('buttonSubmit')}
                      isLoading={state.status === 'loading'}
                    >
                      Sign up
                    </Button>
                  </form>
                </>
              )}
            </div>
          </section>
        </article>
      </div>
    </RouteContentScrollable>
  );
};
