import * as React from 'react';
import 'isomorphic-fetch';
import '../styles/section-toggle.css';

interface SectionToggleProps {
  heading: string;
  isOpen: boolean;
  className?: string;
}

interface SectionToggleState {
  isOpen: boolean;
}

export class SectionToggle extends React.Component<
  SectionToggleProps,
  SectionToggleState
> {
  public static defaultProps: Partial<SectionToggleProps> = {
    className: '',
  };

  constructor(props: SectionToggleProps) {
    super(props);
    this.state = {
      isOpen: props.isOpen,
    };
    this.handleToggle = this.handleToggle.bind(this);
  }

  handleToggle() {
    this.setState((previousState) => {
      return {
        isOpen: !previousState.isOpen,
      };
    });
  }

  public render() {
    return (
      <section className={'col-md-12 ' + this.props.className}>
        <div className="row">
          <div
            className="col-12 section-toggle black-text text-left"
            onClick={this.handleToggle}
          >
            <div className="row">
              <div className="col-xs-10 col-md-11">
                <h2>{this.props.heading}</h2>
              </div>
              <div className="col-xs-2 col-md-1">
                <button
                  className={
                    'glyphicon glyphicon-triangle-' +
                    (this.state.isOpen ? 'top' : 'bottom')
                  }
                ></button>
              </div>
            </div>
          </div>
          <div
            className={
              'col-12 section-toggle-content' +
              (this.state.isOpen ? '' : ' hidden')
            }
          >
            {this.props.children}
          </div>
        </div>
      </section>
    );
  }
}
