import React, { useState } from 'react';
import { useQueryClient } from 'react-query';
import {
  FetchUserResponseType,
  ForgotPasswordResponseType,
  LoginResponseType,
  RegisterResponseType,
  ResetPasswordResponseType,
  VerifyAccountResponseType,
} from 'src/clients/auth/auth';

import { Api$Task } from 'src/clients/types';

import {
  client,
  ForgotPasswordSchemaPayload,
  LoginFormSchemaPayload,
  RegisterFormSchemaPayload,
  ResetPasswordSchemaPayload,
  VerifyAccountSchemaPayload,
} from '../clients/auth';
import {
  getAccountIdInStorage,
  getTokenInStorage,
  getUserIdInStorage,
  setAccountIdInStorage,
  setTokenInStorage,
  setUserIdInStorage,
} from '../clients/helpers';

type AuthContextMethods = {
  login: (props: LoginFormSchemaPayload) => Api$Task<LoginResponseType>;
  logout: (path?: string) => void;
  register: (
    props: RegisterFormSchemaPayload
  ) => Api$Task<RegisterResponseType>;
  forgotPassword: (
    props: ForgotPasswordSchemaPayload
  ) => Api$Task<ForgotPasswordResponseType>;
  resetPassword: (
    props: ResetPasswordSchemaPayload
  ) => Api$Task<ResetPasswordResponseType>;
  verifyAccount: (
    props: VerifyAccountSchemaPayload
  ) => Api$Task<VerifyAccountResponseType>;
  fetchUser: () => Api$Task<FetchUserResponseType>;
  switchAccounts: (id: string) => void;
};

type AuthContextPropsType = {
  isAuthenticated: boolean;
  isLoading: boolean;
  userId?: string;
  accountId?: string;
};

type AuthContextProps = AuthContextMethods & AuthContextPropsType;

const AuthContext = React.createContext<Partial<AuthContextProps>>({});

function AuthProvider(props: any) {
  const queryClient = useQueryClient();
  const [data, setData] = useState<AuthContextPropsType>({
    isAuthenticated: !!getTokenInStorage(),
    isLoading: false,
    userId: getUserIdInStorage() ?? undefined,
    accountId: getAccountIdInStorage() ?? undefined,
  });

  const login = async (form: LoginFormSchemaPayload) => {
    const response = await client.login(form);

    if (response.status === 'success') {
      setTokenInStorage(response.value.id_token);
      setData({
        ...data,
        isAuthenticated: true,
      });
    }

    return response;
  };

  const register = async (form: RegisterFormSchemaPayload) => {
    return await client.register(form);
  };

  const forgotPassword = async (form: ForgotPasswordSchemaPayload) => {
    return await client.forgotPassword(form);
  };

  const resetPassword = async (form: ResetPasswordSchemaPayload) => {
    return await client.resetPassword(form);
  };

  const verifyAccount = async (form: VerifyAccountSchemaPayload) => {
    return await client.verifyAccount(form);
  };

  const fetchUser = async () => {
    const response = await client.fetchUser();

    if (response.status === 'success') {
      const { result } = response.value;

      let accountId;

      if (
        data.accountId &&
        result.accounts.map((a) => a.accountId).includes(data.accountId)
      ) {
        accountId = data.accountId;
      } else {
        accountId = result.accounts[0].accountId;
      }

      setData({
        ...data,
        userId: setUserIdInStorage(`${result.userId}`) ?? undefined,
        accountId: data.accountId
          ? data.accountId
          : setAccountIdInStorage(`${accountId}`) ?? undefined,
      });

      return response;
    } else {
      throw new Error();
    }
  };

  const logout = async (path: string = '/') => {
    await client.logout();
    window.location.replace(path);
  };

  const switchAccounts = (accountId: string) => {
    if (data.accountId !== accountId) {
      setAccountIdInStorage(accountId);
      queryClient.removeQueries('folders');
      queryClient.removeQueries('facilities');
      queryClient.removeQueries('facilitiesList');
      setData({
        ...data,
        accountId: accountId,
      });
    }
  };

  return (
    <AuthContext.Provider
      value={{
        ...data,
        register,
        forgotPassword,
        resetPassword,
        login,
        logout,
        verifyAccount,
        fetchUser,
        switchAccounts,
      }}
      {...props}
    />
  );
}

const useAuth = () => React.useContext(AuthContext);

export { AuthProvider, useAuth };
