import { ProcessedFacility } from 'src/types/processedFacility';
import { asCommaSeparatedString } from 'src/utils/number';
import { CalculatorResults } from './CalculatorResults';

export type CalculatorPdfRequestDataType = {
  facilityName: string;
  facilityCity: string;
  facilityState: string;
  facilityCountry: string;
  projectName: string;
  date: string;
  capitalInvestmentAmount: string;
  capitalInvestmentYear: number;
  waterSavedPerYearAmount: string;
  waterSavedPerYearUnit: string;
  discountRate: string;
  projectedWaterCostIncreaseIncoming: string;
  projectedWaterCostIncreaseOutgoing: string;
  operatingExpenses: Array<any>;
  operatingSavings: Array<any>;
  incomingWaterCostPerUnit: string;
  outgoingWaterCostPerUnit: string;
  combinedWaterCostPerUnit: string;
  totalOperationalExpenses: string;
  totalOperationalSavings: string;
  incomingQuantityRiskAdjustedPricePerUnit: string;
  outgoingRiskAdjustedWaterPricePerUnit: string;
  inAndOutTotalRiskAdjustedPrice: string;
  estimatedAnnualWaterReduction: string;
  annualWaterReductionImpact: string;
  irrYear: string;
  netPresentYear: string;
  conventionalPaybackPeriod: string;
  riskAdjustedPaybackPeriod: string;
  year2NPVConventional: string;
  year5NPVConventional: string;
  year10NPVConventional: string;
  year20NPVConventional: string;
  year2NPVRiskAdjusted: string;
  year5NPVRiskAdjusted: string;
  year10NPVRiskAdjusted: string;
  year20NPVRiskAdjusted: string;
  year2IRRConventional: string;
  year5IRRConventional: string;
  year10IRRConventional: string;
  year20IRRConventional: string;
  year2IRRRiskAdjusted: string;
  year5IRRRiskAdjusted: string;
  year10IRRRiskAdjusted: string;
  year20IRRRiskAdjusted: string;
  svgChart: string;
};

export function mapCalculatorPdfRequestData(
  facility: ProcessedFacility,
  calculatorResults: CalculatorResults,
  irrYear: string,
  npvYear: string,
  waterUnit: string,
  paybackPeriodChartString: string
): CalculatorPdfRequestDataType {
  return {
    facilityName: facility.name,
    facilityCity: facility.cityName,
    facilityState: facility.stateName,
    facilityCountry: facility.countryName,
    projectName: calculatorResults.investmentName,
    date: new Date().toLocaleDateString(),
    capitalInvestmentAmount: formatValueForExport(
      calculatorResults.initialInvestment
    ),
    capitalInvestmentYear: calculatorResults.initialYear,
    waterSavedPerYearAmount: formatValueForExport(calculatorResults.waterSaved),
    waterSavedPerYearUnit: waterUnit,
    discountRate: formatValueForExport(calculatorResults.discountRate),
    projectedWaterCostIncreaseIncoming: formatValueForExport(
      calculatorResults.incomingIncrease
    ),
    projectedWaterCostIncreaseOutgoing: formatValueForExport(
      calculatorResults.outgoingIncrease
    ),
    operatingExpenses: calculatorResults.expenses.reduce((acc, exp) => {
      if (exp.amount != null) {
        exp.amount = formatValueForExport(exp.amount, 2);
        return [...acc, exp];
      }
      return acc;
    }, []),
    operatingSavings: calculatorResults.savings.reduce((acc, sav) => {
      if (sav.amount != null) {
        sav.amount = formatValueForExport(sav.amount, 2);
        return [...acc, sav];
      }
      return acc;
    }, []),
    incomingWaterCostPerUnit: formatValueForExport(
      facility.incomingWaterUnitPrice,
      2
    ),
    outgoingWaterCostPerUnit: formatValueForExport(
      facility.outgoingWaterUnitPrice,
      2
    ),
    combinedWaterCostPerUnit: formatValueForExport(
      facility.outgoingWaterUnitPrice + facility.incomingWaterUnitPrice,
      2
    ),
    totalOperationalExpenses: formatValueForExport(
      calculatorResults.expensesTotal,
      2
    ),
    totalOperationalSavings: formatValueForExport(
      calculatorResults.savingsTotal,
      2
    ),
    incomingQuantityRiskAdjustedPricePerUnit: formatValueForExport(
      calculatorResults.incomingPriceRiskAdjusted,
      2
    ),
    outgoingRiskAdjustedWaterPricePerUnit: formatValueForExport(
      calculatorResults.outgoingPriceRiskAdjusted,
      2
    ),
    inAndOutTotalRiskAdjustedPrice: formatValueForExport(
      calculatorResults.combinedPriceRiskAdjusted,
      2
    ),
    estimatedAnnualWaterReduction: formatValueForExport(
      calculatorResults.waterSaved
    ),
    annualWaterReductionImpact: formatValueForExport(
      calculatorResults.waterReductionImpact,
      2
    ),
    irrYear: irrYear,
    netPresentYear: npvYear,
    conventionalPaybackPeriod: formatValueForExport(
      calculatorResults.paybackPeriodConventional,
      2
    ),
    riskAdjustedPaybackPeriod: formatValueForExport(
      calculatorResults.paybackPeriodRiskAdjusted,
      2
    ),
    year2NPVConventional: formatValueForExport(
      calculatorResults.npvArrayConventional[2],
      2
    ),
    year5NPVConventional: formatValueForExport(
      calculatorResults.npvArrayConventional[5],
      2
    ),
    year10NPVConventional: formatValueForExport(
      calculatorResults.npvArrayConventional[10],
      2
    ),
    year20NPVConventional: formatValueForExport(
      calculatorResults.npvArrayConventional[20],
      2
    ),
    year2NPVRiskAdjusted: formatValueForExport(
      calculatorResults.npvArrayRiskAdjusted[2],
      2
    ),
    year5NPVRiskAdjusted: formatValueForExport(
      calculatorResults.npvArrayRiskAdjusted[5],
      2
    ),
    year10NPVRiskAdjusted: formatValueForExport(
      calculatorResults.npvArrayRiskAdjusted[10],
      2
    ),
    year20NPVRiskAdjusted: formatValueForExport(
      calculatorResults.npvArrayRiskAdjusted[20],
      2
    ),
    year2IRRConventional: formatValueForExport(
      calculatorResults.twoYearIRRConventional,
      2
    ),
    year5IRRConventional: formatValueForExport(
      calculatorResults.fiveYearIRRConventional,
      2
    ),
    year10IRRConventional: formatValueForExport(
      calculatorResults.tenYearIRRConventional,
      2
    ),
    year20IRRConventional: formatValueForExport(
      calculatorResults.twentyYearIRRConventional,
      2
    ),
    year2IRRRiskAdjusted: formatValueForExport(
      calculatorResults.twoYearIRRRiskAdjusted,
      2
    ),
    year5IRRRiskAdjusted: formatValueForExport(
      calculatorResults.fiveYearIRRRiskAdjusted,
      2
    ),
    year10IRRRiskAdjusted: formatValueForExport(
      calculatorResults.tenYearIRRRiskAdjusted,
      2
    ),
    year20IRRRiskAdjusted: formatValueForExport(
      calculatorResults.twentyYearIRRRiskAdjusted,
      2
    ),
    svgChart: paybackPeriodChartString,
  };
}

function formatValueForExport(
  value: string | number | null,
  precision = 0
): string {
  try {
    if (!value || value === 'ERR') {
      throw new Error();
    }

    const valueAsNumber = parseFloat(`${value}`);

    return asCommaSeparatedString(valueAsNumber, precision);
  } catch (e) {
    return '';
  }
}
