import { Env } from 'src/constants/env';
import request from '../request';
import { WrappedResponse } from '../types';

export type InviteUserRequestType = {
  accountId: string;
  email: string;
  externalInvite: boolean;
  consolidateAccount: boolean;
  internalInvite: boolean;
};
export type InviteUserResponseType = WrappedResponse<unknown>;
export function inviteUser(accountId: string, data: InviteUserRequestType) {
  return request.post<InviteUserResponseType, InviteUserRequestType>(
    `${Env.apiUrl}/v4/accounts/${accountId}/invitations`,
    data
  );
}

export type GetInviteResponseType = WrappedResponse<{
  userName: string;
  accountName: string;
}>;
export function getInvite(accountId: string, invitationId: string) {
  return request.get<GetInviteResponseType>(
    `${Env.apiUrl}/v4/accounts/${accountId}/invitations/${invitationId}`
  );
}

export type AcceptInviteRequestType = {
  accepted: boolean;
};
export type AcceptInviteResponseType = WrappedResponse<unknown>;
export function acceptInvite(
  accountId: string,
  invitationId: string,
  data: AcceptInviteRequestType
) {
  return request.post<AcceptInviteResponseType, AcceptInviteRequestType>(
    `${Env.apiUrl}/v4/accounts/${accountId}/invitations/${invitationId}`,
    data
  );
}

export type DeleteUserRequestType = WrappedResponse<unknown>;
export function deleteUserFromAccount(accountId: string, userId: string) {
  return request.del<DeleteUserRequestType>(
    `${Env.apiUrl}/v4/accounts/${accountId}/users/${userId}`
  );
}
