import React, { FC } from 'react';
import classnames from 'classnames/bind';

import styles from './AccountSelector.module.css';
import { useQuery } from 'react-query';
import { getUserAccounts } from 'src/clients/api/user';
import { useAuth } from 'src/hooks/useAuth';
import { SelectInput } from '../SelectInput';

const cx = classnames.bind(styles);

type AccountSelectorProps = {
  hideWhileSingle?: boolean;
};

export const AccountSelector: FC<AccountSelectorProps> = ({
  hideWhileSingle,
}) => {
  const { switchAccounts, accountId, userId } = useAuth();

  const { data: accounts } = useQuery(
    ['user', userId!, 'accounts'],
    () => getUserAccounts(userId!),
    {
      enabled: !!userId,
    }
  );

  const handleSwitch = (value: string) => {
    switchAccounts!(value);
  };

  if (accounts?.status === 'success') {
    if (hideWhileSingle && accounts.value.result.length === 1) {
      return null;
    }

    return (
      <div className={cx('accountSelector')}>
        <small>Switch accounts:</small>
        <SelectInput
          items={accounts.value.result.map((a) => ({
            label: a.companyName,
            value: a.accountId,
          }))}
          value={accountId ?? ''}
          onChange={handleSwitch}
        />
      </div>
    );
  }

  return null;
};

AccountSelector.displayName = 'AccountSelector';
