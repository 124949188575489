import classnames from 'classnames/bind';
import React, { FC } from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { animated, useSpring } from 'react-spring';
import { acceptInvite, getInvite } from 'src/clients/api/account';
import { Alert } from 'src/components/Alert';
import { BlockError } from 'src/components/BlockError';
import { Button } from 'src/components/Button';
import { Copy } from 'src/components/Copy';
import { LoadingSpinner } from 'src/components/LoadingSpinner';
import { RouteContentScrollable } from 'src/components/RouteContentScrollable';
import { FADE_IN_DOWN } from 'src/constants/animations';
import { useAsyncState } from 'src/hooks/useAsyncState';
import { useQueryParams } from 'src/hooks/useQueryParams';
import styles from './Invitation.module.css';

const cx = classnames.bind(styles);

export interface InvitationProps extends React.HTMLAttributes<HTMLDivElement> {}

export const Invitation: FC<InvitationProps> = () => {
  const params = useQueryParams();
  const accountId = params.get('aid');
  const invitationId = params.get('inv');

  const [state, setState] = useAsyncState();

  const { isLoading, error, data, refetch, isFetching } = useQuery(
    ['invite', accountId, invitationId],
    () => getInvite(accountId!, invitationId!),
    {
      enabled: !!(accountId && invitationId),
    }
  );

  const animate = useSpring(FADE_IN_DOWN);

  const onSubmit = async (accepted: boolean) => {
    if (accountId && invitationId) {
      try {
        setState({
          status: 'loading',
        });

        const result = await acceptInvite(accountId, invitationId, {
          accepted,
        });

        if (result.status === 'error') {
          throw new Error(result.value.errors[0].message);
        }

        setState({
          status: 'success',
          message: `Invitation ${
            accepted ? 'accepted' : 'declined'
          } successfully.`,
        });
      } catch (e) {
        setState({
          status: 'error',
          message:
            e.message ??
            `There was an error ${
              accepted ? 'accepting' : 'declining'
            } this invitation.`,
        });
      }
    }
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (error || !data || data.status === 'error') {
    return (
      <BlockError
        retry={refetch}
        isLoading={isFetching}
        title="Error loading invitation"
        message="We were unable to load your invitation successfully"
      />
    );
  }

  return (
    <RouteContentScrollable className={cx('scrollable')}>
      <div>
        <article className={cx('pendingInvite')} data-testid="pendingInvite">
          <section>
            <div className={cx('pendingInviteBox')}>
              <h2>Pending Invitation</h2>
              {state.status === 'success' ? (
                <animated.div style={animate}>
                  <Alert
                    variant="success"
                    message={state.message}
                    className={cx('success')}
                  />

                  <Link to={'/login'}>
                    <Button>Login</Button>
                  </Link>
                </animated.div>
              ) : (
                <>
                  {state.status === 'error' && (
                    <div className={cx('error')}>
                      <animated.div style={animate}>
                        <Alert
                          variant="danger"
                          message={
                            state.message ??
                            'There was an error resetting your password. Please try again.'
                          }
                        />
                      </animated.div>
                    </div>
                  )}

                  <div>
                    <Copy>
                      You have been invited by{' '}
                      <strong>
                        {data.value.result.userName} (
                        {data.value.result.accountName})
                      </strong>{' '}
                      to join Smart Water Navigator.
                    </Copy>
                    <div className={cx('actions')}>
                      <Button
                        variant="secondary"
                        onClick={() => onSubmit(false)}
                        isLoading={state.status === 'loading'}
                      >
                        No
                      </Button>
                      <Button
                        variant="primary"
                        onClick={() => onSubmit(true)}
                        isLoading={state.status === 'loading'}
                      >
                        Yes
                      </Button>
                    </div>
                  </div>
                </>
              )}
            </div>
          </section>
        </article>
      </div>
    </RouteContentScrollable>
  );
};

Invitation.displayName = 'Invitation';
