import { WrappedResponse } from '../types';
import * as request from '../request';
import { Env } from '../../constants/env';
import { ReferenceData } from '../../types/referenceData';

export type ReferenceDataResponseType = WrappedResponse<ReferenceData>;
export function getReferenceData() {
  return request.get<ReferenceDataResponseType>(
    `${Env.apiUrl}/v4/referencedata/options`
  );
}
