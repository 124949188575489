import React, { FC } from 'react';
import classnames from 'classnames/bind';
import styles from '../Tabs.module.css';
import { Copy } from 'src/components/Copy';
import { SelectInput } from 'src/components/SelectInput';
import { Controller, useFormContext } from 'react-hook-form';
import { TextInput } from 'src/components/TextInput';
import { CheckboxInput } from 'src/components/CheckboxInput';
import { MappedReferenceData } from 'src/types/referenceData';
import { FacilityTabFooter } from '../../Helpers/FacilityTabFooter';
import { useFormHelp } from 'src/hooks/useFormHelp';
import { FormHelpButton, FormHelpText } from '../../../FormHelp';
import { REPORTING_YEARS } from 'src/constants/years';

const cx = classnames.bind(styles);

type FacilityDetailsProps = {
  selectedReportingYear: string;
  referenceData: MappedReferenceData;
  onSelectYear: (year: string) => void;
  onSave: () => void;
  onCancel: () => void;
  className?: string;
};

export const FacilityDetails: FC<FacilityDetailsProps> = ({
  selectedReportingYear,
  onSelectYear,
  referenceData,
  onSave,
  onCancel,
  className,
}) => {
  const formHelp = useFormHelp();

  const {
    control,
    formState: { errors },
    register,
    watch,
  } = useFormContext();

  const hasWaterWithdrawalReplenishment = watch('waterWithdrawalReplenishment');
  const hasWaterWithdrawalThirdPartyVerified = watch(
    'waterWithdrawalThirdPartyVerified'
  );

  const waterUnits = referenceData.waterUnits.map((u) => ({
    label: u.name,
    value: `${u.unitId}`,
  }));

  const timePeriods = referenceData.options.timePeriodOptions;

  const currencies = referenceData.currencies.map((c) => ({
    label: c.code,
    value: c.code,
  }));

  return (
    <div className={cx('tabContent', className)}>
      <form>
        <div className={cx('formGroup')}>
          <h1 className={cx('formGroupTitle')}>Reporting Year</h1>
          <div className={cx('row')}>
            <div className={cx('label')}>
              <Copy as="p" align="right">
                Reporting Year
              </Copy>
              <FormHelpButton formHelp={formHelp} name="reportingYear" />
            </div>
            <div className={cx('inputGroup')}>
              <div className={cx('input')}>
                <Controller
                  name="reportingYear"
                  control={control}
                  defaultValue={selectedReportingYear}
                  render={(props) => (
                    <SelectInput
                      {...props}
                      onChange={onSelectYear}
                      className={cx('inputField')}
                      items={REPORTING_YEARS.map((year) => ({
                        label: `${year}`,
                        value: `${year}`,
                      }))}
                      value={selectedReportingYear}
                      placeholder="&nbsp;"
                    />
                  )}
                />
              </div>
              <FormHelpText formHelp={formHelp} name="reportingYear">
                The year in which data entered refers to. This is a required
                field. Updates will be made to the tool to include additional
                reporting years as annual datasets are finalized.
              </FormHelpText>
            </div>
          </div>
        </div>
        <div className={cx('formGroup')}>
          <h1 className={cx('formGroupTitle')}>Incoming Water</h1>
          <div className={cx('row')}>
            <div className={cx('label')}>
              <Copy as="p" align="right">
                Incoming Water Time Period
              </Copy>
              <FormHelpButton
                formHelp={formHelp}
                name="incomingWaterTimePeriod"
              />
            </div>
            <div className={cx('inputGroup')}>
              <div className={cx('input')}>
                <Controller
                  name="incomingWaterTimePeriod"
                  control={control}
                  render={(props) => (
                    <SelectInput
                      {...props}
                      className={cx('inputField')}
                      items={timePeriods}
                      placeholder="&nbsp;"
                      error={errors.incomingWaterTimePeriod}
                      onChange={(val) => props.onChange(val)}
                    />
                  )}
                />
              </div>
              <FormHelpText formHelp={formHelp} name="incomingWaterTimePeriod">
                The total quantity of incoming water can be entered on a weekly,
                monthly, quarterly or annual basis. Enter the time period in
                this field; the tool will automatically calculate the annual
                amount as needed, based on the time period and volume entered in
                the following field. For example, if your facility uses 1,000 m3
                per month, please select Monthly in the 'Incoming Water Time
                Period' field and type 1000 and select m3 within the 'Incoming
                Water Quantity' fields. Data will be annualized automatically to
                12,000 m3 by the tool.
              </FormHelpText>
            </div>
          </div>
          <div className={cx('row')}>
            <div className={cx('label')}>
              <Copy as="p" align="right">
                Incoming Water Quantity
              </Copy>
              <FormHelpButton
                formHelp={formHelp}
                name="incomingWaterQuantity"
              />
            </div>
            <div className={cx('inputGroup')}>
              <div className={cx('input')}>
                <TextInput
                  type="number"
                  className={cx('inputField')}
                  name="incomingWaterQuantity"
                  ref={register}
                  error={errors.incomingWaterQuantity}
                />
                <Controller
                  name="incomingWaterQuantityUnit"
                  control={control}
                  render={(props) => (
                    <SelectInput
                      {...props}
                      className={cx('inputField')}
                      items={waterUnits.filter((u) => u.value !== '16')}
                      placeholder="&nbsp;"
                      error={errors.incomingWaterQuantityUnit}
                      onChange={(val) => props.onChange(val)}
                    />
                  )}
                />
              </div>
              <FormHelpText formHelp={formHelp} name="incomingWaterQuantity">
                The total amount of incoming water to the facility, excluding
                any recycled water. Please enter the volume as a number in the
                text field and select the correct units from the dropdown list.
                For example, to enter 1,000 m3 for the 'Incoming Water Quantity'
                field, type 1000 into the text field and select m3 from the
                dropdown list.
              </FormHelpText>
            </div>
          </div>
          <div className={cx('row')}>
            <div className={cx('label')}>
              <Copy as="p" align="right">
                Incoming Water Price
              </Copy>
              <FormHelpButton formHelp={formHelp} name="incomingWaterPrice" />
            </div>
            <div className={cx('inputGroup')}>
              <div className={cx('input')}>
                <TextInput
                  className={cx('inputField')}
                  name="incomingWaterPrice"
                  ref={register}
                  error={errors.incomingWaterPrice}
                />
                <Controller
                  name="incomingWaterPriceCurrencyCode"
                  control={control}
                  render={(props) => (
                    <SelectInput
                      {...props}
                      className={cx('inputField')}
                      items={currencies}
                      placeholder="&nbsp;"
                      error={errors.incomingWaterPriceCurrencyCode}
                      onChange={(val) => props.onChange(val)}
                    />
                  )}
                />
                <Controller
                  name="incomingWaterPriceUnit"
                  control={control}
                  render={(props) => (
                    <SelectInput
                      {...props}
                      className={cx('inputField')}
                      items={waterUnits.filter((u) => u.value !== '32')}
                      placeholder="&nbsp;"
                      error={errors.incomingWaterPriceUnit}
                      onChange={(val) => props.onChange(val)}
                    />
                  )}
                />
              </div>
              <FormHelpText formHelp={formHelp} name="incomingWaterPrice">
                Total price paid for incoming water to your facility. Exclude
                any fees paid for wastewater treatment or other charges.
              </FormHelpText>
            </div>
          </div>
          <div className={cx('row')}>
            <div className={cx('label')}>
              <Copy as="p" align="right">
                Location of Water Source
              </Copy>
            </div>
            <div className={cx('inputGroup')}>
              <div className={cx('input')}>
                <Controller
                  name="incomingWaterSource"
                  control={control}
                  render={(props) => (
                    <SelectInput
                      {...props}
                      className={cx('inputField')}
                      items={referenceData.options.locationOfWaterSourceOptions}
                      placeholder="&nbsp;"
                      error={errors.incomingWaterSource}
                      onChange={(val) => props.onChange(val)}
                    />
                  )}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={cx('formGroup')}>
          <h1 className={cx('formGroupTitle')}>Water Replenishment</h1>
          <div className={cx('row')}>
            <div className={cx('label')}>
              <Copy as="p" align="right">
                Are you replenishing any of this site’s incoming water through a
                nature-based solution within the water basin?
              </Copy>
              <FormHelpButton
                formHelp={formHelp}
                name="waterWithdrawalReplenishment"
              />
            </div>
            <div className={cx('inputGroup')}>
              <div className={cx('input')}>
                <Controller
                  name="waterWithdrawalReplenishment"
                  control={control}
                  render={(props) => (
                    <CheckboxInput
                      {...props}
                      className={cx('inputField', 'checkbox')}
                      error={errors.waterWithdrawalReplenishment}
                    />
                  )}
                ></Controller>
              </div>
              <FormHelpText
                formHelp={formHelp}
                name="waterWithdrawalReplenishment"
              >
                Replenishment is when a facility replaces water withdrawals by
                returning water back to the basin or leaving more water
                available to support the ecological health of waterways. While
                replenishment activities do not affect the water withdrawal
                risk, it is a useful metric to include when determining a site’s
                overall relationship to its water sources. If a facility has
                current replenishment projects underway, select the checkbox.
              </FormHelpText>
            </div>
          </div>
          {hasWaterWithdrawalReplenishment ? (
            <>
              <div className={cx('row')}>
                <div className={cx('label')}>
                  <Copy as="p" align="right">
                    What is the annualized volumetric benefit returned to the
                    water basin?
                  </Copy>
                  <FormHelpButton
                    formHelp={formHelp}
                    name="waterWithdrawalAnnualizedVolumetricBenefit"
                  />
                </div>
                <div className={cx('inputGroup')}>
                  <div className={cx('input')}>
                    <TextInput
                      type="number"
                      className={cx('inputField')}
                      name="waterWithdrawalAnnualizedVolumetricBenefit"
                      ref={register}
                      error={errors.waterWithdrawalAnnualizedVolumetricBenefit}
                    />
                    <Controller
                      name="waterWithdrawalAnnualizedVolumetricBenefitUOMId"
                      control={control}
                      render={(props) => (
                        <SelectInput
                          {...props}
                          className={cx('inputField')}
                          items={waterUnits.filter((u) => u.value !== '16')}
                          placeholder="&nbsp;"
                          error={
                            errors.waterWithdrawalAnnualizedVolumetricBenefitUOMId
                          }
                          onChange={(val) => props.onChange(val)}
                        />
                      )}
                    />
                  </div>
                  <FormHelpText
                    formHelp={formHelp}
                    name="waterWithdrawalAnnualizedVolumetricBenefit"
                  >
                    The total amount of annual water accounted for in
                    replenishment solutions. Please enter the annual water
                    replenishment volume as a number in the text field and
                    select the correct units from the dropdown list.
                  </FormHelpText>
                </div>
              </div>
              <div className={cx('row')}>
                <div className={cx('label')}>
                  <Copy as="p" align="right">
                    What water fund is the replenishment supporting?
                  </Copy>
                  <FormHelpButton
                    formHelp={formHelp}
                    name="waterWithdrawalSupportingReplenishmentWaterFund"
                  />
                </div>
                <div className={cx('inputGroup')}>
                  <div className={cx('input')}>
                    <TextInput
                      className={cx('inputField')}
                      name="waterWithdrawalSupportingReplenishmentWaterFund"
                      ref={register}
                      error={
                        errors.waterWithdrawalSupportingReplenishmentWaterFund
                      }
                    />
                  </div>
                  <FormHelpText
                    formHelp={formHelp}
                    name="waterWithdrawalSupportingReplenishmentWaterFund"
                  >
                    Water funds are organizations that design and promote water
                    security through nature-based solutions. If your
                    organization is partnering with a water fund on
                    replenishment solutions, enter the name of the fund.
                  </FormHelpText>
                </div>
              </div>
              <div className={cx('row')}>
                <div className={cx('label')}>
                  <Copy as="p" align="right">
                    What is the duration of the volumetric benefit of the
                    nature-based solution?
                  </Copy>
                  <FormHelpButton
                    formHelp={formHelp}
                    name="waterWithdrawalReplenishmentDurationInYears"
                  />
                </div>
                <div className={cx('inputGroup')}>
                  <div className={cx('input')}>
                    <TextInput
                      type="number"
                      className={cx('inputField')}
                      name="waterWithdrawalReplenishmentDurationInYears"
                      ref={register}
                      error={errors.waterWithdrawalReplenishmentDurationInYears}
                    />
                  </div>
                  <FormHelpText
                    formHelp={formHelp}
                    name="waterWithdrawalReplenishmentDurationInYears"
                  >
                    Nature-based replenishment solutions often have time limits
                    on the benefits attributed to the project. Given the
                    selected Reporting Year, enter how many more years the
                    benefits of the nature-based solution may be accounted for,
                    anywhere from one to 10 years. Though the duration of the
                    volumetric benefit of the nature-based solution may exceed
                    10 years, the tool only forecasts water withdrawal risk out
                    10 years from the Reporting Year.
                  </FormHelpText>
                </div>
              </div>
              <div className={cx('row')}>
                <div className={cx('label')}>
                  <Copy as="p" align="right">
                    Have you verified the replenishment amount with a third
                    party?
                  </Copy>
                  <FormHelpButton
                    formHelp={formHelp}
                    name="waterWithdrawalThirdPartyVerified"
                  />
                </div>
                <div className={cx('inputGroup')}>
                  <div className={cx('input')}>
                    <Controller
                      name="waterWithdrawalThirdPartyVerified"
                      control={control}
                      render={(props) => (
                        <CheckboxInput
                          {...props}
                          className={cx('inputField', 'checkbox')}
                          error={errors.waterWithdrawalThirdPartyVerified}
                        />
                      )}
                    ></Controller>
                  </div>
                  <FormHelpText
                    formHelp={formHelp}
                    name="waterWithdrawalThirdPartyVerified"
                  >
                    Third parties use volumetric water benefit accounting
                    methods to assess the benefits of water stewardship
                    activities in a comparable way and ensure they address
                    current or projected water challenges. If the nature-based
                    solution providing water replenishment at this site has been
                    verified by a third party, select the checkbox.
                  </FormHelpText>
                </div>
              </div>
              {hasWaterWithdrawalThirdPartyVerified ? (
                <div className={cx('row')}>
                  <div className={cx('label')}>
                    <Copy as="p" align="right">
                      What is the name of the verifier?
                    </Copy>
                    <FormHelpButton
                      formHelp={formHelp}
                      name="waterWithdrawalThirdPartyVerifier"
                    />
                  </div>
                  <div className={cx('inputGroup')}>
                    <div className={cx('input')}>
                      <TextInput
                        className={cx('inputField')}
                        name="waterWithdrawalThirdPartyVerifier"
                        ref={register}
                        error={errors.waterWithdrawalThirdPartyVerifier}
                      />
                    </div>
                    <FormHelpText
                      formHelp={formHelp}
                      name="waterWithdrawalThirdPartyVerifier"
                    >
                      If the nature-based solution providing water replenishment
                      at this site has been verified by a third party, enter the
                      name of that organization.
                    </FormHelpText>
                  </div>
                </div>
              ) : null}
            </>
          ) : null}
        </div>
        <div className={cx('formGroup')}>
          <h1 className={cx('formGroupTitle')}>Outgoing Water</h1>
          <div className={cx('row')}>
            <div className={cx('label')}>
              <Copy as="p" align="right">
                Outgoing Water Time Period
              </Copy>
              <FormHelpButton
                formHelp={formHelp}
                name="outgoingWaterTimePeriod"
              />
            </div>
            <div className={cx('inputGroup')}>
              <div className={cx('input')}>
                <Controller
                  name="outgoingWaterTimePeriod"
                  control={control}
                  render={(props) => (
                    <SelectInput
                      {...props}
                      className={cx('inputField')}
                      items={timePeriods}
                      placeholder="&nbsp;"
                      error={errors.outgoingWaterTimePeriod}
                      onChange={(val) => props.onChange(val)}
                    />
                  )}
                />
              </div>
              <FormHelpText formHelp={formHelp} name="outgoingWaterTimePeriod">
                The total quantity of outgoing water can be entered on a weekly,
                monthly, quarterly or annual basis. Enter the time period in
                this field; the tool will automatically calculate the annual
                amount as needed, based on the time period and volume entered in
                the following field. For example, if your facility emits 1,000
                m3 per month, please select Monthly in the 'Outgoing Water Time
                Period' field and type 1000 and select m3 within the 'Outgoing
                Water Quantity' fields. Data will be annualized automatically to
                12,000 m3 by the tool.
              </FormHelpText>
            </div>
          </div>
          <div className={cx('row')}>
            <div className={cx('label')}>
              <Copy as="p" align="right">
                Outgoing Water Quantity
              </Copy>
              <FormHelpButton
                formHelp={formHelp}
                name="outgoingWaterQuantity"
              />
            </div>
            <div className={cx('inputGroup')}>
              <div className={cx('input')}>
                <TextInput
                  className={cx('inputField')}
                  name="outgoingWaterQuantity"
                  ref={register}
                  error={errors.outgoingWaterQuantity}
                />
                <Controller
                  name="outgoingWaterQuantityUnit"
                  control={control}
                  render={(props) => (
                    <SelectInput
                      {...props}
                      className={cx('inputField')}
                      items={waterUnits.filter((u) => u.value !== '16')}
                      placeholder="&nbsp;"
                      error={errors.outgoingWaterQuantityUnit}
                      onChange={(val) => props.onChange(val)}
                    />
                  )}
                />
              </div>
              <FormHelpText formHelp={formHelp} name="outgoingWaterQuantity">
                The total amount of outgoing water from the facility. Please
                enter the volume as a number in the text field and select the
                correct units from the dropdown list. For example, to enter
                1,000 m3 for the 'Outgoing Water Quantity' field, type 1000 into
                the text field and select m3 from the dropdown list.
              </FormHelpText>
            </div>
          </div>
          <div className={cx('row')}>
            <div className={cx('label')}>
              <Copy as="p" align="right">
                Outgoing Water Price
              </Copy>
              <FormHelpButton formHelp={formHelp} name="outgoingWaterPrice" />
            </div>
            <div className={cx('inputGroup')}>
              <div className={cx('input')}>
                <TextInput
                  type="number"
                  className={cx('inputField')}
                  name="outgoingWaterPrice"
                  ref={register}
                />
                <Controller
                  name="outgoingWaterPriceCurrencyCode"
                  control={control}
                  render={(props) => (
                    <SelectInput
                      {...props}
                      className={cx('inputField')}
                      items={currencies}
                      placeholder="&nbsp;"
                      error={errors.outgoingWaterPriceCurrencyCode}
                      onChange={(val) => props.onChange(val)}
                    />
                  )}
                />
                <Controller
                  name="outgoingWaterPriceUnit"
                  control={control}
                  render={(props) => (
                    <SelectInput
                      {...props}
                      className={cx('inputField')}
                      items={waterUnits.filter((u) => u.value !== '32')}
                      placeholder="&nbsp;"
                      error={errors.outgoingWaterPriceUnit}
                      onChange={(val) => props.onChange(val)}
                    />
                  )}
                />
              </div>
              <FormHelpText formHelp={formHelp} name="outgoingWaterPrice">
                Total price paid for wastewater treatment at your facility. This
                includes operating expenses for on-site water treatment and fees
                paid to a third party.
              </FormHelpText>
            </div>
          </div>
          <div className={cx('row')}>
            <div className={cx('label')}>
              <Copy as="p" align="right">
                Where is your outgoing water treated?
              </Copy>
            </div>
            <div className={cx('inputGroup')}>
              <div className={cx('input')}>
                <Controller
                  name="outgoingWaterTreatmentSource"
                  control={control}
                  render={(props) => (
                    <SelectInput
                      {...props}
                      className={cx('inputField')}
                      items={
                        referenceData.options.locationOfWaterTreatmentOptions
                      }
                      placeholder="&nbsp;"
                      error={errors.outgoingWaterTreatmentSource}
                      onChange={(val) => props.onChange(val)}
                    />
                  )}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={cx('formGroup')}>
          <h1 className={cx('formGroupTitle')}>Facility Details</h1>
          <div className={cx('row')}>
            <div className={cx('label')}>
              <Copy as="p" align="right">
                Total Facility Output By Year
              </Copy>
              <FormHelpButton formHelp={formHelp} name="facilityOutput" />
            </div>
            <div className={cx('inputGroup')}>
              <div className={cx('input')}>
                <TextInput
                  type="number"
                  className={cx('inputField')}
                  name="facilityOutput"
                  ref={register}
                  error={errors.facilityOutput}
                />
              </div>
              <FormHelpText formHelp={formHelp} name="facilityOutput">
                Total number of units of output from your facility per year. For
                example, if your facility produces 10,000 cars per annum, you
                would enter 'Cars' in the 'Facility Output' field and '10000' in
                this field.
              </FormHelpText>
            </div>
          </div>
          <div className={cx('row')}>
            <div className={cx('label')}>
              <Copy as="p" align="right">
                Facility Output
              </Copy>
              <FormHelpButton
                formHelp={formHelp}
                name="facilityOutputUnitsOfMeasure"
              />
            </div>
            <div className={cx('inputGroup')}>
              <div className={cx('input')}>
                <TextInput
                  className={cx('inputField')}
                  name="facilityOutputUnitsOfMeasure"
                  ref={register}
                  error={errors.facilityOutputUnitsOfMeasure}
                />
              </div>
              <FormHelpText
                formHelp={formHelp}
                name="facilityOutputUnitsOfMeasure"
              >
                The units of output or throughput from your facility. For
                example, if your facility produces cars, please enter 'Cars'
                into this field. If you are in the hospitality industry, you
                could enter 'rooms booked.'
              </FormHelpText>
            </div>
          </div>
          <div className={cx('row')}>
            <div className={cx('label')}>
              <Copy as="p" align="right">
                Projected Facility Output Over 3 Years (%)
              </Copy>
              <FormHelpButton
                formHelp={formHelp}
                name="projectedFacilityOutputIncrease"
              />
            </div>
            <div className={cx('inputGroup')}>
              <div className={cx('input')}>
                <TextInput
                  type="number"
                  className={cx('inputField')}
                  name="projectedFacilityOutputIncrease"
                  ref={register}
                  error={errors.projectedFacilityOutputIncrease}
                />
              </div>
              <FormHelpText
                formHelp={formHelp}
                name="projectedFacilityOutputIncrease"
              >
                Projection of how the output produced by the facility will
                change over the next three years. For example, if your facility
                produces cars and you are expecting an increase of 10% cars
                produced at the facility over the next three years, enter 10.
                You can specify an increase or a decrease (by entering a
                negative percentage). If your facility output is likely to stay
                constant, please enter '0'. This input is used by the tool to
                determine the growth at the facility.
              </FormHelpText>
            </div>
          </div>
        </div>
      </form>
      <FacilityTabFooter
        onCancel={onCancel}
        onNext={onSave}
        nextButtonText="save"
      />
    </div>
  );
};

FacilityDetails.displayName = 'FacilityDetails';
