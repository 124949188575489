import * as React from 'react';
import { RouteComponentProps, Link } from 'react-router-dom';
import { Redirect } from './Redirect';

import '../styles/assessment.css';
import '../styles/modal.css';
import { getTokenInStorage } from 'src/clients/helpers';
import { RouteContentScrollable } from 'src/components/RouteContentScrollable';
import { SiteFooter } from 'src/components/SiteFooter';

interface State {
  fileModalIsOpen: boolean;
}

export class Assessment extends React.Component<
  RouteComponentProps<{}>,
  State
> {
  constructor(props: RouteComponentProps<{}>) {
    super(props);

    this.state = {
      fileModalIsOpen: false,
    };

    this.openFileModal = this.openFileModal.bind(this);
    this.closeModals = this.closeModals.bind(this);
  }

  openFileModal() {
    console.log(this.state.fileModalIsOpen);
    this.setState({ fileModalIsOpen: true });
  }

  closeModals() {
    console.log('close');
    this.setState({
      fileModalIsOpen: false,
    });
  }

  public render() {
    let token = getTokenInStorage();
    if (!token || token === 'expired') {
      return <Redirect to={'/login'}></Redirect>;
    }

    return (
      <RouteContentScrollable className="module-waa">
        <div id="main-content">
          <div className="assessment">
            <div className="centered-content">
              <h1>How does your facility score?</h1>
              <p>
                This assessment contains 13 questions to help you discover where
                your facility scores on the Water Maturity Curve.
              </p>
            </div>
            <div className="row">
              <div className="col-sm-12 col-md-6 col-lg-offset-2 col-lg-4 divider">
                <h2>One facility</h2>
                <p>
                  To see where your facility lives on the Water Maturity Curve,
                  take the assessment to reveal your data and resources.
                </p>
                <div>
                  <Link to={`/wrm/facilities/create/waa`} className="btn-itb">
                    Add Facility
                  </Link>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-4">
                <h2>Multiple facilities</h2>
                <p>
                  Upload the assessment data for your facilities to explore
                  their Water Maturity Levels and see benchmarking data to
                  improve scores.
                </p>
                <div>
                  <Link
                    to={{
                      pathname: '/wrm/upload',
                      state: {
                        type: 'waa',
                      },
                    }}
                    className="btn-itb"
                  >
                    + add multiple sites
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <SiteFooter solid style={{ width: '100%' }} />
      </RouteContentScrollable>
    );
  }
}
