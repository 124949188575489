import { ProcessedFacilities } from 'src/types/processedFacility';
import {
  DEFAULT_CHART,
  getColorByFacilityRank,
  getRegressionLineV3,
} from './utils';

export type BenchmarkingDisplayType = 'Incoming' | 'Outgoing' | 'Consumptive';
export const BENCHMARKING_TYPE_OPTIONS = [
  'Incoming',
  'Outgoing',
  'Consumptive',
];
export type BenchmarkingDisplayIntensity = 'Production' | 'Revenue';
export const BENCHMARKING_INTENSITY_OPTIONS = ['Production', 'Revenue'];

export function benchmarkingChartDataGenerator(
  facilities?: ProcessedFacilities,
  hiddenFacilities?: Set<number>,
  displayState: {
    type: BenchmarkingDisplayType;
    intensity: BenchmarkingDisplayIntensity;
    showOrgAvg: boolean;
    showSectorAvg: boolean;
  } = {
    type: 'Incoming',
    intensity: 'Production',
    showOrgAvg: true,
    showSectorAvg: true,
  }
) {
  const filteredFacilities = (facilities ?? []).filter(
    (f) => !hiddenFacilities?.has(f.facilityId)
  );

  const getDots = function (facilities?: ProcessedFacilities) {
    const dots =
      facilities?.map((f) => ({
        name: f.name,
        color: getColorByFacilityRank(f.twoByTwoQuadrant ?? ''),
        // @ts-ignore
        x: f[`benchmark${displayState.type}XAxis`],
        // @ts-ignore
        y: f[`benchmark${displayState.intensity}YAxis`],
      })) ?? [];

    const data = dots.reduce<{ x: Array<number>; y: Array<number> }>(
      (acc, dot) => {
        acc.x.push(dot.x);
        acc.y.push(dot.y);

        return acc;
      },
      {
        x: [],
        y: [],
      }
    );

    return { dots, data };
  };

  const organizationalAverageData = getDots(filteredFacilities);
  const organizationalAverage = getRegressionLineV3(
    organizationalAverageData.data.x,
    organizationalAverageData.data.y
  );

  organizationalAverage.xData.sort(sortFunc);
  organizationalAverage.yData.sort(sortFunc);

  const sectorId =
    filteredFacilities &&
    filteredFacilities[0] &&
    filteredFacilities[0].sectorId;
  const isSector =
    filteredFacilities &&
    filteredFacilities.every((f) => f.sectorId === sectorId);

  const filteredFacilitiesData = getDots(filteredFacilities);
  const sectorAverage = getRegressionLineV3(
    organizationalAverageData.data.x,
    organizationalAverageData.data.y,
    isSector &&
      filteredFacilities &&
      filteredFacilities[0] &&
      filteredFacilities[0].sectorWaterUsePerRevenue
      ? filteredFacilities[0].sectorWaterUsePerRevenue
      : undefined
  );

  sectorAverage.xData.sort(sortFunc);
  sectorAverage.yData.sort(sortFunc);

  return {
    ...DEFAULT_CHART,
    chart: {
      type: 'scatter',
      animation: false,
      spacing: [20, 10, 10, 10],
    },
    xAxis: {
      title: {
        text: `${displayState.intensity} (${
          displayState.intensity === 'Production' ? 'unit' : '$M'
        })`,
      },
    },
    plotOptions: {
      scatter: {
        marker: {
          radius: 6,
          symbol: 'circle',
        },
        zIndex: 6,
      },
      line: {
        enableMouseTracking: true,
        marker: {
          enabled: true,
        },
        label: {
          enabled: true,
        },
      },
      series: {
        animation: false,
      },
    },
    yAxis: {
      title: {
        text: 'Water Volume (m3)',
      },
    },
    legend: {
      enabled: false,
      squareSymbol: false,
      symbolHeight: 12,
      symbolWidth: 24,
      symbolPadding: 10,
      symbolRadius: 0,
    },
    tooltip: {
      headerFormat:
        '<span style="font-size:10px; color:{series.color}">{point.key}</span><table>',
      pointFormat:
        `<tr><td style=";padding:0">${displayState.intensity} (${
          displayState.intensity === 'Production' ? 'unit' : '$M'
        }):</td>` +
        '<td style="padding:0"><b>{point.x:.5f}</b></td></tr>' +
        '<tr><td style=";padding:0">Water Volume:</td>' +
        '<td style="padding:0"><b>{point.y:.5f}</b></td></tr>',
      footerFormat: '</table>',
      shared: false,
      useHTML: true,
    },
    series: [
      {
        type: 'scatter',
        name: displayState.type,
        data: filteredFacilitiesData.dots.map((d) =>
          Object.assign({}, d, { x: d.y, y: d.x })
        ),
      },
      {
        type: 'line',
        color: '#096490',
        id: 'organizationalAverage',
        name: 'Organizational Average',
        visible: displayState.showOrgAvg,
        data: [
          {
            x: organizationalAverage.values[0],
            y: organizationalAverage.xData[0],
          },
          {
            x:
              organizationalAverage.values[
                organizationalAverage.values.length - 1
              ],
            y:
              organizationalAverage.xData[
                organizationalAverage.xData.length - 1
              ],
          },
        ],
      },
      {
        type: 'line',
        color: '#dc8f18',
        id: 'sectorAverage',
        name: 'Sector Average',
        visible:
          displayState.showSectorAvg &&
          isSector &&
          displayState.intensity === 'Revenue',
        data: [
          {
            x: sectorAverage.values[0],
            y: sectorAverage.xData[0],
          },
          {
            x: sectorAverage.values[sectorAverage.values.length - 1],
            y: sectorAverage.xData[sectorAverage.xData.length - 1],
          },
        ],
      },
    ],
  };
}

function sortFunc(a: number, b: number) {
  return a - b;
}
