import { REPORTING_YEARS } from 'src/constants/years';
import { Facility } from 'src/types/facility';
import { ReportingYear } from 'src/types/reportingYear';

export function mergeFacilityAndReportingYear(
  facility?: Facility,
  reportingYear?: ReportingYear
) {
  const data = {
    // Tab One
    facilityName: facility?.facilityName ?? reportingYear?.facilityName ?? '',
    fkCountry: facility?.fkCountry ?? reportingYear?.fkCountry ?? '',
    fkState: facility?.fkState ?? reportingYear?.fkState ?? '',
    fkCity: facility?.fkCity ?? reportingYear?.fkCity ?? '',
    level1SectorId: `${
      facility?.level1SectorId ?? reportingYear?.level1SectorId ?? ''
    }`,
    level2SectorId: `${
      facility?.level2SectorId ?? reportingYear?.level2SectorId ?? ''
    }`,
    fkSector: `${facility?.fkSector ?? reportingYear?.fkSector ?? ''}`,
    label: facility?.label ?? reportingYear?.label ?? '',
    active: facility?.active ?? reportingYear?.active ?? true,
    modelingOnly:
      facility?.modelingOnly ?? reportingYear?.modelingOnly ?? false,

    // Tab Two
    reportingYear: `${reportingYear?.reportingYear ?? REPORTING_YEARS[0]}`,
    incomingWaterTimePeriod: reportingYear?.incomingWaterTimePeriod ?? '',
    incomingWaterQuantity: reportingYear?.incomingWaterQuantity ?? '',
    incomingWaterQuantityUnit: reportingYear?.incomingWaterQuantityUnit ?? '',
    incomingWaterPrice: reportingYear?.incomingWaterPrice ?? '',
    incomingWaterPriceCurrencyCode:
      reportingYear?.incomingWaterPriceCurrencyCode ?? '',
    incomingWaterPriceUnit: reportingYear?.incomingWaterPriceUnit ?? '',
    incomingWaterSource: reportingYear?.incomingWaterSource ?? '',
    waterWithdrawalReplenishment:
      reportingYear?.waterWithdrawalReplenishment ?? false,
    waterWithdrawalAnnualizedVolumetricBenefit:
      reportingYear?.waterWithdrawalAnnualizedVolumetricBenefit ?? '',
    waterWithdrawalAnnualizedVolumetricBenefitUOMId:
      reportingYear?.waterWithdrawalAnnualizedVolumetricBenefitUOMId ?? '',
    waterWithdrawalSupportingReplenishmentWaterFund:
      reportingYear?.waterWithdrawalSupportingReplenishmentWaterFund ?? '',
    waterWithdrawalReplenishmentDurationInYears:
      reportingYear?.waterWithdrawalReplenishmentDurationInYears ?? '',
    waterWithdrawalThirdPartyVerified:
      reportingYear?.waterWithdrawalThirdPartyVerified ?? false,
    waterWithdrawalThirdPartyVerifier:
      reportingYear?.waterWithdrawalThirdPartyVerifier ?? '',
    outgoingWaterTimePeriod: reportingYear?.outgoingWaterTimePeriod ?? '',
    outgoingWaterQuantity: reportingYear?.outgoingWaterQuantity ?? '',
    outgoingWaterQuantityUnit: reportingYear?.outgoingWaterQuantityUnit ?? '',
    outgoingWaterPrice: reportingYear?.outgoingWaterPrice ?? '',
    outgoingWaterPriceCurrencyCode:
      reportingYear?.outgoingWaterPriceCurrencyCode ?? '',
    outgoingWaterPriceUnit: reportingYear?.outgoingWaterPriceUnit ?? '',
    outgoingWaterTreatmentSource:
      reportingYear?.outgoingWaterTreatmentSource ?? '',
    facilityOutput: reportingYear?.facilityOutput ?? '',
    facilityOutputUnitsOfMeasure:
      reportingYear?.facilityOutputUnitsOfMeasure ?? '',
    projectedFacilityOutputIncrease:
      reportingYear?.projectedFacilityOutputIncrease ?? '',

    // Tab Three
    droughScenario: reportingYear?.droughScenario ?? false,
    projectedIncomingWaterPriceIncrease:
      reportingYear?.projectedIncomingWaterPriceIncrease ?? '',
    projectedIncomingWaterQuantityIncrease:
      reportingYear?.projectedIncomingWaterQuantityIncrease ?? '',
    projectedOutgoingWaterPriceIncrease:
      reportingYear?.projectedOutgoingWaterPriceIncrease ?? '',
    projectedOutgoingWaterQuantityIncrease:
      reportingYear?.projectedOutgoingWaterQuantityIncrease ?? '',
    revenue: reportingYear?.revenue ?? '',
    revenueCurrencyCode: reportingYear?.revenueCurrencyCode ?? '',
    projectedRevenueIncrease: reportingYear?.projectedRevenueIncrease ?? '',
    revenueLostDueToWaterScarcity:
      reportingYear?.revenueLostDueToWaterScarcity ?? '',

    // Tab Four
    regulationOfWaterInLocalCommunityQuestion:
      reportingYear?.regulationOfWaterInLocalCommunityQuestion ?? '',
    facilityEnvironmentalReputationQuestion:
      reportingYear?.facilityEnvironmentalReputationQuestion ?? '',
    dependingSameWaterSourceQuestion:
      reportingYear?.dependingSameWaterSourceQuestion ?? '',
    exemptWaterRestrictionQuestion:
      reportingYear?.exemptWaterRestrictionQuestion ?? '',
    ngoLocalPressureQuestion: reportingYear?.ngoLocalPressureQuestion ?? '',

    // Tab Five
    incomingWaterQualityComparison:
      reportingYear?.incomingWaterQualityComparison ?? '',
    alternativeWaterSourceQuestion:
      reportingYear?.alternativeWaterSourceQuestion ?? '',
    outgoingWaterOnsiteTreatmentCapacity:
      reportingYear?.outgoingWaterOnsiteTreatmentCapacity ?? '',
    outgoingWaterOffsiteTreatmentCapacity:
      reportingYear?.outgoingWaterOffsiteTreatmentCapacity ?? '',
    incomingWaterImpairmentSalinity:
      reportingYear?.incomingWaterImpairmentSalinity ?? false,
    incomingWaterImpairmentOrganics:
      reportingYear?.incomingWaterImpairmentOrganics ?? false,
    incomingWaterImpairmentSuspendedSolids:
      reportingYear?.incomingWaterImpairmentSuspendedSolids ?? false,
    incomingWaterImpairmentTaste:
      reportingYear?.incomingWaterImpairmentTaste ?? false,
    incomingWaterImpairmentOdor:
      reportingYear?.incomingWaterImpairmentOdor ?? false,

    // Tab Six
    facilityPollutantList: reportingYear?.facilityPollutantList,
  };

  return data;
}

export function mergeFacilityAndAssessment(
  reportingYear?: number,
  facility?: Facility
) {
  const data = {
    // Tab One
    facilityName: facility?.facilityName ?? '',
    fkCountry: facility?.fkCountry ?? '',
    fkState: facility?.fkState ?? '',
    fkCity: facility?.fkCity ?? '',
    level1SectorId: `${facility?.level1SectorId ?? ''}`,
    level2SectorId: `${facility?.level2SectorId ?? ''}`,
    fkSector: `${facility?.fkSector ?? ''}`,
    label: facility?.label ?? '',
    active: facility?.active ?? true,
    modelingOnly: facility?.modelingOnly ?? false,
    reportingYear: `${reportingYear ?? REPORTING_YEARS[0]}`,
  };
  return data;
}
