import React, { FC } from 'react';
import classnames from 'classnames/bind';
import styles from '../Tabs.module.css';
import { Copy } from 'src/components/Copy';
import { SelectInput } from 'src/components/SelectInput';
import { ReportingYear } from 'src/types/reportingYear';
import { Controller, useFormContext } from 'react-hook-form';
import { TextInput } from 'src/components/TextInput';
import { CheckboxInput } from 'src/components/CheckboxInput';
import { MappedReferenceData } from 'src/types/referenceData';
import { FacilityTabFooter } from '../../Helpers/FacilityTabFooter';
import { useFormHelp } from 'src/hooks/useFormHelp';
import { FormHelpButton, FormHelpText } from '../../../FormHelp';

const cx = classnames.bind(styles);

type BusinessInformationProps = {
  referenceData: MappedReferenceData;
  reportingYear: ReportingYear | undefined;
  onSave: () => void;
  onCancel: () => void;
  className?: string;
};

export const BusinessInformation: FC<BusinessInformationProps> = ({
  referenceData,
  reportingYear,
  onCancel,
  onSave,
  className,
}) => {
  const formHelp = useFormHelp();

  const {
    control,
    formState: { errors },
    register,
  } = useFormContext();

  const currencies = referenceData.currencies.map((c) => ({
    label: c.code,
    value: c.code,
  }));

  return (
    <div className={cx('tabContent', className)}>
      <form>
        <div className={cx('formGroup')}>
          <h1 className={cx('formGroupTitle')}>Business Information</h1>
          <div className={cx('row')}>
            <div className={cx('label')}>
              <Copy as="p" align="right">
                Drought Scenario
              </Copy>
            </div>
            <div className={cx('inputGroup')}>
              <div className={cx('input')}>
                <Controller
                  name="droughScenario"
                  control={control}
                  defaultValue={reportingYear?.droughScenario ?? false}
                  render={(props) => (
                    <CheckboxInput
                      {...props}
                      className={cx('inputField', 'checkbox')}
                      error={errors.droughScenario}
                    />
                  )}
                ></Controller>
              </div>
            </div>
          </div>
          <div className={cx('row')}>
            <div className={cx('label')}>
              <Copy as="p" align="right">
                Projected Incoming Water Price Over 3 Years (%)
              </Copy>
              <FormHelpButton
                formHelp={formHelp}
                name="projectedIncomingWaterPriceIncrease"
              />
            </div>
            <div className={cx('inputGroup')}>
              <div className={cx('input')}>
                <TextInput
                  type="number"
                  className={cx('inputField')}
                  name="projectedIncomingWaterPriceIncrease"
                  ref={register}
                  defaultValue={
                    reportingYear?.projectedIncomingWaterPriceIncrease ?? ''
                  }
                  error={errors.projectedIncomingWaterPriceIncrease}
                />
              </div>
              <FormHelpText
                formHelp={formHelp}
                name="projectedIncomingWaterPriceIncrease"
              >
                Projection of how the incoming water price at the facility will
                change over the next three years. For example, if you are
                expecting an increase of 3% in the price of incoming water over
                the next 3 years, enter 3. You can specify an increase or a
                decrease (by entering a negative percentage). If your facility
                incoming water price is likely to stay constant, please enter
                '0'. This is an optional field. If you do not enter a value, the
                tool uses historical trend data on country-level water tariffs.
              </FormHelpText>
            </div>
          </div>
          <div className={cx('row')}>
            <div className={cx('label')}>
              <Copy as="p" align="right">
                Projected Incoming Water Quantity Over 3 Years (%)
              </Copy>
              <FormHelpButton
                formHelp={formHelp}
                name="projectedIncomingWaterQuantityIncrease"
              />
            </div>
            <div className={cx('inputGroup')}>
              <div className={cx('input')}>
                <TextInput
                  type="number"
                  className={cx('inputField')}
                  name="projectedIncomingWaterQuantityIncrease"
                  ref={register}
                  defaultValue={
                    reportingYear?.projectedIncomingWaterQuantityIncrease ?? ''
                  }
                  error={errors.projectedIncomingWaterQuantityIncrease}
                />
              </div>
              <FormHelpText
                formHelp={formHelp}
                name="projectedIncomingWaterQuantityIncrease"
              >
                Projection of how the water use at the facility will change over
                the next three years. For example, if you are projecting an
                increase in 10% of water use over the next 3 years, enter 10.
                You can specify an increase or a decrease. If your facility
                water use is likely to stay constant please enter '0'.
              </FormHelpText>
            </div>
          </div>
          <div className={cx('row')}>
            <div className={cx('label')}>
              <Copy as="p" align="right">
                Projected Outgoing Water Price Over 3 Years (%)
              </Copy>
              <FormHelpButton
                formHelp={formHelp}
                name="projectedOutgoingWaterPriceIncrease"
              />
            </div>
            <div className={cx('inputGroup')}>
              <div className={cx('input')}>
                <TextInput
                  type="number"
                  className={cx('inputField')}
                  name="projectedOutgoingWaterPriceIncrease"
                  ref={register}
                  defaultValue={
                    reportingYear?.projectedOutgoingWaterPriceIncrease ?? ''
                  }
                  error={errors.projectedOutgoingWaterPriceIncrease}
                />
              </div>
              <FormHelpText
                formHelp={formHelp}
                name="projectedOutgoingWaterPriceIncrease"
              >
                Projection of how the outgoing water price at the facility will
                change over the next three years. For example, if you are
                expecting an increase of 3% in the price of outgoing water over
                the next 3 years, enter 3. You can specify an increase or a
                decrease (by entering a negative percentage). If your facility
                outgoing water price is likely to stay constant, please enter
                '0'. This is an optional field. If you do not enter a value, the
                tool uses historical trend data on country-level water tariffs.
              </FormHelpText>
            </div>
          </div>
          <div className={cx('row')}>
            <div className={cx('label')}>
              <Copy as="p" align="right">
                Projected Outgoing Water Quantity Over 3 Years (%)
              </Copy>
              <FormHelpButton
                formHelp={formHelp}
                name="projectedOutgoingWaterQuantityIncrease"
              />
            </div>
            <div className={cx('inputGroup')}>
              <div className={cx('input')}>
                <TextInput
                  type="number"
                  className={cx('inputField')}
                  name="projectedOutgoingWaterQuantityIncrease"
                  ref={register}
                  defaultValue={
                    reportingYear?.projectedOutgoingWaterQuantityIncrease ?? ''
                  }
                  error={errors.projectedOutgoingWaterQuantityIncrease}
                />
              </div>
              <FormHelpText
                formHelp={formHelp}
                name="projectedOutgoingWaterQuantityIncrease"
              >
                Projection of how the outgoing water quantity at the facility
                will change over the next three years. For example, if you are
                expecting an increase of 10% in the amount of outgoing water
                over the next three years, enter 10. You can specify an increase
                or a decrease (by entering a negative percentage). If your
                facility outgoing water quantity is likely to stay constant,
                please enter '0'. This is an optional field. If you do not enter
                a value, outgoing water will remain constant.
              </FormHelpText>
            </div>
          </div>
          <div className={cx('row')}>
            <div className={cx('label')}>
              <Copy as="p" align="right">
                Total Facility Revenue Per Year
              </Copy>
              <FormHelpButton formHelp={formHelp} name="revenue" />
            </div>
            <div className={cx('inputGroup')}>
              <div className={cx('input')}>
                <TextInput
                  type="number"
                  className={cx('inputField')}
                  name="revenue"
                  ref={register}
                  defaultValue={reportingYear?.revenue ?? ''}
                  error={errors.revenue}
                />
                <Controller
                  name="revenueCurrencyCode"
                  control={control}
                  defaultValue={reportingYear?.revenueCurrencyCode ?? ''}
                  render={(props) => (
                    <SelectInput
                      {...props}
                      className={cx('inputField')}
                      items={currencies}
                      placeholder="&nbsp;"
                      error={errors.revenueCurrencyCode}
                      onChange={(val) => props.onChange(val)}
                    />
                  )}
                />
              </div>
              <FormHelpText formHelp={formHelp} name="revenue">
                Annual revenue produced by your facility. This is an optional
                field. If you do not enter a value, your facility's revenue will
                be estimated using data modeled by Trucost (2015) based on
                industry average economic data and your facility's industry
                classification.
              </FormHelpText>
            </div>
          </div>
          <div className={cx('row')}>
            <div className={cx('label')}>
              <Copy as="p" align="right">
                Projected Revenue Increase Over 3 Years (%)
              </Copy>
              <FormHelpButton
                formHelp={formHelp}
                name="projectedRevenueIncrease"
              />
            </div>
            <div className={cx('inputGroup')}>
              <div className={cx('input')}>
                <TextInput
                  type="number"
                  className={cx('inputField')}
                  name="projectedRevenueIncrease"
                  ref={register}
                  defaultValue={reportingYear?.projectedRevenueIncrease ?? ''}
                  error={errors.projectedRevenueIncrease}
                />
              </div>
              <FormHelpText formHelp={formHelp} name="projectedRevenueIncrease">
                Projection of how the revenue produced by the facility will
                change over the next three years. For example, if you are
                expecting an increase of 10% revenue generated over the next
                three years, enter 10. You can specify an increase or a decrease
                (by entering a negative percentage). If your facility revenue is
                likely to stay constant, please enter '0'. This is an optional
                field. If you do not enter a value, revenue will remain
                constant.
              </FormHelpText>
            </div>
          </div>
          <div className={cx('row')}>
            <div className={cx('label')}>
              <Copy as="p" align="right">
                Has your business lost revenue due to water scarcity in the last
                year?
              </Copy>
              <FormHelpButton
                formHelp={formHelp}
                name="revenueLostDueToWaterScarcity"
              />
            </div>
            <div className={cx('inputGroup')}>
              <div className={cx('input')}>
                <Controller
                  name="revenueLostDueToWaterScarcity"
                  control={control}
                  defaultValue={
                    reportingYear?.revenueLostDueToWaterScarcity ?? ''
                  }
                  render={(props) => (
                    <SelectInput
                      {...props}
                      className={cx('inputField')}
                      items={referenceData.options.revenueLostOptions}
                      value={props.value}
                      placeholder="&nbsp;"
                      error={errors.revenueLostDueToWaterScarcity}
                      onChange={(val) => props.onChange(val)}
                    />
                  )}
                />
              </div>
              <FormHelpText
                formHelp={formHelp}
                name="revenueLostDueToWaterScarcity"
              >
                The tool provides a revenue at risk likelihood score which
                examines water scarcity, your industry's importance to the local
                economy, your industry's water requirement, the quality of local
                regulations and your facility's historical trend of revenue
                loss. To customize the tool's revenue at risk likelihood score
                to be more specific to local conditions, please indicate whether
                your business has lost revenue due to water scarcity in the
                recent past. If local water scarcity has affected your
                facility's revenue, the revenue at risk likelihood score will be
                higher. If you are unsure, please select 'Do not know or N/A'.
              </FormHelpText>
            </div>
          </div>
        </div>
      </form>
      <FacilityTabFooter onCancel={onCancel} onNext={onSave} />
    </div>
  );
};
