import React from 'react';
import Modal from 'react-modal';
import { RouteComponentProps } from 'react-router';
import { APIService, CallType } from '../services/APIService';
import { Layout } from './Layout';
import { Redirect } from './Redirect';
import { Env } from 'src/constants/env';
import { RouteContentScrollable } from 'src/components/RouteContentScrollable';

import '../styles/facility.css';
import '../styles/forms.css';
import '../styles/modal.css';
import { getTokenInStorage } from 'src/clients/helpers';
import { SiteFooter } from 'src/components/SiteFooter';

interface FacilityInformation {
  id?: number;
  name: string;
  label: string;
  year: number;
  country: string;
  state: string;
  disablestate: boolean;
  city: string;
  disablecity: boolean;
  industry1: string;
  industry2: string;
  industryCode: string;
  naics1: Array<Naic>;
  naics2: Array<Naic>;
  disablenaics2: boolean;
  naics3: Array<Naic>;
  disablenaics3: boolean;
  countries: string[];
  regions: string[];
  cities: string[];
  navigate: boolean;
  modalIsOpen: boolean;
  formInvalid: boolean;

  unauthorized: boolean;
  [key: string]: any;
}

export interface Naic {
  code: number;
  title: string;
}

export class Facility extends React.Component<
  RouteComponentProps<{ id: string }>,
  FacilityInformation
> {
  api: APIService | null = null;

  constructor(props: RouteComponentProps<{ id: string }>) {
    super(props);
    this.state = {
      name: '',
      label: '',
      year: new Date().getFullYear(),
      country: '',
      state: '',
      disablestate: true,
      city: '',
      disablecity: true,
      industry1: '',
      industry2: '',
      industryCode: '',
      naics1: [],
      naics2: [],
      disablenaics2: true,
      naics3: [],
      disablenaics3: true,
      countries: [],
      regions: [],
      cities: [],
      navigate: false,
      modalIsOpen: false,
      formInvalid: false,
      unauthorized: false,
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleIndustry1Change = this.handleIndustry1Change.bind(this);
    this.handleIndustry2Change = this.handleIndustry2Change.bind(this);
    this.handleCountryChange = this.handleCountryChange.bind(this);
    this.handleRegionChange = this.handleRegionChange.bind(this);
    this.submit = this.submit.bind(this);

    this.requestCities = this.requestCities.bind(this);
    this.requestRegions = this.requestRegions.bind(this);

    this.openModal = this.openModal.bind(this);
    this.afterOpenModal = this.afterOpenModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  componentDidMount() {
    this.api = new APIService(Layout.notificationSystem);
    this.api &&
      this.api.perform('/content/naics1.json', CallType.Json).then((data) => {
        this.setState({ naics1: data });
      });

    Layout.waterStressService.getCountries().then((data) => {
      this.setState({ countries: data });
    });

    // Handle existing site case
    if (this.props.match.params.id) {
      this.api
        .perform(
          `${Env.waaApiUrl}/v4/facility/${this.props.match.params.id}`,
          CallType.Json
        )
        .then((data) => {
          console.log(data);
          // Grab substrings used for industry selectors
          let industry1 = data.industryCode.substring(0, 2);
          let industry2 = data.industryCode.substring(0, 3);

          // Set state parameters from data
          this.setState(
            {
              name: data.name,
              label: data.label,
              year: data.year,

              country: data.country,
              state: data.state,
              city: data.city,

              industryCode: data.industryCode,
              industry1: industry1,
              industry2: industry2,
              industry3: data.industryCode,

              id: parseInt(this.props.match.params.id),
            },
            () => {
              // Request necessary options
              this.requestRegions(data.country);
              this.requestCities(data.state);

              this.requestOptionsIndustry2(industry1);
              this.requestOptionsIndustry3(industry2);
            }
          );
        });
    }
    // Check for existing Partially Created Facility
    else if (Layout.facilityCreationService.getFacility()) {
      let partialFacility = Layout.facilityCreationService.getFacility();

      // Set state parameters from data
      if (partialFacility) {
        this.setState(
          {
            name: partialFacility.name,
            label: partialFacility.label,
            year: partialFacility.year,

            country: partialFacility.country,
            state: partialFacility.state,
            city: partialFacility.city,

            industryCode: partialFacility.industryCode,
            industry1: partialFacility.industry1,
            industry2: partialFacility.industry2,
            industry3: partialFacility.industryCode,
          },
          () => {
            if (partialFacility) {
              // Request necessary options
              this.requestRegions(partialFacility.country);
              this.requestCities(partialFacility.state);

              this.requestOptionsIndustry2(partialFacility.industry1);
              this.requestOptionsIndustry3(partialFacility.industry2);
            }
          }
        );
      }

      Layout.notificationSystem.addNotification({
        message:
          'Found a partially complete facility. New facilities cannot be created until the quiz is submitted.',
        level: 'info',
        position: 'tc',
        autoDismiss: 0,
      });
    }
  }

  // Handle Location Changes
  handleCountryChange(event: React.ChangeEvent<any>) {
    const target = event.target;
    const value = target.value;

    this.setState({
      country: value,
      state: '',
      disablestate: true,
      city: '',
      disablecity: true,
    });

    this.requestRegions(value);
  }
  handleRegionChange(event: React.ChangeEvent<any>) {
    const target = event.target;
    const value = target.value;

    this.setState({
      state: value,
      city: '',
      disablecity: true,
    });

    this.requestCities(value);
  }

  // Request Locations
  requestRegions(country: string) {
    Layout.waterStressService.getRegions(country).then((data) => {
      this.setState({ regions: data, disablestate: false });
    });
  }
  requestCities(region: string) {
    Layout.waterStressService
      .getCities(this.state.country, region)
      .then((data) => {
        this.setState({ cities: data, disablecity: false });
      });
  }

  // Handle Industry Changes
  handleIndustry1Change(event: React.ChangeEvent<any>) {
    const target = event.target;
    const value = target.value;

    this.setState({
      industry1: value,
      industry2: '',
      naics2: [],
      disablenaics2: true,
      naics3: [],
      disablenaics3: true,
    });

    this.requestOptionsIndustry2(value);
  }
  handleIndustry2Change(event: React.ChangeEvent<any>) {
    const target = event.target;
    const value = target.value;

    this.setState({
      industry2: value,
      naics3: [],
      disablenaics3: true,
    });

    this.requestOptionsIndustry3(value);
  }

  // Request Industry Options
  requestOptionsIndustry2(industry1: string) {
    this.api &&
      this.api.perform('/content/naics2.json', CallType.Json).then((data) => {
        const naics = data.filter(
          (naic: any) => naic.code.toString().substring(0, 2) === industry1
        );
        this.setState({ naics2: naics, disablenaics2: false });
      });
  }
  requestOptionsIndustry3(industry2: string) {
    this.api &&
      this.api.perform('/content/naics3.json', CallType.Json).then((data) => {
        const naics = data.filter(
          (naic: any) => naic.code.toString().substring(0, 3) === industry2
        );
        this.setState({ naics3: naics, disablenaics3: false });
      });
  }

  handleInputChange(event: React.ChangeEvent<any>) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    if (name === 'name')
      this.setState({
        name: value,
      });

    if (name === 'label')
      this.setState({
        label: value,
      });

    if (name === 'year')
      this.setState({
        year: value,
      });

    if (name === 'industry3')
      this.setState({
        industryCode: value,
      });

    if (name === 'city')
      this.setState({
        city: value,
      });
  }

  isFormValid() {
    const facility = this.state;

    if (
      !facility.name ||
      !facility.country ||
      !facility.state ||
      !facility.city ||
      !facility.year ||
      !facility.industry1 ||
      !facility.industry2 ||
      !facility.industryCode
    ) {
      return false;
    }

    return true;
  }

  submit() {
    const facility = this.state;
    this.setState({ formInvalid: false });
    if (!this.isFormValid()) {
      this.setState({ formInvalid: true });
      window.scrollTo(0, 0);
      return;
    }
    // Save existing facilities
    if (this.state.id) {
      this.api &&
        this.api
          .perform(`${Env.waaApiUrl}/facility`, CallType.Json, facility)
          .then((response) => {
            // navigate to quiz page
            this.setState({
              navigate: true,
              id: response.id,
            });
          });
    }
    // Store new facilities on the service for creation after completion of the Quiz.
    else {
      Layout.facilityCreationService.storeFacility(facility);
      // navigate to quiz page
      this.setState({
        navigate: true,
      });
    }
  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }
  afterOpenModal() {
    // references are now sync'd and can be accessed.
  }
  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  public render() {
    let token = getTokenInStorage();
    if (!token || token === 'expired') {
      return <Redirect to={'/login'}></Redirect>;
    }

    if (this.state.navigate) {
      return (
        <Redirect
          to={'/question' + (this.state.id ? `/${this.state.id}` : '')}
        ></Redirect>
      );
    }
    if (this.state.unauthorized) {
      Layout.notificationSystem.addNotification({
        message:
          'You are not the owner of this facility, so you cannot edit it.',
        level: 'error',
        position: 'tc',
        autoDismiss: 0,
      });
      return <Redirect to={'/result/' + this.state.id}></Redirect>;
    }

    let buttonClass = 'btn-itb';

    if (!this.isFormValid()) {
      buttonClass += ' inactive';
    }

    return (
      <RouteContentScrollable className="module-waa">
        <div id="main-content">
          <div className="facility">
            <Modal
              isOpen={this.state.modalIsOpen}
              onAfterOpen={this.afterOpenModal}
              onRequestClose={this.closeModal}
              className="Modal"
              overlayClassName="Overlay"
              contentLabel="Help Modal"
            >
              <div className="row">
                <div className="col-md-2 pull-right">
                  {' '}
                  <button className="close" onClick={this.closeModal}>
                    &times;
                  </button>
                </div>
                <div className="col-md-10">
                  <h1 className="modal-heading">
                    About the Facility Label Field
                  </h1>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 black-text">
                  <p>
                    Enter a label that classifies this facility and can be used
                    to filter your facilities for your own reference.
                  </p>
                </div>
              </div>
            </Modal>

            <div className="row">
              <div className="col-sm-12 col-md-6 col-md-offset-3 col-lg-4 col-lg-offset-4">
                <header className="row">
                  <h1>New facility information</h1>
                  {this.state.formInvalid && (
                    <p className="alert alert-danger">
                      Please fix your errors below
                    </p>
                  )}
                </header>
                <form>
                  <div
                    className={
                      this.state.formInvalid && !this.state.name
                        ? 'form-group has-error'
                        : 'form-group'
                    }
                  >
                    <label htmlFor="name">Name of facility</label>
                    <input
                      type="text"
                      className="form-control"
                      value={this.state.name}
                      id="name"
                      onChange={this.handleInputChange}
                      name="name"
                    ></input>
                    <p className="help-block error-message">
                      Please enter a valid input
                    </p>
                  </div>

                  <div className="form-group">
                    <label htmlFor="label">Facility label (optional)</label>
                    {/* eslint-disable-next-line */}
                    <a data-toggle="modal" onClick={this.openModal}>
                      <span className="glyphicon glyphicon-info-sign"></span>
                    </a>
                    <input
                      type="text"
                      className="form-control"
                      value={this.state.label}
                      id="label"
                      onChange={this.handleInputChange}
                      name="label"
                    ></input>
                  </div>

                  <div
                    className={
                      this.state.formInvalid && !this.state.year
                        ? 'form-group has-error'
                        : 'form-group'
                    }
                  >
                    <label htmlFor="year">Reporting year</label>
                    <input
                      type="number"
                      className="form-control"
                      value={this.state.year}
                      id="year"
                      onChange={this.handleInputChange}
                      name="year"
                    ></input>
                    <p className="help-block error-message">
                      Please enter a valid input
                    </p>
                  </div>

                  <div
                    className={
                      this.state.formInvalid && !this.state.country
                        ? 'form-group has-error'
                        : 'form-group'
                    }
                  >
                    <label htmlFor="country">Country</label>
                    <select
                      className="form-control"
                      value={this.state.country}
                      id="country"
                      onChange={this.handleCountryChange}
                      name="country"
                    >
                      <option></option>
                      {this.state.countries.sort().map((country) => (
                        <option key={country} value={country}>
                          {country}
                        </option>
                      ))}
                    </select>
                    <p className="help-block error-message">
                      Please choose a valid input
                    </p>
                  </div>

                  <div
                    className={
                      this.state.formInvalid &&
                      this.state.country &&
                      !this.state.state
                        ? 'form-group has-error'
                        : 'form-group'
                    }
                  >
                    <label htmlFor="state">State/Province</label>
                    <select
                      className="form-control"
                      value={this.state.state}
                      id="state"
                      onChange={this.handleRegionChange}
                      name="state"
                      disabled={this.state.disablestate}
                    >
                      <option></option>
                      {this.state.regions.sort().map((region) => (
                        <option key={region} value={region}>
                          {region}
                        </option>
                      ))}
                    </select>
                    <p className="help-block error-message">
                      Please choose a valid input
                    </p>
                  </div>

                  <div
                    className={
                      this.state.formInvalid &&
                      this.state.state &&
                      !this.state.city
                        ? 'form-group has-error'
                        : 'form-group'
                    }
                  >
                    <label htmlFor="city">City</label>
                    <select
                      className="form-control"
                      value={this.state.city}
                      id="city"
                      onChange={this.handleInputChange}
                      name="city"
                      disabled={this.state.disablecity}
                    >
                      <option></option>
                      {this.state.cities.sort().map((city) => (
                        <option key={city} value={city}>
                          {city}
                        </option>
                      ))}
                    </select>
                    <p className="help-block error-message">
                      Please choose a valid input
                    </p>
                  </div>

                  <div
                    className={
                      this.state.formInvalid && !this.state.industry1
                        ? 'form-group closer has-error'
                        : 'form-group closer'
                    }
                  >
                    <label htmlFor="industry1">Industry Classification</label>
                    <select
                      className="form-control"
                      value={this.state.industry1}
                      id="industry1"
                      onChange={this.handleIndustry1Change}
                    >
                      <option></option>
                      {this.state.naics1.map((naic) => (
                        <option key={naic.code} value={naic.code}>
                          [{naic.code}] {naic.title}
                        </option>
                      ))}
                    </select>
                    <p className="help-block error-message">
                      Please choose a valid input
                    </p>
                  </div>
                  <div
                    className={
                      this.state.formInvalid &&
                      this.state.industry1 &&
                      !this.state.industry2
                        ? 'form-group closer has-error'
                        : 'form-group closer'
                    }
                  >
                    <label htmlFor="industry2"></label>
                    <select
                      className="form-control"
                      value={this.state.industry2}
                      id="industry2"
                      onChange={this.handleIndustry2Change}
                      disabled={this.state.disablenaics2}
                    >
                      <option></option>
                      {this.state.naics2.map((naic) => (
                        <option key={naic.code} value={naic.code}>
                          [{naic.code}] {naic.title}
                        </option>
                      ))}
                    </select>
                    <p className="help-block error-message">
                      Please choose a valid input
                    </p>
                  </div>
                  <div
                    className={
                      this.state.formInvalid &&
                      this.state.industry2 &&
                      !this.state.industryCode
                        ? 'form-group closer has-error'
                        : 'form-group closer'
                    }
                  >
                    <label htmlFor="industry3"></label>
                    <select
                      className="form-control"
                      value={this.state.industryCode}
                      id="industry3"
                      onChange={this.handleInputChange}
                      name="industry3"
                      disabled={this.state.disablenaics3}
                    >
                      <option></option>
                      {this.state.naics3.map((naic) => (
                        <option key={naic.code} value={naic.code}>
                          [{naic.code}] {naic.title}
                        </option>
                      ))}
                    </select>
                    <p className="help-block error-message">
                      Please choose a valid input
                    </p>
                  </div>
                </form>
                <button onClick={this.submit} className={buttonClass}>
                  {this.state.id ? 'Update' : 'Create'} Facility
                </button>
              </div>
            </div>
          </div>
        </div>
        <SiteFooter solid style={{ width: '100%' }} />
      </RouteContentScrollable>
    );
  }
}
