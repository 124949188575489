import React, { FC } from 'react';
import classnames from 'classnames/bind';
import { useQuery } from 'react-query';
import { getFacilities } from 'src/clients/api/facilty';
import { LoadingSpinner } from 'src/components/LoadingSpinner';
import { BlockError } from 'src/components/BlockError';
import { Copy } from 'src/components/Copy';
import { Link } from 'react-router-dom';
import styles from './RiskAnalysis.module.css';
import tabStyles from '../../components/FacilityForm/Helpers/FacilityTabNavigator/FacilityTabNavigator.module.css';
import { FacilityRiskFilter } from '../../components/FacilityRiskFilter';
import { FacilityRiskFilterProvider } from 'src/hooks/useFacilityRiskFilter';
import { FilterAndSort } from '../../components/FilterAndSort';
import { RiskAnalysisChart } from '../../components/RiskAnalysisChart';
import { useFilterAndSort } from 'src/hooks/useFilterAndSort';
import { getFolders } from 'src/clients/api/folder';
import { ProcessedFacilities } from 'src/types/processedFacility';
import { Folders } from 'src/types/folder';
import { useDeepCompareMemo } from 'src/hooks/useDeepCompare';
import { filter } from 'src/hooks/useFilterAndSort/utils';

const cx = classnames.bind(styles);

type RiskAnalysisProps = {
  facilities: ProcessedFacilities;
  folders: Folders;
};

const RiskAnalysisWrapper: FC<RiskAnalysisProps> = ({
  children,
  facilities,
  folders,
}) => (
  <>
    <header>
      <nav className={cx('tabs', tabStyles.tabs)}>
        <div className={cx('shadow', tabStyles.shadow)}></div>
        <div
          className={cx(
            'tab',
            'active',
            `${tabStyles.tab} ${tabStyles.active}`
          )}
        >
          Risk Analysis
        </div>
        <Link
          className={cx('tab', tabStyles.tab)}
          to={'/wrm/enterprise/benchmarking'}
        >
          Benchmarking
        </Link>
      </nav>
    </header>
    <div className={cx('riskAnalysis')}>
      <div className={cx('header')}>
        <Copy as="h2">Risk Analysis</Copy>
        <Copy as="p">
          This Water Risk Analysis charts a company's enterprise risk profile by
          assessing each facility's risk based on three year projected output
          growth and location-specific water stress and assigns action plans for
          facilities based on risk likelihood. This analysis provides a way for
          business leaders to prioritize and determine appropriate risk
          mitigation strategies for each location in order to reduce a company's
          overall risk profile.
        </Copy>
        <br />
        <Copy as="p">
          The following steps can be used to develop a successful corporate
          water strategy,
        </Copy>
        <ol>
          <li>
            <Copy as="p">
              Establish a water management plan and goals that are aligned with
              overarching business and sustainability strategies
            </Copy>
          </li>
          <li>
            <Copy as="p">
              Assess and monetize business risks based on a holistic
              understanding of what water means to your business
            </Copy>
          </li>
          <li>
            <Copy as="p">Prioritize actions based on site-specific risk</Copy>
          </li>
          <li>
            <Copy as="p">
              dentify opportunities to minimize water risk, maximize performance
              results and optimize costs (reduce, reuse and recycle)
            </Copy>
          </li>
          <li>
            <Copy as="p">
              Execute water management plan using a "plan-do-check-act" cycle
            </Copy>
          </li>
        </ol>
      </div>
      <div className={cx('filterAndSort')}>
        <FilterAndSort facilities={facilities} folders={folders} />
      </div>
      {children}
    </div>
  </>
);

export const RiskAnalysis: FC<RiskAnalysisProps> = () => {
  const filterState = useFilterAndSort();

  const { data: folderData } = useQuery(['folders'], () => getFolders());

  const { isLoading, isFetching, data, error, refetch } = useQuery(
    ['facilities', 'folder', filterState.folderId],
    () => getFacilities(filterState.folderId)
  );

  const facilities =
    data?.status === 'success' && data?.value.result ? data?.value.result : [];

  const folders =
    folderData?.status === 'success' && folderData?.value.result
      ? folderData?.value.result
      : [];

  const filteredFacilities = useDeepCompareMemo(
    () => filter(facilities, filterState),
    [facilities, filterState]
  );

  if (isLoading) {
    return (
      <RiskAnalysisWrapper facilities={filteredFacilities} folders={folders}>
        <LoadingSpinner />
      </RiskAnalysisWrapper>
    );
  }

  if (error || !data || data.status === 'error') {
    return (
      <RiskAnalysisWrapper facilities={filteredFacilities} folders={folders}>
        <BlockError
          retry={refetch}
          isLoading={isFetching}
          title="Error loading facilities"
          message="We were unable to load your facilities successfully"
        />
      </RiskAnalysisWrapper>
    );
  }

  return (
    <RiskAnalysisWrapper facilities={facilities} folders={folders}>
      <div className={cx('content')}>
        <FacilityRiskFilterProvider facilities={filteredFacilities}>
          <div className={cx('charts')}>
            <RiskAnalysisChart />
          </div>
          <div className={cx('sidebar')}>
            <FacilityRiskFilter />
          </div>
        </FacilityRiskFilterProvider>
      </div>
    </RiskAnalysisWrapper>
  );
};

RiskAnalysis.displayName = 'RiskAnalysis';
