import { Env } from 'src/constants/env';
import { Folders } from 'src/types/folder';
import { ProcessedFacilities } from 'src/types/processedFacility';
import request from '../request';
import { WrappedResponse } from '../types';

export type GetFoldersResponseType = WrappedResponse<Folders>;
export function getFolders() {
  return request.get<GetFoldersResponseType>(`${Env.apiUrl}/v3.1/folders`);
}

export type CreateFolderRequestType = {
  folderName: string;
};
export type CreateFolderResponseType = WrappedResponse<{}>;
export function createFolder(folderName: string) {
  return request.post<CreateFolderResponseType, CreateFolderRequestType>(
    `${Env.apiUrl}/v3.1/folders`,
    { folderName }
  );
}

export type UpdateFolderRequestType = {
  folderName: string;
};
export type UpdateFolderResponseType = WrappedResponse<{}>;
export function updateFolder(folderId: number, folderName: string) {
  return request.put<UpdateFolderResponseType, UpdateFolderRequestType>(
    `${Env.apiUrl}/v3.1/folders/${folderId}`,
    { folderName }
  );
}

export type DeleteFolderResponseType = WrappedResponse<{}>;
export function deleteFolder(folderId: number) {
  return request.del<DeleteFolderResponseType>(
    `${Env.apiUrl}/v3.1/folders/${folderId}`
  );
}

export type GetFolderFacilitiesResponseType = WrappedResponse<
  ProcessedFacilities
>;
export function getFolderFacilities(folderId: string) {
  return request.get<GetFolderFacilitiesResponseType>(
    `${Env.apiUrl}/v3.1/facilities/folder/${folderId}`
  );
}
