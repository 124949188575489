import React, { FC } from 'react';
import classnames from 'classnames/bind';

import styles from './Tabs.module.css';
import { ProcessedFacility } from 'src/types/processedFacility';
import { useDeepCompareMemo } from 'src/hooks/useDeepCompare';
import HighchartsReact from 'highcharts-react-official';
import {
  COLOR_MAP,
  incomingRiskChartDataGenerator,
} from '../../../../../utils/chart';
import { Copy } from 'src/components/Copy';
import Highcharts from 'highcharts';
import { asCommaSeparatedString } from 'src/utils/number';

const cx = classnames.bind(styles);
const legendYears = [1, 3, 5, 10] as const;

const legendColors = ['#b7dbe6', '#93c9da', '#6fb7ce', '#cccccc'];

const legend = {
  usdPerYear: [
    { label: 'Quality Risk', rowKey: 'quality' },
    { label: 'Quantity Risk', rowKey: 'scarcity' },
    { label: 'Water Bill', rowKey: 'waterbill' },
    { label: 'Combined', rowKey: 'total' },
  ] as const,
  riskAdjustedPricing: [
    { label: 'Quality Risk Premium', rowKey: 'quality' },
    { label: 'Quantity Risk Premium', rowKey: 'quantity' },
    { label: 'Water Bill Unit Price', rowKey: 'waterbill' },
    { label: 'Combined Risk Adjusted Price', rowKey: 'combined' },
  ] as const,
};

type IncomingRiskProps = {
  facility: ProcessedFacility;
};

export const IncomingRisk: FC<IncomingRiskProps> = ({ facility }) => {
  const attributes = facility.charts.incomingWaterRiskChart.attributes;
  const chart = useDeepCompareMemo<Highcharts.Options>(
    () =>
      incomingRiskChartDataGenerator(facility.charts.incomingWaterRiskChart),
    [facility.charts.incomingWaterRiskChart]
  );

  const score =
    COLOR_MAP[facility.individualQuantityRiskLevel] ?? COLOR_MAP['N'];

  return (
    <div className={cx('facilityTargetTab')}>
      <div className={cx('header')}>
        <Copy as="h2">Incoming Risk</Copy>
        <Copy as="p">
          Incoming water risk premium places a monetary value on the local
          environmental, human-health and domestic supply impacts of water
          depletion and the future costs of incoming water treatment. Local
          water availability, local water quality and local population density
          are all variables that impact the size of the risk premium. Locations
          that have high levels of water stress (due to quantity or quality
          related impacts) and high population densities will usually have a
          higher risk premium.
        </Copy>
        <Copy as="p" className="score">
          Incoming Risk Likelihood Score ={' '}
          <span className={cx('score', score.color)}>{score.text}</span>
        </Copy>
      </div>

      <HighchartsReact highcharts={Highcharts} options={chart} />

      <table className={cx('legendTable', 'incomingLegendTable')}>
        <tbody>
          <tr className={cx('legendTableHeader')}>
            <td>USD per year</td>
            <td>1 year</td>
            <td>3 year</td>
            <td>5 year</td>
            <td>10 year</td>
          </tr>
          {legend['usdPerYear'].map(({ label, rowKey }, index, arr) => (
            <tr
              className={cx({
                strong: index === arr.length - 1,
              })}
            >
              <td className={cx('label')}>
                <span
                  className={cx('icon')}
                  style={{ backgroundColor: legendColors[index] }}
                ></span>
                <span className={cx('value')}>{label}</span>
              </td>
              {legendYears.map((number) => {
                const key = `year${number}-${rowKey}` as const;
                return (
                  <td>
                    <span
                      className={cx('icon')}
                      style={{ backgroundColor: legendColors[index] }}
                    ></span>
                    <span className={cx('value')}>
                      {asCommaSeparatedString(attributes[key])}
                    </span>
                  </td>
                );
              })}
            </tr>
          ))}
          <tr className={cx('legendTableHeader')}>
            <td>
              Risk Adjusted Unit Pricing (USD per m<sup>3</sup>)
            </td>
            <td>1 year</td>
            <td>3 year</td>
            <td>5 year</td>
            <td>10 year</td>
          </tr>
          {legend['riskAdjustedPricing'].map(
            ({ label, rowKey }, index, arr) => (
              <tr
                className={cx({
                  strong: index === arr.length - 1,
                })}
              >
                <td className={cx('label')}>
                  <span
                    className={cx('icon')}
                    style={{ backgroundColor: legendColors[index] }}
                  ></span>
                  <span className={cx('value')}>{label}</span>
                </td>
                {legendYears.map((number) => {
                  const key = `year${number}-${rowKey}-unit-price` as const;
                  return (
                    <td>
                      <span
                        className={cx('icon')}
                        style={{ backgroundColor: legendColors[index] }}
                      ></span>
                      <span className={cx('value')}>
                        {asCommaSeparatedString(attributes[key], 2)}
                      </span>
                    </td>
                  );
                })}
              </tr>
            )
          )}
        </tbody>
      </table>
    </div>
  );
};

IncomingRisk.displayName = 'IncomingRisk';
