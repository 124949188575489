import React, { FC, useState } from 'react';
import classnames from 'classnames/bind';
import styles from '../../Tabs/Tabs.module.css';
import { Button } from 'src/components/Button';
import { ConfirmDialog } from 'src/components/ConfirmDialog';
import { Alert } from 'src/components/Alert';
import { useAsyncFormState } from 'src/hooks/useAsyncFormState';

const cx = classnames.bind(styles);

export interface FacilityTabFooterProps {
  canNext?: boolean;
  canBack?: boolean;
  nextButtonText?: string;
  backButtonText?: string;
  onNext: (values?: any) => void;
  onBack?: () => void;
  onCancel: () => void;
  onDelete?: () => void;
}

export const FacilityTabFooter: FC<FacilityTabFooterProps> = ({
  onNext,
  onBack,
  onCancel,
  onDelete,
  backButtonText = 'previous',
  nextButtonText = 'save',
  canNext = true,
  canBack = false,
}) => {
  const { saveState, deleteState } = useAsyncFormState();
  const [isShowingDeleteModal, setIsShowingDeleteModal] = useState(false);

  return (
    <footer className={cx('footer')}>
      <div>
        <Button
          onClick={onCancel}
          variant="secondary"
          style={{ marginLeft: '5px' }}
        >
          cancel
        </Button>
        {onDelete ? (
          <>
            <Button
              variant="danger"
              onClick={() => setIsShowingDeleteModal(true)}
              style={{ marginLeft: '2rem' }}
            >
              delete
            </Button>
            {isShowingDeleteModal ? (
              <ConfirmDialog
                variant="danger"
                isOpen={isShowingDeleteModal}
                title="Delete Facility"
                description="Are you sure you want to delete this facility?"
                isLoading={deleteState?.status === 'loading'}
                onClose={() => setIsShowingDeleteModal(false)}
                onConfirm={onDelete}
              >
                {deleteState?.status === 'error' ? (
                  <Alert variant="danger" message={deleteState.message} />
                ) : null}
              </ConfirmDialog>
            ) : null}
          </>
        ) : null}
      </div>
      <div>
        {onBack ? (
          <Button
            onClick={onBack}
            disabled={!canBack || saveState?.status === 'loading'}
          >
            {backButtonText}
          </Button>
        ) : null}
        &nbsp;
        <Button
          onClick={onNext}
          disabled={!canNext}
          isLoading={saveState?.status === 'loading'}
        >
          {nextButtonText}
        </Button>
      </div>
    </footer>
  );
};

FacilityTabFooter.displayName = 'FacilityTabFooter';
