const LOCAL_STORAGE_TOKEN_KEY = '__ECOLAB_WAP_TOKEN__';
const LOCAL_STORAGE_USER_KEY = '__ECOLAB_WAP_USER__';
const LOCAL_STORAGE_ACCOUNT_KEY = '__ECOLAB_WAP_ACCOUNT__';

export function getTokenInStorage() {
  return window.localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY);
}

export function setTokenInStorage(id_token: string) {
  window.localStorage.setItem(LOCAL_STORAGE_TOKEN_KEY, id_token);
  return getTokenInStorage();
}

export function removeTokenInStorage() {
  window.localStorage.removeItem(LOCAL_STORAGE_TOKEN_KEY);
}

export function getUserIdInStorage() {
  return window.localStorage.getItem(LOCAL_STORAGE_USER_KEY);
}

export function setUserIdInStorage(id: string) {
  window.localStorage.setItem(LOCAL_STORAGE_USER_KEY, `${id}`);
  return getUserIdInStorage();
}

export function removeUserIdInStorage() {
  window.localStorage.removeItem(LOCAL_STORAGE_USER_KEY);
}

export function getAccountIdInStorage() {
  return window.localStorage.getItem(LOCAL_STORAGE_ACCOUNT_KEY);
}

export function setAccountIdInStorage(id: string) {
  window.localStorage.setItem(LOCAL_STORAGE_ACCOUNT_KEY, `${id}`);
  return getAccountIdInStorage();
}

export function removeAccountIdInStorage() {
  window.localStorage.removeItem(LOCAL_STORAGE_ACCOUNT_KEY);
}

export function clearStorage() {
  removeAccountIdInStorage();
  removeUserIdInStorage();
  removeTokenInStorage();
}
