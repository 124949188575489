import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import 'isomorphic-fetch';
import { Choice, Question } from './Question';
import { APIService, CallType } from '../../services/APIService';

import { Layout } from '../Layout';
import { Redirect } from '../Redirect';
import { getTokenInStorage } from 'src/clients/helpers';
import { Env } from 'src/constants/env';

import '../../styles/questionnaire.css';
import '../../styles/modal.css';
import { RouteContentScrollable } from 'src/components/RouteContentScrollable';
import { SiteFooter } from 'src/components/SiteFooter';
interface QuestionnaireState {
  questions: QuizQuestion[];
  currentQuestion: number;
  currentHelpText?: string;
  inProgress: boolean;
  loading: boolean;
  navigate: boolean;
  quizId: number;
  facilityId: string;
  resultId?: number;
}

export class Questionnaire extends React.Component<
  RouteComponentProps<{ id: string }>,
  QuestionnaireState
> {
  api: APIService | null = null;

  constructor(props: RouteComponentProps<{ id: string }>) {
    super(props);
    this.state = {
      questions: [],
      currentQuestion: 0,
      inProgress: true,
      loading: true,
      navigate: false,
      quizId: 0,
      facilityId: '0',
      resultId: 0,
    };
    this.nextQuestion = this.nextQuestion.bind(this);
    this.goBackOneQuestion = this.goBackOneQuestion.bind(this);
    this.startOver = this.startOver.bind(this);
    this.submit = this.submit.bind(this);
    this.submitQuiz = this.submitQuiz.bind(this);
    this.handleChoiceSelect = this.handleChoiceSelect.bind(this);
  }

  componentDidMount() {
    this.api = new APIService(Layout.notificationSystem);

    const facilityId = this.props.match.params.id
      ? this.props.match.params.id
      : '-1';
    this.setState({ facilityId: facilityId });

    this.api &&
      this.api
        .perform(`${Env.waaApiUrl}/Question`, CallType.Json)
        .then((data) => {
          this.setState({
            questions: data,
            loading: false,
            quizId: data[0].quizID,
          });

          // Check for existing results
          this.api &&
            this.api
              .perform(
                `${Env.waaApiUrl}/quizhistory/facility/${facilityId}`,
                CallType.Json
              )
              .then((response) => response as QuizResponse[])
              .then((quizResponses) => {
                if (quizResponses.length > 0) {
                  let questions: QuizQuestion[] = data.slice();
                  quizResponses.forEach((response) => {
                    let question = questions.find(
                      (q) => q.id === response.questionId
                    );
                    if (question) {
                      question.selectedId = response.choiceId;
                    }
                  });

                  this.setState({ questions: questions });
                }
              });
        });
  }

  public render() {
    if (this.state.navigate) {
      return <Redirect to={'/results/' + this.state.resultId}></Redirect>;
    }

    let token = getTokenInStorage();
    if (!token || token === 'expired') {
      return <Redirect to={'/login'}></Redirect>;
    }

    let contents = this.state.loading ? (
      <div className="loading-text">Loading...</div>
    ) : (
      this.renderQuestion()
    );

    return (
      <RouteContentScrollable className="module-waa">
        <div id="main-content">
          <div className="question background row">
            <div
              className="modal"
              tabIndex={parseInt('-1')}
              role="dialog"
              id="help"
            >
              <div className="modal-dialog" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                    <h4 className="modal-title">Help with this question</h4>
                  </div>
                  <div className="modal-body">
                    <p>{this.state.currentHelpText}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-5 col-md-offset-4 window">
              {contents}
              {this.renderQuestionNavigation()}
            </div>
          </div>
        </div>
        <SiteFooter solid style={{ width: '100%' }} />
      </RouteContentScrollable>
    );
  }

  renderQuestion() {
    let currentQuestion = this.state.questions[this.state.currentQuestion];

    if (this.state.inProgress) {
      return (
        <div className="left-content">
          <p className="number">
            {this.getCurrentQuestionDisplay(currentQuestion)}/
            {this.getQuizLength()}
          </p>
          <Question
            id={currentQuestion.id}
            order={currentQuestion.order}
            text={currentQuestion.prompt}
            choices={currentQuestion.choices}
            selectedId={currentQuestion.selectedId}
            handleSelect={this.handleChoiceSelect}
            helperText={currentQuestion.helperText}
            answerPrefix={currentQuestion.answerPrefix}
          />
        </div>
      );
    } else {
      return (
        <div className="left-content">
          <p className="question">All done!</p>
          <p className="answer-prefix">Click the button to submit.</p>
        </div>
      );
    }
  }

  getParentResponse(currentQuestion: QuizQuestion) {
    let parentQuestionResponse;
    for (let index = 0; index <= this.state.questions.length - 1; index++) {
      if (this.state.questions[index].id === currentQuestion.parentId) {
        parentQuestionResponse = this.state.questions[index].selectedId;
        break;
      }
    }

    return parentQuestionResponse;
  }

  getQuizLength() {
    let allQuestions = this.state.questions.length;
    let conditionalQuestions = 0;
    this.state.questions.forEach((question) => {
      if (question.conditional) {
        conditionalQuestions++;
      }
    });

    return allQuestions - conditionalQuestions;
  }

  getCurrentQuestionDisplay(currentQuestion: QuizQuestion) {
    if (currentQuestion.conditional) {
      return currentQuestion.order - 1;
    } else {
      return currentQuestion.order;
    }
  }

  handleChoiceSelect(event: React.MouseEvent<HTMLDivElement>) {
    let id = event.currentTarget.getAttribute('data-id');
    let selected: number = 0;
    if (id) {
      selected = parseInt(id);
    }
    let questions = this.state.questions.slice();
    questions[this.state.currentQuestion].selectedId = selected;
    this.setState({
      questions,
    });
  }

  goBackOneQuestion(event: React.MouseEvent<HTMLAnchorElement>) {
    event.preventDefault();
    if (this.state.currentQuestion > 0) {
      let questionIndex = this.state.currentQuestion - 1;
      if (!this.state.inProgress) {
        this.setState({
          inProgress: true,
        });
      } else {
        this.setState({
          currentQuestion: questionIndex,
          inProgress: true,
        });
      }
    }
  }

  noSelectionMade() {
    let currentQuestion = this.state.questions[this.state.currentQuestion];
    return !currentQuestion || !currentQuestion.selectedId;
  }

  nextQuestion() {
    if (this.noSelectionMade()) {
      return;
    }
    let questionIndex = this.state.currentQuestion + 1;

    this.checkAndProcessNextQuestion(questionIndex);
  }

  checkAndProcessNextQuestion(questionIndex: number) {
    let nextQuestion = this.state.questions[questionIndex];
    let nextIndex = questionIndex + 1;
    if (nextQuestion && nextQuestion.conditional) {
      // get parent response
      if (
        this.getParentResponse(nextQuestion) !==
        nextQuestion.parentConditionValue
      ) {
        if (questionIndex === this.state.questions.length - 1) {
          this.setState({
            inProgress: false,
          });
        } else {
          this.setState({
            currentQuestion: nextIndex,
            inProgress: true,
          });
        }
      } else {
        this.setState({
          currentQuestion: questionIndex,
          inProgress: true,
        });
      }
    } else if (questionIndex === this.state.questions.length) {
      this.setState({
        currentQuestion: questionIndex - 1,
        inProgress: false,
      });
    } else {
      this.setState({
        currentQuestion: questionIndex,
        inProgress: true,
      });
    }

    // scroll to top of page
    window.scroll(0, 0);
  }

  startOver(event: React.MouseEvent<HTMLAnchorElement>) {
    event.preventDefault();
    this.setState({
      currentQuestion: 0,
      inProgress: true,
    });
  }

  submit() {
    if (this.noSelectionMade()) {
      return;
    }

    let quizResponse = this.prepareResponse();

    // Save Quiz if facility ID was passed
    if (parseInt(this.state.facilityId) > 0) {
      this.submitQuiz(quizResponse);
    }
    // Otherwise we're creating a facility stored in FCService and then saving the quiz responses
    else {
      let facility = Layout.facilityCreationService.getFacility();
      this.api &&
        this.api
          .perform(`${Env.waaApiUrl}/facility`, CallType.Json, facility)
          .then((response) => {
            // Submit Quiz
            if (response.id) {
              Layout.facilityCreationService.clearFacility();
              quizResponse.facilityId = response.id;
              this.submitQuiz(quizResponse);
            }
          });
    }
  }

  private submitQuiz(quizResponse: any) {
    this.api &&
      this.api
        .perform(`${Env.waaApiUrl}/QuizResponse`, CallType.Json, quizResponse)
        .then((response) => {
          this.setState({
            resultId: parseInt(response),
            navigate: true,
          });
        });
  }

  prepareResponse() {
    let answers = this.state.questions
      .filter((question) => {
        return question.selectedId;
      })
      .map((question) => {
        return {
          questionId: question.id,
          choiceId: question.selectedId,
        };
      });
    let response = {
      quizId: this.state.quizId,
      facilityId: this.state.facilityId,
      answers: answers,
    };
    return response;
  }

  renderQuestionNavigation() {
    let sectionClass = 'left-content question-nav';

    if (this.state.inProgress) {
      if (this.state.currentQuestion === 0) {
        sectionClass += ' first-question';
      }
    } else {
      sectionClass += ' last-question';
    }

    return (
      <section className={sectionClass}>
        <div className={'col-md-12'}>
          <button
            className={
              'btn-itb btn-next' + (this.noSelectionMade() ? ' inactive' : '')
            }
            onClick={this.nextQuestion}
          >
            Next Question
          </button>
          <button className={'btn-itb btn-submit'} onClick={this.submit}>
            Submit
          </button>
          {/* eslint-disable-next-line */}
          <a href="#" onClick={this.goBackOneQuestion}>
            &lt; Back to previous
          </a>
          {/* eslint-disable-next-line */}
          <a href="#" onClick={this.startOver}>
            &lt;&lt; Start over
          </a>
        </div>
      </section>
    );
  }
}

interface QuizQuestion {
  id: number;
  order: number;
  prompt: string;
  choices: Choice[];
  selectedId: number;
  quizID: number;
  conditional: boolean;
  parentId: number;
  parentConditionValue: number;
  helperText: string;
  answerPrefix: string;
}

interface QuizResponse {
  id?: number;
  questionId: number;
  choiceId: number;
}
