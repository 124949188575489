import React, { FC } from 'react';
import classnames from 'classnames/bind';
import styles from '../Tabs.module.css';
import { TextInput } from 'src/components/TextInput';
import { Copy } from 'src/components/Copy';
import { SelectInput } from 'src/components/SelectInput';
import { Controller, useFormContext } from 'react-hook-form';
import { CheckboxInput } from 'src/components/CheckboxInput';
import { getCities, getCountries, getRegions } from 'src/clients/api/country';
import { getClassifications } from 'src/clients/api/classification';
import { useQuery } from 'react-query';
import { Facility } from 'src/types/facility';
import { FacilityTabFooter } from '../../Helpers/FacilityTabFooter';
import { useFormHelp } from 'src/hooks/useFormHelp';
import { FormHelpButton, FormHelpText } from '../../../FormHelp';
import { FacilityFormSchema } from '../../validation';
import { REPORTING_YEARS } from 'src/constants/years';

const cx = classnames.bind(styles);

type FacilityInformationProps = {
  facility?: Facility;
  onNext: () => void;
  onCancel: () => void;
  onDelete?: () => void;
  className?: string;
  showReportingYear?: boolean;
};

export const FacilityInformation: FC<FacilityInformationProps> = ({
  facility,
  onNext,
  onCancel,
  onDelete,
  className,
  showReportingYear = false,
}) => {
  const formHelp = useFormHelp();

  const {
    control,
    formState: { errors },
    watch,
    register,
  } = useFormContext<FacilityFormSchema>();

  const { data: countriesData, isLoading: isLoadingCountries } = useQuery(
    ['countries'],
    () => getCountries()
  );

  const countries =
    (countriesData?.status === 'success' && countriesData.value.result) || [];

  const fkCountry = watch('fkCountry');
  const { data: regionData, isLoading: isLoadingRegions } = useQuery(
    ['regions', fkCountry],
    () => getRegions(fkCountry),
    {
      enabled: !!fkCountry,
    }
  );

  const regions =
    (regionData?.status === 'success' && regionData.value.result) || [];

  const fkState = watch('fkState');
  const { data: cityData, isLoading: isLoadingCities } = useQuery(
    ['citys', fkState],
    () => getCities(fkState),
    {
      enabled: !!fkState,
    }
  );

  const citys = (cityData?.status === 'success' && cityData.value.result) || [];

  const {
    data: sectorsLevelOneData,
    isLoading: isLoadingClassificationsLevelOne,
  } = useQuery(['sectors', 'levelOne'], () => getClassifications());

  const sectorsLevelOne =
    (sectorsLevelOneData?.status === 'success' &&
      sectorsLevelOneData.value.result) ||
    [];

  const level1SectorId = watch('level1SectorId');
  const {
    data: sectorsLevelTwoData,
    isLoading: isLoadingClassificationsLevelTwo,
  } = useQuery(
    ['sectors', 'levelTwo', level1SectorId],
    () => getClassifications('2', `${level1SectorId}`),
    {
      enabled: !!level1SectorId,
    }
  );

  const sectorsLevelTwo =
    (sectorsLevelTwoData?.status === 'success' &&
      sectorsLevelTwoData.value.result) ||
    [];

  const level2SectorId = watch('level2SectorId');
  const {
    data: sectorsLevelThreeData,
    isLoading: isLoadingClassificationsLevelThree,
  } = useQuery(
    ['sectors', 'levelThree', level2SectorId],
    () => getClassifications('3', `${level2SectorId}`),
    {
      enabled: !!level2SectorId,
    }
  );

  const sectorsLevelThree =
    (sectorsLevelThreeData?.status === 'success' &&
      sectorsLevelThreeData.value.result) ||
    [];

  return (
    <div className={cx('tabContent', className)}>
      <form>
        <div className={cx('formGroup')}>
          <h1 className={cx('formGroupTitle')}>Facility Information</h1>
          <div className={cx('row')}>
            <div className={cx('label')}>
              <Copy as="h4">Facility Name</Copy>
            </div>
            <div className={cx('inputGroup')}>
              <div className={cx('input')}>
                <TextInput
                  className={cx('inputField')}
                  name="facilityName"
                  ref={register({ required: 'Required' })}
                  error={errors.facilityName}
                />
              </div>
            </div>
          </div>
          <div className={cx('row')}>
            <div className={cx('label')}>
              <Copy as="h4">Country</Copy>
            </div>
            <div className={cx('inputGroup')}>
              <div className={cx('input')}>
                <Controller
                  name="fkCountry"
                  control={control}
                  rules={{ required: 'Required' }}
                  render={(props) => (
                    <SelectInput
                      {...props}
                      className={cx('inputField')}
                      items={countries.map(({ id, local_name: localName }) => ({
                        label: localName,
                        value: `${id}`,
                      }))}
                      placeholder="&nbsp;"
                      error={errors.fkCountry}
                      disabled={countriesData?.status !== 'success'}
                      loading={isLoadingCountries}
                      onChange={(val) => props.onChange(val)}
                    />
                  )}
                />
              </div>
            </div>
          </div>
          <div className={cx('row')}>
            <div className={cx('label')}>
              <Copy as="h4">State/Province</Copy>
            </div>
            <div className={cx('inputGroup')}>
              <div className={cx('input')}>
                <Controller
                  name="fkState"
                  control={control}
                  rules={{ required: 'Required' }}
                  render={(props) => (
                    <SelectInput
                      {...props}
                      error={errors.fkState}
                      items={regions.map(({ id, local_name: localName }) => ({
                        label: localName,
                        value: `${id}`,
                      }))}
                      placeholder="&nbsp;"
                      className={cx('inputField')}
                      disabled={regionData?.status !== 'success'}
                      loading={isLoadingRegions}
                      onChange={(val) => props.onChange(val)}
                    />
                  )}
                />
              </div>
            </div>
          </div>
          <div className={cx('row')}>
            <div className={cx('label')}>
              <Copy as="h4">City</Copy>
            </div>
            <div className={cx('inputGroup')}>
              <div className={cx('input')}>
                <Controller
                  name="fkCity"
                  control={control}
                  rules={{ required: 'Required' }}
                  render={(props) => (
                    <SelectInput
                      {...props}
                      error={errors.fkCity}
                      placeholder="&nbsp;"
                      items={citys.map(({ id, local_name: localName }) => ({
                        label: localName,
                        value: `${id}`,
                      }))}
                      className={cx('inputField')}
                      disabled={cityData?.status !== 'success'}
                      loading={isLoadingCities}
                      onChange={(val) => props.onChange(val)}
                    />
                  )}
                />
              </div>
            </div>
          </div>
          {showReportingYear ? (
            <div className={cx('row')}>
              <div className={cx('label')}>
                <Copy as="h4">Reporting Year</Copy>
              </div>
              <div className={cx('inputGroup')}>
                <div className={cx('input')}>
                  <Controller
                    name="reportingYear"
                    control={control}
                    defaultValue={REPORTING_YEARS[0]}
                    render={(props) => (
                      <SelectInput
                        {...props}
                        className={cx('inputField')}
                        items={REPORTING_YEARS.map((year) => ({
                          label: `${year}`,
                          value: `${year}`,
                        }))}
                        placeholder="&nbsp;"
                        onChange={(val) => props.onChange(val)}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          ) : null}
          <div className={cx('row')}>
            <div className={cx('label')}>
              <Copy as="h4">Industry Classification</Copy>
            </div>
            <div className={cx('inputGroup')}>
              <div className={cx('input')}>
                <Controller
                  name="level1SectorId"
                  control={control}
                  rules={{ required: 'Required' }}
                  render={(props) => (
                    <SelectInput
                      {...props}
                      error={errors.level1SectorId}
                      placeholder="&nbsp;"
                      items={sectorsLevelOne.map(
                        ({ fkSector, sectorName: label }) => ({
                          label,
                          value: `${fkSector}`,
                        })
                      )}
                      className={cx('inputField')}
                      disabled={sectorsLevelOneData?.status !== 'success'}
                      loading={isLoadingClassificationsLevelOne}
                      onChange={(val) => props.onChange(val)}
                    />
                  )}
                />
              </div>
            </div>
          </div>
          <div className={cx('row')}>
            <div className={cx('label')}></div>
            <div className={cx('inputGroup')}>
              <div className={cx('input')}>
                <Controller
                  name="level2SectorId"
                  control={control}
                  rules={{ required: 'Required' }}
                  render={(props) => (
                    <SelectInput
                      {...props}
                      error={errors.level2SectorId}
                      placeholder="&nbsp;"
                      items={sectorsLevelTwo.map(
                        ({ fkSector, sectorName: label }) => ({
                          label,
                          value: `${fkSector}`,
                        })
                      )}
                      className={cx('inputField')}
                      disabled={sectorsLevelTwoData?.status !== 'success'}
                      loading={isLoadingClassificationsLevelTwo}
                      onChange={(val) => props.onChange(val)}
                    />
                  )}
                />
              </div>
            </div>
          </div>
          <div className={cx('row')}>
            <div className={cx('label')}></div>
            <div className={cx('inputGroup')}>
              <div className={cx('input')}>
                <Controller
                  name="fkSector"
                  control={control}
                  rules={{ required: 'Required' }}
                  render={(props) => (
                    <SelectInput
                      {...props}
                      error={errors.fkSector}
                      placeholder="&nbsp;"
                      items={sectorsLevelThree.map(
                        ({ fkSector, sectorName: label }) => ({
                          label,
                          value: `${fkSector}`,
                        })
                      )}
                      className={cx('inputField')}
                      disabled={sectorsLevelThreeData?.status !== 'success'}
                      loading={isLoadingClassificationsLevelThree}
                      onChange={(val) => props.onChange(val)}
                    />
                  )}
                />
              </div>
            </div>
          </div>
          <div className={cx('row')}>
            <div className={cx('label')}>
              <Copy as="h4">Facility Label</Copy>
              <FormHelpButton formHelp={formHelp} name="label" />
            </div>
            <div className={cx('inputGroup')}>
              <div className={cx('input')}>
                <TextInput
                  className={cx('inputField')}
                  name="label"
                  ref={register}
                />
              </div>
              <FormHelpText formHelp={formHelp} name="label">
                Enter a label that classifies this facility and can be used to
                filter your facilities.
              </FormHelpText>
            </div>
          </div>
          <div className={cx('row')}>
            <div className={cx('label')}>
              <Copy as="h4">Active</Copy>
              <FormHelpButton formHelp={formHelp} name="active" />
            </div>
            <div className={cx('inputGroup')}>
              <div className={cx('input')}>
                <Controller
                  name="active"
                  control={control}
                  render={(props) => (
                    <CheckboxInput
                      {...props}
                      className={cx('inputField', 'checkbox')}
                      error={errors.active}
                    />
                  )}
                ></Controller>
              </div>
              <FormHelpText formHelp={formHelp} name="active">
                Indicates whether a facility is active or inactive. This field
                is helpful if facilities are closed at certain times or
                permanently.
              </FormHelpText>
            </div>
          </div>
          <div className={cx('row')}>
            <div className={cx('label')}>
              <Copy as="h4">Modeling Only</Copy>
              <FormHelpButton formHelp={formHelp} name="modelingOnly" />
            </div>
            <div className={cx('inputGroup')}>
              <div className={cx('input')}>
                <Controller
                  name="modelingOnly"
                  control={control}
                  defaultValue={facility?.modelingOnly ?? false}
                  render={(props) => (
                    <CheckboxInput
                      {...props}
                      className={cx('inputField', 'checkbox')}
                      error={errors.modelingOnly}
                    />
                  )}
                ></Controller>
              </div>
              <FormHelpText formHelp={formHelp} name="modelingOnly">
                Indicates if a facility is to be used for modeling purposes only
                i.e., if theoretical data will be input to model different
                scenarios or plan for new facilities.
              </FormHelpText>
            </div>
          </div>
        </div>
      </form>
      <FacilityTabFooter
        onDelete={onDelete}
        onCancel={onCancel}
        onNext={onNext}
        canNext={true}
        nextButtonText="next"
      />
    </div>
  );
};

FacilityInformation.displayName = 'FacilityInformation';
