import React, { FC, useState } from 'react';
import classnames from 'classnames/bind';

import styles from './User.module.css';
import { TextInput } from 'src/components/TextInput';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  editUserFormSchema,
  saveUser,
  SaveUserRequestType,
} from 'src/clients/api/user';
import { Button } from 'src/components/Button';
import { RouteContentScrollable } from 'src/components/RouteContentScrollable';
import { Copy } from 'src/components/Copy';
import { useQuery, useQueryClient } from 'react-query';
import { useEffect } from 'react';
import { LoadingSpinner } from 'src/components/LoadingSpinner';
import { BlockError } from 'src/components/BlockError';
import { animated, config, useSpring } from 'react-spring';
import { Alert } from 'src/components/Alert';
import Icon from '@mdi/react';
import { mdiAccountCircle } from '@mdi/js';
import { useAuth } from 'src/hooks/useAuth';

const cx = classnames.bind(styles);
const RESOLVER = yupResolver<SaveUserRequestType>(editUserFormSchema);

type UserProps = {};

export const User: FC<UserProps> = () => {
  const { fetchUser, isAuthenticated } = useAuth();
  const queryClient = useQueryClient();
  const [submission, setSubmission] = useState<
    { state: 'success' | 'error'; message: string } | undefined
  >();

  const { isLoading, error, data } = useQuery(['user', 'current'], fetchUser!, {
    enabled: !!isAuthenticated,
  });

  const [isSaving, setIsSaving] = useState(false);

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors, isValid },
  } = useForm<SaveUserRequestType>({
    resolver: RESOLVER,
    mode: 'onChange',
  });

  useEffect(() => {
    if (data?.status === 'success') {
      const {
        firstName,
        lastName,
        companyName,
        jobTitle,
        email,
      } = data.value.result;
      reset({ firstName, lastName, companyName, jobTitle, email });
    }
  }, [data, reset]);

  const onSubmit = async (values: SaveUserRequestType) => {
    if (data?.status === 'success' && data.value.result.userId) {
      setSubmission(undefined);
      setIsSaving(true);
      try {
        const result = await saveUser(`${data.value.result.userId}`, values);

        if (result.status === 'error') {
          throw new Error(result.value.errors[0].message);
        }

        setIsSaving(false);
        setSubmission({
          state: 'success',
          message: 'User information updated successfully',
        });
        queryClient.refetchQueries(['user', 'current']);
      } catch (e) {
        setIsSaving(false);
        setSubmission({
          state: 'error',
          message:
            e.message ?? 'There was an error updating your user information.',
        });
      }
    }
  };

  const animate = useSpring({
    opacity: 1,
    transform: 'translate(0px, 0px)',
    from: { opacity: 0, transform: 'translate(0px, -10px)' },
    config: config.molasses,
  });

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (error || data?.status !== 'success') {
    return <BlockError />;
  }

  return (
    <RouteContentScrollable className={cx('scrollable')}>
      <div>
        <div className={cx('user')} data-testid="user">
          <aside>
            <div className={cx('sidearm')}>
              <Icon path={mdiAccountCircle} size="128" className={cx('icon')} />
              <div>
                <span className={cx('userName')}>
                  {data.value.result.firstName} {data.value.result.lastName}
                </span>
                <span className={cx('companyName')}>
                  {data.value.result.companyName}
                </span>
              </div>
            </div>
          </aside>
          <section>
            <div className={cx('userBox')}>
              <Copy as="h2">Edit Profile</Copy>
              {submission && (
                <div className={cx(submission.state)}>
                  <animated.div style={animate}>
                    <Alert
                      variant={
                        submission.state === 'error' ? 'danger' : 'success'
                      }
                      message={submission.message}
                    />
                  </animated.div>
                </div>
              )}

              <form
                onSubmit={handleSubmit(onSubmit)}
                className={cx('editUserForm')}
              >
                <TextInput
                  readOnly
                  disabled
                  type="text"
                  name="email"
                  ref={register}
                  placeholder="email"
                  className={cx('input')}
                />

                <TextInput
                  type="text"
                  name="firstName"
                  ref={register}
                  placeholder="First name"
                  autoComplete="given-name"
                  error={errors.firstName}
                  className={cx('input')}
                />

                <TextInput
                  type="text"
                  name="lastName"
                  ref={register}
                  placeholder="Last name"
                  autoComplete="family-name"
                  error={errors.lastName}
                  className={cx('input')}
                />

                <TextInput
                  type="text"
                  name="companyName"
                  ref={register}
                  placeholder="Company name"
                  autoComplete="organization"
                  error={errors.companyName}
                  className={cx('input')}
                />

                <TextInput
                  type="text"
                  name="jobTitle"
                  ref={register}
                  placeholder="Job title"
                  autoComplete="organization-title"
                  error={errors.jobTitle}
                  className={cx('input')}
                />

                <Button
                  type="submit"
                  className={cx('buttonSubmit')}
                  isLoading={isSaving}
                  disabled={!isValid}
                >
                  Save
                </Button>
              </form>
            </div>
          </section>
        </div>
      </div>
    </RouteContentScrollable>
  );
};

User.displayName = 'User';
