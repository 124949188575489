import { Env } from 'src/constants/env';
import { Assessment } from 'src/types/assessment';
import request from '../request';
import { WrappedResponse } from '../types';

export type GetAssessmentResponseType = WrappedResponse<Assessment>;
export function getAssessment() {
  const assessmentId = `15e58462-9ac6-4a10-800e-47ffdc484d69`;
  return request.get<GetAssessmentResponseType>(
    `${Env.apiUrl}/v4/assessments/${assessmentId}`
  );
}
