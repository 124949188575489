import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Link } from '../Link';
import '../../styles/assessment.css';
import { RouteContentScrollable } from 'src/components/RouteContentScrollable';
import { SiteFooter } from 'src/components/SiteFooter';

export class ResultOptions extends React.Component<
  RouteComponentProps<{ id: string }>,
  {}
> {
  public render() {
    return (
      <RouteContentScrollable className="module-waa">
        <div id="main-content">
          <div className="assessment">
            <div className="centered-content">
              <h1>See the results</h1>
              <p>
                You're almost there! Please choose if you would like to dive
                into your facility results, or view company benchmark data. You
                can access both of these areas at any time throughout the
                dashboard.
              </p>
            </div>
            <div className="row">
              <div className="col-sm-12 col-md-4 col-md-offset-2 divider">
                <h2>Dive into the data</h2>
                <p>
                  The site detail page is where all of the data around your
                  facility results are collected and displayed. All the tools
                  and resources are available through your specific Industry
                  Guide to begin the process of moving your site up the Water
                  Maturity Curve.
                </p>
                <div>
                  <Link
                    to={'/result/' + this.props.match.params.id}
                    className="btn-itb"
                  >
                    Site Details
                  </Link>
                </div>
              </div>
              <div className="col-sm-12 col-md-4">
                <h2>Go to company dashboard</h2>
                <p>
                  The dashboard page displays all of the sites within your
                  company. You can download site data, see company benchmarks,
                  and sort results.
                </p>
                <div>
                  <Link to={'/dashboard'} className="btn-itb">
                    Dashboard
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <SiteFooter solid style={{ width: '100%' }} />
      </RouteContentScrollable>
    );
  }
}
