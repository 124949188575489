import React, { FC, useState } from 'react';
import classnames from 'classnames/bind';
import Icon from '@mdi/react';
import {
  mdiChevronDown,
  mdiAlertCircle,
  mdiFileEditOutline,
  mdiDownloadBoxOutline,
} from '@mdi/js';
import { useQuery } from 'react-query';
import {
  getFacilitiesList,
  getFacilitiesListExport,
} from 'src/clients/api/facilty';
import { LoadingSpinner } from 'src/components/LoadingSpinner';
import { BlockError } from 'src/components/BlockError';
import { TruncatedFacility } from 'src/types/facility';
import { Link } from 'react-router-dom';
import styles from './Facilities.module.css';
import { Copy } from 'src/components/Copy';
import { SelectInput } from 'src/components/SelectInput';
import { useDeepCompareMemo } from 'src/hooks/useDeepCompare';
import { useAuth } from 'src/hooks/useAuth';
import natsort from 'natsort';

const cx = classnames.bind(styles);

const FILTER_OPTIONS = {
  name: 'Name',
  reportingYear: 'Reporting Year',
  folderName: 'Folder Name',
  cityName: 'City Name',
  stateName: 'State Name',
  countryName: 'Country Name',
  sectorName: 'Sector Name',
  active: 'Status',
  modelingOnly: 'Modeling Only',
} as const;

type FacilitiesListItemProps = {
  facility: TruncatedFacility;
};

const FacilitiesListItem: FC<FacilitiesListItemProps> = ({ facility }) => {
  const { userId } = useAuth();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <li className={cx('listItem')}>
      <div className={cx('edit')}>
        <Link
          className={cx('link')}
          to={`/wrm/facilities/${facility.facilityId}/wrm/year/${facility.reportingYear}`}
        >
          <Icon path={mdiFileEditOutline} size="18" />
        </Link>
      </div>

      <div className={cx('content')}>
        <div className={cx('titleBar')} onClick={() => setIsOpen(!isOpen)}>
          <span className={cx('title')}>
            {facility.name} - {facility.reportingYear} | {facility.cityName},{' '}
            {facility.stateName}
          </span>
          <div className={cx('titleBarSecondary')}>
            {!(facility.waaFacility && facility.wrmFacility) ? (
              <Icon path={mdiAlertCircle} className={cx('icon', 'alert')} />
            ) : null}
            <span>{facility.active ? 'ACTIVE' : 'INACTIVE'}</span>
            <Icon
              path={mdiChevronDown}
              className={cx('icon', { open: isOpen })}
            />
          </div>
        </div>
        {isOpen ? (
          <div className={cx('extended')}>
            <div className="facility-year">
              <strong>Year:</strong> {facility.reportingYear}
            </div>
            <div className="facility-label">
              <strong>Label:</strong> {facility.label}
            </div>
            <div className="facility-status">
              <strong>Status: </strong>{' '}
              {facility.active ? 'Active' : 'Inactive'}
            </div>
            <div className="facility-modeling">
              <strong>Modeling only: </strong>{' '}
              {facility.modelingOnly ? 'True' : 'False'}
            </div>
            <br />
            {!(facility.waaFacility && facility.wrmFacility) ? (
              <div className="facility-modeling">
                <strong>Missing data: </strong>
                <br />

                {!facility.wrmFacility ? (
                  <Link
                    to={`/wrm/facilities/${facility.facilityId}/wrm/year/${facility.reportingYear}`}
                  >
                    <Icon
                      path={mdiAlertCircle}
                      className={cx('icon', 'alert')}
                    />
                    Water Risk Monetizer
                  </Link>
                ) : null}
                <br />
                {!facility.waaFacility ? (
                  `${facility.userId}` === userId ? (
                    <Link
                      to={{
                        state: {
                          facilityId: facility.facilityId,
                        },
                        pathname: `/wrm/facilities/create/waa`,
                      }}
                    >
                      <Icon
                        path={mdiAlertCircle}
                        className={cx('icon', 'alert')}
                      />
                      Water Action Assessment
                    </Link>
                  ) : (
                    <>
                      <Icon
                        path={mdiAlertCircle}
                        className={cx('icon', 'alert')}
                      />
                      Request admin to complete Water Action Assessment
                    </>
                  )
                ) : null}
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
    </li>
  );
};

type FacilitiesProps = {};
export const Facilities: FC<FacilitiesProps> = () => {
  const { accountId } = useAuth();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [sortState, setSortState] = useState<{
    key: keyof typeof FILTER_OPTIONS;
    dir: 'asc' | 'desc';
  }>({
    key: 'name',
    dir: 'asc',
  });

  const { isLoading, isFetching, data, error, refetch } = useQuery(
    ['facilitiesList'],
    () => getFacilitiesList()
  );

  const facilities =
    data?.status === 'success' && data?.value.result ? data?.value.result : [];

  const sortedFacilities = useDeepCompareMemo(() => {
    const sorter = natsort({ desc: sortState.dir === 'desc' });
    return facilities.sort((a, b) => {
      return sorter(
        `${(a[sortState.key] ?? '').toString()}`,
        `${(b[sortState.key] ?? '').toString()}`
      );
    });
  }, [facilities, sortState]);

  if (isLoading || isFetching) {
    return <LoadingSpinner block />;
  }

  if (error || !data || data.status === 'error') {
    return (
      <BlockError
        retry={refetch}
        isLoading={isFetching}
        title="Error loading facilities"
        message="We were unable to load your facilities successfully"
      />
    );
  }

  const handleExport = async () => {
    setIsSubmitting(true);
    try {
      if (accountId) {
        const result = await getFacilitiesListExport(accountId);

        if (result.status === 'success') {
          window.open(result.value.result, '_self');
        }

        setIsSubmitting(false);
      } else {
        throw new Error();
      }
    } catch (e) {
      setIsSubmitting(false);
    }
  };
  const handleSortChange = (value: string) => {
    if (sortState.key === value) {
      setSortState({
        ...sortState,
        dir: sortState.dir === 'asc' ? 'desc' : 'asc',
      });
    } else {
      setSortState({
        ...sortState,
        key: value as keyof typeof FILTER_OPTIONS,
      });
    }
  };

  return (
    <div className={cx('facilities')}>
      <Copy as="h2">Facilities List</Copy>
      <header className={cx('actions')}>
        <div className={cx('sort')}>
          <header className={cx('sortAndFilterTitle')}>Filter by:</header>
          <SelectInput
            items={Object.entries(FILTER_OPTIONS).map(([value, label]) => ({
              value,
              label,
            }))}
            value={sortState.key}
            placeholder="&nbsp;"
            onChange={handleSortChange}
          />
        </div>
        <div className={cx('download')}>
          <button type="button" onClick={handleExport}>
            {isSubmitting ? (
              <LoadingSpinner />
            ) : (
              <Icon
                path={mdiDownloadBoxOutline}
                size="42"
                className={cx('downloadIcon')}
              />
            )}
          </button>
        </div>
      </header>
      <ul className={cx('list')}>
        {sortedFacilities.map((f) => (
          <FacilitiesListItem facility={f} key={f.facilityId} />
        ))}
      </ul>
    </div>
  );
};

Facilities.displayName = 'Facilities';
