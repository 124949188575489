import { RouteProps } from '../../components/Route';
import { About } from './modules/About';
import { Account } from './modules/Account';
import { Dashboard } from './modules/Dashboard';
import { ForgotPassword } from './modules/ForgotPassword';
import { Invitation } from './modules/Invitation';
import { Journey } from './modules/Journey';
import { Login } from './modules/Login';
import { Logout } from './modules/Logout';
import { Registration } from './modules/Registration';
import { ResetPassword } from './modules/ResetPassword';
import { User } from './modules/User';
import { VerificationRequired } from './modules/VerificationRequired';
import { VerifyAccount } from './modules/VerifyAccount';

const routes: Array<RouteProps> = [
  {
    path: '/dashboard',
    component: Dashboard,
    variant: 'protected',
  },
  {
    path: '/login',
    component: Login,
    variant: 'public',
  },
  {
    path: '/logout',
    component: Logout,
  },
  {
    path: '/register',
    component: Registration,
    variant: 'public',
  },
  {
    path: '/forgot-password',
    component: ForgotPassword,
    variant: 'public',
  },
  {
    path: '/(reset-password|change-password)',
    component: ResetPassword,
    variant: 'public',
  },
  {
    path: '/verify-account',
    exact: true,
    component: VerificationRequired,
  },
  {
    path: '/verify-account/:code',
    exact: true,
    component: VerifyAccount,
  },
  {
    path: '/journey',
    component: Journey,
  },
  {
    path: '/user',
    component: User,
    variant: 'protected',
  },
  {
    path: '/account',
    component: Account,
    variant: 'protected',
  },
  {
    path: '/invitation',
    component: Invitation,
  },
  {
    path: '/about',
    exact: false,
    component: About,
  },
];

export default routes;
