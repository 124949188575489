import React, { FC } from 'react';
import classnames from 'classnames/bind';
import { useQuery } from 'react-query';
import { getFacilities } from 'src/clients/api/facilty';
import { LoadingSpinner } from 'src/components/LoadingSpinner';
import { BlockError } from 'src/components/BlockError';
import { Copy } from 'src/components/Copy';
import { Link } from 'react-router-dom';
import styles from './Benchmarking.module.css';
import tabStyles from '../../components/FacilityForm/Helpers/FacilityTabNavigator/FacilityTabNavigator.module.css';
import { FacilityRiskFilter } from '../../components/FacilityRiskFilter';
import { FacilityRiskFilterProvider } from 'src/hooks/useFacilityRiskFilter';
import { FilterAndSort } from '../../components/FilterAndSort';
import { BenchmarkingChart } from '../../components/BenchmarkingChart';
import { useFilterAndSort } from 'src/hooks/useFilterAndSort';
import { getFolders } from 'src/clients/api/folder';
import { ProcessedFacilities } from 'src/types/processedFacility';
import { Folders } from 'src/types/folder';
import { useDeepCompareMemo } from 'src/hooks/useDeepCompare';
import { filter } from 'src/hooks/useFilterAndSort/utils';

const cx = classnames.bind(styles);

type BenchmarkingProps = {};

const BenchmarkingWrapper: FC<{
  facilities: ProcessedFacilities;
  folders: Folders;
}> = ({ facilities, folders, children }) => (
  <>
    <header>
      <nav className={cx('tabs', tabStyles.tabs)}>
        <div className={cx('shadow', tabStyles.shadow)}></div>
        <Link
          className={cx('tab', tabStyles.tab)}
          to={'/wrm/enterprise/risk-analysis'}
        >
          Risk Analysis
        </Link>
        <div
          className={cx(
            'tab',
            'active',
            `${tabStyles.tab} ${tabStyles.active}`
          )}
        >
          Benchmarking
        </div>
      </nav>
    </header>
    <div className={cx('benchmarking')}>
      <div className={cx('header')}>
        <Copy as="h2">Benchmarking</Copy>
        <Copy as="p">
          This Benchmarking chart allows a company to compare the performance of
          different sites within a facility portfolio. This analysis provides a
          way for business leaders to prioritize and determine appropriate water
          use efficiency strategies for each location in order to bring sites
          closer to the industry, or organizational, average or median
          water-intensity.
        </Copy>
      </div>
      <div className={cx('filterAndSort')}>
        <FilterAndSort facilities={facilities} folders={folders} />
      </div>
      {children}
    </div>
  </>
);

export const Benchmarking: FC<BenchmarkingProps> = () => {
  const filterState = useFilterAndSort();

  const { data: folderData } = useQuery(['folders'], () => getFolders());

  const { isLoading, isFetching, data, error, refetch } = useQuery(
    ['facilities', 'folder', filterState.folderId],
    () => getFacilities(filterState.folderId)
  );

  const facilities =
    data?.status === 'success' && data?.value.result ? data?.value.result : [];

  const folders =
    folderData?.status === 'success' && folderData?.value.result
      ? folderData?.value.result
      : [];

  const filteredFacilities = useDeepCompareMemo(
    () => filter(facilities, filterState),
    [facilities, filterState]
  );

  if (isLoading) {
    return (
      <BenchmarkingWrapper facilities={filteredFacilities} folders={folders}>
        <LoadingSpinner />
      </BenchmarkingWrapper>
    );
  }

  if (error || !data || data.status === 'error') {
    return (
      <BenchmarkingWrapper facilities={filteredFacilities} folders={folders}>
        <BlockError
          retry={refetch}
          isLoading={isFetching}
          title="Error loading facilities"
          message="We were unable to load your facilities successfully"
        />
      </BenchmarkingWrapper>
    );
  }

  return (
    <BenchmarkingWrapper facilities={facilities} folders={folders}>
      <div className={cx('content')}>
        <FacilityRiskFilterProvider facilities={filteredFacilities}>
          <div className={cx('charts')}>
            <BenchmarkingChart />
          </div>
          <div className={cx('sidebar')}>
            <FacilityRiskFilter />
          </div>
        </FacilityRiskFilterProvider>
      </div>
    </BenchmarkingWrapper>
  );
};

Benchmarking.displayName = 'Benchmarking';
