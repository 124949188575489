import React, { FC, PropsWithChildren, useEffect } from 'react';
import { useThemeManager } from 'src/hooks/useThemeManager';

type TakeActionProps = Record<string, unknown>;

export const TakeAction: FC<PropsWithChildren<TakeActionProps>> = () => {
  const themeManager = useThemeManager();

  useEffect(() => {
    if (themeManager.setThemeProperties) {
      themeManager?.setThemeProperties({
        showSubheader: true,
        subheaderVariant: 'wrm',
      });
    }

    return () => {
      if (themeManager.resetThemeProperties) {
        themeManager.resetThemeProperties();
      }
    };
  }, []);

  return (
    <div className="container" id="main-container">
      <div className="row">
        <div id="primary" className="content-area col-md-8">
          <main id="main" className="site-main" role="main">
            <article
              id="post-3764"
              className="post-3764 page type-page status-publish hentry"
            >
              <header className="entry-header">
                <h1 className="entry-title">Take Action</h1>{' '}
              </header>
              {/* .entry-header */}
              <div className="entry-content">
                <p>
                  By monetizing risk, the tool can help companies align water
                  use with availability and quality factors and evaluate new
                  infrastructure investments, procurement strategies, innovation
                  and product portfolios. The information provided by the tool
                  can be used to support a successful corporate water strategy.
                </p>
                <h3>
                  <span style={{ color: '#096490' }}>
                    Key attributes of a successful corporate water strategy
                  </span>
                </h3>
                <ol>
                  <li>
                    <strong>
                      <span style={{ color: '#096490' }}>
                        Establish a water management plan and goals aligned with
                        overarching business and sustainability strategies
                      </span>
                    </strong>
                  </li>
                  <li>
                    <strong>
                      <span style={{ color: '#096490' }}>
                        Assess and monetize business risks based on a holistic
                        understanding of what water means to your business
                      </span>
                    </strong>
                  </li>
                  <li>
                    <strong>
                      <span style={{ color: '#096490' }}>
                        Prioritize actions and set context-based targets based
                        on site-specific risk
                      </span>
                    </strong>
                  </li>
                  <li>
                    <strong>
                      <span style={{ color: '#096490' }}>
                        Identify opportunities to minimize water risk, maximize
                        performance results and optimize costs (reduce, reuse
                        and recycle)
                      </span>
                    </strong>
                  </li>
                  <li>
                    <strong>
                      <span style={{ color: '#096490' }}>
                        Execute water management plan using a
                        “plan-do-check-act” cycle
                      </span>
                    </strong>
                  </li>
                </ol>
                <p>
                  The Water Risk Monetizer provides actionable information and
                  directional guidance to help companies make decisions based on
                  the intersection of growth goals, key business drivers and
                  water-related risks.
                </p>
                <hr />
                <h3>
                  <span style={{ color: '#6fb7ce' }}>
                    Use the Water Risk Monetizer to:
                  </span>
                </h3>
                <ul>
                  <li>Understand the full value of water to an operation</li>
                  <li>
                    Identify operations/locations at greatest risk based on
                    quantity and quality risks and growth projections
                  </li>
                  <li>
                    Inform site-specific sustainable water withdrawal targets to
                    contribute to risk reduction in a water basin
                  </li>
                  <li>
                    Prioritize water conservation and reuse investments based on
                    site-specific water risks
                  </li>
                  <li>
                    Incorporate risk-adjusted incoming and outgoing water costs
                    into a facility budget, financial projections, business
                    scenarios, project proposals, etc.
                  </li>
                  <li>
                    Make the case for proactive water management strategies by
                    utilizing risk-adjusted costs to demonstrate potential
                    risk-based return on investment compared to conventional
                    modeling
                  </li>
                  <li>
                    Select where and how to manage water intensity on a
                    production or revenue basis
                  </li>
                  <li>
                    Determine where to expand to meet demand in new regions
                  </li>
                </ul>
                <p>
                  <strong>At an enterprise level</strong>, the Water Risk
                  Monetizer can be used to evaluate and prioritize locations
                  that are high priority (at highest risk) for investing in
                  water efficiency and stewardship projects.
                </p>
                <p>
                  <strong>At a facility level</strong>, sustainable water
                  withdrawal targets can be set, and risk-based return can be
                  calculated to help prioritize investment projects. Facility
                  managers should seek expert advice from solution providers
                  about site specific conditions and the suitability of
                  different solutions at these high-risk locations.
                </p>
                <p>&nbsp;</p>
                <blockquote>
                  <p style={{ textAlign: 'left' }}>
                    <span style={{ color: '#096490' }}>
                      <em>
                        “When considering the various ways that Mondelez
                        International addresses sustainability issues around the
                        world, nothing is more important than conserving the
                        valuable resource of water. We worked with Ecolab and
                        Trucost to help develop the Water Risk Monetizer tool
                        which will help us start to quantify water risk in
                        financial terms, make more informed decisions to
                        mitigate water-related risks and support company
                        growth.” &nbsp;
                      </em>{' '}
                      – Mondelez
                    </span>
                  </p>
                  <p style={{ textAlign: 'left' }}></p>
                </blockquote>
                <hr />
                <h3>
                  <span style={{ color: '#6fb7ce' }}>
                    Risk-based action planning
                  </span>
                </h3>
                <p>
                  The information provided by the Water Risk Monetizer can be
                  used to help businesses around the world better understand
                  water risks and the potential cost implications of water
                  quantity and quality at a particular facility. The data
                  provides valuable information to help determine how water
                  costs or scarcity may affect growth plans and inform business
                  goals around prioritization of water management programs and
                  local target-setting strategies. Facilities are categorized in
                  one of four risk levels outlined in the table below.
                </p>
                <table
                  style={{
                    lineHeight: '16px',
                    height: 0,
                    border: '1px solid #d9d9d9',
                    backgroundColor: '#f0f8fa',
                  }}
                  width="100%"
                  cellSpacing={5}
                  cellPadding={0}
                >
                  <tbody>
                    <tr
                      style={{
                        backgroundColor: '#94ceda',
                        height: 55,
                        padding: 15,
                      }}
                    >
                      <td
                        style={{
                          width: 127,
                          padding: 15,
                          textAlign: 'left',
                          verticalAlign: 'top',
                        }}
                        width={127}
                      >
                        <h4>
                          <strong>
                            <span style={{ color: '#ffffff' }}>
                              Facility Risk Level
                            </span>
                          </strong>
                        </h4>
                      </td>
                      <td
                        style={{
                          width: 250,
                          padding: 15,
                          textAlign: 'left',
                          verticalAlign: 'top',
                        }}
                        width={154}
                      >
                        <h4>
                          <strong>
                            <span style={{ color: '#ffffff' }}>Context</span>
                          </strong>
                        </h4>
                      </td>
                      <td
                        style={{
                          width: 250,
                          padding: 15,
                          textAlign: 'left',
                          verticalAlign: 'top',
                        }}
                        width={192}
                      >
                        <h4>
                          <strong>
                            <span style={{ color: '#ffffff' }}>
                              Action&nbsp;&nbsp;
                            </span>
                          </strong>
                        </h4>
                      </td>
                    </tr>
                    <tr
                      style={{
                        backgroundColor: '#ffffff',
                        height: 40,
                        padding: 15,
                      }}
                    >
                      <td
                        style={{
                          width: 127,
                          padding: 15,
                          textAlign: 'left',
                          verticalAlign: 'top',
                        }}
                        width={127}
                      >
                        <h6>
                          <strong>
                            Significant potential for business risk
                          </strong>
                        </h6>
                        <h6>(High Growth – High Water Scarcity)</h6>
                      </td>
                      <td
                        style={{
                          width: 250,
                          padding: 15,
                          lineHeight: '16px',
                          textAlign: 'left',
                          verticalAlign: 'top',
                        }}
                        width={154}
                      >
                        <h6>
                          Today, more than ever before, companies recognize that
                          water poses a significant risk to business. For some
                          facilities located in high water stress basins, urgent
                          action is required to prevent threats to business
                          vitality ranging from disruptions in operations and
                          rising costs to reputational damage and loss of
                          license to operate. Facilities in locations where high
                          growth rates could be hindered by high water scarcity
                          should take the following steps:
                        </h6>
                        <ol>
                          <li>
                            <h6>
                              Prioritize locations for targeted action plans
                              based on site-specific risk&nbsp;(locations where
                              water risk is most material)
                            </h6>
                          </li>
                          <li>
                            <h6>
                              Identify opportunities to minimize water risk,
                              maximize performance results and optimize
                              costs&nbsp;(reduce, reuse and recycle)
                            </h6>
                          </li>
                          <li>
                            <h6>
                              Execute water management plan using a
                              “plan-do-check-act” cycle
                            </h6>
                          </li>
                        </ol>
                      </td>
                      <td
                        style={{
                          width: 250,
                          padding: 15,
                          textAlign: 'left',
                          verticalAlign: 'top',
                        }}
                        width={192}
                      >
                        <h6>
                          <strong>INVEST:</strong>
                        </h6>
                        <ul>
                          <li>
                            <h6>
                              Take the{' '}
                              <a href="http://www.smartwaternavigator.com/waa">
                                Water Action Assessment
                              </a>{' '}
                              to receive a tailored guide to implement
                              improvements in water management, use,
                              target-setting and stewardship practices
                            </h6>
                          </li>
                          <li>
                            <h6>
                              Use the Risk Analysis Investment Calculator to
                              model project investment outcomes
                            </h6>
                          </li>
                          <li>
                            <h6>
                              Establish a process to monitor local water
                              availability (for example droughts or pollution
                              incidents) so that acute events can be managed in
                              a way that reduces business disruption or cost
                            </h6>
                          </li>
                        </ul>
                      </td>
                    </tr>
                    <tr
                      style={{
                        backgroundColor: '#f0f8fa',
                        height: 40,
                        padding: 15,
                      }}
                    >
                      <td
                        style={{
                          width: 127,
                          padding: 15,
                          textAlign: 'left',
                          verticalAlign: 'top',
                        }}
                        width={127}
                      >
                        <h6>
                          <strong>
                            Moderate potential for business risk based on low
                            growth potential
                          </strong>
                        </h6>
                        <h6>(Low Growth – High Water Scarcity)</h6>
                      </td>
                      <td
                        style={{
                          width: 250,
                          padding: 15,
                          textAlign: 'left',
                          verticalAlign: 'top',
                        }}
                        width={154}
                      >
                        <h6>
                          Today, more than ever before, companies recognize the
                          growing challenges posed by resource scarcity,
                          population growth, energy demands, climate change and
                          increasing competition for freshwater resources. Even
                          at current use levels, companies operating in water
                          scarce regions face increasingly tangible business
                          risks. Water is a shared resource and therefore
                          requires a stewardship approach to ensure sustainable
                          outcomes for all.
                        </h6>
                        <h6>
                          Good water stewards understand their own water use,
                          catchment context and shared concerns related to water
                          governance, water balance and water quality. With this
                          awareness, good water stewards engage in meaningful
                          individual and collective actions that benefit people
                          and the environment.
                        </h6>
                      </td>
                      <td
                        style={{
                          width: 250,
                          padding: 15,
                          textAlign: 'left',
                          verticalAlign: 'top',
                        }}
                        width={192}
                      >
                        <h6>
                          <strong>ENGAGE:</strong>
                        </h6>
                        <ul>
                          <li>
                            <h6>
                              Take the{' '}
                              <a href="http://www.smartwaternavigator.com/waa">
                                Water Action Assessment
                              </a>{' '}
                              to receive a tailored guide to implement
                              improvements in water management, use,
                              target-setting and stewardship practices
                            </h6>
                          </li>
                          <li>
                            <h6>
                              Use the Risk Analysis Investment Calculator to
                              model investment project outcomes
                            </h6>
                          </li>
                          <li>
                            <h6>
                              Meet with local water service providers to
                              understand the current state of the watershed,
                              plans to manage stakeholder needs, how water will
                              be allocated in the future, how future water rates
                              may be linked to availability and quality and
                              current and future treatment capacity. Model
                              scenarios of productivity, local community growth,
                              and water provider plans to understand how these
                              variables will impact future water demand and
                              risk.
                            </h6>
                          </li>
                          <li>
                            <h6>
                              Collaborate with other local users on a water
                              stewardship plan and explore where there may be
                              opportunities to share data that would minimize
                              risk.
                            </h6>
                          </li>
                          <li>
                            <h6>
                              Leverage the{' '}
                              <a href="http://allianceforwaterstewardship.org">
                                Alliance for Water Stewardship
                              </a>
                              ’s six step framework to implement a water
                              stewardship plan
                            </h6>
                          </li>
                        </ul>
                      </td>
                    </tr>
                    <tr
                      style={{
                        backgroundColor: '#ffffff',
                        height: 40,
                        padding: 15,
                      }}
                    >
                      <td
                        style={{
                          width: 127,
                          padding: 15,
                          textAlign: 'left',
                          verticalAlign: 'top',
                        }}
                        width={127}
                      >
                        <h6>
                          <strong>
                            Low potential for business risk based on low water
                            scarcity
                          </strong>
                        </h6>
                        <h6>(High Growth – Low Water Scarcity)</h6>
                      </td>
                      <td
                        style={{
                          width: 250,
                          padding: 15,
                          textAlign: 'left',
                          verticalAlign: 'top',
                        }}
                        width={154}
                      >
                        <h6>
                          Water dependency is known but business risk is low
                          because sufficient water is available given current
                          conditions. To manage acute risks and prevent future
                          risk, develop a plan to monitor changes in water
                          quality and quantity. Update your Water Risk Monetizer
                          risk assessment annually using updated growth
                          projections.
                        </h6>
                      </td>
                      <td
                        style={{
                          width: 250,
                          padding: 15,
                          textAlign: 'left',
                          verticalAlign: 'top',
                        }}
                        width="`92"
                      >
                        <h6>
                          <strong>MONITOR:</strong>
                        </h6>
                        <ul>
                          <li>
                            <h6>
                              Take the{' '}
                              <a href="http://www.smartwaternavigator.com/waa">
                                Water Action Assessment
                              </a>{' '}
                              to receive a tailored guide to implement
                              improvements in water management, use,
                              target-setting and stewardship practices
                            </h6>
                          </li>
                          <li>
                            <h6>
                              Monitor water availability and growth in the basin
                              and&nbsp;enact&nbsp;a process to respond to
                              changes that could place the facility at a higher
                              risk. Monitoring growth and local water
                              availability can include a combination of private
                              and public efforts. Examples include trends in
                              water demand by category (agricultural, domestic
                              and industrial), real time satellite data on water
                              availability, water use intensity within the
                              region, changes in economic development or
                              basin-wide water conservation initiatives.
                            </h6>
                          </li>
                        </ul>
                      </td>
                    </tr>
                    <tr
                      style={{
                        backgroundColor: '#f0f8fa',
                        height: 40,
                        padding: 15,
                      }}
                    >
                      <td
                        style={{
                          width: 127,
                          padding: 15,
                          textAlign: 'left',
                          verticalAlign: 'top',
                        }}
                        width={127}
                      >
                        <h6>
                          <strong>Low potential for business risk</strong>
                        </h6>
                        <h6>(Low Growth – Low Water Scarcity)</h6>
                      </td>
                      <td
                        style={{
                          width: 250,
                          padding: 15,
                          textAlign: 'left',
                          verticalAlign: 'top',
                        }}
                        width={154}
                      >
                        <h6>
                          While water is necessary for operations, facilities
                          with low growth potential in areas of low water stress
                          face minimal business risk.
                        </h6>
                      </td>
                      <td
                        style={{
                          width: 250,
                          padding: 15,
                          textAlign: 'left',
                          verticalAlign: 'top',
                        }}
                        width={192}
                      >
                        <h6>
                          <strong>CONTINUE ONGOING CONSERVATION:</strong>
                        </h6>
                        <ul>
                          <li>
                            <h6>
                              Continue ongoing water efficiency efforts to
                              reduce reliance on fresh water
                            </h6>
                          </li>
                          <li>
                            <h6>
                              Take the{' '}
                              <a href="http://www.smartwaternavigator.com/waa">
                                Water Action Assessment
                              </a>{' '}
                              to receive a tailored guide to implement
                              improvements in water management, use,
                              target-setting and stewardship practices
                            </h6>
                          </li>
                          <li>
                            <h6>
                              Periodically assess water basin conditions and
                              local growth to understand changes that might
                              increase risk
                            </h6>
                          </li>
                        </ul>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </article>
          </main>
        </div>
      </div>
    </div>
  );
};

TakeAction.displayName = 'TakeAction';
