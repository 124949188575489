import React, { FC, useRef, useState } from 'react';
import classnames from 'classnames/bind';

import styles from './UploadMultipleDialog.module.css';
import { Dialog } from 'src/components/Dialog';
import { Copy } from 'src/components/Copy';
import { Button } from 'src/components/Button';
import { AsyncState } from 'src/types/asyncState';
import {
  getWrmBatchUploadTemplate,
  postWrmBatchUpload,
} from 'src/clients/api/facilty';
import { TextInput } from 'src/components/TextInput';

const cx = classnames.bind(styles);

type UploadMultipleDialogProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const UploadMultipleDialog: FC<UploadMultipleDialogProps> = ({
  isOpen,
  onClose,
}) => {
  const fileRef = useRef<HTMLInputElement>(null);
  const [asyncDownloadState, setAsyncDownloadState] = useState<AsyncState>({
    state: 'idle',
  });
  const [asyncUploadState, setAsyncUploadState] = useState<AsyncState>({
    state: 'idle',
  });
  const [canUpload, setCanUpload] = useState<boolean>(false);

  const handleDownload = async () => {
    try {
      setAsyncDownloadState({ state: 'loading' });
      const result = await getWrmBatchUploadTemplate();

      if (result.status !== 'success') {
        throw Error(result.value.errors[0].message);
      }

      setAsyncDownloadState({
        state: 'success',
        message: `Template generated successfully!`,
      });

      window.open(result.value.result, '_self');
    } catch (e) {
      setAsyncDownloadState({
        state: 'error',
        message: e.message ?? 'There was an error downloading your template.',
      });
    }
  };

  const handleUpload = async () => {
    const value = fileRef.current;
    if (value && value.files) {
      try {
        setAsyncUploadState({ state: 'loading' });

        await postWrmBatchUpload(value.files[0]);

        setAsyncUploadState({
          state: 'success',
          message: `Template generated successfully!`,
        });
      } catch (e) {
        setAsyncUploadState({
          state: 'error',
          message: e,
        });
      }
    }
  };

  return (
    <Dialog
      isOpen={isOpen}
      title="Upload Multiple Facilities"
      description="Enter multiple facilities at once in three easy steps:"
      className={cx('uploadMultipleDialog')}
      onClose={onClose}
    >
      <div className={cx('content')}>
        <ol>
          <li>
            <Copy>Download the facility information template</Copy>
          </li>
          <li>
            <Copy>
              Complete the template by entering your facility information
            </Copy>
          </li>
          <li>
            <Copy>
              {' '}
              Save the Facility Form Questions tab from the provided template as
              a .csv and upload the file for automatic data entry
            </Copy>
          </li>
        </ol>
        <div className={cx('actions')}>
          <Button
            isLoading={asyncDownloadState.state === 'loading'}
            onClick={handleDownload}
          >
            Download Template
          </Button>
        </div>
        <div className={cx('separator')}>
          <span className={cx('separatorText')}>Then</span>
          <div className={cx('separatorLine')}></div>
        </div>
        <Copy>
          Once you've completed the .csv template, select your file to complete
          the batch facility upload.
        </Copy>
        <TextInput
          type="file"
          className={cx('input')}
          onChange={(value) => setCanUpload(!!value)}
          ref={fileRef}
        />
        <div className={cx('actions')}>
          <Button
            isLoading={asyncUploadState.state === 'loading'}
            onClick={handleUpload}
            disabled={!canUpload}
          >
            Upload File
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

UploadMultipleDialog.displayName = 'UploadMultipleDialog';
