import React, { FC, PropsWithChildren } from 'react';

type DevelopmentPartnersProps = Record<string, unknown>;

export const DevelopmentPartners: FC<PropsWithChildren<
  DevelopmentPartnersProps
>> = () => {
  return (
    <>
      <div
        style={{
          height: '48px',
          backgroundColor: '#005075',
          width: '100%',
          maxWidth: '1920px',
        }}
      />
      <div className="container" id="main-container">
        <div className="row">
          <div id="primary" className="content-area col-md-8">
            <main id="main" className="site-main" role="main">
              <article
                id="post-3753"
                className="post-3753 page type-page status-publish hentry"
              >
                <header className="entry-header">
                  <h1 className="entry-title">Development Partners</h1>
                </header>

                <div className="entry-content">
                  <p className="bigintro">
                    The Smart Water Navigator was developed by Ecolab in
                    conjunction with S&amp;P Global Trucost and Microsoft.
                  </p>
                  <p>
                    The Smart Water Navigator was developed by &nbsp;
                    <a href="http://www.ecolab.com/">Ecolab</a>, a global leader
                    in water technologies and services; &nbsp;
                    <a href="https://www.trucost.com/">
                      S&amp;P Global Trucost
                    </a>
                    , a global leader in environmental data and risk analysis;
                    and &nbsp;
                    <a href="http://www.microsoft.com/">Microsoft</a>, a leader
                    in cloud computing technology. Through this collaboration,
                    the platform leverages industry insights, advanced analytics
                    and Azure Cloud technology to help business leaders easily
                    access information to better understand and address the
                    impacts of water scarcity.
                  </p>
                  <blockquote>
                    <p>
                      <span style={{ color: '#166691' }}>
                        <em>
                          “Water is the primary means through which climate
                          change will be experienced and must be integrated into
                          a comprehensive approach to addressing climate change.
                          Collaborating on the development of the Smart Water
                          Navigator in partnership with Ecolab and S&amp;P
                          Global Trucost enables us to harness the power of data
                          to deliver actionable insights to users on their water
                          management strategy.”
                        </em>
                        &nbsp;
                      </span>
                    </p>
                    <h6 style={{ textAlign: 'right' }}>
                      <strong>
                        <span style={{ color: '#166691' }}>
                          – Paul Fleming, Global Water Program Manager,
                          Microsoft
                        </span>
                      </strong>
                    </h6>
                  </blockquote>
                  <p>
                    Our data partners and advisors include the &nbsp;
                    <a href="https://www.wri.org/">World Resources Institute</a>
                    , the &nbsp;
                    <a href="https://ceowatermandate.org/">
                      UN CEO Water Mandate
                    </a>
                    , the &nbsp;
                    <a href="https://thepacificinstitute.com/">
                      Pacific Institute
                    </a>
                    , and the &nbsp;
                    <a href="https://a4ws.org/">
                      Alliance for Water Stewardship
                    </a>
                    .
                  </p>
                  <blockquote>
                    <p>
                      <span style={{ color: '#166691' }}>
                        <em>
                          “To eliminate water stress in at-risk water basins and
                          reach conditions of sustainable water use, all water
                          users in a basin need to do their part. The Ecolab
                          Smart Water Navigator is one such tool that helps
                          companies responsibly manage water issues by providing
                          an action plan for improving facility water
                          management, use practices, target setting, stewardship
                          and conservation.”
                        </em>
                        &nbsp;
                      </span>
                    </p>
                    <h6 style={{ textAlign: 'right' }}>
                      <strong>
                        <span style={{ color: '#166691' }}>
                          –Colin Strong, Research Associate, Corporate Water
                          Stewardship, World Resources Institute
                        </span>
                      </strong>
                    </h6>
                  </blockquote>
                </div>
              </article>
            </main>
          </div>
        </div>
      </div>
    </>
  );
};

DevelopmentPartners.displayName = 'DevelopmentPartners';
