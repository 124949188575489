import React, { FC, useRef } from 'react';
import classnames from 'classnames/bind';
import styles from './FacilityReportDialog.module.css';
import { Dialog } from 'src/components/Dialog';
import { useAsyncState } from 'src/hooks/useAsyncState';
import { LoadingSpinner } from 'src/components/LoadingSpinner';
import { Alert } from 'src/components/Alert';
import { ProcessedFacility } from 'src/types/processedFacility';
import {
  incomingRiskChartDataGenerator,
  outgoingRiskChartDataGenerator,
  riskSourceChartDataGenerator,
  riskFactorChartDataGenerator,
  revenueRiskChartDataGenerator,
  waterWithdrawalChartDataGenerator,
} from 'src/utils/chart';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useEffect } from 'react';
import { generatePdfReportForFacility } from 'src/clients/api/reports';
import { escapeQuotes } from 'src/utils/string';

require('highcharts-exporting')(Highcharts);

const cx = classnames.bind(styles);

type FacilityReportDialogProps = {
  facility: ProcessedFacility;
  isOpen: boolean;
  onClose: () => void;
};

export const FacilityReportDialog: FC<FacilityReportDialogProps> = ({
  facility,
  isOpen,
  onClose,
}) => {
  const [state, setState] = useAsyncState({ status: 'loading' });

  const riskSourceChartComponent = useRef(null);
  const riskFactorChartComponent = useRef(null);
  const incomingChartComponent = useRef(null);
  const outgoingChartComponent = useRef(null);
  const revenueChartComponent = useRef(null);
  const waterWithdrawalChartComponent = useRef(null);

  useEffect(() => {
    const timeout = setTimeout(async () => {
      try {
        const result = await generatePdfReportForFacility(
          facility.facilityId,
          facility.reportingYear,
          {
            facilityRank: facility.facilityRank,
            riskFactorSvgChart: escapeQuotes(
              // @ts-ignore
              riskSourceChartComponent.current!.chart.getSVG()
            ),
            riskSourceSvgChart: escapeQuotes(
              // @ts-ignore
              riskFactorChartComponent.current!.chart.getSVG()
            ),
            incomingSvgChart: escapeQuotes(
              // @ts-ignore
              incomingChartComponent.current!.chart.getSVG()
            ),
            outgoingSvgChart: escapeQuotes(
              // @ts-ignore
              outgoingChartComponent.current!.chart.getSVG()
            ),
            revenueSvgChart: escapeQuotes(
              // @ts-ignore
              revenueChartComponent.current!.chart.getSVG()
            ),
            waterWithdrawalSvgChart: escapeQuotes(
              // @ts-ignore
              waterWithdrawalChartComponent.current!.chart.getSVG()
            ),
          }
        );

        if (
          (result.status === 'success' && result.value.result === null) ||
          result.status !== 'success'
        ) {
          throw new Error(
            'An error was encountered while generating your report.'
          );
        }

        window.open(result.value.result);

        onClose();
      } catch (e) {
        setState({
          status: 'error',
          message:
            e ?? 'An error was encountered while generating your report.',
        });
      }
    }, 3000);

    return () => clearTimeout(timeout);
  }, [
    facility.facilityId,
    facility.reportingYear,
    facility.facilityRank,
    setState,
    onClose,
  ]);

  const result = () => {
    switch (state.status) {
      case 'loading': {
        return <LoadingSpinner />;
      }

      case 'error': {
        return (
          <Alert
            variant="danger"
            message="Error encountered while generating report"
          />
        );
      }

      default: {
        return <></>;
      }
    }
  };

  return (
    <Dialog
      isOpen={isOpen}
      title="Facility Report"
      description="Generating facility report"
      className={cx('FacilityReportDialog')}
      onClose={onClose}
    >
      <div className={cx('content')}>
        <div className={cx('charts')} style={{ display: 'none' }}>
          <HighchartsReact
            ref={riskSourceChartComponent}
            options={riskSourceChartDataGenerator(
              facility.charts.waterRiskPremiumIncomingOutgoingChart
            )}
          />
          <HighchartsReact
            ref={riskFactorChartComponent}
            options={riskFactorChartDataGenerator(
              facility.charts.waterRiskPremiumScarcityQualityChart
            )}
          />
          <HighchartsReact
            ref={incomingChartComponent}
            options={incomingRiskChartDataGenerator(
              facility.charts.incomingWaterRiskChart
            )}
          />
          <HighchartsReact
            ref={outgoingChartComponent}
            options={outgoingRiskChartDataGenerator(
              facility.charts.outgoingWaterRiskChart
            )}
          />
          <HighchartsReact
            ref={revenueChartComponent}
            options={revenueRiskChartDataGenerator(
              facility.charts.revenueRiskChart
            )}
          />
          <HighchartsReact
            ref={waterWithdrawalChartComponent}
            options={waterWithdrawalChartDataGenerator(
              facility.charts.sustainableWaterAllocationChart
            )}
          />
        </div>
        {result()}
      </div>
    </Dialog>
  );
};

FacilityReportDialog.displayName = 'FacilityReportDialog';
