import classnames from 'classnames/bind';
import React, { FC, PropsWithChildren } from 'react';
import styles from './styles.module.css';

const cx = classnames.bind(styles);

type ResourcesProps = Record<string, unknown>;

export const Resources: FC<PropsWithChildren<ResourcesProps>> = () => {
  return (
    <>
      <div
        style={{
          height: '48px',
          backgroundColor: '#005075',
          width: '100%',
        }}
      />
      <div className="container" id="main-container">
        <div className="row">
          <div id="primary" className="content-area col-md-8">
            <main id="main" className="site-main" role="main">
              <article
                id="post-3750"
                className="post-3750 page type-page status-publish hentry"
              >
                <header className="entry-header">
                  <h1 className="entry-title">Resources</h1>{' '}
                </header>

                <div className="entry-content">
                  <p className="bigintro">
                    The Ecolab Smart Water Navigator encourages global efforts
                    to help industry reuse, recycle and reduce water, supporting
                    resilient operations and healthy communities.
                  </p>
                  <p>
                    There are a number of resources and tools businesses can use
                    to understand and address water-related risks and
                    opportunities to better develop strategies for managing
                    water.
                  </p>
                  <h3>Resource Highlight</h3>
                  <p>
                    A recent session at the{' '}
                    <a href="https://www.worldwaterweek.org/event/10060-increase-water-resilience-with-this-new-digital-tool">
                      2021 SIWI World Water Week
                    </a>
                    &nbsp;addressed the disparity between freshwater supply and
                    demand by training participants in the use of the Smart
                    Water Navigator.&nbsp;The session includes an overview of
                    the Smart Water Navigator, training on how to use it and a
                    question-and-answer period with subject matter experts.
                  </p>
                  <p className="schedule-detail__title">
                    SIWI World Water Week Session Recording:&nbsp;
                    <a href="https://www.youtube.com/watch?v=1OEqTI5hs28">
                      Increase Water Resilience With This New Digital Tool
                    </a>
                  </p>
                  <table
                    className={cx('resources-table')}
                    width="100%"
                    cellSpacing="5"
                    cellPadding="0"
                  >
                    <tbody>
                      <tr className={cx('resources-header-tr')}>
                        <td className={cx('resources-td')} width="200">
                          <h4>
                            <span className={cx('color-white')}>Reports</span>
                          </h4>
                        </td>
                        <td className={cx('resources-td')} width="200">
                          <h4>
                            <span className={cx('color-white')}>Articles</span>
                          </h4>
                        </td>
                        <td className={cx('resources-td')} width="200">
                          <h4>
                            <span className={cx('color-white')}>Websites</span>
                          </h4>
                        </td>
                      </tr>
                      <tr className={cx('resources-body-tr')}>
                        <td className={cx('resources-td')} width="200">
                          <h6>
                            <span
                              style={{ fontSize: '16px', fontWeight: 'normal' }}
                            >
                              <br />
                            </span>
                          </h6>
                          <p>
                            <span className={cx('color-dark')}>
                              <a href="https://ecolab.widen.net/s/8mlk7dwnsp/smart-water-navigator-working-paper">
                                You’ve Set Corporate Water Goals. Now What?
                              </a>
                              &nbsp;
                              <a href="https://www.greenbiz.com/article/state-green-business-2021 ">
                                <br />
                              </a>
                              Ecolab,&nbsp;Trucost and Bluerisk
                              <br />
                              March 2021
                              <a href="https://www.greenbiz.com/article/state-green-business-2021 ">
                                <br />
                              </a>
                            </span>
                          </p>
                          <p>
                            <span className={cx('color-dark')}>
                              <a href="https://www.greenbiz.com/article/state-green-business-2021 ">
                                State of Green Business 2021
                              </a>
                              <br />
                              GreenBiz and Trucost
                              <br />
                              January 2021
                            </span>
                          </p>
                          <p>
                            <span className={cx('color-dark')}>
                              <a href="https://www.weforum.org/reports/the-global-risks-report-2021">
                                Global Risks 2021
                              </a>
                              <br />
                              World Economic Forum
                              <br />
                              January 2021
                            </span>
                          </p>
                          <p>
                            <span className={cx('color-dark')}>
                              <a href="https://www.cdp.net/en/research/global-reports/global-water-report-2020">
                                Global Water Report 2020
                              </a>
                              <br />
                              CDP
                              <br />
                              January 2021
                            </span>
                          </p>
                          <p>
                            <span className={cx('color-dark')}>
                              <a href="https://www.unwater.org/publications/world-water-development-report-2020/">
                                UN World Water Development Report 2020: Water
                                and Climate Change
                              </a>
                              <br />
                              UNESCO
                              <br />
                              March 2020
                            </span>
                          </p>
                          <p>
                            <span className={cx('color-dark')}>
                              <a href="https://pacinst.org/publication/exploring-the-case-for-corporate-context-based-water-targets/">
                                Exploring the Case for Corporate Context-Based
                                Water Targets
                              </a>
                              <br />
                              The Pacific Institute
                              <br />
                              April 2017
                            </span>
                          </p>
                          <p>
                            <span className={cx('color-dark')}>
                              <a href="https://www.ceres.org/resources/reports/water-connection-charges-tool-encouraging-water-efficient-growth">
                                Water Connection Charges: A Tool for Encouraging
                                Water-Efficient Growth
                              </a>
                              <br />
                              Ceres
                              <br />
                              August 2015
                            </span>
                          </p>
                          <p>
                            <span className={cx('color-dark')}>
                              <a href="http://www.imf.org/external/pubs/ft/sdn/2015/sdn1511.pdf">
                                Is the Glass Half Empty or Half Full? Issues in
                                Managing Water Challenges and Policy Instruments
                              </a>
                              <br />
                              International Monetary Fund
                              <br />
                              June 2015
                            </span>
                          </p>
                        </td>
                        <td className={cx('resources-td')} width="200">
                          <p>
                            <span className={cx('color-dark')}>
                              <a href="https://www.wri.org/publication/achieving-abundance">
                                Achieving Abundance: Understanding the Cost of a
                                Sustainable Water Future
                              </a>
                              <br />
                              Colin Strong, Samantha Kuzma, Samuel Vionnet and
                              Paul Reig, World Resources Institute
                              <br />
                              January 2020
                            </span>
                          </p>
                          <p>
                            <span className={cx('color-dark')}>
                              <a href="https://www.mckinsey.com/business-functions/sustainability/our-insights/water-a-human-and-business-priority">
                                Water: A human and business priority
                              </a>
                              <br />
                              Thomas Hundertmark, Kun Lueck, and Brent Packer,
                              McKinsey &amp; Company
                              <br />
                              May 2020
                            </span>
                          </p>
                          <p>
                            <span className={cx('color-dark')}>
                              <a href="https://www.greenbiz.com/article/ideas-using-true-cost-water-sustainability-strategy">
                                Ideas for using the true cost of water in
                                sustainability strategy
                              </a>
                              <br />
                              Josh Prigge, GreenBiz
                              <br />
                              March 2019
                            </span>
                          </p>
                          <p>
                            <span className={cx('color-dark')}>
                              <a href="https://www.fastcompany.com/90323551/companies-say-they-want-to-use-less-water-theyre-just-not-doing-anything-about-it">
                                Companies say they want to use less
                                water–they’re just not doing anything about it
                              </a>
                              <br />
                              Eillie Anzilotti, Fast Company
                              <br />
                              March 2019
                            </span>
                          </p>
                          <p>
                            <span className={cx('color-dark')}>
                              <a href="http://www.forbes.com/sites/toddmillay/2016/11/28/investing-in-water/#6e2eb1144f08">
                                Investing in Water
                              </a>
                              <br />
                              Todd Millay, Forbes
                              <br />
                              November 2016
                            </span>
                          </p>
                          <p>
                            <span className={cx('color-dark')}>
                              <a href="http://www.theguardian.com/sustainable-business/2014/dec/05/algorithms-water-energy-hygiene-cost-drought">
                                How do you put a price on water? Try algorithms
                              </a>
                              <br />
                              Sarah Shemkus, The Guardian
                              <br />
                              December 2014
                            </span>
                          </p>
                          <p>
                            <span className={cx('color-dark')}>
                              <a href="http://dupress.com/articles/water-stewardship-growth-strategies/">
                                Fueling Growth: You Can’t Always Buy What You
                                Need
                              </a>
                              <br />
                              Will Sarni, Deloitte Review
                              <br />
                              July 2014
                            </span>
                          </p>
                          <p>
                            <span className={cx('color-dark')}>
                              <a href="http://www.greenbiz.com/blog/2013/04/29/true-cost-water">
                                The true cost of water
                              </a>
                              <br />
                              Libby Bernick, GreenBiz
                              <br />
                              April 2013
                            </span>
                          </p>
                        </td>
                        <td className={cx('resources-td')} width="200">
                          <p>
                            <a href="http://www.wri.org/our-work/project/aqueduct">
                              World Resources Institute Aqueduct
                            </a>
                            <br />
                            Aqueduct’s global water risk mapping tool helps
                            companies, investors, governments, and other users
                            understand where and how water risks and
                            opportunities are emerging worldwide.
                          </p>
                          <p>
                            <a href="http://waterriskfilter.panda.org/">
                              WWF Water Risk Filter
                            </a>
                            <br />
                            The WWF Water Risk Filter tool helps companies and
                            investors ask the right questions about water. It
                            allows you to assess risks and offers guidance on
                            what to do in response.
                          </p>
                          <p>
                            <a href="https://ceowatermandate.org/toolbox/">
                              UN CEO Water Mandate Water Stewardship Toolbox
                            </a>
                            <br />
                            The Toolbox connects your business to the latest
                            tools, guidance, case studies, datasets, and more
                            most relevant to you based on your circumstances and
                            interests. It features more than 250+ resources from
                            dozens of organizations and is updated every week.
                          </p>
                          <p>
                            <a href="https://wateractionhub.org/">
                              UN Global Compact Water Action Hub
                            </a>
                            <br />
                            The Water Action Hub is a global online
                            collaboration and knowledge sharing platform for
                            water sustainability, developed by the CEO Water
                            Mandate. The Water Action Hub raises awareness,
                            catalyzes collaboration, and scales critical lessons
                            on water sustainability around the world.
                          </p>
                          <p>
                            <a href="http://www.gemi.org/localwatertool/">
                              GEMI Local Water Tool
                            </a>
                            <br />
                            The GEMI Local Water Tool™ (LWT) is a free tool for
                            companies and organizations to evaluate the external
                            impacts, business risks, opportunities and
                            management plans related to water use and discharge
                            at a specific site or operation.
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </article>
            </main>
          </div>
        </div>
      </div>
    </>
  );
};

Resources.displayName = 'Resources';
