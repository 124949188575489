import React, { FC } from 'react';
import classnames from 'classnames/bind';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { CheckboxInput } from 'src/components/CheckboxInput';
import styles from '../Tabs.module.css';
import { ReportingYear } from 'src/types/reportingYear';
import { FacilityTabFooter } from '../../Helpers/FacilityTabFooter';
import { TextInput } from 'src/components/TextInput';
import {
  FormHelpButton,
  FormHelpText,
  FormHelpTooltip,
} from '../../../FormHelp';
import { useFormHelp } from 'src/hooks/useFormHelp';

const cx = classnames.bind(styles);

type PollutantsProps = {
  reportingYear?: ReportingYear;
  onSave: () => void;
  onCancel: () => void;
  className?: string;
};

export const Pollutants: FC<PollutantsProps> = ({
  reportingYear,
  onSave,
  onCancel,
  className,
}) => {
  const formHelp = useFormHelp();
  const {
    control,
    formState: { errors },
    register,
  } = useFormContext();

  const { fields } = useFieldArray({
    control,
    name: 'facilityPollutantList',
    keyName: 'key',
  });

  const hiddenPollutantFields = [
    'casNumber',
    'created',
    'deleted',
    'fkFacility',
    'fkResource',
    'fkUser',
    'id',
    'modified',
    'pollutantName',
  ];

  return (
    <div className={cx('tabContent', className)}>
      <form>
        <div className={cx('formGroup')}>
          <h1 className={cx('formGroupTitle')}>Pollutants</h1>
          <div className={cx('row')}>
            <Controller
              name="waterWithdrawalReplenishment"
              control={control}
              render={(props) => (
                <CheckboxInput
                  {...props}
                  className={cx('inputField', 'checkbox')}
                  error={errors.waterWithdrawalReplenishment}
                >
                  The tool uses water quality standards for material pollutants
                  as the levels to which outgoing water should be treated. If
                  you wish to use your own standards please click here.
                </CheckboxInput>
              )}
            ></Controller>
          </div>
          <div className={cx('row')}>
            <table className={cx('table')}>
              <thead>
                <tr>
                  <td>
                    CAS Number{' '}
                    <FormHelpTooltip text="CAS numbers are unique identifiers for chemical substances developed by the CAS Registry and used globally." />
                  </td>
                  <td>
                    Pollutant Name{' '}
                    <FormHelpTooltip text="Standard pollutants (BOD, COD, SS, and DS) are included for every facility. Other non-standard pollutants are determined based on their materiality to your facility's industry classification using data modelled by Trucost (2015)." />
                  </td>
                  <td>
                    Threshold (mg/L){' '}
                    <FormHelpTooltip text="Thresholds are based on drinking water standards as determined by the US EPA. If you wish to use your own standards please check the box above and enter the thresholds here in mg per litre." />
                  </td>
                  <td>
                    Concentration (mg/L){' '}
                    <FormHelpTooltip text="Please enter the concentration of pollutants at your facility here in mg per litre. As a default, standard pollutants (BOD, COD, SS and DS) are assumed to be in breach of the threshold and non-standard pollutant concentrations are estimated based on your facility's industry classification using data modeled by Trucost (2015). Please enter 0 against any pollutants that are not applicable to your facility. To revert back to the default concentrations, please leave the cells blank." />
                  </td>
                </tr>
              </thead>
              <tbody>
                {fields.map((pollutant, index) => (
                  <tr key={pollutant.key}>
                    <td>{pollutant.casNumber}</td>
                    <td>{pollutant.pollutantName}</td>
                    <td>
                      <TextInput
                        type="number"
                        className={cx('inputField')}
                        name={
                          `facilityPollutantList.${index}.threshold` as const
                        }
                        ref={register()}
                        error={errors[`${pollutant.id}Threshold`]}
                        defaultValue={
                          `facilityPollutantList.${index}.threshold` ?? ''
                        }
                      />
                    </td>
                    <td>
                      <TextInput
                        type="number"
                        className={cx('inputField')}
                        name={
                          `facilityPollutantList.${index}.concentration` as const
                        }
                        ref={register()}
                        error={errors[`${pollutant.id}Concentration`]}
                        defaultValue={
                          `facilityPollutantList.${index}.concentration` ?? ''
                        }
                      />
                    </td>
                    {hiddenPollutantFields.map((fieldName) => (
                      <Controller
                        key={fieldName}
                        control={control}
                        name={
                          `facilityPollutantList.${index}.${fieldName}` as const
                        }
                        render={() => <></>}
                        defaultValue={
                          `facilityPollutantList.${index}.${fieldName}` ?? ''
                        }
                      />
                    ))}
                  </tr>
                )) ?? null}
              </tbody>
            </table>
          </div>
          <div>
            <p>
              If you know the dilution factor at your facility's discharge
              location please enter it here:
              <FormHelpButton
                formHelp={formHelp}
                name="facilityDilutionFactor"
              />
            </p>
          </div>
          <div style={{ width: '25%' }}>
            <TextInput
              type="number"
              className={cx('inputField')}
              name="facilityDilutionFactor"
              ref={register}
              error={errors.facilityDilutionFactor}
              defaultValue={reportingYear?.facilityDilutionFactor ?? ''}
            />
          </div>
          <FormHelpText formHelp={formHelp} name="facilityDilutionFactor">
            Projection of how the output produced by the facility will change
            over the next three years. For example, if your facility produces
            cars and you are expecting an increase of 10% cars produced at the
            facility over the next three years, enter 10. You can specify an
            increase or a decrease (by entering a negative percentage). If your
            facility output is likely to stay constant, please enter '0'. This
            input is used by the tool to determine the growth at the facility.
          </FormHelpText>
        </div>
      </form>
      <FacilityTabFooter onCancel={onCancel} onNext={onSave} />
    </div>
  );
};
