import { ProcessedFacility } from 'src/types/processedFacility';
import { ReferenceDataWaterUnit } from 'src/types/referenceData';
import { CalculatorResults } from './CalculatorResults';

export class InvestmentCalculatorService {
  referenceData: Record<number, ReferenceDataWaterUnit>;

  constructor(referenceData: Record<number, ReferenceDataWaterUnit>) {
    this.referenceData = referenceData;
  }

  calculateResults(
    expenses: Array<any>,
    savings: Array<any>,
    initialInvestment: number,
    initialYear: number,
    waterSaved: number,
    discountRate: number,
    facility: ProcessedFacility,
    incomingIncrease: number,
    outgoingIncrease: number,
    investmentName: string
  ) {
    return new CalculatorResults(
      expenses,
      savings,
      initialInvestment,
      initialYear,
      waterSaved,
      discountRate,
      facility,
      incomingIncrease,
      outgoingIncrease,
      investmentName,
      this.referenceData
    );
  }
}
