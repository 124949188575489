import React, { FC, PropsWithChildren } from 'react';

type DisclaimerProps = Record<string, unknown>;

export const Disclaimer: FC<PropsWithChildren<DisclaimerProps>> = () => {
  return (
    <>
      <div
        style={{
          height: '48px',
          backgroundColor: '#005075',
          width: '100%',
          maxWidth: '1920px',
        }}
      />
      <div className="container" id="main-container">
        <div className="row">
          <div id="primary" className="content-area col-md-8">
            <main id="main" className="site-main" role="main">
              <article
                id="post-3785"
                className="post-3785 page type-page status-publish hentry"
              >
                <header className="entry-header">
                  <h1 className="entry-title">Disclaimer</h1>{' '}
                </header>

                <div className="entry-content">
                  <h3>Statement</h3>
                  <p>
                    The{' '}
                    <a href="http://www.smartwaternavigator.com">
                      www.smartwaternavigator.com
                    </a>
                    &nbsp;website is owned by Ecolab USA Inc. and the underlying
                    tool is administered by&nbsp;S&amp;P Global Trucost
                    (hereinafter “The Parties”).
                  </p>
                  <h3>1. Disclaimer of Warranties</h3>
                  <p>
                    This{' '}
                    <a href="http://www.smartwaternavigator.com">
                      www.smartwaternavigator.com
                    </a>
                    &nbsp;and its content are provided by the Parties on an “as
                    is” and “as available” basis. While we endeavor to keep the
                    information up to date and correct, we make no
                    representations or warranties of any kind, express or
                    implied, about the completeness, accuracy, reliability,
                    suitability or availability with respect to the website or
                    the information, services, assessments, results, reports,
                    mitigation actions, maps or related graphics contained on
                    the website for any purpose. Any reliance you place on such
                    information is therefore strictly at your own risk.
                  </p>
                  <h3>2. Limitation of Liability</h3>
                  <p>
                    In no event will the Parties be liable for any loss or
                    damage including without limitation, indirect or
                    consequential loss or damage, or any loss or damage
                    whatsoever arising from loss of data or profits arising out
                    of, or in connection with, the use of this website.
                  </p>
                  <h3>3. External Content</h3>
                  <p>
                    There are links on this website that take you to other
                    websites outside of our service. Any links provided to third
                    party websites outside of this site are provided solely for
                    your convenience. The operation and content of such third
                    party websites is beyond our control, and we do not endorse
                    in any manner whatsoever or accept any responsibility for
                    the content or other material that may be contained on such
                    websites, the use of such websites, or any products or
                    services advertised on or sold through any such websites. We
                    have no control over the nature, content and availability of
                    those sites. The inclusion of any links does not necessarily
                    imply a recommendation or endorse the views expressed within
                    them.
                  </p>
                  <h3>4. Facility Locations</h3>
                  <p>
                    The tool asks users to designate the location of their
                    facility. The terms “country,” “state,” or “province” or
                    “city” do not imply any judgment on the legal status of any
                    territory, or any endorsement or acceptance of disputed
                    boundaries, on the Parties or the data providers.
                  </p>
                  <h3>5. Proprietary Rights</h3>
                  <p>
                    All material on this website, including the methodology,
                    text and images, are protected by copyright law and such
                    copyright is owned by the Parties unless credited otherwise.
                    It may not be copied, reproduced, republished, downloaded,
                    posted, distributed, broadcast or transmitted in any way
                    without the copyright owner’s consent, except for your own
                    personal, non-commercial use. If a consultant or other user
                    would like to use the tool for commercial purposes, please
                    contact{' '}
                    <a href="mailto:northamerica@trucost.com">
                      northamerica@trucost.com
                    </a>{' '}
                    for information about licensing.
                  </p>
                  <h3>6. Provision of Services</h3>
                  <p>
                    Every effort is made to keep the website up and running
                    smoothly. However, the Parties take no responsibility for,
                    and will not be liable for, the website being virus-free or
                    temporarily unavailable due to technical issues beyond our
                    control.
                  </p>
                  <p>
                    The Parties reserve the exclusive right to change,
                    complement, delete or halt publication completely or for a
                    limited period, of parts of the webpages or the entire offer
                    without separate notification.
                  </p>
                  <h3>7. Applicable law and jurisdiction</h3>
                  <p>
                    By accessing the{' '}
                    <a href="http://www.smartwaternavigator.com">
                      www.smartwaternavigator.com
                    </a>
                    &nbsp;site, you agree that all relations between yourself
                    and the Parties are subject to United States law only.
                    Likewise, you subject yourself to the exclusive jurisdiction
                    of the ordinary courts of the State of Minnesota, for all
                    disputes of whatever nature that might arise between
                    yourself, Ecolab and/or Trucost.
                  </p>
                  <h3>8. Data protection</h3>
                  <p>
                    By accessing the{' '}
                    <a href="http://www.smartwaternavigator.com">
                      www.smartwaternavigator.com
                    </a>{' '}
                    site, you accept that the Parties collect, transfer, compile
                    and/or archive your personal data pursuant to the online
                    privacy policy and you waive your data protection rights in
                    accordance with such online privacy policy.
                  </p>
                  <h3>9. Changes to the Terms</h3>
                  <p>
                    The Parties reserve the right to modify these terms from
                    time to time at our sole discretion and without any notice.
                    Changes to our terms become effective on the date they are
                    posted and your continued use of{' '}
                    <a href="http://www.smartwaternavigator.com">
                      www.smartwaternavigator.com
                    </a>
                    &nbsp;after any changes to the terms will signify your
                    agreement to be bound by them.
                  </p>
                  <p>March 2021</p>
                  <p>Contact Information:</p>
                  <p>
                    Ecolab: <a href="http://www.ecolab.com">www.ecolab.com</a>{' '}
                    or contact{' '}
                    <a href="mailto:smartwaternavigator@ecolab.com">
                      smartwaternavigator@ecolab.com
                    </a>
                    <br />
                    S&amp;P Global Trucost:{' '}
                    <a href="http://www.trucost.com">www.trucost.com</a> or
                    contact{' '}
                    <a href="mailto:info@Trucost.com">info@Trucost.com</a> or{' '}
                    <a href="mailto:northamerica@trucost.com">
                      northamerica@trucost.com
                    </a>
                    .
                  </p>
                </div>
              </article>
            </main>
          </div>
        </div>
      </div>
    </>
  );
};

Disclaimer.displayName = 'Disclaimer';
