import React, { FC } from 'react';
import classnames from 'classnames/bind';
import { ProcessedFacility } from 'src/types/processedFacility';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import { useDeepCompareMemo } from 'src/hooks/useDeepCompare';
import styles from './Tabs.module.css';
import { DEFAULT_CHART } from '../../../../../utils/chart';
import { Copy } from 'src/components/Copy';

const cx = classnames.bind(styles);

type AbsoluteVolumeProps = {
  facility: ProcessedFacility;
};

export const AbsoluteVolume: FC<AbsoluteVolumeProps> = ({ facility }) => {
  const attributes = facility.charts.potWaterVolume.attributes;
  const chart = useDeepCompareMemo<Highcharts.Options>(
    () => ({
      ...DEFAULT_CHART,
      chart: {
        ...DEFAULT_CHART.chart,
        type: 'column',
      },
      colors: ['#096490', '#93c9da', '#dc8f18'],
      xAxis: {
        categories: attributes.categories,
      },
      yAxis: {
        title: {
          text: 'Cubic meters (m3)',
        },
      },
      legend: {
        squareSymbol: false,
        symbolHeight: 12,
        symbolWidth: 24,
        symbolPadding: 10,
        symbolRadius: 0,
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat:
          '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y}</b></td></tr>',
        footerFormat: '</table>',
        shared: true,
        useHTML: true,
      },
      series: attributes.series,
    }),
    [facility]
  );

  return (
    <div className={cx('facilityPerformanceTab')}>
      <div className={cx('header')}>
        <Copy as="h2">Absolute Water Volume Over Time</Copy>
        <Copy as="p">
          Absolute water volume over time compares the incoming, outgoing and
          calculated consumptive water use over multiple years.
        </Copy>
      </div>

      {/* @ts-ignore */}
      <HighchartsReact highcharts={Highcharts} options={chart} />
    </div>
  );
};

AbsoluteVolume.displayName = 'AbsoluteVolume';
