import React, { ReactNode, useState } from 'react';

import { ProcessedFacilities } from 'src/types/processedFacility';
import { useDeepCompareMemo } from './useDeepCompare';
// import { useFilterAndSort } from './useFilterAndSort';

type FacilityRiskFilterContextMethods = {
  filter: () => void;
  toggleFacilityVisibility: (id: number) => void;
};

type FacilityRiskFilterContextPropsType = {
  hiddenFacilities: Set<number>;
  allFacilities: ProcessedFacilities;
  filteredFacilities: ProcessedFacilities;
};

type FacilityRiskFilterContextProps = FacilityRiskFilterContextMethods &
  FacilityRiskFilterContextPropsType;

const FacilityRiskFilterContext = React.createContext<
  Partial<FacilityRiskFilterContextProps>
>({});

function FacilityRiskFilterProvider({
  children,
  facilities,
}: {
  children: ReactNode;
  facilities: ProcessedFacilities;
}) {
  // const { sectorId } = useFilterAndSort();
  const [hiddenFacilities, setHiddenFacilities] = useState<Set<number>>(
    new Set()
  );

  const toggleFacilityVisibility = (id: number) => {
    const set = new Set(hiddenFacilities);

    if (set.has(id)) {
      set.delete(id);
    } else {
      set.add(id);
    }

    setHiddenFacilities(new Set(set));
  };

  const filteredFacilities = useDeepCompareMemo(
    () => facilities.filter((f) => !hiddenFacilities.has(f.facilityId)),
    [facilities, hiddenFacilities]
  );

  return (
    <FacilityRiskFilterContext.Provider
      value={{
        hiddenFacilities,
        allFacilities: facilities,
        filteredFacilities,
        toggleFacilityVisibility,
      }}
    >
      {children}
    </FacilityRiskFilterContext.Provider>
  );
}

const useFacilityRiskFilter = () => React.useContext(FacilityRiskFilterContext);

export { FacilityRiskFilterProvider, useFacilityRiskFilter };
