import * as React from 'react';

import '../styles/water-risk-level.css';

interface IWaterRiskLevel {
  [index: string]: string;
}

export class WaterRiskLevel extends React.Component<{ level: string }, {}> {
  private riskLevelColors: IWaterRiskLevel = {
    'Low risk': '#FFFFA3',
    'Low to medium risk': '#FFE600',
    'Medium to high risk': '#FF9A01',
    'High risk': '#FF1901',
    'Extremely high risk': '#BB0107',
  };

  render() {
    const levelColor = this.riskLevelColors[this.props.level];
    const style = { backgroundColor: levelColor };
    const levelDisplay = this.props.level;

    return (
      <div className="water-risk-level">
        <h2>Overall water risk</h2>
        <div className="circle" style={style}></div>
        <span>{levelDisplay}</span>
      </div>
    );
  }
}
