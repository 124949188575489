import {
  mdiFileOutline,
  mdiFileUploadOutline,
  mdiHelp,
  mdiPlusCircleOutline,
} from '@mdi/js';
import { Icon } from '@mdi/react';
import classnames from 'classnames/bind';
import React, { FC, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { AccountSelector } from 'src/components/AccountSelector';
import {
  FacilitiesIcon,
  FacilityDashboardIcon,
  FacilityFoldersIcon,
  RiskAnalysisIcon,
} from '../../../../components/Icons';
import { GenerateReportDialog } from '../GenerateReportDialog';
import styles from './SidebarNav.module.css';

const cx = classnames.bind(styles);

type SidebarNavProps = {};

export const SidebarNav: FC<SidebarNavProps> = () => {
  const [isGenerateReportOpen, setIsGenerateReportOpen] = useState(false);

  return (
    <div className={cx('sidebarNav')}>
      <div className={cx('header')}>
        <div className={cx('accountSelector')}>
          <AccountSelector />
        </div>
      </div>

      <ul className={cx('list', 'primaryNav')}>
        <li>
          <NavLink
            to="/wrm/dashboard"
            className={cx('link', 'primaryLink')}
            activeClassName={cx('active')}
          >
            <FacilityDashboardIcon className={cx('linkIcon', 'primaryIcon')} />
            <span className={cx('linkText')}>Facility Dashboard</span>
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/wrm/facilities"
            className={cx('link', 'primaryLink')}
            activeClassName={cx('active')}
          >
            <FacilitiesIcon className={cx('linkIcon', 'primaryIcon')} />
            <span className={cx('linkText')}>Facilities</span>
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/wrm/folders"
            className={cx('link', 'primaryLink')}
            activeClassName={cx('active')}
          >
            <FacilityFoldersIcon className={cx('linkIcon', 'primaryIcon')} />
            <span className={cx('linkText')}>Facility Folders</span>
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/wrm/enterprise"
            exact={false}
            className={cx('link', 'primaryLink')}
            activeClassName={cx('active')}
          >
            <RiskAnalysisIcon className={cx('linkIcon', 'primaryIcon')} />
            <span className={cx('linkText')}>Enterprise Dashboard</span>
          </NavLink>
        </li>
      </ul>
      <ul className={cx('list')}>
        <li>
          <div
            className={cx('link', 'secondaryLink')}
            onClick={() => {
              setIsGenerateReportOpen(!isGenerateReportOpen);
            }}
          >
            <div className={cx('linkIcon', 'secondaryIcon')}>
              <Icon path={mdiFileOutline} size="20" />
            </div>
            <span className={cx('linkText')}>Generate Report</span>
          </div>
        </li>
        <li>
          <Link
            to={`/wrm/facilities/create/wrm/year/${
              new Date().getFullYear() - 1
            }`}
            className={cx('link', 'secondaryLink')}
          >
            <div className={cx('linkIcon', 'secondaryIcon')}>
              <Icon path={mdiPlusCircleOutline} size="20" />
            </div>
            <span className={cx('linkText')}>Add New Facility</span>
          </Link>
        </li>
        <li>
          <Link to={`/wrm/upload`} className={cx('link', 'secondaryLink')}>
            <div className={cx('linkIcon', 'secondaryIcon')}>
              <Icon path={mdiFileUploadOutline} size="20" />
            </div>
            <span className={cx('linkText')}>Upload Multiple Facilities</span>
          </Link>
        </li>
      </ul>
      {isGenerateReportOpen ? (
        <GenerateReportDialog
          isOpen={isGenerateReportOpen}
          onClose={() => setIsGenerateReportOpen(false)}
        />
      ) : null}
      <ul className={cx('list')}>
        <li>
          <a
            href="mailto:smartwaternavigator@ecolab.com"
            className={cx('link', 'tertiaryLink')}
          >
            <div className={cx('linkIcon', 'tertiaryLinkIcon')}>
              <Icon path={mdiHelp} size="14" />
            </div>
            <span className={cx('linkText')}>Help</span>
          </a>
        </li>
      </ul>
    </div>
  );
};

SidebarNav.displayName = 'SidebarNav';
