import { RouteProps } from '../../components/Route';
import { Home } from './components/Home';
import { About } from './components/About';
import { CaseStudies } from './components/CaseStudies';
import { Assessment } from './components/Assessment';
import { Facility } from './components/Facility';
import { Dashboard } from './components/Dashboard';
import { QuizHome } from './components/Quiz/QuizHome';
import { Questionnaire } from './components/Quiz/Questionnaire';
import { ResultOptions } from './components/Quiz/ResultOptions';
import { Result } from './components/Quiz/Result';
import { IndustryGuide } from './components/IndustryGuide';

export const APP_SLUG = '/waa';

export const routes: Array<RouteProps> = [
  {
    path: APP_SLUG,
    component: Home,
  },
  {
    path: `${APP_SLUG}/about`,
    component: About,
    variant: 'protected',
  },
  {
    path: `${APP_SLUG}/case-studies`,
    component: CaseStudies,
    variant: 'protected',
  },
  {
    path: `${APP_SLUG}/assessment`,
    component: Assessment,
    variant: 'protected',
  },
  {
    path: `${APP_SLUG}/facility/:id?`,
    component: Facility,
    variant: 'protected',
  },
  {
    path: `${APP_SLUG}/quiz`,
    component: QuizHome,
    variant: 'protected',
  },
  {
    path: `${APP_SLUG}/question/:id?`,
    component: Questionnaire,
    variant: 'protected',
  },
  {
    path: `${APP_SLUG}/results/:id`,
    component: ResultOptions,
    variant: 'protected',
  },
  {
    path: `${APP_SLUG}/result/:id`,
    component: Result,
    variant: 'protected',
  },
  {
    path: `${APP_SLUG}/industryguide/:naics/:maturity/:id?`,
    component: IndustryGuide,
    variant: 'protected',
  },
  {
    path: `${APP_SLUG}/dashboard`,
    component: Dashboard,
    variant: 'protected',
  },
];
