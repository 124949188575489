import React, { FC, forwardRef } from 'react';
import classnames from 'classnames/bind';

import styles from './TextInput.module.css';
import { FieldError } from 'react-hook-form';

const cx = classnames.bind(styles);

export interface TextInputProps extends React.ComponentPropsWithRef<'input'> {
  error?: FieldError;
}

export const TextInput: FC<TextInputProps> = forwardRef(
  ({ className, error, disabled, readOnly, ...props }, ref) => {
    return (
      <div className={cx(className)}>
        <input
          ref={ref}
          {...props}
          className={cx('textInput', { disabled: disabled || readOnly })}
        />
        {error && <span className={cx('error')}>{error.message}</span>}
      </div>
    );
  }
);

TextInput.displayName = 'TextInput';
