import React, { FC, HTMLAttributes, useState } from 'react';
import classnames from 'classnames/bind';
import type { ProcessedFacility } from 'src/types/processedFacility';
import { Copy } from 'src/components/Copy';
import { Link } from 'react-router-dom';
import Icon from '@mdi/react';
import {
  mdiFileEditOutline,
  mdiFileDownloadOutline,
  mdiFinance,
  mdiFolderPlusOutline,
  mdiChartLine,
  mdiChevronRight,
} from '@mdi/js';
import { FacilityTargets } from '../FacilityTargets';
import { FacilityPerformance } from '../FacilityPerformance';
import styles from './FacilityListItem.module.css';
import tableStyles from '../FacilityList/FacilityList.module.css';
import { asCommaSeparatedString } from 'src/utils/number';
import { FolderAssignDialog } from '../FolderAssignDialog';
import { useQueryClient } from 'react-query';
import { FacilityReportDialog } from '../FacilityReportDialog';
import { useAuth } from 'src/hooks/useAuth';

const cx = classnames.bind(styles);

export interface FacilityListItemProps extends HTMLAttributes<HTMLDivElement> {
  facility: ProcessedFacility;
}

export const FacilityListItem: FC<FacilityListItemProps> = (props) => {
  const { facility, className } = props;
  const { name, stateName, cityName, countryName, reportingYear } = facility;
  const { userId } = useAuth();

  const queryClient = useQueryClient();
  const [openModalView, setOpenModalView] = useState<string | null>();
  const [currentOpenView, setCurrentOpenView] = useState<
    'targets' | 'performance' | null
  >(null);

  const [isGeneratingReport, setIsGeneratingReport] = useState(false);

  return (
    <div className={cx('facilityListItem', className)}>
      <div
        className={cx('row')}
        onClick={() =>
          currentOpenView !== null
            ? setCurrentOpenView(null)
            : setCurrentOpenView('targets')
        }
      >
        <div className={cx('cell', 'title', tableStyles.title)}>
          <div className={cx('cellContent')}>
            <Copy as="h3">
              <strong>{name}</strong>
            </Copy>
            <Copy
              as="p"
              className={cx('smallText')}
            >{`${cityName}, ${stateName}, ${countryName}`}</Copy>
            <Copy as="p" className={cx('smallText')}>
              {reportingYear}
            </Copy>
          </div>
        </div>
        <div className={cx('cell', 'incoming', tableStyles.incoming)}>
          <div className={cx('cellContent')}>
            <small>Incoming Water</small>
            <Copy as="p" className={cx('cellValue')}>
              {asCommaSeparatedString(
                facility?.incomingOutgoingWaterTotal ?? 0
              )}
            </Copy>
            <small>
              m<sup>3</sup> per year
            </small>
          </div>
        </div>
        <div className={cx('cell', 'stress', tableStyles.stress)}>
          <div className={cx('cellContent')}>
            <small>Water Stress Indicator</small>
            <Copy as="p" className={cx('cellValue')}>
              {facility.overallWaterStressCategory
                ? `${asCommaSeparatedString(
                    facility.overallWaterStressCategory,
                    0
                  )}`
                : '0'}
            </Copy>
          </div>
        </div>
        <div className={cx('cell', 'premium', tableStyles.premium)}>
          <div className={cx('cellContent')}>
            <small>Water Risk Premium</small>
            <Copy as="p" className={cx('cellValue')}>
              {facility.waterRiskPremium
                ? `${asCommaSeparatedString(facility.waterRiskPremium, 1)}x`
                : 'N/A'}
            </Copy>
          </div>
        </div>
        <div className={cx('cell', 'risk', tableStyles.risk)}>
          <div className={cx('cellContent')}>
            <small>Revenue at Risk</small>
            <Copy as="p" className={cx('cellValue')}>
              {facility.revenueAtRiskPercentage}%
            </Copy>
          </div>
        </div>
        <div className={cx('cell', 'rank', tableStyles.rank)}>
          <div className={cx('cellContent')}>
            <small>Facility Rank</small>
            <Copy as="p" className={cx('cellValue')}>
              {facility.facilityRank}
            </Copy>
          </div>
        </div>
      </div>
      <div className={cx('row', 'footer')}>
        <Link
          className={cx('footerLink')}
          to={`/wrm/facilities/${facility.facilityId}/wrm/year/${facility.reportingYear}`}
        >
          <Icon path={mdiFileEditOutline} className={cx('footerIcon')} />{' '}
          View/Edit
        </Link>
        <div
          className={cx('footerLink')}
          onClick={() => setIsGeneratingReport(true)}
        >
          {isGeneratingReport ? (
            <FacilityReportDialog
              facility={facility}
              isOpen={isGeneratingReport}
              onClose={() => setIsGeneratingReport(false)}
            />
          ) : null}
          <Icon path={mdiFileDownloadOutline} className={cx('footerIcon')} />{' '}
          Download Report
        </div>
        <Link
          className={cx('footerLink')}
          to={`/wrm/facilities/${facility.facilityId}/calculator`}
        >
          <Icon path={mdiFinance} className={cx('footerIcon')} /> Investment
          Calculator
        </Link>
        <div
          className={cx('footerLink')}
          onClick={() =>
            setOpenModalView(`assignFolder:${facility.facilityId}`)
          }
        >
          <Icon path={mdiFolderPlusOutline} className={cx('footerIcon')} />{' '}
          Assign to Folder
        </div>
        {openModalView === `assignFolder:${facility.facilityId}` ? (
          <FolderAssignDialog
            isOpen={openModalView === `assignFolder:${facility.facilityId}`}
            onSave={() => {
              setOpenModalView(null);
              queryClient.refetchQueries('facilities');
            }}
            onClose={() => setOpenModalView(null)}
            facilityId={facility.facilityId}
            reportingYear={facility.reportingYear}
            facilityFolderId={facility.folderId}
          />
        ) : null}

        <div className={cx('switchGroup')}>
          <div
            className={cx('footerLink', {
              active: currentOpenView === 'performance',
            })}
            onClick={() =>
              setCurrentOpenView(
                currentOpenView !== 'performance' ? 'performance' : null
              )
            }
          >
            <Icon path={mdiChartLine} className={cx('footerIcon')} />{' '}
            Performance
          </div>
          <div
            className={cx('footerLink', {
              active: currentOpenView === null || currentOpenView === 'targets',
            })}
            onClick={() =>
              setCurrentOpenView(
                currentOpenView !== 'targets' ? 'targets' : null
              )
            }
          >
            Set Targets
            <Icon
              size="12"
              path={mdiChevronRight}
              className={cx('targetsIcon', {
                open: currentOpenView === 'targets',
              })}
            />
          </div>
        </div>
      </div>
      {currentOpenView === 'targets' ? (
        <>
          <FacilityTargets
            facility={facility}
            className={cx('row', 'rowContent')}
          />
          {`${facility.userId}` === userId ? (
            <Copy as="p" align="center" className={cx('targetsFooter')}>
              Take the next step and develop a plan to reduce water use at this
              facility. &nbsp;
              {facility.waaFacility ? (
                <Link
                  to={`/wrm/facilities/${facility.facilityId}/waa`}
                  className={cx('targetsFooterLink')}
                >
                  Update this facility's Water Action Assessment
                </Link>
              ) : (
                <Link
                  to={{
                    state: {
                      facilityId: facility.facilityId,
                    },
                    pathname: `/wrm/facilities/create/waa`,
                  }}
                  className={cx('targetsFooterLink')}
                >
                  Take the Water Action Assessment
                </Link>
              )}
            </Copy>
          ) : null}
        </>
      ) : null}
      {currentOpenView === 'performance' ? (
        <>
          <Copy as="p" className={cx('tabHeader')}>
            A facility's performance over time can be charted to show variation
            in incoming, outgoing and calculated consumptive water use over
            multiple years. This visualization provides a way for facility
            leaders to contextualize the interdependencies of production volume,
            revenue and water use to inform water efficiency.
          </Copy>
          <FacilityPerformance
            facility={facility}
            className={cx('row', 'rowContent')}
          />
        </>
      ) : null}
    </div>
  );
};

FacilityListItem.displayName = 'FacilityListItem';
