import React, { FC } from 'react';
import classnames from 'classnames/bind';

import styles from '../FacilityForm.module.css';
import { Facility } from 'src/types/facility';
import { Assessment, Question } from 'src/types/assessment';
import { useDeepCompareMemo } from 'src/hooks/useDeepCompare';
import { useTabNavigator } from '../Helpers/FacilityTabNavigator/useTabNavigator';
import { FacilityTabNavigator } from '../Helpers/FacilityTabNavigator';
import { FormProvider, useForm } from 'react-hook-form';
import { mergeFacilityAndAssessment } from '../utils';
import { FacilityInformation } from '../Tabs';
import { Carousel } from '../Tabs/Carousel';
import { useEffect } from 'react';
import { Alert } from 'src/components/Alert';
import { useScrollTop } from 'src/hooks/useScrollTop';
import { useHistory } from 'react-router-dom';

const cx = classnames.bind(styles);

type AssessmentCarouselProps = {
  facility?: Facility;
  assessment: Assessment;
  onSave: (values: any) => void;
};

export const AssessmentCarousel: FC<AssessmentCarouselProps> = ({
  facility,
  assessment,
  onSave,
}) => {
  const { scrollTop } = useScrollTop();
  const history = useHistory();

  const tabNavigator = useTabNavigator([
    'Facility Information',
    assessment.assessmentName,
  ]);
  const { activeTabIndex, onNextTab } = tabNavigator;

  const questions = useDeepCompareMemo(
    () =>
      assessment.categories
        .map((cat) => cat.questions)
        .flat(1)
        .reduce<Record<string, Question>>(
          (acc, q) => ({
            ...acc,
            [q.questionId]: q,
          }),
          {}
        ),
    [assessment]
  );

  const defaultValues = useDeepCompareMemo(
    () => ({
      ...mergeFacilityAndAssessment(undefined, facility),
      ...{
        answers: Object.values(questions).reduce<Record<string, string>>(
          (acc, question) => {
            return {
              ...acc,
              [question.questionId]: '',
            };
          },
          {}
        ),
      },
    }),
    [questions]
  );

  const methods = useForm({ defaultValues });
  const { formState } = methods;

  const onNext = () => {
    onNextTab();
    scrollTop();
  };
  const onCancel = () => history.go(-1);

  const handleSave = methods.handleSubmit(onSave, (errors) => {
    console.log(errors);
    scrollTop();
  });

  useEffect(() => {
    methods.reset(defaultValues);
  }, [defaultValues]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <FormProvider {...methods}>
      {formState.isSubmitted && !formState.isSubmitSuccessful ? (
        <div className={cx('alert')}>
          <Alert
            variant="danger"
            message="All fields are required on all tabs."
          />
        </div>
      ) : null}
      <FacilityTabNavigator tabNavigator={tabNavigator} />
      <article className={cx('content')}>
        <FacilityInformation
          showReportingYear
          onNext={onNext}
          onCancel={onCancel}
          className={cx({ active: activeTabIndex === 0 })}
        />
        <Carousel
          questions={questions}
          className={cx({ active: activeTabIndex === 1 })}
          onSave={handleSave}
          onCancel={onCancel}
        />
      </article>
    </FormProvider>
  );
};

AssessmentCarousel.displayName = 'AssessmentCarousel';
