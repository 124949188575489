import { mdiFileUploadOutline, mdiPlusCircleOutline } from '@mdi/js';
import Icon from '@mdi/react';
import classnames from 'classnames/bind';
import React, { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'src/components/Button';
import { Copy } from 'src/components/Copy';
import { useFilterAndSort } from 'src/hooks/useFilterAndSort';
import { UploadMultipleDialog } from '../UploadMultipleDialog';
import styles from './NoFacilities.module.css';

const cx = classnames.bind(styles);

type NoFacilitiesProps = {};

const CN_VIDEO_URL =
  'https://marwrmstor001p.blob.core.chinacloudapi.cn/asset-f4df027c-92b5-475e-b22c-8f409373f1b9/Ecolab-WRM-2017_746x420.mp4?sv=2015-07-08&sr=c&si=899250ad-8e7a-4544-8016-e8e2a0ecbd98&sig=uVDRyfh1KB%2BAZm94YlXQx%2BxEDFTnUAkVjwehhfOtEZs%3D&st=2018-04-12T06%3A59%3A28Z&se=2118-04-12T06%3A59%3A28Z';

export const NoFacilities: FC<NoFacilitiesProps> = () => {
  const [isUploadMultipleOpen, setIsUploadMultipleOpen] = useState(false);
  const { folderId } = useFilterAndSort();
  const [showCnVideo] = useState(false);

  return (
    <>
      <div className={cx('noFacilities')}>
        {folderId ? (
          <Copy as="h3" color="blueLight" className={cx('header')}>
            There are no facilities assigned to this folder
          </Copy>
        ) : (
          <>
            <Copy as="h2" color="blueLight" className={cx('header')}>
              Welcome to the water risk monetizer!
            </Copy>
            <Copy align="center" className={cx('subheader')}>
              There aren't any facility records to display at the moment. Let's
              change that.
              <br />
              Click the Add Facility button or upload multiple facilities and
              begin entering information for a specific location.
            </Copy>
            <div className={cx('actions')}>
              <Link
                to={`/wrm/facilities/create/wrm/year/${
                  new Date().getFullYear() - 1
                }`}
                className={cx('action')}
              >
                <Icon
                  path={mdiPlusCircleOutline}
                  size="24"
                  style={{ marginRight: '5px' }}
                />
                <span>ADD NEW FACILITY</span>
              </Link>
              <Button
                className={cx('action')}
                onClick={() => {
                  setIsUploadMultipleOpen(!isUploadMultipleOpen);
                }}
              >
                <Icon
                  path={mdiFileUploadOutline}
                  size="24"
                  style={{ marginRight: '5px' }}
                />
                <span>Upload Multiple Facilities</span>
              </Button>
            </div>
            {isUploadMultipleOpen ? (
              <UploadMultipleDialog
                isOpen={isUploadMultipleOpen}
                onClose={() => setIsUploadMultipleOpen(false)}
              />
            ) : null}
            <p>Review the newest features, see our latest video tutorial.</p>
            {showCnVideo ? (
              <video width="560" height="315" preload="metadata" controls>
                <source src={CN_VIDEO_URL} type="video/mp4" />
                Your browser does not support HTML5 video.
              </video>
            ) : (
              <iframe
                title="Welcome Video"
                src="https://www.youtube.com/embed/N_PUMqUI-i4?rel=0"
                width="560"
                height="315"
                frameBorder="0"
                allowFullScreen={true}
                style={{ marginBottom: '20px' }}
              ></iframe>
            )}
            <Copy className="help">
              Need Help? For tips on this or any other topic,{' '}
              <a href="/about/how-it-works/" className={cx('link')}>
                click here
              </a>
              .
            </Copy>
          </>
        )}
      </div>
    </>
  );
};

NoFacilities.displayName = 'NoFacilities';
