import React, { FC, PropsWithChildren, useEffect } from 'react';
import { useThemeManager } from 'src/hooks/useThemeManager';

type HowItWorksProps = Record<string, unknown>;

export const HowItWorks: FC<PropsWithChildren<HowItWorksProps>> = () => {
  const themeManager = useThemeManager();

  useEffect(() => {
    if (themeManager.setThemeProperties) {
      themeManager?.setThemeProperties({
        showSubheader: true,
        subheaderVariant: 'wrm',
      });
    }

    return () => {
      if (themeManager.resetThemeProperties) {
        themeManager.resetThemeProperties();
      }
    };
  }, []);

  return (
    <div className="container" id="main-container">
      <div className="row">
        <div id="primary" className="content-area col-md-8">
          <main id="main" className="site-main" role="main">
            <article
              id="post-3760"
              className="post-3760 page type-page status-publish hentry"
            >
              <header className="entry-header">
                <h1 className="entry-title">How It Works</h1>{' '}
              </header>

              <div className="entry-content">
                <p className="bigintro">
                  The Water Risk Monetizer is an easy-to-use global tool to help
                  businesses around the world assess water-related risks in
                  financial terms based on readily available information about
                  current water use and production projections at individual
                  facility and enterprise levels.
                </p>
                <h3>
                  <span style={{ color: '#6fb7ce' }}>Get started</span>
                </h3>
                <p>
                  As part of Ecolab’s Smart Water Navigator, the Water Risk
                  Monetizer enacts a proven, four-step process to enhance
                  business resilience at all levels of an organization through
                  smart water management. To get started, register as user
                  through the Smart Water Navigator.
                </p>
                <h4 style={{ paddingLeft: '30px' }}>
                  <span style={{ color: '#096490' }}>Step 1: Identify</span>
                </h4>
                <p style={{ paddingLeft: '30px' }}>
                  Enter information for as many facilities – active or for
                  modeling purposes – as desired by selecting “Add New
                  Facilities.” Click “Upload Multiple Facilities” for
                  instructions on entering data for multiple facilities. Assess
                  water-related risks by analyzing facility-level details on the
                  Water Risk Monetizer Facility Dashboard and exploring the
                  Enterprise Dashboard. To make viewing information easy on an
                  enterprise scale across multiple industry sectors, assign
                  facilities to folders. Within the Enterprise Dashboard,
                  understand your organizational risk profile on the Risk
                  Analysis tab and compare the performance of different sites
                  within a portfolio on the Benchmarking tab.
                </p>
                <h4 style={{ paddingLeft: '30px' }}>
                  <span style={{ color: '#096490' }}>Step 2: Target</span>
                </h4>
                <p style={{ paddingLeft: '30px' }}>
                  On the Water Risk Monetizer Facility Dashboard, click on the
                  Set Targets button for any given facility to discover what a
                  sustainable annual water withdrawal target would be given
                  current water withdrawal and baseline water stress.
                </p>
                <h4 style={{ paddingLeft: '30px' }}>
                  <span style={{ color: '#096490' }}>Step 3: Implement</span>
                </h4>
                <p style={{ paddingLeft: '30px' }}>
                  On an enterprise scale, visit the Enterprise Dashboard to
                  prioritize facilities where action should be taken to improve
                  water management practices. The Risk Analysis tab provides
                  insight to business leaders to prioritize and determine
                  appropriate risk mitigation strategies for each location to
                  reduce a company’s overall risk profile. And the Benchmarking
                  tab allows a way for business leaders to prioritize and
                  determine appropriate water use efficiency strategies for each
                  location to bring sites closer to the industry, or
                  organizational, average water-intensity. Then, take the Water
                  Action Assessment for high-priority sites to receive a
                  practical, industry-specific guide to improve water
                  management, use, target-setting and stewardship practices.
                  Once improvement projects have been identified, return to the
                  Investment Calculator for any given facility on the Water Risk
                  Monetizer Facility Dashboard to prioritize water initiatives.
                </p>
                <h4 style={{ paddingLeft: '30px' }}>
                  <span style={{ color: '#096490' }}>Step 4: Track</span>
                </h4>
                <p style={{ paddingLeft: '30px' }}>
                  For any given facility on the Water Risk Monetizer Facility
                  Dashboard, monitor water use over time by inputting multiple
                  years of data and clicking on the facility Performance.
                  Generate meaningful risk reports by downloading a
                  facility-level report or generating an enterprise risk
                  analysis report.
                </p>
                <blockquote>
                  <p>
                    <span style={{ color: '#166691' }}>
                      <em>
                        “You’ve got to have a plan. You have to know where you
                        are, and that’s not just talking about your operations
                        and your business, you have to understand your local
                        watershed and the water quality that’s coming into that
                        particular location. The Ecolab Smart Water Navigator
                        has helped us develop that context-based plan and we are
                        now putting it into action.”
                      </em>
                      &nbsp;{' '}
                    </span>
                  </p>
                  <h6 style={{ textAlign: 'right' }}>
                    <strong>
                      <span style={{ color: '#166691' }}>
                        –Walter Leclerc, Director of Environmental Occupational
                        Health and Safety, Digital Realty
                      </span>
                    </strong>
                  </h6>
                </blockquote>
                <h3>
                  <span style={{ color: '#6fb7ce' }}>
                    Use the Water Risk Monetizer to calculate risk and set
                    targets
                  </span>
                </h3>
                <p>
                  The Water Risk Monetizer charts a company’s enterprise risk
                  profile by assessing each facility’s risk based on three-year
                  projected output growth and location-specific water stress.
                  The tool provides directional guidance based on risk that
                  business leaders can use to determine how best to approach
                  water management for specific facilities within the context of
                  broader enterprise risk. The Water Risk Monetizer calculates:
                </p>
                <ul>
                  <li>
                    <strong>Total risk premium</strong>: monetary estimate of
                    the full value of water to the facility
                  </li>
                  <li>
                    <strong>Incoming water risks</strong>: monetary value of the
                    impacts of incoming water use on human health and ecosystems
                    and the future costs of incoming water treatment
                  </li>
                  <li>
                    <strong>Outgoing water risk</strong>: monetary value of the
                    impacts of outgoing water pollution on human health and
                    ecosystems and the future costs of water treatment
                  </li>
                  <li>
                    <strong>Potential revenue at risk</strong>: monetary value
                    of the impacts of water availability based on water required
                    to do business
                  </li>
                  <li>
                    <strong>Water withdrawal risk</strong>: sustainable annual
                    water withdrawal target to achieve a &lt;40% risk threshold
                    of water stress to a water basin
                  </li>
                </ul>
                <p>
                  The Water Risk Monetizer does not predict whether water risks
                  will be realized in a water bill or revenue loss. The incoming
                  and outgoing water risk premiums, potential revenue at risk
                  and water withdrawal risk should be used as components in a
                  comprehensive risk assessment process and are intended to be
                  refined based on local conditions and business particulars.
                </p>
                <blockquote>
                  <p>
                    <span style={{ color: '#096490' }}>
                      <em>
                        “The Water Risk Monetizer is a forward-thinking tool
                        that helps businesses address the water scarcity and
                        water quality challenges of the future. The tool
                        provides valuable information to drive informed,
                        long-term business strategy for companies that rely on
                        freshwater resources. Applying principles of water risk
                        assessment to investment decisions will help ensure that
                        companies are ahead of the curve.”
                      </em>
                      &nbsp;
                    </span>
                  </p>
                  <h6 style={{ textAlign: 'right' }}>
                    <strong>
                      <span style={{ color: '#096490' }}>
                        —Marianne Balfe, Director of Energy and Environmental
                        Sustainability, the Americas, Marriott International
                      </span>
                    </strong>
                  </h6>
                </blockquote>
                <h3>
                  <span style={{ color: '#6fb7ce' }}>
                    The Water Risk Monetizer is easy to use
                  </span>
                </h3>
                <p>This short video shows you how it works.</p>
                <div className="embed-responsive embed-responsive-16by9 wrm-tutorial-yt-container">
                  <iframe
                    className="embed-responsive-item"
                    src="https://www.youtube.com/embed/N_PUMqUI-i4?rel=0"
                    width="300"
                    height="150"
                    title="Water Risk Monetizer Tutorial"
                  ></iframe>
                </div>
                <h3>
                  <span style={{ color: '#6fb7ce' }}>
                    How to use the information provided by the Water Risk
                    Monetizer
                  </span>
                </h3>
                <p>
                  The information provided by the Water Risk Monetizer can be
                  used to help businesses around the world better understand
                  water risks and the potential cost implications of water
                  quantity and quality at a particular facility. The data
                  provides valuable information to help assess different
                  business models, determine how water costs or scarcity may
                  affect growth plans and inform business goals.
                </p>
                <p>
                  See{' '}
                  <a
                    href="/about/take-action/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Take Action
                  </a>{' '}
                  section for additional guidance.
                </p>
              </div>
            </article>
          </main>
        </div>
      </div>
    </div>
  );
};

HowItWorks.displayName = 'HowItWorks';
