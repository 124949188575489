import * as React from 'react';

import '../styles/steps-table.css';

export interface StepsTableRow {
  Description: any;
  Examples: string;
  Tools: string;
}
interface StepsTableProps {
  steps: StepsTableRow[];
}

export class StepsTable extends React.Component<StepsTableProps, {}> {
  render() {
    return (
      <table className="steps-table print-sub-heading">
        <thead>
          <tr>
            <th className="col-sm-1"></th>
            <th className="col-sm-3">Description</th>
            <th className="col-sm-3">Examples</th>
            <th className="col-sm-3">Tools</th>
          </tr>
        </thead>
        <tbody>
          {this.props.steps.map((step, index) => (
            <tr key={`step_${index}`}>
              <td className="col-sm-1 step">
                <p>Step</p>
                <p className="step-number">{index}</p>
              </td>
              <td className="col-sm-3">{step.Description}</td>
              <td className="col-sm-3">
                <ul>
                  {step.Examples.split('\n').map(
                    (example: string, i: number) => {
                      var trimmed = example.replace('•', '').trim();
                      if (trimmed) {
                        return <li key={`example_${i}`}>{trimmed}</li>;
                      }
                      return <></>;
                    }
                  )}
                </ul>
              </td>
              <td className="col-sm-3 links">
                <ul>
                  {step.Tools.split('\n').map((tool: string, i: number) => {
                    var trimmed = tool.replace('•', '').trim();

                    if (trimmed) {
                      var splitHref = trimmed.split('href=');

                      if (splitHref.length === 2) {
                        return (
                          <li key={`tools_${i}`}>
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href={splitHref[1]}
                            >
                              {splitHref[0]}
                            </a>
                          </li>
                        );
                      } else {
                        return <li key={`tools_${i}`}>{trimmed}</li>;
                      }
                    }

                    return <></>;
                  })}
                </ul>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }
}
