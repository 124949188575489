import React, { FC } from 'react';
import { VerticalTabContainer } from '../VerticalTabContainer';
import classnames from 'classnames/bind';
import styles from './FacilityPerformance.module.css';
import { AbsoluteVolume } from './Tabs/AbsoluteVolume';
import { ProcessedFacility } from 'src/types/processedFacility';
import { ProductionBasis } from './Tabs/ProductionBasis';
import { RevenueBasis } from './Tabs/RevenueBasis';

export type FacilityPerformanceProps = {
  facility: ProcessedFacility;
  className?: string;
};

const TABS = [
  'Absolute Volume',
  'Intensity - Production Basis',
  'Intensity - Revenue Basis',
];

const cx = classnames.bind(styles);

export const FacilityPerformance: FC<FacilityPerformanceProps> = ({
  facility,
  className,
}) => {
  return (
    <VerticalTabContainer
      tabs={TABS}
      className={cx('animate', 'fadeInDown', className)}
    >
      {(idx) => (
        <>
          {idx === 0 ? <AbsoluteVolume facility={facility} /> : null}
          {idx === 1 ? <ProductionBasis facility={facility} /> : null}
          {idx === 2 ? <RevenueBasis facility={facility} /> : null}
        </>
      )}
    </VerticalTabContainer>
  );
};

FacilityPerformance.displayName = 'FacilityPerformance';
