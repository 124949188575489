import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Link } from '../Link';
import '../../styles/quiz-home.css';

export class QuizHome extends React.Component<RouteComponentProps<{}>, {}> {
  public render() {
    return (
      <div className="quiz-home">
        <div className="centered-content">
          <h1>Are you Water-smart?</h1>
          <p>
            This assessment contains 12 questions to help you discover where
            your facility scores on the Water Maturity Curve.
          </p>
          <div>
            <Link to={'/question'} className="btn-itb">
              Create Account to Start
            </Link>
          </div>
        </div>
      </div>
    );
  }
}
