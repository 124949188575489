import React, { FC, PropsWithChildren, useEffect } from 'react';
import { useThemeManager } from 'src/hooks/useThemeManager';

type AboutHomeProps = Record<string, unknown>;

export const AboutHome: FC<PropsWithChildren<AboutHomeProps>> = () => {
  const themeManager = useThemeManager();

  useEffect(() => {
    if (themeManager.setThemeProperties) {
      themeManager?.setThemeProperties({
        showSubheader: true,
        subheaderVariant: 'wrm',
      });
    }

    return () => {
      if (themeManager.resetThemeProperties) {
        themeManager.resetThemeProperties();
      }
    };
  }, []);

  return (
    <>
      <section className="slider">
        <div className="container">
          <div className="row">
            <div className="col-sm-7 col-md-8">
              <div className="vertical_align_table coming-soon">
                <h1>
                  Water is a Business Issue.
                  <br />
                  <strong>Is your business at risk?</strong>
                </h1>
                <p className="top">
                  Find out how water availability and quality impact your
                  business and your bottom line. Get started today.
                </p>
              </div>
            </div>
            <div className="col-sm-12 ipad hidden-xs"></div>
          </div>
        </div>
      </section>
      <section className="summary">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <h5>
                The relatively low price of water often means the risks
                associated with its use are overlooked. The Water Risk Monetizer
                helps you assess water-related business risks in order to
                understand the gap between what your business pays for water and
                the potential costs of water risks to your business.
              </h5>
              <p>
                By assessing the impact of declining water quantity and quality
                on operations, the Water Risk Monetizer provides actionable
                information to help companies turn water risks into business
                strategies that enable growth.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="call_to_action">
        <div className="container">
          <div className="row">
            <div className="col-md-3 cta">
              <div
                className="image"
                style={{
                  backgroundImage: 'url("/images/about/asses.png")',
                }}
              />
              <h4>Assess</h4>
              <p>
                Water quantity and quality risks at a site and/or enterprise
                level
              </p>
              <div className="clearfix" />
            </div>
            <div className="col-md-3 cta">
              <div
                className="image"
                style={{
                  backgroundImage: 'url("/images/about/understand-1.png")',
                }}
              />
              <h4>Quantify</h4>
              <p>
                Water risks in financial terms to understand the full value of
                water to our business
              </p>
              <div className="clearfix" />
            </div>
            <div className="col-md-3 cta">
              <div
                className="image"
                style={{
                  backgroundImage: 'url("/images/about/calculate-1.png")',
                }}
              />
              <h4>Calculate</h4>
              <p>
                Return on investment of different water management strategies
                and prioritize action
              </p>
              <div className="clearfix" />
            </div>
            <div className="col-md-3 cta">
              <div
                className="image"
                style={{
                  backgroundImage:
                    'url("/images/about/WaterRiskMonetizer_Homepage_V4-2X2.jpg")',
                }}
              />
              <h4>Inform</h4>
              <p>
                Corporate water strategies to mitigate risk and enable growth
              </p>
              <div className="clearfix" />
            </div>
          </div>
        </div>
      </section>
      <section className="info">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xs-12 col-sm-6">
              <div className="content">
                <h3>
                  Scarcity is a function of <br />
                  quantity + quality
                </h3>
                <p>
                  Resulting in increasing operating costs for businesses and
                  human, health and environmental costs for communities.
                </p>
                <h4>Quantity</h4>
                <p>
                  Demand for fresh water is outpacing available supplies which
                  impacts availability
                </p>
                <h4>Quality</h4>
                <p>
                  Fresh water supplies are increasingly contaminated which
                  impacts usability
                </p>
              </div>
            </div>
            <div className="col-xs-12 col-sm-6 computer"></div>
          </div>
        </div>
      </section>
      <section className="get-started">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <h2>
                The Water Risk Monetizer is globally relevant, simple to use and
                applicable across a wide range of businesses and industries. The
                tool provides credible, actionable information that businesses
                can use to make more informed decisions to protect against water
                quantity and quality constraints to growth.
              </h2>
              <p>
                <a href="/login">Get Started Now</a>
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

AboutHome.displayName = 'AboutHome';
