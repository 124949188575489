export class FacilityCreationService {
  private facility: Facility | null = null;

  public getFacility(): Facility | null {
    return this.facility;
  }

  public storeFacility(facility: Facility) {
    this.facility = facility;
  }

  public clearFacility() {
    this.facility = null;
  }

  //TODO store quiz process here, so partially completed quizzes can be saved temporarily
}

export interface Facility {
  id?: number;
  name: string;
  label: string;
  year: number;
  country: string;
  state: string;
  disablestate: boolean;
  city: string;
  disablecity: boolean;
  industry1: string;
  industry2: string;
  industryCode: string;
  disablenaics2: boolean;
  disablenaics3: boolean;
  countries: string[];
  regions: string[];
  cities: string[];
  navigate: boolean;
  modalIsOpen: boolean;
  formInvalid: boolean;

  // Index signature
  [key: string]: any;
}
