import { WrappedResponse } from '../types';
import * as request from '../request';
import * as yup from 'yup';
import { User as UserType } from '../../types/user';
import { Env } from '../../constants/env';
import { Accounts as AccountsType } from '../../types/account';

export type UserResponseType = WrappedResponse<UserType>;

export function getUser() {
  return request.get<UserResponseType>(`${Env.apiUrl}/v3.1/users/user`);
}

export const editUserFormSchema = yup.object({
  firstName: yup.string().trim().required('Required'),
  lastName: yup.string().trim().required('Required'),
  companyName: yup.string().trim().required('Required'),
  jobTitle: yup.string().trim().required('Required'),
  email: yup.string().trim().required('Required'),
});
export type SaveUserRequestType = yup.InferType<typeof editUserFormSchema>;
export type SaveUserResponseType = WrappedResponse<UserType>;

export function saveUser(userId: string, payload: SaveUserRequestType) {
  return request.put<SaveUserResponseType, SaveUserRequestType>(
    `${Env.apiUrl}/v4/users/${userId}`,
    payload
  );
}

export type UserAccountsResponseType = WrappedResponse<AccountsType>;

export function getUserAccounts(userId: string) {
  return request.get<UserAccountsResponseType>(
    `${Env.apiUrl}/v4/users/${userId}/accounts`
  );
}

export type ResendUserVerificationRequestType = {};
export type ResendUserVerificationResponseType = WrappedResponse<unknown>;
export function resendVerificationEmail(userId: string) {
  return request.post<
    ResendUserVerificationResponseType,
    ResendUserVerificationRequestType
  >(`${Env.apiUrl}/v4/users/${userId}/resend-verification`);
}
