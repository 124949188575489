import React, { FC } from 'react';
import classnames from 'classnames/bind';
import { Facility } from 'src/types/facility';
import { FacilityTabNavigator } from '../Helpers/FacilityTabNavigator';
import { useTabNavigator } from '../Helpers/FacilityTabNavigator/useTabNavigator';
import { FacilityInformation } from '../Tabs/FacilityInformation';
import { mergeFacilityAndAssessment } from '../utils';
import { FormProvider, useForm } from 'react-hook-form';
import {
  useDeepCompareEffect,
  useDeepCompareMemo,
} from 'src/hooks/useDeepCompare';
import styles from '../FacilityForm.module.css';
import { Alert } from 'src/components/Alert';
import {
  Answer,
  Assessment as AssessmentType,
  Question,
} from 'src/types/assessment';
import { Assessment } from '../Tabs/Assessment';
import { useHistory } from 'react-router-dom';
import { FacilityTabFooter } from '../Helpers/FacilityTabFooter';
import { useScrollTop } from 'src/hooks/useScrollTop';

const cx = classnames.bind(styles);

export type WaaFormProps = {
  facility?: Facility;
  answers: Array<Answer>;
  reportingYear?: number;
  assessment: AssessmentType;
  onSave: (values: any) => void;
  onDelete?: () => void;
  isSaving: boolean;
};

export const WaaForm: FC<WaaFormProps> = ({
  facility,
  reportingYear,
  assessment,
  answers,
  onSave,
}) => {
  const { scrollTop } = useScrollTop();
  const history = useHistory();

  const tabs = assessment.categories.map((category) => category.name);
  const tabNavigator = useTabNavigator(['Facility Information', ...tabs]);

  const questions = useDeepCompareMemo(
    () =>
      assessment.categories.reduce<Record<string, Record<string, Question>>>(
        (acc, { questions, questionCategoryId }) => ({
          ...acc,
          [questionCategoryId]: questions.reduce<Record<string, Question>>(
            (acc2, question) => ({
              ...acc2,
              [question.questionId]: question,
            }),
            {}
          ),
        }),
        {}
      ),
    [assessment]
  );

  const mappedAnswers = useDeepCompareMemo<Record<string, Answer>>(
    () =>
      answers.reduce(
        (acc, answer) => ({
          ...acc,
          [answer.questionId]: answer,
        }),
        {}
      ),
    [answers]
  );

  const defaultValues = useDeepCompareMemo(
    () => ({
      ...{ reportingYear },
      ...mergeFacilityAndAssessment(reportingYear, facility),
      answers: assessment.categories.reduce(
        (acc, cat) => ({
          ...acc,
          [cat.questionCategoryId]: cat.questions.map((q) => ({
            questionId: q.questionId,
            questionChoiceId:
              mappedAnswers[q.questionId]?.questionChoiceId ?? '',
          })),
        }),
        {}
      ),
    }),
    [facility, mappedAnswers, assessment]
  );

  const methods = useForm({
    defaultValues,
  });

  useDeepCompareEffect(() => {
    methods.reset(defaultValues);
  }, [defaultValues]);

  const { formState } = methods;
  const { activeTabIndex, onNextTab, canNext } = tabNavigator;

  const onNext = () => {
    onNextTab();
    scrollTop();
  };
  const onCancel = () => history.go(-1);
  const handleSave = methods.handleSubmit(onSave, () => {
    scrollTop();
  });

  return (
    <FormProvider {...methods}>
      {formState.isSubmitted && !formState.isSubmitSuccessful ? (
        <div className={cx('alert')}>
          <Alert
            variant="danger"
            message="All fields are required on all tabs."
          />
        </div>
      ) : null}
      <div className={cx('waaForm')}>
        <FacilityTabNavigator tabNavigator={tabNavigator} />
        <article className={cx('content')}>
          <FacilityInformation
            onNext={onNext}
            onCancel={onCancel}
            className={cx({ active: tabNavigator.activeTabIndex === 0 })}
            showReportingYear
          />
          {assessment.categories.map((category, index) => {
            const isLastTab = index === assessment.categories.length - 1;
            return (
              <Assessment
                key={category.questionCategoryId}
                category={category}
                questions={questions[category.questionCategoryId]}
                answers={mappedAnswers}
                className={cx({ active: activeTabIndex === index + 1 })}
              >
                <FacilityTabFooter
                  canNext={canNext || isLastTab}
                  onNext={isLastTab ? handleSave : onNext}
                  onCancel={onCancel}
                  nextButtonText={isLastTab ? 'save' : 'next'}
                />
              </Assessment>
            );
          })}
        </article>
      </div>
    </FormProvider>
  );
};

WaaForm.displayName = 'WaaForm';
