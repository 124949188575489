import React, { FC, PropsWithChildren } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { RouteContentScrollable } from 'src/components/RouteContentScrollable';
import { SiteFooter } from 'src/components/SiteFooter';
import { AboutHome } from './AboutHome';
import { DevelopmentPartners } from './DevelopmentPartners';
import { Disclaimer } from './Disclaimer';
import { Faq } from './Faq';
import { HowItWorks } from './HowItWorks';
import { Methodology } from './Methodology';
import { Resources } from './Resources';
import { TakeAction } from './TakeAction';

type AboutProps = Record<string, unknown>;

export const About: FC<PropsWithChildren<AboutProps>> = () => {
  let { path } = useRouteMatch();

  return (
    <>
      <RouteContentScrollable>
        <div id="about">
          <div id="page" className="hfeed site">
            <div id="content" className="site-content">
              <Switch>
                <Route
                  exact
                  path={`${path}/development-partners`}
                  component={DevelopmentPartners}
                />
                <Route exact path={`${path}/faq`} component={Faq} />
                <Route
                  exact
                  path={`${path}/disclaimer`}
                  component={Disclaimer}
                />
                <Route
                  exact
                  path={`${path}/how-it-works`}
                  component={HowItWorks}
                />
                <Route
                  exact
                  path={`${path}/take-action`}
                  component={TakeAction}
                />
                <Route
                  exact
                  path={`${path}/methodology`}
                  component={Methodology}
                />
                <Route exact path={`${path}/resources`} component={Resources} />
                <Route exact path={`${path}/`} component={AboutHome} />
              </Switch>
            </div>
          </div>
        </div>
        <SiteFooter solid style={{ width: '100%' }} />
      </RouteContentScrollable>
    </>
  );
};

About.displayName = 'About';
