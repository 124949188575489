import { RouteProps } from '../../components/Route';
import { Benchmarking } from './modules/Benchmarking';
import { Calculator } from './modules/Calculator';
import { CreateWaaFacility } from './modules/CreateWaaFacility';
import { CreateWrmFacility } from './modules/CreateWrmFacility';
import { Dashboard } from './modules/Dashboard';
import { Facilities } from './modules/Facilities';
import { Folders } from './modules/Folders';
import { UpdateWrmFacility } from './modules/UpdateWrmFacility';
import { RiskAnalysis } from './modules/RiskAnalysis';
import { UpdateWaaFacility } from './modules/UpdateWaaFacility';
import { Upload } from './modules/Upload';
import { Enterprise } from './modules/Enterprise';

export const APP_SLUG = '/wrm';

export const routes: Array<RouteProps> = [
  {
    path: `${APP_SLUG}/dashboard`,
    component: Dashboard,
    variant: 'protected',
  },
  {
    path: `${APP_SLUG}/facilities`,
    component: Facilities,
    variant: 'protected',
  },
  {
    path: `${APP_SLUG}/facilities/create/wrm/year/:year`,
    component: CreateWrmFacility,
    variant: 'protected',
  },
  {
    path: `${APP_SLUG}/facilities/create/waa`,
    component: CreateWaaFacility,
    variant: 'protected',
    exact: true,
  },
  {
    path: `${APP_SLUG}/facilities/:id/wrm/year/:year`,
    component: UpdateWrmFacility,
    variant: 'protected',
  },
  {
    path: `${APP_SLUG}/facilities/:id/waa`,
    component: UpdateWaaFacility,
    variant: 'protected',
    exact: true,
  },
  {
    path: `${APP_SLUG}/facilities/:id/calculator`,
    component: Calculator,
    variant: 'protected',
  },
  {
    path: `${APP_SLUG}/folders`,
    component: Folders,
    variant: 'protected',
  },
  {
    path: `${APP_SLUG}/enterprise`,
    component: Enterprise,
    variant: 'protected',
  },
  {
    path: `${APP_SLUG}/enterprise/risk-analysis`,
    component: RiskAnalysis,
    variant: 'protected',
  },
  {
    path: `${APP_SLUG}/enterprise/benchmarking`,
    component: Benchmarking,
    variant: 'protected',
  },
  {
    path: `${APP_SLUG}/upload`,
    component: Upload,
    variant: 'protected',
  },
];
