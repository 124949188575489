import React, { FC, useState } from 'react';
import classnames from 'classnames/bind';
import { Dialog } from 'src/components/Dialog';
import { TextInput } from 'src/components/TextInput';
import { Button } from 'src/components/Button';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import styles from './FolderCreateDialog.module.css';
import { createFolder, updateFolder } from 'src/clients/api/folder';
import { Folder } from 'src/types/folder';

const cx = classnames.bind(styles);
const createFolderFormSchema = yup.object({
  folderName: yup.string().defined('Required'),
});

type AsyncState =
  | {
      state: 'loading' | 'idle';
    }
  | {
      state: 'success' | 'error';
      message: string;
    };

type CreateFolderFormSchema = yup.InferType<typeof createFolderFormSchema>;

const RESOLVER = yupResolver(createFolderFormSchema);

type FolderCreateDialogProps = {
  isOpen: boolean;
  onSave: () => void;
  onClose: () => void;
  folder?: Folder;
};
export const FolderCreateDialog: FC<FolderCreateDialogProps> = ({
  isOpen,
  onClose,
  onSave,
  folder,
}) => {
  const [asyncState, setAsyncState] = useState<AsyncState>({ state: 'idle' });
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: RESOLVER,
    defaultValues: {
      folderName: folder?.folderName ?? '',
    },
  });

  const handleCreateFolder = handleSubmit(
    async (values: CreateFolderFormSchema) => {
      if (values) {
        try {
          setAsyncState({ state: 'loading' });
          const result = folder?.folderId
            ? await updateFolder(folder.folderId, values.folderName)
            : await createFolder(values.folderName);

          if (result.status !== 'success') {
            throw Error(result.value.errors[0].message);
          }

          setAsyncState({
            state: 'success',
            message: `Folder ${
              folder?.folderId ? 'edited' : 'created'
            } successfully!`,
          });

          onSave();
        } catch (e) {
          setAsyncState({
            state: 'error',
            message: e.message ?? 'There was an error creating your folder.',
          });
        }
      }
    }
  );

  return (
    <Dialog
      isOpen={isOpen}
      title="Create folder"
      description="Please provide the name of your new folder"
    >
      <TextInput name="folderName" ref={register} error={errors.folderName} />

      <div className={cx('footer')}>
        <Button
          className={cx('secondaryAction')}
          onClick={onClose}
          variant="secondary"
        >
          Cancel
        </Button>
        <Button
          className={cx('primaryAction')}
          onClick={handleCreateFolder}
          isLoading={asyncState.state === 'loading'}
        >
          Save
        </Button>
      </div>
    </Dialog>
  );
};

FolderCreateDialog.displayName = 'FolderCreateDialog';
