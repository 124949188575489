import React, { FC } from 'react';
import classnames from 'classnames/bind';
import styles from '../Tabs.module.css';
import { Copy } from 'src/components/Copy';
import { Answer, Category, Choice, Question } from 'src/types/assessment';
import { RadioList } from 'src/components/RadioList';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';

const cx = classnames.bind(styles);

export const Assessment: FC<{
  category: Category;
  answers: Record<string, Answer>;
  questions: Record<string, Question>;
  className?: string;
}> = ({ category, questions, className, children }) => {
  const { control, errors } = useFormContext();

  const fieldNamespace = `answers.${category.questionCategoryId}`;
  const { fields } = useFieldArray({
    control,
    name: fieldNamespace,
    keyName: 'key',
  });

  return (
    <div className={cx('tabContent', className)}>
      <form>
        <h1 className={cx('questionGroupTitle')}>{category.name}</h1>
        {fields.map((field, index) => {
          const question = questions[field.questionId];
          return (
            <div className={cx('row', 'question')} key={field.key}>
              <div className={cx('label')}>
                <Copy as="p" align="right">
                  {question.displayText}
                </Copy>
              </div>
              <div className={cx('inputGroup')}>
                <div className={cx('input')}>
                  <Controller
                    control={control}
                    name={`${fieldNamespace}.${index}.questionId` as const}
                    render={() => <></>}
                    defaultValue={field.questionId ?? ''}
                  />
                  <Controller
                    control={control}
                    name={
                      `${fieldNamespace}.${index}.questionChoiceId` as const
                    }
                    rules={question.conditional ? {} : { required: 'Required' }}
                    defaultValue={field.questionChoicId ?? ''}
                    render={(props) => (
                      <RadioList
                        {...props}
                        items={question.questionChoices.map((c: Choice) => ({
                          label: c.displayText,
                          value: c.questionChoiceId,
                        }))}
                        error={findError(
                          errors,
                          category.questionCategoryId,
                          index
                        )}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          );
        })}
      </form>
      {children}
    </div>
  );
};

const findError = (errors: any, catId: string, index: number) => {
  try {
    return errors.answers[catId][index].questionChoiceId;
  } catch (e) {
    return undefined;
  }
};

Assessment.displayName = 'Assessment';
