import React, { FC, useEffect } from 'react';
import classnames from 'classnames/bind';
import styles from './UpdateWaaFacility.module.css';
import { RouteComponentProps } from 'react-router-dom';
import { useQuery, useQueryClient } from 'react-query';
import { useAsyncFormState } from 'src/hooks/useAsyncFormState';
import {
  CreateWaaFacilityReportingYearRequestPayloadType,
  getFacility,
  getFacilityAssessment,
  updateWaaFacility,
} from 'src/clients/api/facilty';
import { LoadingSpinner } from 'src/components/LoadingSpinner';
import { Copy } from 'src/components/Copy';
import { BlockError } from 'src/components/BlockError';
import { WaaForm } from '../../components/FacilityForm/WaaForm';
import { getAssessment } from 'src/clients/api/assessment';
import { Answer } from 'src/types/assessment';
import { useThemeManager } from 'src/hooks/useThemeManager';

const cx = classnames.bind(styles);

type UpdateWaaFacilityProps = {};

type FacilityFormValueType = Omit<
  CreateWaaFacilityReportingYearRequestPayloadType,
  'assessmentAnswers'
> & {
  answers: Record<string, Array<Answer>>;
};

export const UpdateWaaFacility: FC<
  UpdateWaaFacilityProps & RouteComponentProps<{ id: string }>
> = ({ match, history }) => {
  const { id } = match.params;
  const { saveState, setState, resetState } = useAsyncFormState();
  const queryClient = useQueryClient();
  const themeManager = useThemeManager();

  useEffect(() => {
    if (resetState) {
      resetState();
      themeManager.setThemeProperties!({
        showSidebar: false,
        subheaderVariant: 'waa',
      });
    }

    return () => {
      themeManager.resetThemeProperties!();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const { isLoading: isLoadingAssessment, data: assessmentData } = useQuery(
    ['assessment'],
    () => getAssessment()
  );
  const { isLoading: isLoadingFacility, data: facilityData } = useQuery(
    ['facility', id, 'waa'],
    () => getFacility(id, 'waa')
  );
  const {
    isLoading: isLoadingFacilityAssessment,
    data: facilityAssessmentData,
  } = useQuery(['facility', id, 'assessment'], () => getFacilityAssessment(id));

  if (isLoadingFacility || isLoadingAssessment || isLoadingFacilityAssessment) {
    return (
      <div className={cx('facility', 'loading')}>
        <LoadingSpinner />
      </div>
    );
  }

  if (
    facilityData &&
    facilityData.status === 'success' &&
    assessmentData &&
    assessmentData.status === 'success' &&
    facilityAssessmentData &&
    facilityAssessmentData.status === 'success'
  ) {
    const onSave = async ({ answers, ...values }: FacilityFormValueType) => {
      if (setState) {
        try {
          setState('saveState', { status: 'loading' });

          const result = await updateWaaFacility(id, {
            ...values,
            reportingYear: Number(values.reportingYear),
            fkCity: Number(values.fkCity),
            fkState: Number(values.fkState),
            fkSector: Number(values.fkSector),
            assessmentAnswers: Object.values(answers).flat(1),
          });

          if (result.status === 'error') {
            throw new Error(result.value.errors[0].message);
          }

          setState('saveState', {
            status: 'success',
            message: 'Facility updated successfully!',
          });

          queryClient.refetchQueries(['facilities']);
          queryClient.invalidateQueries(['facilitiesList']);
          queryClient.removeQueries(['facility', id]);
          history.push('/waa/dashboard');
        } catch (e) {
          setState('saveState', {
            status: 'error',
            message: e.message ?? 'There was an error updating your facility.',
          });
        }
      }
    };

    return (
      <>
        <header className={cx('header')}>
          <Copy as="h2">Edit Facility</Copy>

          <Copy as="p">
            Your privacy is important to us. Precautions are in place to protect
            your information against any misuse. Please refer to the{' '}
            <a href="https://www.ecolab.com/privacy-policy">PRIVACY POLICY</a>{' '}
            for additional details.
          </Copy>
        </header>
        <WaaForm
          facility={facilityData.value.result}
          answers={facilityAssessmentData.value.result.assessmentAnswers}
          reportingYear={facilityAssessmentData.value.result.reportingYear}
          assessment={assessmentData.value.result}
          onSave={onSave}
          isSaving={saveState?.status === 'loading'}
        />
      </>
    );
  }
  return <BlockError />;
};

UpdateWaaFacility.displayName = 'UpdateWaaFacility';
