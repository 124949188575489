import React, { FC, useEffect, useState } from 'react';
import classnames from 'classnames/bind';

import styles from './ImageRotator.module.css';
import { useTransition, config, animated } from 'react-spring';

const cx = classnames.bind(styles);

const images = [
  {
    id: 0,
    url: './images/water-banner-one.jpg',
    alt: 'Turquoise water and rocky shoreline.',
  },
  {
    id: 1,
    url: './images/water-banner-two.jpg',
    alt: 'Water flowing out of a large dam.',
  },
  {
    id: 2,
    url: './images/water-banner-three.jpg',
    alt: 'Causeway in the middle of a large body of blue water.',
  },
];

export interface ImageRotatorProps
  extends React.HTMLAttributes<HTMLDivElement> {}

export const ImageRotator: FC<ImageRotatorProps> = ({ style }) => {
  const [index, set] = useState(0);
  const transitions = useTransition(images[index], (item) => item.id, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: config.molasses,
  });

  useEffect(() => {
    const interval = setInterval(() => set((state) => (state + 1) % 3), 5000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className={cx('imageRotator', style)}>
      <div className={cx('gradient')}></div>
      {transitions.map(({ item, props, key }) => (
        <animated.div
          key={key}
          style={{ ...props, backgroundImage: `url(${item.url})` }}
          className={cx('image')}
        />
      ))}
    </div>
  );
};

ImageRotator.displayName = 'ImageRotator';
