import React, { createElement, FC } from 'react';
import classnames from 'classnames/bind';

import styles from './Copy.module.css';

const cx = classnames.bind(styles);

export interface CopyProps extends React.HTMLAttributes<HTMLDivElement> {
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'p';
  color?: 'white' | 'blueLight';
  align?: 'left' | 'right' | 'center';
}

export const Copy: FC<CopyProps> = ({
  children,
  className,
  as = 'p',
  color = 'black',
  align,
}) => {
  const combinedClassName = cx('copy', as, color, align, className);

  return createElement(
    as,
    {
      className: combinedClassName,
    },
    children
  );
};

Copy.displayName = 'Copy';
