import React from 'react';
import { RouteComponentProps } from 'react-router';
import 'isomorphic-fetch';
import { StepsTable, StepsTableRow } from './StepsTable';
import { SectionToggle } from './SectionToggle';
import { ListBoxes } from './Cards/ListBoxes';
import { Link } from './Link';
import { Redirect } from './Redirect';
import Modal from 'react-modal';
import { getTokenInStorage } from 'src/clients/helpers';

import '../styles/industry-guide.css';
import '../styles/modal.css';
import { RouteContentScrollable } from 'src/components/RouteContentScrollable';

const caseStudyImages = [
  '/images/waa/case_study_1.jpg',
  '/images/waa/case_study_2.jpg',
];

interface GuideProps {
  naics: string;
  maturity: string;
  id: string;
}

interface CaseStudy {
  Heading: string;
  Content: string;
}

interface Industry {
  naicscode: string;
  naicsname: string;
  guide: string;
}

interface Guide {
  BusinessCase: {
    Content: string;
    Benefits: {
      Tangible: string;
      Intangible: string;
      Local: string;
    };
  };
  CaseStudies: CaseStudy[];
  Checklist: {
    Strategy: string;
    WaterManagement: string;
    TargetSetting: string;
    WaterStewardship: string;
  };
  Scenario: {
    Heading: string;
    Content: string;
  };
  Tools: {
    Steps: StepsTableRow[];
  };
}
interface GuideState {
  guide: Guide;
  industry: Industry;
  canGeneratePdf: boolean;
  modalIsOpen: boolean;
}

export class IndustryGuide extends React.Component<
  RouteComponentProps<GuideProps>,
  GuideState
> {
  constructor(props: RouteComponentProps<GuideProps>) {
    super(props);

    this.state = {
      guide: {
        BusinessCase: {
          Content: '',
          Benefits: {
            Tangible: '',
            Intangible: '',
            Local: '',
          },
        },
        CaseStudies: [],
        Checklist: {
          Strategy: '',
          WaterManagement: '',
          TargetSetting: '',
          WaterStewardship: '',
        },
        Scenario: {
          Heading: '',
          Content: '',
        },
        Tools: {
          Steps: [],
        },
      },
      industry: {
        naicscode: '',
        naicsname: '',
        guide: '',
      },
      canGeneratePdf: false,
      modalIsOpen: false,
    };

    this.loadJson = this.loadJson.bind(this);
    this.openModal = this.openModal.bind(this);
    this.afterOpenModal = this.afterOpenModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  componentDidMount() {
    // Only perform api call if logged in
    if (!getTokenInStorage()) {
      console.warn(
        'Unable to request dashboard data, you are currently not logged in'
      );
      return;
    }

    this.loadJson();
  }

  loadJson() {
    var parseLevel: any = function (acc: any, parts: string[], value: string) {
      var first: string = parts.shift() || '';
      if (!parts.length) {
        if (!isNaN(parseInt(first, 10))) {
          // if (!Array.isArray(acc)) acc = [];
          acc[parseInt(first, 10)] = value;
          return acc;
        } else {
          return Object.assign({}, acc, { [first]: value });
        }
      } else {
        if (!isNaN(parseInt(first, 10))) {
          if (!Array.isArray(acc)) acc = [];
          acc[parseInt(first, 10)] = parseLevel(
            Object.assign({}, acc[first]),
            parts,
            value
          );
          return acc;
        } else {
          if (Array.isArray(acc[first])) {
            return Object.assign({}, acc, {
              [first]: parseLevel(acc[first], parts, value),
            });
          } else {
            return Object.assign({}, acc, {
              [first]: parseLevel(Object.assign({}, acc[first]), parts, value),
            });
          }
        }
      }
    };

    // Look up the industry guide mapping
    var guideMapping = '/content/naics-to-guide.json';
    var naics = this.props.match.params.naics;
    fetch(guideMapping)
      .then((response) => response.json())
      .then((data) => {
        let industry = data.filter(function (value: any) {
          return value.naicscode === naics;
        });

        // look up the guide content for the industry
        var parsedGuide: any = {};
        var parsedIndustry: any = industry[0];
        this.setState({
          industry: parsedIndustry,
        });

        let maturity = this.props.match.params.maturity;
        var fileName =
          '/content/guides/' +
          industry[0].guide.toLowerCase() +
          ' ' +
          maturity.toLowerCase() +
          '.json';

        fetch(fileName)
          .then((response) => response.json())
          .then((data) => {
            Object.keys(data[0]).forEach((key: string) => {
              var parts = key.split('.');
              var value = data[0][key];
              parsedGuide = parseLevel(parsedGuide, parts, value);
            });
            this.setState({
              guide: parsedGuide,
              canGeneratePdf: true,
              modalIsOpen: false,
            });
          });
      });
  }

  renderList(content: string) {
    return (
      <ul>
        {content.split('\n').map((tool: string, i: number) => {
          var trimmed = tool.replace('•', '').trim();
          if (trimmed) {
            return <li key={i}>{trimmed}</li>;
          }
          return <></>;
        })}
      </ul>
    );
  }

  openModal() {
    this.setState({
      modalIsOpen: true,
    });
  }

  afterOpenModal() {
    // references are now sync'd and can be accessed.
  }

  closeModal() {
    this.setState({
      modalIsOpen: false,
    });
  }

  render() {
    let token = getTokenInStorage();
    if (!token || token === 'expired') {
      return <Redirect to={'/login'}></Redirect>;
    }

    let industryCode = this.props.match.params.naics;
    let icon = '/images/waa/water-mature@3x.png';
    let wmc = '/images/waa/wmc_watersmart.png';
    let maturity = this.props.match.params.maturity.toLowerCase();
    let industryTitle =
      this.state.industry != null ? this.state.industry.naicsname : '';
    let intro = 'Guide for Water-smart';

    if (maturity === 'exploratory') {
      icon = '/images/waa/icon_exploratory.png';
      wmc = '/images/waa/wmc_exploratory.png';
      intro = 'Guide for moving your facility from Exploratory to Water-smart';
    }

    if (maturity === 'linear') {
      icon = '/images/waa/icon_linear.png';
      wmc = '/images/waa/wmc_linear.png';
      intro = 'Guide for moving your facility from Linear to Exploratory';
    }

    if (maturity === 'untapped') {
      icon = '/images/waa/icon-untapped.png';
      wmc = '/images/waa/wmc_untapped.png';
      intro = 'Guide for moving your facility from Untapped to Linear';
    }

    return (
      <RouteContentScrollable className="module-waa">
        <div id="main-content">
          <div className="industry-guide" id="industry-guide">
            <Modal
              isOpen={this.state.modalIsOpen}
              onAfterOpen={this.afterOpenModal}
              onRequestClose={this.closeModal}
              className="Modal"
              overlayClassName="Overlay"
              contentLabel="Help Modal"
            >
              <div className="row">
                <div className="col-md-2 pull-right">
                  {' '}
                  <button className="close" onClick={this.closeModal}>
                    &times;
                  </button>
                </div>
                <div className="col-md-10">
                  <h1 className="modal-heading">PDF Printing Instructions</h1>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 black-text">
                  <p>
                    Select "Print to PDF" to save to your desktop and save some
                    trees.
                  </p>
                </div>
              </div>
            </Modal>
            <header className="row">
              <img
                className="print-logo"
                src={'/images/waa/logo.svg'}
                alt="Smart Water Navigator"
              />
              <h1>Industry guide</h1>
            </header>
            <div className="row intro-waves"></div>
            <div className="row">
              <main className="black-text white-background container-fluid">
                <div className="col-lg-10 col-lg-offset-1 text-left back">
                  {this.props.match.params.id == null ? (
                    <Link to={'/dashboard'}>&lt; back to dashboard</Link>
                  ) : (
                    <Link to={'/result/' + this.props.match.params.id}>
                      &lt; back to site details
                    </Link>
                  )}
                </div>
                <div
                  className="industry-guide-content"
                  id="industry-guide-content"
                >
                  <section className="col-md-12 pagebreak">
                    <div className="row">
                      <div className="col-sm-12 col-md-6 industry-details">
                        <img className="icon" src={icon} alt="" />
                        <br />
                        <h3 className="inline-heading">{maturity}</h3>
                        <p>
                          Industry: {industryTitle} [{industryCode}]
                        </p>
                        <p className="center-block">{intro}</p>
                      </div>
                      <div className="col-sm-12 col-md-6">
                        <img src={wmc} className="wmc-curve" alt="" />
                      </div>
                    </div>
                  </section>

                  <SectionToggle
                    heading="Scenario and description"
                    isOpen={true}
                  >
                    <div className="black-text text-left">
                      <h3>{this.state.guide.Scenario.Heading}</h3>
                      {this.state.guide.Scenario.Content.split('\n\n').map(
                        (paragraph: string, i: number) => (
                          <p key={i}>{paragraph}</p>
                        )
                      )}
                    </div>
                  </SectionToggle>

                  <SectionToggle
                    className="print-heading"
                    heading="Checklist"
                    isOpen={true}
                  >
                    <SectionToggle
                      heading="Site Management"
                      isOpen={true}
                      className="toggle-blue print-sub-heading"
                    >
                      {this.renderList(this.state.guide.Checklist.Strategy)}
                    </SectionToggle>
                    <SectionToggle
                      heading="Water Management Practices"
                      isOpen={true}
                      className="toggle-blue print-sub-heading"
                    >
                      {this.renderList(
                        this.state.guide.Checklist.WaterManagement
                      )}
                    </SectionToggle>
                    <SectionToggle
                      heading="Target Setting"
                      isOpen={true}
                      className="toggle-blue print-sub-heading"
                    >
                      {this.renderList(
                        this.state.guide.Checklist.TargetSetting
                      )}
                    </SectionToggle>
                    <SectionToggle
                      heading="Water Stewardship"
                      isOpen={true}
                      className="toggle-blue print-sub-heading"
                    >
                      {this.renderList(
                        this.state.guide.Checklist.WaterStewardship
                      )}
                    </SectionToggle>
                  </SectionToggle>

                  <SectionToggle
                    className="print-heading print-keep-together"
                    heading="Tools and resources"
                    isOpen={true}
                  >
                    <StepsTable steps={this.state.guide.Tools.Steps} />
                  </SectionToggle>
                  <SectionToggle
                    className="print-heading"
                    heading="Business case"
                    isOpen={true}
                  >
                    <div className="print-business-case">
                      {this.state.guide.BusinessCase.Content.split('\n\n').map(
                        (paragraph: string, i: number) => (
                          <p key={i}>{paragraph}</p>
                        )
                      )}
                    </div>
                    <div className="print-keep-together">
                      <h3 className="print-benefits">Benefits</h3>
                      <ListBoxes
                        boxes={[
                          {
                            title: 'Tangible',
                            items: this.state.guide.BusinessCase.Benefits.Tangible.split(
                              '\n'
                            ),
                          },
                          {
                            title: 'Intangible',
                            items: this.state.guide.BusinessCase.Benefits.Intangible.split(
                              '\n'
                            ),
                          },
                        ]}
                      />
                    </div>
                  </SectionToggle>

                  <SectionToggle
                    className="print-heading print-case"
                    heading="Case studies"
                    isOpen={true}
                  >
                    {this.state.guide.CaseStudies.map(
                      (caseStudy: any, i: number) => (
                        <div className="col-md-6 case-study" key={i}>
                          <img
                            src={caseStudyImages[i % caseStudyImages.length]}
                            alt=""
                          />
                          <h3 className="print-case-h3">{caseStudy.Heading}</h3>
                          {caseStudy.Content &&
                            caseStudy.Content.split('\n\n').map(
                              (p: string, i: number) => (
                                <p className="print-case-copy" key={i}>
                                  {p}
                                </p>
                              )
                            )}
                        </div>
                      )
                    )}
                  </SectionToggle>

                  <section
                    className="col-md-12 centered-section"
                    id="pdf-generation"
                  >
                    <button
                      onClick={() => window.print()}
                      className="btn-itb"
                      disabled={!this.state.canGeneratePdf}
                    >
                      Generate PDF of this guide
                    </button>
                    {/* eslint-disable-next-line */}
                    <a data-toggle="modal" onClick={this.openModal}>
                      <span className="glyphicon glyphicon-info-sign"></span>
                    </a>
                  </section>
                </div>
              </main>
            </div>
          </div>
        </div>
      </RouteContentScrollable>
    );
  }
}
