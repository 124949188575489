import React, { forwardRef } from 'react';
import classnames from 'classnames/bind';

import styles from './SelectInput.module.css';
import { useSelect, UseSelectStateChange } from 'downshift';
import Icon from '@mdi/react';
import { mdiChevronDown } from '@mdi/js';
import { FieldError } from 'react-hook-form';
import { LoadingSpinner } from '../LoadingSpinner';

const cx = classnames.bind(styles);

export type ListItemType = {
  label: string;
  value: string;
};

export type SelectInputProps = {
  items: Array<ListItemType>;
  value: ListItemType['value'];
  onChange: (value: string) => void;
  placeholder?: string;
  className?: string;
  error?: FieldError;
  disabled?: boolean;
  loading?: boolean;
};

export const SelectInput = forwardRef<HTMLDivElement, SelectInputProps>(
  (
    {
      items,
      placeholder,
      value,
      onChange,
      className,
      error,
      disabled = false,
      loading = false,
    },
    ref
  ) => {
    const selectedItemObject = items.find(({ value: v }) => v === `${value}`);

    const handleSelectedItemChange = (
      changes: UseSelectStateChange<ListItemType>
    ) => {
      onChange(changes.selectedItem?.value ?? '');
    };

    const {
      isOpen,
      getToggleButtonProps,
      getMenuProps,
      getItemProps,
    } = useSelect({
      items,
      onSelectedItemChange: handleSelectedItemChange,
      initialSelectedItem: selectedItemObject,
      itemToString: (item) => item?.value ?? '',
    });

    return (
      <div className={cx('selectInput', className)} ref={ref}>
        <button
          type="button"
          {...getToggleButtonProps({ disabled })}
          className={cx('input', {
            empty: !selectedItemObject?.label,
            disabled,
          })}
        >
          <span className={cx('text')}>
            {selectedItemObject?.label ?? placeholder}
          </span>
          {loading ? (
            <LoadingSpinner height={18} width={18} />
          ) : (
            <Icon
              path={mdiChevronDown}
              className={cx('icon', { open: isOpen })}
            />
          )}
        </button>
        {error && <span className={cx('error')}>{error.message}</span>}
        <div {...getMenuProps()} style={{ position: 'relative' }}>
          {isOpen && (
            <ul className={cx('menu')}>
              {items.map((item, index) => (
                <li
                  className={cx('menuItem', {
                    selected: item.value === selectedItemObject?.value,
                  })}
                  key={item.value}
                  {...getItemProps({ item, index })}
                >
                  {item.label}
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    );
  }
);

SelectInput.displayName = 'SelectInput';
