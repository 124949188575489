import * as React from 'react';
import { RouteComponentProps } from 'react-router';

import { RouteContentScrollable } from 'src/components/RouteContentScrollable';

import '../styles/site-details.css';
import '../styles/case-studies.css';
import { SiteFooter } from 'src/components/SiteFooter';

export class CaseStudies extends React.Component<RouteComponentProps<{}>, {}> {
  scrollTo(target: any, e: any) {
    e.preventDefault();
    let element = document.getElementById(target);

    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  public render() {
    return (
      <RouteContentScrollable className="module-waa">
        <div id="main-content">
          <div className="caseStudies">
            <header className="row">
              <div className="col-md-12">
                <h1>Industry Case Studies</h1>
              </div>
            </header>
            <div className="row intro-waves dark-waves">
              <div className="col-lg-8 col-lg-offset-2">
                <p className="headline">
                  See sample case studies covering different industries and
                  water maturity levels. Take the assessment to see your
                  personalized guide and view case studies relevant to your
                  facilities.
                </p>
              </div>
            </div>
            <div className="row scroll-to-section-buttons">
              <button
                className="scroll-button"
                onClick={this.scrollTo.bind(this, 'mining')}
              >
                Mining
              </button>
              <button
                className="scroll-button"
                onClick={this.scrollTo.bind(this, 'lodging')}
              >
                Lodging
              </button>
              <button
                className="scroll-button"
                onClick={this.scrollTo.bind(this, 'general')}
              >
                General
              </button>
            </div>
            <div className="row">
              <main className="white-background black-text container-fluid">
                <div className="row case-study">
                  <div className="col-lg-8 col-lg-offset-2">
                    <p>
                      After taking the Ecolab Smart Water Navigator assessment
                      and discovering your place on the Water Maturity Curve,
                      you will receive an industry-specific guide based on your
                      results. The guide offers practical action steps that will
                      help you move up on the Water Maturity Curve. It also
                      contains examples of how other companies have built smart
                      water management practices.
                    </p>
                  </div>
                  <div className="col-lg-8 col-lg-offset-2">
                    <hr></hr>
                  </div>
                </div>
                <div className="row case-study" id="mining">
                  <div className="col-lg-4 col-lg-offset-2">
                    <img
                      className="case-study--label-icon"
                      src="/images/waa/mining.png"
                      alt=""
                    />
                    <p className="case-study--label">mining</p>
                    <h2>
                      Copper Mine in Spain Reduces Water Discharge by 40 Percent
                    </h2>
                    <p className="case-study--level">Level 2: Linear</p>
                    <img
                      className="case-study--level-icon"
                      src="/images/waa/icon_linear.png"
                      alt=""
                    />
                  </div>
                  <div className="col-lg-4 case-study--info">
                    <p>
                      A new copper mine in southern Spain was looking to
                      maintain consistent water quality, reduce energy and water
                      use and lessen its impact on the surrounding communities.
                    </p>
                    <p>
                      It addressed these needs with a combination of smart
                      technologies to monitor and improve water membrane
                      filtration and a water management and treatment strategy
                      to enhance overall production.
                    </p>
                    <p>
                      The results were a 40 percent reduction in water discharge
                      and a 22 percent reduction in energy use. The mine was
                      also able to extend the life of its assets, resulting in
                      an overall cost savings of $1.3 million per year.{' '}
                    </p>
                  </div>
                  <div className="col-lg-8 col-lg-offset-2">
                    <hr></hr>
                  </div>
                </div>

                <div className="row case-study" id="lodging">
                  <div className="col-lg-4 col-lg-offset-2">
                    <img
                      className="case-study--label-icon"
                      src="/images/waa/lodging.png"
                      alt=""
                    />
                    <p className="case-study--label">lodging</p>
                    <h2>
                      Large Hotel in India Saves Water While Improving
                      Operations
                    </h2>
                    <p className="case-study--level">Level 3: Exploratory</p>
                    <img
                      className="case-study--level-icon"
                      src="/images/waa/icon_exploratory.png"
                      alt=""
                    />
                  </div>
                  <div className="col-lg-4 case-study--info">
                    <p>
                      A 5-star hotel in India faced complicated issues when its
                      water supply was abruptly cut off due to a municipal water
                      shortage.
                    </p>
                    <p>
                      To maintain heating and cooling comfort for its guests and
                      employees, the hotel resorted to using treated sewage
                      water in its cooling towers. Using this type of water
                      necessitated more frequent condenser cleanings, which
                      produced more effluent discharge from blow downs (flushing
                      the cooling tower’s water when mineral content becomes too
                      high).
                    </p>
                    <p>
                      Installing a technology that treated the blow down water
                      improved the water-reuse system’s efficiency, allowing the
                      hotel to save 1.8 million gallons (6,800 m3) of fresh
                      water per year and use 4.8 million gallons (18,000 m3) of
                      treated sewer water for operations.
                    </p>
                  </div>
                  <div className="col-lg-8 col-lg-offset-2">
                    <hr></hr>
                  </div>
                </div>

                <div className="row case-study" id="general">
                  <div className="col-lg-4 col-lg-offset-2">
                    <img
                      className="case-study--label-icon"
                      src="/images/waa/general.png"
                      alt=""
                    />
                    <p className="case-study--label">general</p>
                    <h2>
                      Consumer Goods Company Treats Wastewater in China and
                      Brazil
                    </h2>
                    <p className="case-study--level">Level 4: Water-smart</p>
                    <img
                      className="case-study--level-icon larger"
                      src="/images/waa/water-mature@2x.png"
                      alt=""
                    />
                  </div>
                  <div className="col-lg-4 case-study--info">
                    <p>
                      The more a site becomes water efficient, the more
                      concentrated the wastewater becomes and the more difficult
                      and expensive it is to treat. A multi-national company was
                      looking to reduce water and continue to meet regulatory
                      requirements.
                    </p>
                    <p>
                      Three innovation solutions were combined to effectively
                      use membrane technology to extract the raw materials from
                      the wash water. In doing so, the company was able to use
                      this treated wastewater in other applications. As a
                      result, the company was able to reduce its water use in
                      China and Brazil while also meeting regulatory
                      requirements.
                    </p>
                  </div>
                </div>
              </main>
            </div>
          </div>
        </div>
        <SiteFooter solid style={{ width: '100%' }} />
      </RouteContentScrollable>
    );
  }
}
