import waterRisk from './water-risk.json';

export class WaterStressService {
  private waterStressData: Array<WaterStressRow> | null = null;

  constructor() {
    // @ts-ignore
    this.waterStressData = waterRisk;
  }

  public serviceIsReady() {
    return this.waterStressData != null;
  }

  private async waitForReady(tries: number, type: string): Promise<boolean> {
    var wait = (ms: number) => new Promise((r) => setTimeout(r, ms));
    while (tries < 6 && !this.serviceIsReady()) {
      tries++;
      console.log(
        'waiting for waterstress ' + type + ' data to load (try #' + tries + ')'
      );
      await wait(1000);
    }
    return this.serviceIsReady();
  }

  public async getCountries(): Promise<string[]> {
    await this.waitForReady(0, 'country');
    return this.waterStressData
      ? this.waterStressData
          .map((w) => w.CNTRYName)
          .filter((value, index, self) => self.indexOf(value) === index)
      : [];
  }

  public async getRegions(country: string): Promise<string[]> {
    await this.waitForReady(0, 'region');
    return this.waterStressData
      ? this.waterStressData
          .filter((w) => w.CNTRYName === country)
          .map((w) => w.REGName)
          .filter((value, index, self) => self.indexOf(value) === index)
      : [];
  }

  public async getCities(country: string, region: string): Promise<string[]> {
    await this.waitForReady(0, 'city');
    return this.waterStressData
      ? this.waterStressData
          .filter((w) => w.CNTRYName === country && w.REGName === region)
          .map((w) => w.CTYName)
      : [];
  }
}

export interface WaterStressRow {
  CNTRYID: string;
  CNTRYName: string;
  REGID: string;
  REGName: string;
  CTYID: string;
  CTYName: string;
  BWS_S: string;
}
