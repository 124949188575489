import React, { FC, useEffect } from 'react';
import classnames from 'classnames/bind';
import { useQuery, useQueryClient } from 'react-query';
import { RouteComponentProps } from 'react-router-dom';
import { getCountries } from '../../../../clients/api/country';
import { BlockError } from '../../../../components/BlockError';
import { LoadingSpinner } from '../../../../components/LoadingSpinner';
import { getClassifications } from '../../../../clients/api/classification';
import {
  deleteFacility,
  getFacility,
  getFacilityReportingYear,
  updateWrmFacility,
} from '../../../../clients/api/facilty';
import { getReferenceData } from '../../../../clients/api/referenceData';
import styles from './UpdateWrmFacility.module.css';
import { Copy } from 'src/components/Copy';
import { MappedReferenceOptions } from 'src/types/referenceData';
import { FacilityFormSchema } from 'src/modules/WaterRiskMonetizer/components/FacilityForm/validation';
import { useAsyncFormState } from 'src/hooks/useAsyncFormState';
import { WrmForm } from '../../components/FacilityForm/WrmForm';

const cx = classnames.bind(styles);

type UpdateWrmFacilityProps = {};

export const UpdateWrmFacility: FC<
  UpdateWrmFacilityProps & RouteComponentProps<{ id: string; year: string }>
> = ({ match, history }) => {
  const { id, year } = match.params;
  const { setState, resetState } = useAsyncFormState();
  const queryClient = useQueryClient();

  useEffect(() => {
    if (resetState) {
      resetState();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const { isLoading: isLoadingCountries } = useQuery(['countries'], () =>
    getCountries()
  );

  const { isLoading: isLoadingClassifications } = useQuery(
    ['classifications', 'levelOne'],
    () => getClassifications()
  );

  const { isLoading: isLoadingReferenceData, data: referenceData } = useQuery(
    ['referenceData'],
    () => getReferenceData()
  );

  const { isLoading: isLoadingFacility, data: facilityData } = useQuery(
    ['facility', id],
    () => getFacility(id)
  );

  const {
    data: reportingYearData,
    isLoading: isLoadingReportingYear,
  } = useQuery(
    ['facility', id, 'reportingYear', year],
    () => getFacilityReportingYear(`${id}`, `${year}`),
    {
      cacheTime: 0,
    }
  );

  if (
    isLoadingCountries ||
    isLoadingClassifications ||
    isLoadingReferenceData ||
    isLoadingFacility
  ) {
    return (
      <div className={cx('facility', 'loading')}>
        <LoadingSpinner />
      </div>
    );
  }

  if (
    referenceData &&
    referenceData?.status === 'success' &&
    facilityData &&
    facilityData.status === 'success'
  ) {
    const onSave = async (values: FacilityFormSchema) => {
      if (setState) {
        try {
          setState('saveState', { status: 'loading' });

          const result = await updateWrmFacility(id, {
            ...values,
            reportingYear: Number(year),
            fkSector: Number(values.fkSector),
            projectedFacilityOutputIncrease: Number(
              values.projectedFacilityOutputIncrease
            ),
            folderId: facilityData.value.result.folderId,
            folderName: facilityData.value.result.folderName,
          });

          if (result.status === 'error') {
            throw new Error(result.value.errors[0].message);
          }
          setState('saveState', {
            status: 'success',
            message: 'Facility created successfully!',
          });

          queryClient.refetchQueries(['facilities']);
          history.push('/wrm/dashboard');
          queryClient.invalidateQueries(['facilitiesList']);
          queryClient.invalidateQueries(['facility', id]);
        } catch (e) {
          setState('saveState', {
            status: 'error',
            message:
              e.message ?? 'There was an error submitting your facility data.',
          });
        }
      }
    };

    const onDelete = async () => {
      if (setState) {
        try {
          setState('deleteState', { status: 'loading' });
          await deleteFacility(id);

          setState('deleteState', {
            status: 'success',
            message: 'Facility deleted successfully.',
          });

          queryClient.refetchQueries(['facilities']);
          history.push('/wrm/dashboard');
        } catch (e) {
          setState('deleteState', {
            status: 'error',
            message: 'There was an error processing your request.',
          });
        }
      }
    };

    const formReferenceData = {
      waterUnits: referenceData.value.result.waterUnits,
      currencies: referenceData.value.result.currencies,
      options: referenceData.value.result.options.reduce<
        MappedReferenceOptions
      >(
        (out, option) => ({
          ...out,
          [option.type]: option.options,
        }),
        {} as MappedReferenceOptions
      ),
    };

    return (
      <>
        <header className={cx('header')}>
          <Copy as="h2">Edit Facility</Copy>

          <Copy as="p">
            Your privacy is important to us. Precautions are in place to protect
            your information against any misuse. Please refer to the{' '}
            <a href="https://www.ecolab.com/privacy-policy">PRIVACY POLICY</a>{' '}
            for additional details.
          </Copy>
        </header>
        <WrmForm
          selectedReportingYear={year}
          facility={facilityData.value.result}
          referenceData={formReferenceData}
          reportingYear={
            reportingYearData?.status === 'success'
              ? reportingYearData.value.result
              : undefined
          }
          onSave={onSave}
          onDelete={onDelete}
          isLoadingReportingYear={isLoadingReportingYear}
        />
      </>
    );
  }

  return <BlockError />;
};

UpdateWrmFacility.displayName = 'UpdateWrmFacility';
