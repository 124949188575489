import React from 'react';

import '../../styles/list-boxes.css';

interface ListBox {
  title: string;
  items: string[];
}
interface ListBoxesProps {
  boxes: ListBox[];
}

export class ListBoxes extends React.Component<ListBoxesProps, {}> {
  render() {
    return (
      <div className="list-boxes col-sm-12">
        <div className="row">
          {this.props.boxes.map((box, i) => (
            <div key={i} className={'list-box col-md-6'}>
              <h4>{box.title}</h4>
              <ul>
                {box.items.map((item, j) => (
                  <li key={j}>{item.replace(/^o /, '').trim()}</li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    );
  }
}
