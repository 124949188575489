import React, { FC, useEffect } from 'react';
import classnames from 'classnames/bind';
import { LoadingSpinner } from 'src/components/LoadingSpinner';
import { BlockError } from 'src/components/BlockError';
import { Copy } from 'src/components/Copy';
import { RouteComponentProps } from 'react-router-dom';
import styles from './CreateWaaFacility.module.css';
import { useQuery, useQueryClient } from 'react-query';
import { getAssessment } from 'src/clients/api/assessment';
import {
  createWaaFacility,
  CreateWaaFacilityReportingYearRequestPayloadType,
  getFacility,
} from 'src/clients/api/facilty';
import { useAsyncFormState } from 'src/hooks/useAsyncFormState';
import { AssessmentCarousel } from '../../components/FacilityForm/AssessmentCarousel';
import { AccountSelector } from 'src/components/AccountSelector';
import { useThemeManager } from 'src/hooks/useThemeManager';

const cx = classnames.bind(styles);

type CreateWaaFacilityProps = {};

type FacilityFormValueType = Omit<
  CreateWaaFacilityReportingYearRequestPayloadType,
  'assessmentAnswers'
> & {
  answers: Record<string, string>;
};

export const CreateWaaFacility: FC<
  CreateWaaFacilityProps & RouteComponentProps<{}, {}, { facilityId?: string }>
> = ({ history, location }) => {
  const facilityId = location.state?.facilityId;
  const { setState, resetState } = useAsyncFormState();
  const queryClient = useQueryClient();
  const themeManager = useThemeManager();

  useEffect(() => {
    if (resetState) {
      resetState();
      themeManager.setThemeProperties!({
        showSidebar: false,
        subheaderVariant: 'waa',
      });
    }

    return () => {
      themeManager.resetThemeProperties!();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const { isLoading, data } = useQuery(['assessment'], () => getAssessment());
  const { isLoading: isLoadingFacility, data: facilityData } = useQuery(
    ['facility', facilityId, 'waa'],
    () => getFacility(facilityId!, 'waa'),
    { enabled: !!facilityId }
  );

  if (isLoading || isLoadingFacility) {
    return (
      <div className={cx('createFacility', 'loading')}>
        <LoadingSpinner />
      </div>
    );
  }

  const onSave = async ({ answers, ...values }: FacilityFormValueType) => {
    if (setState) {
      try {
        setState('saveState', { status: 'loading' });

        const result = await createWaaFacility(
          {
            ...values,
            reportingYear: Number(values.reportingYear),
            fkSector: Number(values.fkSector),
            assessmentAnswers: Object.entries(answers).map(
              ([questionId, questionChoiceId]) => ({
                questionId,
                questionChoiceId,
              })
            ),
            questionnaireId: '15e58462-9ac6-4a10-800e-47ffdc484d69',
          },
          facilityId
        );

        if (result.status === 'error') {
          throw new Error(result.value.errors[0].message);
        }

        setState('saveState', {
          status: 'success',
          message: 'Facility created successfully!',
        });

        queryClient.refetchQueries(['facilities']);
        queryClient.invalidateQueries(['facilitiesList']);
        history.push('/waa/dashboard');
      } catch (e) {
        setState('saveState', {
          status: 'error',
          message: e.message ?? 'There was an error saving your facility.',
        });
      }
    }
  };

  if (data && data?.status === 'success') {
    return (
      <>
        <header className={cx('header')}>
          <Copy as="h2">Create Facility</Copy>

          <Copy as="p">
            Your privacy is important to us. Precautions are in place to protect
            your information against any misuse. Please refer to the{' '}
            <a href="https://www.ecolab.com/privacy-policy">PRIVACY POLICY</a>{' '}
            for additional details.
          </Copy>

          <div style={{ width: '35%' }}>
            <AccountSelector hideWhileSingle />
          </div>
        </header>
        <AssessmentCarousel
          assessment={data.value.result}
          onSave={onSave}
          facility={
            facilityData?.status === 'success'
              ? facilityData.value.result
              : undefined
          }
        />
      </>
    );
  }

  return <BlockError />;
};

CreateWaaFacility.displayName = 'CreateWaaFacility';
