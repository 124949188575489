import { ProcessedFacility } from 'src/types/processedFacility';
import { ReferenceDataWaterUnit } from 'src/types/referenceData';
import {
  ExpenseOrSavings,
  OptionalExpensesSavingsYear,
} from './OptionalExpensesSavingsYear';

export class CashFlowModel {
  initialAmount: number;
  initialYear: number;
  waterSaved: number;
  facility: ProcessedFacility;
  incomingIncrease: number;
  outgoingIncrease: number;
  optionalExpensesSavingsYears: Record<string, OptionalExpensesSavingsYear>;
  cashFlowArray: Array<number>;
  cashFlowArrayRiskAdjusted: Array<number>;
  discountedCashFlowArray: Array<number>;
  discountedCashFlowArrayRiskAdjusted: Array<number>;
  npvArray: Array<number>;
  npvArrayRiskAdjusted: Array<number>;
  volumeUnitFactor: number;

  constructor(
    expenses: Array<any>,
    savings: Array<any>,
    initialAmount: number,
    initialYear: number,
    waterSaved: number,
    discountRate: number,
    facility: ProcessedFacility,
    incomingIncrease: number,
    outgoingIncrease: number,
    waterUnitsMap: Record<number, ReferenceDataWaterUnit>
  ) {
    this.initialAmount = initialAmount || 0;
    this.initialYear = initialYear;
    this.waterSaved = waterSaved || 0;
    this.facility = facility;
    this.incomingIncrease = incomingIncrease;
    this.outgoingIncrease = outgoingIncrease;
    this.volumeUnitFactor =
      waterUnitsMap[facility.incomingWaterQuantityUnitId].factor;

    this.optionalExpensesSavingsYears = getOptionalExpensesSavingsMap(
      expenses,
      savings
    );

    this.cashFlowArray = this.getCashFlowArray(20);
    this.cashFlowArrayRiskAdjusted = this.getCashFlowArrayRiskAdjusted(20);
    this.discountedCashFlowArray = getDiscountedCashFlowArray(
      this.cashFlowArray,
      discountRate / 100
    );
    this.discountedCashFlowArrayRiskAdjusted = getDiscountedCashFlowArray(
      this.cashFlowArrayRiskAdjusted,
      discountRate / 100
    );
    this.npvArray = getNPVArray(this.discountedCashFlowArray);
    this.npvArrayRiskAdjusted = getNPVArray(
      this.discountedCashFlowArrayRiskAdjusted
    );
  }

  getCashFlowArray(numOfTerms: number) {
    let cashFlowArray = [this.initialAmount];

    for (let i = 0; i < numOfTerms; i++) {
      let year = this.initialYear + i;
      let cashFlowAmount = 0;

      //input
      cashFlowAmount +=
        this.waterSaved *
        this.facility.incomingWaterUnitPrice *
        this.volumeUnitFactor *
        Math.pow(1 + (this.incomingIncrease || 0) / 100, i);
      //output
      // cashFlowAmount += this.waterSaved * this.facility.outgoingWaterUnitPrice * Math.pow((1 + (this.outgoingIncrease || 0)/100), i);
      cashFlowAmount +=
        this.waterSaved *
        this.facility.outgoingWaterUnitPrice *
        this.volumeUnitFactor *
        Math.pow(1 + (this.outgoingIncrease || 0) / 100, i);
      if (this.optionalExpensesSavingsYears[year]) {
        cashFlowAmount += this.optionalExpensesSavingsYears[
          year
        ].getOtherExpensesSavings();
      }
      cashFlowArray.push(cashFlowAmount);
    }
    return cashFlowArray;
  }

  getCashFlowArrayRiskAdjusted(numOfTerms: number) {
    var cashFlowArray = [this.initialAmount];

    for (var i = 0; i < numOfTerms; i++) {
      var year = this.initialYear + i;
      var cashFlowAmount = 0;

      var outgoingPrice = this.facility.charts.outgoingWaterRiskChart
        .attributes['year1-combined-unit-price'];
      var incomingPrice =
        this.facility.charts.incomingWaterRiskChart.attributes[
          'year1-quantity-unit-price'
        ] +
        this.facility.charts.incomingWaterRiskChart.attributes[
          'year1-waterbill-unit-price'
        ];

      //incoming
      cashFlowAmount +=
        this.waterSaved *
        incomingPrice *
        this.volumeUnitFactor *
        Math.pow(1 + (this.incomingIncrease || 0) / 100, i);

      //outgoing
      cashFlowAmount +=
        this.waterSaved *
        outgoingPrice *
        this.volumeUnitFactor *
        Math.pow(1 + (this.outgoingIncrease || 0) / 100, i);

      if (this.optionalExpensesSavingsYears[year]) {
        cashFlowAmount += this.optionalExpensesSavingsYears[
          year
        ].getOtherExpensesSavings();
      }

      cashFlowArray.push(cashFlowAmount);
    }

    return cashFlowArray;
  }
}

function getOptionalExpensesSavingsMap(
  expenses: Array<ExpenseOrSavings>,
  savings: Array<ExpenseOrSavings>
) {
  let optionalExpensesSavingsYears: Record<
    string,
    OptionalExpensesSavingsYear
  > = {};

  for (let i = expenses.length - 1; i >= 0; i--) {
    let e = expenses[i];
    if (!e.year || !e.amount) {
      continue;
    }
    if (!optionalExpensesSavingsYears[e.year]) {
      optionalExpensesSavingsYears[e.year] = new OptionalExpensesSavingsYear();
    }
    optionalExpensesSavingsYears[e.year].expenses.push(e);
    optionalExpensesSavingsYears[e.year].expenseAmount += e.amount;
  }

  for (let i = savings.length - 1; i >= 0; i--) {
    let s = savings[i];
    if (!s.year || !s.amount) {
      continue;
    }
    if (!optionalExpensesSavingsYears[s.year]) {
      optionalExpensesSavingsYears[s.year] = new OptionalExpensesSavingsYear();
    }
    optionalExpensesSavingsYears[s.year].savings.push(s);
    optionalExpensesSavingsYears[s.year].savingsAmount += s.amount;
  }
  return optionalExpensesSavingsYears;
}

function getDiscountedCashFlowArray(
  cashFlow: Array<number>,
  discountPercent: number
) {
  let discountedCashFlow = [];

  for (let i = 0; i < cashFlow.length; i++) {
    discountedCashFlow.push(
      cashFlow[i] / Math.pow(1 + (discountPercent || 0), i)
    );
  }

  return discountedCashFlow;
}

function getNPVArray(discountedCashFlowArray: Array<number>) {
  let npvArray = [discountedCashFlowArray[0]];

  for (let i = 1; i < discountedCashFlowArray.length; i++) {
    npvArray.push(npvArray[i - 1] + discountedCashFlowArray[i]);
  }

  return npvArray;
}
