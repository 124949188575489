import * as React from 'react';
import * as d3 from 'd3';
import { DefaultArcObject } from 'd3';

const width = 511;
const height = 300;
const margin = { top: 20, right: 5, bottom: 20, left: 35, legend: 20 };

const legendRadius = 15;

const maturityLevelsOrder = [
  'Untapped',
  'Linear',
  'Exploratory',
  'Water-smart',
];

export interface MaturityData {
  maturity: string;
  count: number;
  color: string;
}

interface GraphProps {
  maturityCounts: any;
  markAsLoaded: Function;
}

interface GraphState {}

export class MaturityWithinCompany extends React.Component<
  GraphProps,
  GraphState
> {
  legendRef: any = {};
  donutRef: any = {};

  constructor(props: GraphProps) {
    super(props);
    this.state = {};

    //this.wrap = this.wrap.bind(this);
    this.updateData = this.updateData.bind(this);
    this.drawLegend = this.drawLegend.bind(this);
  }

  componentDidUpdate(prevProps: GraphProps) {
    if (
      this.getTotalCount(prevProps.maturityCounts) !==
        this.getTotalCount(this.props.maturityCounts) &&
      this.getTotalCount(this.props.maturityCounts) > 0
    ) {
      let preparedData = this.prepareMaturityChartData();

      this.drawLegend(preparedData);
      this.drawPie(preparedData);
    }
  }

  private getTotalCount(maturityCounts: any): number {
    let total = 0;
    if (maturityCounts) {
      Object.keys(this.props.maturityCounts).forEach(
        (key) => (total += maturityCounts[key])
      );
    }
    return total;
  }

  componentDidMount() {
    if (
      this.props.maturityCounts &&
      Object.keys(this.props.maturityCounts).length > 0
    ) {
      let preparedData = this.prepareMaturityChartData();

      this.drawLegend(preparedData);
      this.drawPie(preparedData);
    }
    this.props.markAsLoaded();
  }

  prepareMaturityChartData(): MaturityData[] {
    let maturityData: MaturityData[] = [];
    Object.keys(this.props.maturityCounts).forEach((maturityLevel) => {
      maturityData.push({
        maturity: maturityLevel, // need to cast to any or add an index signature. Casting in this case.
        count: this.props.maturityCounts[maturityLevel],
        color: this.getMaturityColor(maturityLevel),
      });
    });

    return maturityData;
  }

  getMaturityColor(maturity: string): string {
    switch (maturity) {
      case 'Water-smart': {
        return '#005474';
      }
      case 'Exploratory': {
        return '#158BCC';
      }
      case 'Linear': {
        return '#49BBEB';
      }
      case 'Untapped': {
        return '#BFE7F7';
      }
      default: {
        return '#000000';
      }
    }
  }

  drawLegend(data: MaturityData[]) {
    // get d3 selection tied to the legend group
    let legendGroup = d3.select(this.legendRef);
    legendGroup.selectAll('*').remove();

    // get sum for percentage calculation
    let valueSum = 0;
    data.forEach((dataPoint) => (valueSum += dataPoint.count));

    let legendHeight =
      2 * legendRadius * data.length + (legendRadius * data.length - 1);
    let shiftDown = (height - legendHeight) / 2;

    // sort by maturity order
    data.sort(
      (a, b) =>
        maturityLevelsOrder.indexOf(b.maturity) -
        maturityLevelsOrder.indexOf(a.maturity)
    );

    // draw legend items
    data.forEach((dataPoint, i) => {
      let y = legendRadius + shiftDown + 3 * legendRadius * i;

      legendGroup
        .append('circle')
        .attr('cx', legendRadius)
        .attr('cy', y)
        .attr('r', legendRadius)
        .style('fill', dataPoint.color);

      legendGroup
        .append('text')
        .text(
          ((dataPoint.count / valueSum) * 100).toFixed(0) +
            '% ' +
            dataPoint.maturity
        )
        .attr('x', 2 * legendRadius + 10)
        .attr('y', y)
        .style('fill', '#000000')
        .attr('text-anchor', 'start')
        .attr('alignment-baseline', 'middle');
    });
  }

  drawPie(chartData: MaturityData[]) {
    let diameter = height - margin.bottom - margin.top;

    // create arc generator
    let arcGenerator = d3
      .arc()
      .innerRadius(diameter / 3.5)
      .outerRadius(diameter / 2);

    // sort by maturity order
    chartData.sort(
      (a, b) =>
        maturityLevelsOrder.indexOf(b.maturity) -
        maturityLevelsOrder.indexOf(a.maturity)
    );

    // calculate the start and stop angles for each slice. also order from smallest to biggest
    let pieGenerator = d3
      .pie()
      .startAngle(0)
      .endAngle(2 * Math.PI)
      .sort(null);
    let pieArcs = pieGenerator(chartData.map((dataPoint) => dataPoint.count));

    let valueSum = 0;
    chartData.forEach((dataPoint) => (valueSum += dataPoint.count));

    // get d3 selection handle for pie Group
    let pieGroup = d3.select(this.donutRef);
    pieGroup.selectAll('*').remove();

    // create all of the slices
    pieArcs.forEach((data, i) => {
      let arcData = {
        startAngle: data.startAngle,
        endAngle: data.endAngle,
      } as DefaultArcObject;

      // create an arc
      let arc = arcGenerator(arcData);

      // Add pie slice to the pie group
      pieGroup
        .append('path')
        .attr('d', arc as string)
        .attr('class', 'pie-slice')
        .attr('fill', chartData[i].color);

      // Add Values to slices
      let center = arcGenerator.centroid(arcData);
      let percent = (data.value / valueSum) * 100;

      pieGroup
        .append('text')
        .text(percent > 3.5 ? percent.toFixed(0) + '%' : '')
        .attr('x', center[0])
        .attr('y', center[1])
        .style('fill', '#FFFFFF')
        .attr('text-anchor', 'middle');
    });
  }

  updateData() {
    this.setState({
      data: this.prepareMaturityChartData(),
    });
  }

  wrap(text: any) {
    text.each(function (node: any, index: number, arr: any) {
      let text = d3.select(arr[index]);
      let words = node.split(/\s+/).reverse(),
        word,
        lineNumber = 0,
        lineHeight = 1.1, // ems
        y = text.attr('y'),
        dy = parseFloat(text.attr('dy'));

      text
        .text(null)
        .append('tspan')
        .attr('x', 0)
        .attr('y', y)
        .attr('dy', dy + 'em');

      while ((word = words.pop())) {
        text
          .append('tspan')
          .attr('x', 0)
          .attr('y', y)
          .attr('dy', ++lineNumber * lineHeight + dy + 'em')
          .text(word);
      }
    });
  }

  render() {
    let legendRef = (el: any) => (this.legendRef = el);
    let donutRef = (el: any) => (this.donutRef = el);
    let diameter = height - margin.bottom - margin.top;

    return (
      <svg
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        preserveAspectRatio="xMinYMid meet"
      >
        <g
          ref={donutRef}
          className="chart-donut"
          transform={`translate(${diameter / 2 + margin.left}, ${
            diameter / 2 + margin.top
          })`}
        />

        <g
          ref={legendRef}
          className="chart-legend"
          transform={`translate(${2 * margin.left + diameter}, 0)`}
        />
      </svg>
    );
  }
}
