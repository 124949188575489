import { WrappedResponse } from '../types';
import * as request from '../request';
import { Countries as CountriesType } from '../../types/country';
import { Regions as RegionsType } from '../../types/region';
import { Cities as CitiesType } from '../../types/city';
import { Env } from '../../constants/env';

export type CountriesResponseType = WrappedResponse<CountriesType>;

export function getCountries() {
  return request.get<CountriesResponseType>(
    `${Env.apiUrl}/v3.1/referencedata/countries`
  );
}

export type RegionsResponseType = WrappedResponse<RegionsType>;

export function getRegions(countryId: number) {
  return request.get<RegionsResponseType>(
    `${Env.apiUrl}/v3.1/referencedata/regions?parentLocationId=${countryId}`
  );
}

export type CitiesResponseType = WrappedResponse<CitiesType>;

export function getCities(regionId: number) {
  return request.get<CitiesResponseType>(
    `${Env.apiUrl}/v3.1/referencedata/cities?parentLocationId=${regionId}`
  );
}
