import React from 'react';
import Modal from 'react-modal';
import { Layout } from '../Layout';
import { APIService, CallType } from '../../services/APIService';
import { Env } from 'src/constants/env';

interface Props {
  isOpen: boolean;
  closeModal: Function;
}

interface State {
  isValid: boolean;
  name: string;
  email: string;
  message: string;
}

export class InviteModal extends React.Component<Props, State> {
  api: APIService | null = null;

  constructor(props: Props) {
    super(props);

    this.state = {
      isValid: false,
      name: '',
      email: '',
      message:
        'I’d like to invite you to use the Ecolab Smart Water Navigator. It’s a practical tool used to evaluate the state of water management at the individual facility level and identify action steps to improve performance. If you do this, you can make your own site work more efficiently and help improve overall water management for the company.',
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.sendInvite = this.sendInvite.bind(this);
  }

  handleInputChange(event: React.ChangeEvent<any>) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    // @ts-ignore
    this.setState({
      [name]: value,
      isValid:
        this.state.name !== '' &&
        this.state.email !== '' &&
        this.state.message !== '',
    } as State);
  }

  sendInvite() {
    if (!this.state.isValid) {
      Layout.notificationSystem.addNotification({
        message: 'Please complete the invite form.',
        level: 'error',
        position: 'tc',
        autoDismiss: 0,
      });
    } else {
      this.api &&
        this.api
          .perform(`${Env.waaApiUrl}/account/sendinvite`, CallType.Json, {
            name: this.state.name,
            email: this.state.email,
            message: this.state.message,
          })
          .catch((error) => {
            console.log(error);
          });

      this.setState({
        name: '',
        email: '',
      });
      this.props.closeModal();
    }
  }

  componentDidMount() {
    this.api = new APIService(Layout.notificationSystem);
  }

  render() {
    return (
      <Modal
        isOpen={this.props.isOpen}
        className="Modal"
        overlayClassName="Overlay"
      >
        <div className="modal-content-wrapper col-sm-12">
          <div className="row">
            <div className="col-md-2 pull-right">
              {' '}
              <button className="close" onClick={() => this.props.closeModal()}>
                &times;
              </button>
            </div>
            <div className="col-md-10">
              <h1 className="modal-heading">Invite others from your company</h1>
            </div>
          </div>
          <div className="row">
            <form>
              <div className="form-group">
                <label htmlFor="name">Name</label>
                <input
                  type="text"
                  onChange={this.handleInputChange}
                  className="form-control"
                  value={this.state.name}
                  id="name"
                  name="name"
                />
              </div>
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  onChange={(e) => this.handleInputChange(e)}
                  className="form-control"
                  value={this.state.email}
                  id="email"
                  name="email"
                />
              </div>
              <div className="form-group">
                <label htmlFor="message">Message</label>
                <textarea
                  onChange={this.handleInputChange}
                  className="form-control"
                  rows={4}
                  value={this.state.message}
                  id="message"
                  name="message"
                ></textarea>
              </div>
            </form>
          </div>

          <div className="row">
            <button
              onClick={this.sendInvite}
              className="btn-itb pull-right"
              disabled={!this.state.isValid}
            >
              Send
            </button>
          </div>
        </div>
      </Modal>
    );
  }
}
