/* eslint-disable import/first */
import React from 'react';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import { Redirect } from './Redirect';
import Switch from 'react-switch';
import { transform } from '@babel/standalone';

import 'isomorphic-fetch';
import '../styles/site-details.css';
import '../styles/dashboard.css';

import { APIService, CallType } from '../services/APIService';
import { Layout } from './Layout';
import { BatchUploadModal } from './Modals/BatchUploadModal';
import { InviteModal } from './Modals/InviteModal';
import {
  MaturityWithinCompany,
  MaturityData,
} from './Cards/Graphs/MaturityWithinCompany';
import { CompanyComparison } from './Cards/Graphs/CompanyComparison';

import 'datatables.net';
import { getTokenInStorage } from 'src/clients/helpers';
import { RouteContentScrollable } from 'src/components/RouteContentScrollable';
import { Env } from 'src/constants/env';
import { SiteFooter } from 'src/components/SiteFooter';
import { getQueryParam } from 'src/hooks/useQueryParams';

// @ts-ignore
window.React = React;
// @ts-ignore
window.Link = Link;

interface Analysis {
  facilityId: string;
  rawScore: number;
  maturityLevel: string;
  categoryScores: Array<CategoryScore>;
  risk: string;
  action: string;
}
export interface CategoryScore {
  category: string;
  score: number;
  averageScore: number;
}

export interface Facility {
  id: number;
  name: string;
  label: string;
  year: number;
  country: string;
  state: string;
  city: string;
  industryCode: string;
  maturity?: string;
  industry?: string;
  uploadedBy: any;
  uploadedByName: string;
  analysis: Analysis;
  score: number;
}

interface DashboardData {
  redirectFacility: { name: 'string' };
  companyFacilities: Facility[];
  userFacilities: Facility[];
  facilities: Facility[];
  filteredFacilities: Facility[];
  maturityChartData: MaturityData[];
  loading: boolean;
  backgroundLoading: boolean;
  maturityLoading: boolean;
  companyLoading: boolean;
  inviteModalIsOpen: boolean;
  fileModalIsOpen: boolean;
  viewEntireCompany: boolean;
  country: string;
  state: string;
  maturity: string;
  countries: string[];
  regions: string[];
  maturityLevels: string[][];

  companyAverageScore: number;
  overallAverageScore: number;
  scorePercentile: number;

  maturityCounts: Record<string, number>;

  facilityTable: any;
  facilityTableComponent: null | ((prop: any) => any);
  tableNeedsUpdate: boolean;
}
export class Dashboard extends React.Component<
  RouteComponentProps<{}>,
  DashboardData
> {
  api: APIService | null = null;

  constructor(props: RouteComponentProps<{}>) {
    super(props);

    const params = getQueryParam(props.location.search);
    const hash = params.get('c');

    let redirectFacility;
    if (hash) {
      console.log(JSON.parse(atob(hash)));
      redirectFacility = JSON.parse(atob(hash));
    }

    this.state = {
      redirectFacility,
      companyFacilities: [],
      userFacilities: [],
      facilities: [],
      filteredFacilities: [],
      maturityChartData: [],
      loading: false,
      backgroundLoading: true,
      maturityLoading: true,
      companyLoading: true,
      inviteModalIsOpen: false,
      fileModalIsOpen: false,
      viewEntireCompany: Layout.viewFullCompany,
      country: '',
      state: '',
      maturity: '',
      countries: [],
      regions: [],
      maturityLevels: [
        ['Untapped', 'Untapped'],
        ['Linear', 'Linear'],
        ['Exploratory', 'Exploratory'],
        ['Water-smart', 'Water-smart'],
      ],
      companyAverageScore: -1,
      overallAverageScore: -1,
      scorePercentile: -1,

      maturityCounts: {},

      facilityTable: null,
      facilityTableComponent: null,
      tableNeedsUpdate: false,
    };
    this.fetchFacilities = this.fetchFacilities.bind(this);
    this.openInviteModal = this.openInviteModal.bind(this);
    this.openFileModal = this.openFileModal.bind(this);
    this.closeModals = this.closeModals.bind(this);
    this.markMaturityAsLoaded = this.markMaturityAsLoaded.bind(this);
    this.markCompanyAsLoaded = this.markCompanyAsLoaded.bind(this);
    this.downloadCsvReport = this.downloadCsvReport.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.handleViewEntireCompanyChange = this.handleViewEntireCompanyChange.bind(
      this
    );
    this.updateDataTable = this.updateDataTable.bind(this);
    this.initializeDataTable = this.initializeDataTable.bind(this);
    this.updateDataTableReactComponent = this.updateDataTableReactComponent.bind(
      this
    );
    this.sortDataTable = this.sortDataTable.bind(this);
    this.setPageDataTable = this.setPageDataTable.bind(this);
    this.setPerPageDataTable = this.setPerPageDataTable.bind(this);
    this.loadAdditionalTableData = this.loadAdditionalTableData.bind(this);
    this.fetchCompanyMaturity = this.fetchCompanyMaturity.bind(this);
    this.getTotalCompanyCount = this.getTotalCompanyCount.bind(this);
  }

  componentDidMount() {
    this.api = new APIService(Layout.notificationSystem);

    // Only perform api call if logged in
    if (!getTokenInStorage()) {
      console.warn(
        'Unable to request dashboard data, you are currently not logged in'
      );
      return;
    }

    this.setState({ viewEntireCompany: Layout.viewFullCompany });

    this.fetchFacilities();
    // this.fetchAllFacilities();
    this.fetchCompanyComparison();
    this.fetchCompanyMaturity();
    this.initializeDataTable();
  }

  componentDidUpdate() {
    if (this.state.tableNeedsUpdate) {
      this.updateDataTable();
    }
  }

  private initializeDataTable() {
    let facilityTableElement: any = $('#facilities-table');
    let table = facilityTableElement.DataTable({
      columns: [
        {
          data: 'name',
          render: function (data: any, type: string) {
            if (type === 'display') {
              return `<h3><a href=${'/waa/result/' + data.id}>${
                data.name
              }</a></h3> <p>${data.city}, ${data.state}, ${data.country}</p>`;
            } else {
              return data.name;
            }
          },
        },
        {
          data: 'maturity',
          render: function (data: any, type: string) {
            if (type === 'display') {
              return `<p><a href=${
                '/waa/industryguide/' +
                data.industryCode +
                '/' +
                data.maturity.toLowerCase()
              }>${data.maturity}<br/>(get the guide)</a></p>`;
            } else if (type === 'sort') {
              return [
                'untapped',
                'linear',
                'exploratory',
                'water-smart',
              ].indexOf(data.maturity.toLowerCase());
            } else {
              return data.maturity;
            }
          },
          className: 'text-nowrap',
        },
        {
          data: 'label',
          render: function (data: any, type: string) {
            if (type === 'display') {
              return `<p>${data}</p>`;
            } else {
              return data;
            }
          },
        },
        {
          data: 'industry',
          render: function (data: any, type: string) {
            if (type === 'display') {
              return `<p>${data}</p>`;
            } else {
              return data;
            }
          },
        },
        {
          data: 'risk',
          render: function (data: any, type: string) {
            if (!data.risk) {
              return '';
            }
            if (type === 'display') {
              return `<p><i class="${data.class + ' risk-indicator'}"></i>${
                data.risk
              }</p>`;
            } else {
              return ['low', 'low-med', 'med-high', 'high', 'ex-high'].indexOf(
                data.class
              );
            }
          },
        },
        {
          data: 'action',
          render: function (data: any, type: string) {
            if (!data || data === undefined) {
              return '';
            }
            if (type === 'display') {
              return `<p>${data}</p>`;
            } else {
              return ['monitor', 'leverage', 'invest', 'optimize'].indexOf(
                data.toLowerCase()
              );
            }
          },
        },
        {
          data: 'uploadedBy',
          render: function (data: any, type: string) {
            if (!data || data === undefined) {
              return '';
            }
            if (type === 'display') {
              return `<p>${data}</p>`;
            } else {
              return data;
            }
          },
        },
        {
          data: 'link',
          render: function (data: any, type: string) {
            if (type === 'display') {
              return `<a href=${
                '/waa/result/' + data
              } class="btn-itb"> view/edit </a>`;
            } else {
              return data;
            }
          },
          orderable: false,
        },
      ],
      searching: false,
      dom: '<"top">rt<"bottom"pil>',
      lengthMenu: [
        [5, 10, 15, -1],
        [5, 10, 15, 'All'],
      ],
      pagingType: 'simple',
      language: {
        lengthMenu: '_MENU_',
        zeroRecords: 'No facilities found',
        paginate: {
          previous: '<',
          next: '>',
        },
      },
      fnInfoCallback: function (
        _oSettings: any,
        iStart: number,
        iEnd: number,
        _iMax: number,
        iTotal: number,
        _sPre: string
      ) {
        return `${iStart} - ${iEnd} of ${iTotal}`;
      },
    });
    this.setState({ facilityTable: table });
  }

  private updateDataTable() {
    // Clear table and prep an array for table data info.
    this.state.facilityTable.clear();
    let tableData: any[] = [];

    // Add tableData for each facility
    this.state.filteredFacilities.forEach((facility) => {
      tableData.push({
        name: {
          id: facility.id,
          name: facility.name,
          city: facility.city,
          state: facility.state,
          country: facility.country,
        },
        maturity: {
          industryCode: facility.industryCode,
          maturity: facility.analysis.maturityLevel,
        },
        label: facility.label,
        industry: facility.industry,
        risk: {
          risk: facility.analysis.risk,
          class: this.getRiskClass(facility.analysis.risk),
        },
        action: facility.analysis.action,
        uploadedBy: facility.uploadedByName,
        link: facility.id,
      });
    });
    // Add rows to table and redraw
    this.state.facilityTable.rows.add(tableData);
    this.state.facilityTable.draw();

    this.updateDataTableReactComponent();

    // Set state to indicate that the table has been updated
    this.setState({ tableNeedsUpdate: false });
  }

  private updateDataTableReactComponent() {
    // Grab the current page length to make sure it's set on the React component rendering
    let pageLength = this.state.facilityTable.page.info().length;

    // Pass get datatable html and parse to JSX with link tags.
    // I know this is ugly. I'm sorry for anyone who has to look at / edit this code.
    let facilitiesTableJSX = document
      ?.getElementById('hidden-datatables-container')
      ?.innerHTML.replace(/class=/g, 'className=')
      .replace(/tabindex=/g, 'tabIndex=')
      .replace(/rowspan=/g, 'rowSpan=')
      .replace(/colspan=/g, 'colSpan=')
      .replace(/<br>/g, '<br/>') // For some reason <br/> tags are missing the slash
      .replace(/style="([a-z]*):([ 0-9a-z.]*);"/g, "style={{$1: '$2'}}") // change inline style to JSX format
      .replace(
        /<a href="([/a-z0-9]*)" ?(?:className="([a-z-]*)")?>([ a-zA-Z/()]*(?:<br\/>)?[ a-zA-Z/()]*)<\/a>/g,
        '<Link to={"$1"} className="$2">$3</Link>'
      ) // Change all anchor tags to Link components
      .replace(/<th /g, '<th onClick={sort} ') // add sort handler function to regenerated th elements
      .replace(
        /<a className="paginate_button/g,
        '<a onClick={changePage} className="paginate_button'
      ) // Add handler to pagination buttons
      .replace(
        /<select/g,
        '<select onChange={changePerPage} value="' + pageLength + '"'
      ); // add onChange handler to

    // Set state to indicate that the table has been updated
    this.setState({
      // @ts-ignore
      facilityTableComponent: ({ sort, changePage, changePerPage }) => {
        // eslint-disable-next-line no-eval
        let Component = eval(
          transform(facilitiesTableJSX ?? '', { presets: ['react'] }).code ?? ''
        );
        return Component;
      },
    });
  }

  // I hate this
  private sortDataTable(event: React.MouseEvent) {
    let sortColumn = event.target;
    let columnDirection = $(sortColumn).hasClass('sorting_asc')
      ? 'desc'
      : 'asc'; //toggle
    let column = $(sortColumn).index();
    this.state.facilityTable.order([column, columnDirection]).draw();
    this.updateDataTableReactComponent();
  }

  // This is gross
  private setPageDataTable(event: React.MouseEvent) {
    let change = $(event.target).hasClass('next') ? 'next' : 'previous';
    this.state.facilityTable.page(change).draw('page');
    this.updateDataTableReactComponent();
  }

  // I can't belive it's come to this.
  private setPerPageDataTable(event: React.ChangeEvent) {
    let perPage = $(event.target).val();
    this.state.facilityTable.page.len(perPage).draw();
    this.updateDataTableReactComponent();
  }

  // This is only called once on mount. It will populate all facilities for domain that is used for the intra company benchmarks. (Did it this way to avoid redoing facility display logic)
  fetchAllFacilities() {
    this.api &&
      this.api
        .perform(`${Env.waaApiUrl}/v4/facility`, CallType.Json)
        .then((data) => {
          this.setState({
            companyFacilities: data ? data : [],
            facilities: Layout.viewFullCompany
              ? data
                ? data
                : []
              : this.state.facilities,
            loading: false,
          });

          if (Layout.viewFullCompany) {
            this.loadAdditionalTableData();
          }
        });
  }

  fetchFacilities() {
    const path = Layout.viewFullCompany ? 'v4/facility' : 'v4/user/facility';
    this.api &&
      this.api
        .perform(`${Env.waaApiUrl}/${path}`, CallType.Json)
        .then((data) => {
          this.setState({
            facilities: data ? data : [],
            loading: false,
            backgroundLoading: false,
          });

          this.loadAdditionalTableData();
        });
  }

  loadAdditionalTableData() {
    Layout.waterStressService.getCountries().then((countryData) => {
      var availableCountries = this.state.facilities.map((f) => f.country);
      var countries = countryData.filter(
        (c) => availableCountries.indexOf(c) !== -1
      );

      this.setState({
        countries: countries,
        country: '',
        state: '',
      });

      this.updateFilters(
        this.state.country,
        this.state.state,
        this.state.maturity
      );
    });

    this.setState({
      tableNeedsUpdate: true,
    });
  }

  fetchCompanyComparison() {
    this.api &&
      this.api
        .perform(
          `${Env.waaApiUrl}/v4/facility/companycomparison`,
          CallType.Json
        )
        .then((data) => {
          this.setState({
            companyAverageScore: data.companyAverage,
            overallAverageScore: data.globalAverage,
            scorePercentile: data.percentBelow,
          });
        });
  }

  fetchCompanyMaturity() {
    this.api &&
      this.api
        .perform(`${Env.waaApiUrl}/v4/facility/companymaturity`, CallType.Json)
        .then((data) => {
          this.setState({
            maturityCounts: data ? data : {},
          });
        });
  }

  markMaturityAsLoaded() {
    this.setState({ maturityLoading: false });
  }

  markCompanyAsLoaded() {
    this.setState({ companyLoading: false });
  }

  private getTotalCompanyCount(): number {
    let total = 0;
    if (this.state.maturityCounts) {
      Object.keys(this.state.maturityCounts).forEach(
        (key) => (total += this.state.maturityCounts[key])
      );
    }
    console.log(total);
    return total;
  }

  openInviteModal() {
    this.setState({ inviteModalIsOpen: true });
  }

  openFileModal() {
    this.setState({ fileModalIsOpen: true });
  }

  closeModals() {
    this.setState({
      inviteModalIsOpen: false,
      fileModalIsOpen: false,
      backgroundLoading: true,
    });
    this.fetchFacilities();
    this.fetchAllFacilities();
  }

  downloadCsvReport() {
    var facilities = this.state.facilities.map((facility) => {
      return {
        id: facility.id,
        name: facility.name,
        label: facility.label,
        year: facility.year,
        country: facility.country,
        city: facility.city,
        industryCode: facility.industryCode,
        // quizHistories: facility.quizHistories,
        // userGroupId: facility.userGroupId,
        risk: facility.analysis.risk,
        uploadedBy: facility.uploadedByName,
      };
    });
    var headers = Object.keys(facilities[0])
      .map((header) => {
        return (
          '"' +
          header
            .replace(/^\w|[A-Z]/g, function (t) {
              return ' ' + t.toUpperCase();
            })
            .trim() +
          '"'
        );
      })
      .join(',');
    var body = facilities.map((f: any) =>
      Object.keys(f)
        .map((k: any) => '"' + f[k] + '"')
        .join(',')
    );
    var csv = [headers, ...body].join('\n');
    var data = new Blob([csv], { type: 'text/csv' });
    var csvURL = window.URL.createObjectURL(data);
    var tempLink = document.createElement('a');
    tempLink.href = csvURL;
    tempLink.setAttribute('download', 'Company Report.csv');
    tempLink.click();
  }

  handleInputChange(event: React.ChangeEvent<any>) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    // @ts-ignore
    this.setState({
      [name]: value,
    } as DashboardData);
  }

  handleFilterChange(event: React.ChangeEvent<any>) {
    const target = event.target;
    const value = target.value;
    const name = target.name as string;

    this.setState({ [name]: value } as DashboardData);

    let country = this.state.country;
    let region = this.state.state;
    let maturity = this.state.maturity;

    switch (name) {
      case 'country':
        country = value;
        // Clear region options and selection
        region = '';
        this.setState({ regions: [], state: '' });
        // Set Region Options if the country has a value
        if (country !== '') {
          Layout.waterStressService.getRegions(value).then((data) => {
            var availableStates = this.state.facilities.map((f) => f.state);
            var states = data.filter((c) => availableStates.indexOf(c) !== -1);
            this.setState({ regions: states });
          });
        }
        break;
      case 'state':
        region = value;
        break;
      case 'maturity':
        maturity = value;
        break;
    }

    this.updateFilters(country, region, maturity);
  }

  updateFilters(country = '', region = '', maturity = '') {
    if (this.state.facilities) {
      this.setState({
        filteredFacilities: this.state.facilities.filter((facility) => {
          return (
            (country === '' || facility.country === country) &&
            (region === '' || facility.state === region) &&
            (maturity === '' || facility.analysis.maturityLevel === maturity)
          );
        }),
        tableNeedsUpdate: true,
      });
    }
  }

  getRiskClass(risk = '') {
    switch (risk) {
      case 'Low risk':
        return 'low';
      case 'Low to medium risk':
        return 'low-med';
      case 'Medium to high risk':
        return 'med-high';
      case 'High risk':
        return 'high';
      case 'Extremely high risk':
        return 'ex-high';
      default:
        return '';
    }
  }

  handleViewEntireCompanyChange() {
    Layout.toggleFullCompany();
    // this.setState({
    //     viewEntireCompany: Layout.viewFullCompany,
    //     facilities: Layout.viewFullCompany ? this.state.companyFacilities : this.state.userFacilities
    // }, this.updateFilters);
    this.setState(
      { viewEntireCompany: Layout.viewFullCompany },
      this.fetchFacilities
    );
  }

  getMaturityFromScore(score: number) {
    if (score < 140) {
      return 'Untapped';
    }
    if (score < 280) {
      return 'Linear';
    }
    if (score < 420) {
      return 'Exploratory';
    }
    return 'Water-smart';
  }

  public render() {
    let token = getTokenInStorage();
    if (!token || token === 'expired') {
      return <Redirect to={'/login'}></Redirect>;
    }

    let contents = this.state.loading ? (
      <div className="loading-text">Loading...</div>
    ) : (
      this.renderResults()
    );

    return <>{contents}</>;
  }

  renderResults() {
    return (
      <RouteContentScrollable className="module-waa">
        <div id="main-content">
          <div className="dashboard">
            <BatchUploadModal
              isOpen={this.state.fileModalIsOpen}
              closeModal={this.closeModals}
            ></BatchUploadModal>
            <InviteModal
              isOpen={this.state.inviteModalIsOpen}
              closeModal={this.closeModals}
            ></InviteModal>
            <header className="row">
              <div className="col-md-12">
                <h1>Dashboard</h1>
              </div>
            </header>
            <div className="row intro-waves"></div>

            {this.state.redirectFacility?.name && (
              <div
                className="row"
                style={{ backgroundColor: 'white', color: '#333' }}
              >
                <div
                  className="col-sm-12 col-md-4 col-md-offset-4"
                  style={{
                    padding: 20,
                    color: '#084298',
                    backgroundColor: '#cfe2ff',
                    borderColor: '#b6d4fe',
                    borderRadius: '5px',
                  }}
                >
                  <h3 style={{ fontSize: '14px', margin: 0 }}>
                    Find or create facility matching{' '}
                    <strong>{this.state.redirectFacility.name}</strong> and
                    complete your Water Action Assessment.
                  </h3>
                </div>
              </div>
            )}
            <div className="row">
              <main className="white-background black-text container-fluid">
                <div
                  className={
                    this.state.maturityLoading && this.state.companyLoading
                      ? 'row'
                      : 'hidden'
                  }
                >
                  <div className="loading-text">Loading...</div>
                </div>
                <div className="row">
                  <form className="form-inline filters">
                    <div className="form-group">
                      <label>Filter by:</label>
                      <select
                        value={this.state.country}
                        className="form-control"
                        id="country"
                        onChange={this.handleFilterChange}
                        name="country"
                      >
                        <option value="" disabled hidden>
                          Country
                        </option>
                        <option value=""></option>
                        {this.state.countries.sort().map((country) => (
                          <option key={country} value={country}>
                            {country}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="form-group">
                      <select
                        className="form-control"
                        value={this.state.state}
                        id="state"
                        onChange={this.handleFilterChange}
                        name="state"
                      >
                        <option value="" disabled hidden>
                          State/Province
                        </option>
                        <option value=""></option>
                        {this.state.regions.sort().map((region) => (
                          <option key={region} value={region}>
                            {region}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="form-group">
                      <select
                        className="form-control"
                        value={this.state.maturity}
                        id="maturity"
                        onChange={this.handleFilterChange}
                        name="maturity"
                      >
                        <option value="" disabled hidden>
                          Water maturity
                        </option>
                        <option value=""></option>
                        {this.state.maturityLevels.map((level) => (
                          <option key={level[0]} value={level[0]}>
                            {level[1]}
                          </option>
                        ))}
                      </select>
                    </div>

                    <label htmlFor="material-switch">
                      <span>View entire company:</span>
                      <Switch
                        checked={Layout.viewFullCompany}
                        onChange={this.handleViewEntireCompanyChange}
                        onColor="#008BD1"
                        handleDiameter={22}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                        height={14}
                        width={32}
                        className="react-switch"
                        id="material-switch"
                      />
                    </label>
                  </form>
                </div>
                <div className="row">
                  <div className="col-md-12 black-text text-left">
                    <div id="hidden-datatables-container">
                      <table className="facilities-table" id="facilities-table">
                        <thead>
                          <tr>
                            <th className="first">Facility name</th>
                            <th onClick={this.sortDataTable}>Water maturity</th>
                            <th onClick={this.sortDataTable}>Facility label</th>
                            <th onClick={this.sortDataTable}>Industry</th>
                            <th onClick={this.sortDataTable}>
                              Overall Water Risk
                            </th>
                            <th onClick={this.sortDataTable}>Action</th>
                            <th onClick={this.sortDataTable}>Uploaded by</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody></tbody>
                      </table>
                    </div>

                    {this.state.facilityTableComponent ? (
                      <this.state.facilityTableComponent
                        sort={this.sortDataTable}
                        changePage={this.setPageDataTable}
                        changePerPage={this.setPerPageDataTable}
                      />
                    ) : (
                      ''
                    )}
                  </div>
                  <div className="col-md-12 text-right">
                    <button
                      onClick={this.downloadCsvReport}
                      className="btn-itb btn-itb-blue"
                      disabled={this.state.backgroundLoading}
                    >
                      Generate CSV report
                    </button>
                    <Link
                      to={'/wrm/facilities/create/waa'}
                      className="btn-itb btn-itb-blue"
                    >
                      + add another site
                    </Link>
                    <Link
                      to={{
                        pathname: '/wrm/upload',
                        state: {
                          type: 'waa',
                        },
                      }}
                      className="btn-itb btn-itb-blue"
                    >
                      + add multiple sites
                    </Link>
                  </div>
                </div>
                <div
                  className={
                    this.getTotalCompanyCount() > 2 &&
                    !this.state.maturityLoading &&
                    !this.state.companyLoading
                      ? 'row'
                      : 'hidden'
                  }
                >
                  <div className="col-md-12 black-text text-left">
                    <h1>Company benchmark data</h1>
                    <p>Number of facilities: {this.getTotalCompanyCount()}</p>
                    <div className="maturity-performance row">
                      <div className="col-sm-12 col-md-6 card">
                        {this.state.facilities.length > 0 ? (
                          <MaturityWithinCompany
                            maturityCounts={this.state.maturityCounts}
                            markAsLoaded={this.markMaturityAsLoaded}
                          ></MaturityWithinCompany>
                        ) : (
                          ''
                        )}
                      </div>
                      <div className="col-sm-12 col-md-6">
                        <h2>Water maturity performance within your company</h2>
                        <p>
                          Use this graphic to see how your facilities and any
                          facilities within your company are performing overall.
                        </p>
                      </div>
                    </div>

                    <div className="maturity-performance row">
                      <div className="col-sm-12 col-md-6 card">
                        {this.state.companyAverageScore >= 0 ? (
                          <CompanyComparison
                            companyAverage={this.state.companyAverageScore}
                            overallAverage={this.state.overallAverageScore}
                            markAsLoaded={this.markCompanyAsLoaded}
                          ></CompanyComparison>
                        ) : (
                          ''
                        )}
                      </div>
                      <div className="col-sm-12 col-md-6">
                        <h2>
                          Your company's average Water Maturity performance
                          compared to other companies
                        </h2>
                        <p>
                          Your company's average score across its facilities is{' '}
                          <b>
                            {this.getMaturityFromScore(
                              this.state.companyAverageScore
                            )}
                          </b>{' '}
                          and performed better than{' '}
                          <b>{this.state.scorePercentile.toFixed(1)}%</b> of
                          other companies.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </main>
            </div>
          </div>
        </div>
        <SiteFooter solid style={{ width: '100%' }} />
      </RouteContentScrollable>
    );
  }
}
