import React, { FC } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Link } from './Link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

import { RouteContentScrollable } from 'src/components/RouteContentScrollable';

import '../styles/site.css';
import '../styles/home.css';
import { SiteFooter } from 'src/components/SiteFooter';
import { Helmet } from 'src/components/Helmet';

export interface HomePropsType {}

export const Home: FC<RouteComponentProps<HomePropsType>> = () => {
  return (
    <RouteContentScrollable className="module-waa">
      <Helmet titleTemplate="">
        <title>
          Water Action Assessment – Moving Toward an Achievable Water Action
          Plan
        </title>
        <Helmet titleTemplate="">
          <meta
            name="description"
            content="Water scarcity presents a growing business risk. By taking the Water Action Assessment, you can start implementing a water action plan that helps you meet your company’s water goals."
          />
        </Helmet>
      </Helmet>
      <div id="main-content">
        <div className="home">
          <div id="intro" className="row section">
            <div className="col-sm-6 col-lg-5 col-lg-offset-1">
              <h1>Move toward smarter water management</h1>
              <p>
                Water scarcity presents a growing risk to business. By taking
                the Water Action Assessment, you can start implementing a water
                action plan to protect your facilities and ensure continued
                growth.
              </p>
            </div>
            <div className="col-sm-6">
              <img src="./images/waa/wmc-4raw.png" alt="" />
              <br />
            </div>
            <a href="#program">Get started</a>
            <div className="water-drop-line"></div>
            <div className="water-drop"></div>
          </div>
          <div className="row intro-waves"></div>
          <div id="program" className="row section white">
            <div className="col-sm-6 col-lg-5 col-lg-offset-1">
              <p>
                Because of climate change, population growth and economic
                growth, water will grow scarcer and costlier in years to come.
              </p>
              <h3>
                Now is the time for companies to make their water action plan.
              </h3>
              <p>
                What if there was a practical program to prepare your business
                for a water-scarce world, limiting your exposure to risk and
                setting you up for continued success in a challenging
                environment?
              </p>
              <p>As a matter of fact, there is.</p>
            </div>
            <div className="col-sm-6">
              <CheckList
                uncheckedItems={[
                  'Water scarcity',
                  'Higher operating costs',
                  'Supply chain disruption',
                  'Water supply disruption',
                  'Brand damage',
                ]}
                checkedItems={[
                  'Target setting',
                  'Water management',
                  'Technology',
                  'Corporate citizenship',
                  'Water maturity',
                ]}
              />
            </div>
          </div>
          <div id="roadmap" className="row section">
            <div className="col-sm-12 col-lg-8 col-lg-offset-2">
              <p>
                The Smart Water Navigator is a roadmap for business. It is a
                holistic approach to corporate water management, supported by a
                practical guide to smart, sustainable water practices at the
                facility level.
              </p>
              <p>
                In a world where water scarcity is the new normal, progressive
                companies are future-proofing their facilities to safeguard
                their growth – minimizing risk, maximizing performance and
                optimizing cost.
              </p>
              <p>
                Following a practical, step-by-step program, you can move your
                facilities up the Water Maturity Curve and go from a state of
                “untapped” to operating "Water-smart" – meeting smart water
                goals and delivering more sustainable outcomes for your business
                and the world.
              </p>
              <Link to={'/about'} className="btn-itb btn-itb-blue">
                Learn more
              </Link>
              <Link to={'/assessment'} className="btn-itb">
                Take the assessment
              </Link>
            </div>
          </div>
          <div className="row roadmap-waves"></div>
          <div id="case-studies" className="row section white">
            <h3>Explore</h3>
            <p>
              All industries are affected by this urgent global issue. Read more
              about how water scarcity affects your industry.
            </p>
            <Link to={'/case-studies'} className="btn-itb">
              Read more
            </Link>
          </div>
        </div>
      </div>
      <SiteFooter solid style={{ width: '100%' }} />
    </RouteContentScrollable>
  );
};
interface CheckListProps {
  uncheckedItems: string[];
  checkedItems: string[];
}
interface CheckListState {
  isChecked: boolean;
}

class CheckList extends React.Component<CheckListProps, CheckListState> {
  #timeout: number | undefined;

  constructor(props: CheckListProps) {
    super(props);
    this.state = { isChecked: false };
  }

  componentDidMount() {
    this.#timeout = window.setTimeout(() => {
      this.setToChecked();
    }, 5000);
  }

  componentWillUnmount() {
    window.clearTimeout(this.#timeout);
  }

  setToChecked() {
    this.setChecked();

    window.setTimeout(() => {
      this.setToUnchecked();
    }, 5000);
  }

  setToUnchecked() {
    this.setUnchecked();

    window.setTimeout(() => {
      this.setToChecked();
    }, 5000);
  }

  render() {
    const transitionDelay = this.state.isChecked ? 850 : 0;
    return (
      <ul className="fa-ul">
        {this.props.uncheckedItems.map((uncheckedItem, index) => (
          <li
            style={{ transitionDelay: index * transitionDelay + 'ms' }}
            className={this.state.isChecked ? 'is-checked' : ''}
            key={index}
          >
            <div
              className="unchecked"
              style={{ transitionDelay: index * transitionDelay + 'ms' }}
            >
              <FontAwesomeIcon icon={faTimes} listItem />
              {uncheckedItem}
            </div>
            <div
              className="checked"
              style={{ transitionDelay: index * transitionDelay + 'ms' }}
            >
              <FontAwesomeIcon icon={faCheck} listItem />
              {this.props.checkedItems[index]}
            </div>
          </li>
        ))}
      </ul>
    );
  }

  private setChecked() {
    this.setState((previousState) => {
      if (previousState.isChecked === false) {
        return {
          isChecked: true,
        };
      }
      return null;
    });
  }

  private setUnchecked() {
    this.setState((previousState) => {
      if (previousState.isChecked === true) {
        return {
          isChecked: false,
        };
      }
      return null;
    });
  }
}
