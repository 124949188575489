import React, { FC, useState } from 'react';
import classnames from 'classnames/bind';
import { Dialog } from 'src/components/Dialog';
import { Button } from 'src/components/Button';
import styles from './FolderAssignDialog.module.css';
import { addFacilityFolderAssociation } from 'src/clients/api/facilty';
import { RadioList } from 'src/components/RadioList';
import { getFolders } from 'src/clients/api/folder';
import { useQuery, useQueryClient } from 'react-query';
import { LoadingSpinner } from 'src/components/LoadingSpinner';
import { BlockError } from 'src/components/BlockError';
import { AsyncState } from 'src/types/asyncState';
import { Alert } from 'src/components/Alert';

const cx = classnames.bind(styles);

type FolderAssignDialogProps = {
  isOpen: boolean;
  onSave: () => void;
  onClose: () => void;
  facilityId: number;
  reportingYear: number;
  facilityFolderId: number;
};
export const FolderAssignDialog: FC<FolderAssignDialogProps> = ({
  isOpen,
  onClose,
  onSave,
  facilityId,
  reportingYear,
  facilityFolderId,
}) => {
  const queryClient = useQueryClient();
  const [selectedFolder, setSelectedFolder] = useState<string>(
    `${facilityFolderId}`
  );
  const [asyncState, setAsyncState] = useState<AsyncState>({ state: 'idle' });

  const { isLoading, isFetching, data, error, refetch } = useQuery(
    ['folders'],
    () => getFolders()
  );

  const handleCreateFolder = async () => {
    if (selectedFolder) {
      try {
        setAsyncState({ state: 'loading' });
        const result = await addFacilityFolderAssociation(
          facilityId,
          Number(selectedFolder),
          reportingYear
        );

        if (result.status !== 'success') {
          throw Error(result.value.errors[0].message);
        }

        setAsyncState({
          state: 'success',
          message: `Folder assigned successfully!`,
        });

        queryClient.refetchQueries(['folders']);
        queryClient.refetchQueries(['facilities']);
        onSave();
      } catch (e) {
        setAsyncState({
          state: 'error',
          message: e.message ?? 'There was an error creating your folder.',
        });
      }
    }
  };

  let content;
  let hideActions = false;
  if (isLoading) {
    content = <LoadingSpinner />;
  } else if (data?.code === 404) {
    content = <Alert variant="info" message="No folders found." />;
    hideActions = true;
  } else if (error || !data || data.status === 'error') {
    content = (
      <BlockError
        retry={refetch}
        isLoading={isFetching}
        title="Error loading folders"
        message="We were unable to load your folders successfully"
      />
    );
  } else {
    content = (
      <RadioList
        items={data.value.result.map((folder) => ({
          label: folder.folderName,
          value: `${folder.folderId}`,
        }))}
        value={selectedFolder}
        onChange={setSelectedFolder}
      />
    );
  }

  return (
    <Dialog
      isOpen={isOpen}
      title="Assign facility to folder"
      description="Please provide the name of your new folder"
    >
      {content}

      <div className={cx('footer')}>
        <Button
          className={cx('secondaryAction')}
          onClick={onClose}
          variant="secondary"
        >
          Cancel
        </Button>
        {!hideActions ? (
          <Button
            className={cx('primaryAction')}
            onClick={handleCreateFolder}
            isLoading={asyncState.state === 'loading'}
          >
            Save
          </Button>
        ) : null}
      </div>
    </Dialog>
  );
};

FolderAssignDialog.displayName = 'FolderAssignDialog';
