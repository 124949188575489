import React, { FC } from 'react';
import classnames from 'classnames/bind';
import { FacilityListItem } from '../FacilityListItem';
import { ProcessedFacilities } from 'src/types/processedFacility';
import { Link } from 'react-router-dom';
import { Icon } from '@mdi/react';
import { mdiPlusCircleOutline } from '@mdi/js';
import styles from './FacilityList.module.css';
import { useFilterAndSort } from 'src/hooks/useFilterAndSort';
import { useDeepCompareMemo } from 'src/hooks/useDeepCompare';
import { filter } from 'src/hooks/useFilterAndSort/utils';
import { NoFacilities } from '../NoFacilities';

const cx = classnames.bind(styles);

export type FacilityListProps = {
  facilities: ProcessedFacilities;
};

export const FacilityList: FC<FacilityListProps> = ({ facilities }) => {
  const filterState = useFilterAndSort();
  const filteredFacilities = useDeepCompareMemo(
    () => filter(facilities, filterState),
    [facilities, filterState]
  );

  if (!facilities.length) {
    return <NoFacilities />;
  }

  return (
    <div className={cx('facilityList')}>
      <div className={cx('header')}>
        <div className={cx('headerTitle', 'title', 'action')}>
          <Link
            to={`/wrm/facilities/create/wrm/year/${
              new Date().getFullYear() - 1
            }`}
            className={cx('action')}
          >
            <Icon
              path={mdiPlusCircleOutline}
              size="24"
              style={{ marginRight: '5px' }}
            />
            <span>ADD NEW FACILITY</span>
          </Link>
        </div>

        <div className={cx('headerTitle', 'incoming')}>
          <span className={cx('headerTitleText')}>
            Incoming Water
            <br />
            (m3 per year)
          </span>
        </div>
        <div className={cx('headerTitle', 'stress')}>
          <span className={cx('headerTitleText')}>
            WRI Overall
            <br />
            Water Risk Indicator
          </span>
        </div>
        <div className={cx('headerTitle', 'premium')}>
          <span className={cx('headerTitleText')}>
            Water Risk
            <br />
            Premium
          </span>
          <span className={cx('headerTitleSubtext')}>
            for incoming and outgoing water
          </span>
        </div>
        <div className={cx('headerTitle', 'risk')}>
          <span className={cx('headerTitleText')}>
            Potential Revenue <br /> at Risk
          </span>
          <span className={cx('headerTitleSubtext')}>
            Due to water quantity risk
          </span>
        </div>
        <div className={cx('headerTitle', 'rank')}>
          <span className={cx('headerTitleText')}>
            Facility <br /> Rank
          </span>
          <span className={cx('headerTitleSubtext')}>
            Based on water quantities, monetized risk and likelihood
          </span>
        </div>
      </div>
      {filteredFacilities.map((facility) => (
        <FacilityListItem
          key={facility.facilityId}
          facility={facility}
          className={cx('listItem')}
        />
      ))}
    </div>
  );
};

FacilityList.displayName = 'FacilityList';
