import React, { FC, useEffect } from 'react';
import classnames from 'classnames/bind';
import { useQuery, useQueryClient } from 'react-query';
import { getCountries } from 'src/clients/api/country';
import { getClassifications } from 'src/clients/api/classification';
import { getReferenceData } from 'src/clients/api/referenceData';
import { LoadingSpinner } from 'src/components/LoadingSpinner';
import { BlockError } from 'src/components/BlockError';
import { Copy } from 'src/components/Copy';
import { MappedReferenceOptions } from 'src/types/referenceData';
import { RouteComponentProps } from 'react-router-dom';
import { FacilityFormSchema } from '../../components/FacilityForm/validation';
import { createWrmFacility } from 'src/clients/api/facilty';
import styles from './CreateWrmFacility.module.css';
import { useAsyncFormState } from 'src/hooks/useAsyncFormState';
import { WrmForm } from '../../components/FacilityForm/WrmForm';

const cx = classnames.bind(styles);

type CreateWrmFacilityProps = {};

export const CreateWrmFacility: FC<
  CreateWrmFacilityProps & RouteComponentProps<{ year: string }>
> = ({ match, history }) => {
  const { year } = match.params;
  const { setState, resetState } = useAsyncFormState();
  const queryClient = useQueryClient();

  useEffect(() => {
    if (resetState) {
      resetState();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const { isLoading: isLoadingCountries } = useQuery('countries', () =>
    getCountries()
  );

  const { isLoading: isLoadingClassifications } = useQuery(
    ['classifications', 'levelOne'],
    () => getClassifications()
  );

  const { isLoading: isLoadingReferenceData, data: referenceData } = useQuery(
    ['referenceData'],
    () => getReferenceData()
  );

  if (
    isLoadingCountries ||
    isLoadingClassifications ||
    isLoadingReferenceData
  ) {
    return (
      <div className={cx('createFacility', 'loading')}>
        <LoadingSpinner block />
      </div>
    );
  }

  const onSave = async (values: FacilityFormSchema) => {
    if (setState) {
      try {
        setState('saveState', { status: 'loading' });

        const result = await createWrmFacility({
          ...values,
          reportingYear: Number(year),
          fkSector: Number(values.fkSector),
          projectedFacilityOutputIncrease: Number(
            values.projectedFacilityOutputIncrease
          ),
        });

        if (result.status === 'error') {
          throw new Error(result.value.errors[0].message);
        }

        setState('saveState', {
          status: 'success',
          message: 'Facility created successfully!',
        });

        queryClient.refetchQueries(['facilities']);
        queryClient.invalidateQueries(['facilitiesList']);
        history.push('/wrm/dashboard');
      } catch (e) {
        setState('saveState', {
          status: 'error',
          message: 'There was an error saving your facility.',
        });
      }
    }
  };

  if (referenceData && referenceData?.status === 'success') {
    const formReferenceData = {
      waterUnits: referenceData.value.result.waterUnits,
      currencies: referenceData.value.result.currencies,
      options: referenceData.value.result.options.reduce<
        MappedReferenceOptions
      >(
        (out, option) => ({
          ...out,
          [option.type]: option.options,
        }),
        {} as MappedReferenceOptions
      ),
    };

    return (
      <>
        <header className={cx('header')}>
          <Copy as="h2">Create Facility</Copy>

          <Copy as="p">
            Your privacy is important to us. Precautions are in place to protect
            your information against any misuse. Please refer to the{' '}
            <a href="https://www.ecolab.com/privacy-policy">PRIVACY POLICY</a>{' '}
            for additional details.
          </Copy>
        </header>
        <WrmForm
          selectedReportingYear={year}
          referenceData={formReferenceData}
          onSave={onSave}
        />
      </>
    );
  }

  return <BlockError />;
};

CreateWrmFacility.displayName = 'CreateWrmFacility';
