import * as React from 'react';
import * as d3 from 'd3';
import { CategoryScore } from '../../Quiz/Result';

const width = 511;
const height = 300;
const margin = { top: 20, right: 5, bottom: 90, left: 35 };

const categories = [
  { position: 0, label: 'Site Management', points: 40, fill: '#49BBEB' },
  {
    position: 1,
    label: 'Water Management Practices',
    points: 10,
    fill: '#015474',
  },
  { position: 2, label: 'Target Setting', points: 100, fill: '#158BCC' },
  { position: 3, label: 'Water Stewardship', points: 150, fill: '#BFE7F7' },
];

interface Data {
  categories: Array<CategoryScore>;
}

interface CategoryData {
  position: number;
  label: string;
  points: number;
  fill: string;
}

interface GraphState {
  categories: Array<CategoryData>;
}

export class ImprovementOpportunity extends React.Component<Data, GraphState> {
  constructor(props: Data) {
    super(props);
    this.state = {
      categories: categories,
    };
    //this.wrap = this.wrap.bind(this);
    this.updateCategories = this.updateCategories.bind(this);
  }

  xAxisRef: any = {};
  gridRef: any = {};

  min = 0;
  max = 160;

  xScale = d3
    .scaleBand()
    .domain(categories.map((cat) => cat.label))
    .range([margin.left, width - margin.right])
    .padding(0.2);

  yScale = d3
    .scaleLinear()
    .domain([this.min, this.max])
    .range([height - margin.bottom, margin.top]);

  componentDidUpdate(prevProps: Data) {
    if (
      prevProps.categories.length !== this.props.categories.length ||
      (prevProps.categories.length > 1 &&
        prevProps.categories[1].score !== this.props.categories[1].score)
    ) {
      this.updateCategories();

      d3.select(this.xAxisRef)
        .call(d3.axisBottom(this.xScale))
        .selectAll('.tick text')
        .call(this.wrap, this.xScale.bandwidth());
    }
  }

  componentDidMount() {
    if (this.props.categories.length > 0) {
      this.updateCategories();
    }

    // determine when to calculate data
    let ticks = d3
      .select(this.xAxisRef)
      .call(d3.axisBottom(this.xScale))
      .selectAll('.tick text');

    ticks.call(this.wrap, this.xScale.bandwidth());

    d3.select(this.gridRef).call(
      d3
        .axisLeft(this.yScale)
        .ticks(4)
        .tickSize(-width)
        .tickFormat(() => {
          return '';
        })
    );
  }

  updateCategories() {
    let updatedCategories = this.state.categories.slice();

    let newCategories: Array<CategoryData> = [];

    // @ts-ignore
    // eslint-disable-next-line
    updatedCategories.map((category) => {
      this.props.categories.forEach((cat) => {
        if (category.label === cat.category) {
          category.points = cat.score;
          newCategories.push(category);
        }
      });
    });

    this.setState({
      categories: newCategories,
    });
  }

  wrap(text: any) {
    text.each(function (node: any, index: number, arr: any) {
      let text = d3.select(arr[index]);
      let words = node.split(/\s+/).reverse(),
        word,
        lineNumber = 0,
        lineHeight = 1.1, // ems
        y = text.attr('y'),
        dy = parseFloat(text.attr('dy'));

      text
        .text(null)
        .append('tspan')
        .attr('x', 0)
        .attr('y', y)
        .attr('dy', dy + 'em');
      while ((word = words.pop())) {
        text
          .append('tspan')
          .attr('x', 0)
          .attr('y', y)
          .attr('dy', ++lineNumber * lineHeight + dy + 'em')
          .text(word);
      }
    });
  }

  getTextWidth(text: string): number {
    let c = document.createElement('canvas');
    let ctx = c.getContext('2d');

    if (ctx) {
      ctx.font = '14px interstate';
      console.log(`Width: ${ctx.measureText(text).width}`);
      return ctx.measureText(text).width;
    }
    // Fallback if ctx is not defined, this is what the value should be with default font.
    else {
      return 40;
    }
  }

  render() {
    let ref = (el: any) => (this.xAxisRef = el);
    let gridRef = (el: any) => (this.gridRef = el);

    let target = {
      value: 97.75,
      labelWidth: this.getTextWidth('Target'),
      lineColor: '#00568F',
    };

    return (
      <svg
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        preserveAspectRatio="xMinYMid meet"
      >
        <g
          ref={gridRef}
          className="chart-grid"
          transform={`translate(${margin.left + 10}, 0)`}
        />
        {this.state.categories.map((category) => (
          <rect
            key={category.label}
            x={this.xScale(category.label)}
            width={this.xScale.bandwidth()}
            y={this.yScale(category.points)}
            // @ts-ignore
            height={this.yScale(0) - this.yScale(category.points)}
            fill={category.fill}
          />
        ))}
        <g
          className="x-axis"
          ref={ref}
          transform={`translate(0, ${height - margin.bottom})`}
        />

        <line
          x1={this.xScale.range()[0]}
          y1={this.yScale(target.value)}
          x2={this.xScale.range()[1] - target.labelWidth}
          y2={this.yScale(target.value)}
          stroke={target.lineColor}
          strokeWidth={2}
        />
        <text
          className="target-label"
          y={this.yScale(target.value)}
          x={this.xScale.range()[1] - target.labelWidth}
          fill={target.lineColor}
          alignmentBaseline="middle"
        >
          Target
        </text>

        <text className="middle-label" transform="rotate(-90)" y="-20" x="-115">
          <tspan y="-20" x={margin.bottom - height} dy="2.2em">
            Water Maturity Performance
          </tspan>
          <tspan
            y="-20"
            x={(margin.bottom - height) / 2}
            dy="3.2em"
            textAnchor="middle"
          >
            (low to high)
          </tspan>
        </text>
      </svg>
    );
  }
}
