import { WrappedResponse } from '../types';
import * as request from '../request';
import { Classifications as ClassificationsType } from '../../types/classification';
import { Env } from '../../constants/env';

export type ClassificationsResponseType = WrappedResponse<ClassificationsType>;

export function getClassifications(level: string = '1', parent?: string) {
  const parentParam = parent ? `&parentId=${parent}` : '';
  return request.get<ClassificationsResponseType>(
    `${Env.apiUrl}/v3.1/referencedata/sectors?level=${level}${parentParam}`
  );
}
