import React, { FC, useEffect, useRef, useState } from 'react';
import classnames from 'classnames/bind';
import { RouteComponentProps } from 'react-router-dom';
import styles from './Upload.module.css';
import { Copy } from 'src/components/Copy';
import { Alert } from 'src/components/Alert';
import { animated, config, useSpring } from 'react-spring';
import { useAsyncState } from 'src/hooks/useAsyncState';
import { Button } from 'src/components/Button';
import { TextInput } from 'src/components/TextInput';
import {
  getWrmBatchUploadTemplate,
  postWaaBatchUpload,
  postWrmBatchUpload,
} from 'src/clients/api/facilty';
import { FacilityTabNavigator } from '../../components/FacilityForm/Helpers/FacilityTabNavigator';
import { useTabNavigator } from '../../components/FacilityForm/Helpers/FacilityTabNavigator/useTabNavigator';
import { Env } from 'src/constants/env';
import { useThemeManager } from 'src/hooks/useThemeManager';

const cx = classnames.bind(styles);

type UploadProps = {};

const TABS = ['Water Risk Monetizer', 'Water Action Assessment'];

export const Upload: FC<
  UploadProps & RouteComponentProps<{}, {}, { type: 'waa' | 'wrm' }>
> = ({ location }) => {
  const tabNavigator = useTabNavigator(
    TABS,
    location.state?.type === 'waa' ? 1 : 0
  );
  const fileRef = useRef<HTMLInputElement>(null);
  const [asyncDownloadState, setAsyncDownloadState] = useAsyncState();
  const [asyncUploadState, setAsyncUploadState] = useAsyncState();
  const [canUpload, setCanUpload] = useState<boolean>(false);

  const themeManager = useThemeManager();

  useEffect(() => {
    themeManager.setThemeProperties!({
      showSidebar: false,
      subheaderVariant: tabNavigator.activeTabIndex === 0 ? 'wrm' : 'waa',
    });

    return () => {
      themeManager.resetThemeProperties!();
    };
  }, [tabNavigator.activeTabIndex]); // eslint-disable-line react-hooks/exhaustive-deps

  const animate = useSpring({
    opacity: 1,
    transform: 'translate(0px, 0px)',
    from: { opacity: 0, transform: 'translate(0px, -10px)' },
    config: config.molasses,
  });

  const handleUpload = async () => {
    const value = fileRef.current;
    if (value && value.files) {
      try {
        setAsyncUploadState({ status: 'loading' });

        const dest =
          tabNavigator.activeTabIndex === 1
            ? postWaaBatchUpload
            : postWrmBatchUpload;

        const result = await dest(value.files[0]);

        if (result.status === 'error') {
          throw new Error(result.value.errors[0].message);
        }

        setAsyncUploadState({
          status: 'success',
          message: 'File uploaded successfully!',
        });
      } catch (e) {
        setAsyncUploadState({
          status: 'error',
          message: e.message ?? 'There was an error uploading your file.',
        });
      }
    }
  };

  const handleDownload = async () => {
    try {
      if (tabNavigator.activeTabIndex === 1) {
        window.open(
          `${Env.baseUrl}/content/Smart%20Water%20Navigator%20Batch%20Upload%20Template.xlsx`,
          '_self'
        );
      } else {
        setAsyncDownloadState({ status: 'loading' });
        const result = await getWrmBatchUploadTemplate();

        if (result.status !== 'success') {
          throw Error(
            result.value.errors[0].message ??
              'There was an error generating your template.'
          );
        }

        setAsyncDownloadState({
          status: 'success',
          message: `Template generated successfully!`,
        });

        window.open(result.value.result, '_self');
      }
    } catch (e) {
      setAsyncDownloadState({
        status: 'error',
        message: e,
      });
    }
  };

  return (
    <div className={cx('upload')}>
      <section>
        <FacilityTabNavigator tabNavigator={tabNavigator} />
        <div className={cx('uploadBox')}>
          <Copy as="h2">Upload to {TABS[tabNavigator.activeTabIndex]}</Copy>
          {asyncUploadState.status === 'success' && (
            <div className={cx('success')}>
              <animated.div style={animate}>
                <Alert variant="success" message={asyncUploadState.message} />
              </animated.div>
            </div>
          )}
          {asyncUploadState.status === 'error' && (
            <div className={cx('error')}>
              <animated.div style={animate}>
                <Alert variant="danger" message={asyncUploadState.message} />
              </animated.div>
            </div>
          )}
          <div className={cx('content')}>
            <ol>
              <li>
                <Copy>Download the facility information template</Copy>
              </li>
              <li>
                <Copy>
                  Complete the template by entering your facility information
                </Copy>
              </li>
              <li>
                <Copy>
                  Save the Facility Form Questions tab from the template as a
                  .csv and upload the file for automatic data entry
                </Copy>
              </li>
            </ol>
            <div className={cx('actions')}>
              <Button
                isLoading={asyncDownloadState.status === 'loading'}
                onClick={handleDownload}
              >
                Download Template
              </Button>
            </div>
            <div className={cx('separator')}>
              <span className={cx('separatorText')}>Then</span>
              <div className={cx('separatorLine')}></div>
            </div>
            <Copy>
              Once you've completed the .csv template, select your file to
              complete the batch facility upload.
            </Copy>
            <TextInput
              type="file"
              className={cx('input')}
              onChange={(value) => setCanUpload(!!value)}
              ref={fileRef}
            />
            <div className={cx('actions')}>
              <Button
                isLoading={asyncUploadState.status === 'loading'}
                onClick={handleUpload}
                disabled={!canUpload}
              >
                Upload File
              </Button>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

Upload.displayName = 'Upload';
