import React from 'react';
import Modal from 'react-modal';

import 'isomorphic-fetch';

interface QuestionState {
  id: number;
  order: number;
  text: string;
  choices: Choice[];
  selectedId: number;
  handleSelect: React.EventHandler<React.MouseEvent<HTMLDivElement>>;
  helperText?: string;
  answerPrefix?: string;
}

export interface Choice {
  id: number;
  text: string;
  selected: boolean;
  order: number;
}

interface OptionState {
  id: number;
  key: number;
  selected: boolean;
  value: string;
  handleSelect: React.EventHandler<React.MouseEvent<HTMLDivElement>>;
}

// Modal.setAppElement('#root');

export class Question extends React.Component<
  QuestionState,
  { modalIsOpen: boolean }
> {
  constructor(props: QuestionState) {
    super(props);

    this.state = {
      modalIsOpen: false,
    };

    this.openModal = this.openModal.bind(this);
    this.afterOpenModal = this.afterOpenModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  afterOpenModal() {
    // references are now sync'd and can be accessed.
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  public render() {
    let helper = 'info';

    if (!this.props.helperText) {
      helper += ' helper-text-hidden';
    }

    return (
      <div>
        <Modal
          isOpen={this.state.modalIsOpen}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={this.closeModal}
          className="Modal"
          overlayClassName="Overlay"
          contentLabel="Help Modal"
        >
          <div className="row">
            <div className="col-md-2 pull-right">
              <button className="close" onClick={this.closeModal}>
                &times;
              </button>
            </div>
            <div className="col-md-10">
              <h1 className="modal-heading">Help with this question</h1>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 black-text">
              <p>{this.props.helperText}</p>
            </div>
          </div>
        </Modal>
        <p className="question">{this.props.text}</p>
        <p className="answer-prefix">{this.props.answerPrefix}</p>
        <div className="choices">
          {this.props.choices.map((choice) => (
            <ChoiceOption
              key={choice.id}
              id={choice.id}
              selected={choice.id === this.props.selectedId}
              value={choice.text}
              handleSelect={this.props.handleSelect}
            />
          ))}
        </div>
        <div className={helper}>
          {/* eslint-disable-next-line */}
          <a data-toggle="modal" onClick={this.openModal}>
            <span className="glyphicon glyphicon-info-sign"></span>Help me
            understand this question.
          </a>
        </div>
      </div>
    );
  }
}

function ChoiceOption(props: OptionState) {
  return (
    <div
      className={'choice-option' + (props.selected ? ' selected' : '')}
      data-id={props.id}
      onClick={props.handleSelect}
    >
      <button className="choice-option-circle">
        <div className="choice-option-fill"></div>
      </button>
      <input
        type="radio"
        data-id={props.id}
        checked={props.selected}
        value={props.value}
      />
      <div className="label-text">{props.value}</div>
    </div>
  );
}
