import classnames from 'classnames/bind';
import React, { FC } from 'react';
import styles from './SiteSubHeader.module.css';

const cx = classnames.bind(styles);

type Variants = 'waa' | 'wrm';

export interface SiteSubHeaderProps
  extends React.HTMLAttributes<HTMLDivElement> {
  variant: Variants;
}

type SubNavLinks = Record<
  Variants,
  {
    title: string;
    moduleUrl: string;
    links: Record<string, string>;
  }
>;

export const SUB_NAV_LINKS: SubNavLinks = {
  wrm: {
    title: 'Water Risk Monetizer',
    moduleUrl: '/wrm',
    links: {
      'How it works': '/about/how-it-works/',
      Methodology: '/about/methodology',
      'Take Action': '/about/take-action/',
    },
  },
  waa: {
    title: 'Water Action Assessment',
    moduleUrl: '/waa',
    links: {
      'How it works': '/waa/about',
      Methodology:
        'https://p.widencdn.net/bseels/Ecolab-Smart-Water-Navigator-User-Manual',
      'Take Action': '/waa/assessment',
    },
  },
};

export const SiteSubHeader: FC<SiteSubHeaderProps> = ({
  variant,
  className,
}) => {
  const { title, moduleUrl, links } = SUB_NAV_LINKS[variant];

  return (
    <div className={cx('siteSubHeader', className)}>
      <a className={cx('title')} href={moduleUrl}>
        {title}
      </a>
      <ul className={cx('links')}>
        {Object.entries(links).map(([title, href]) => (
          <li className={cx('link')} key={title}>
            <a href={href}>{title}</a>
          </li>
        ))}
      </ul>
    </div>
  );
};

SiteSubHeader.displayName = 'SiteSubHeader';
