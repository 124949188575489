import * as React from 'react';
import * as d3 from 'd3';

const width = 511;
const height = 300;
const margin = { top: 20, right: 5, bottom: 90, left: 35 };

const barLabels = ['Your Company', 'Smart Water Navigator Tool Average'];

interface GraphProps {
  companyAverage: number;
  overallAverage: number;
  markAsLoaded: Function;
}

interface GraphInfo {
  companyAverage: number;
  overallAverage: number;
}

export class CompanyComparison extends React.Component<GraphProps, GraphInfo> {
  constructor(props: GraphProps) {
    super(props);
    this.state = {
      companyAverage: this.props.companyAverage,
      overallAverage: this.props.overallAverage,
    };

    this.wrap = this.wrap.bind(this);
  }

  xAxisRef: any = {};
  gridRef: any = {};

  min = 0;
  max = 560;

  xScale = d3
    .scaleBand()
    .domain(barLabels)
    .range([margin.left + 70, width - margin.right])
    .padding(0.2);

  yScale = d3
    .scaleLinear()
    .domain([this.min, this.max])
    .range([height - margin.bottom, margin.top]);

  componentDidUpdate(prevProps: GraphProps) {
    if (prevProps.companyAverage !== this.props.companyAverage) {
      d3.select(this.xAxisRef)
        .call(d3.axisBottom(this.xScale))
        .selectAll('.tick text')
        .call(this.wrap, this.xScale.bandwidth());
    }
  }

  componentDidMount() {
    // determine when to calculate data
    let ticks = d3
      .select(this.xAxisRef)
      .call(d3.axisBottom(this.xScale))
      .selectAll('.tick text');

    ticks.call(this.wrap, this.xScale.bandwidth());

    this.props.markAsLoaded();
  }

  wrap(text: any) {
    let xScale = this.xScale;

    text.each(function (node: any, index: number, arr: any) {
      let text = d3.select(arr[index]);
      let words = node.split(/\s+/).reverse();
      let word = '';
      let line = '';

      let lineNumber = 0;
      let lineHeight = 1.1; // ems
      let y = text.attr('y');
      let dy = parseFloat(text.attr('dy'));

      let c = document.createElement('canvas');
      let ctx = c.getContext('2d');

      text
        .text(null)
        .append('tspan')
        .attr('x', 0)
        .attr('y', y)
        .attr('dy', dy + 'em');

      while ((word = words.pop())) {
        if (ctx) {
          let length = ctx.measureText(`${line} ${word}`).width;
          if (length > xScale.bandwidth()) {
            text
              .append('tspan')
              .attr('x', 0)
              .attr('y', y)
              .attr('dy', ++lineNumber * lineHeight + dy + 'em')
              .text(line);
            line = word;
          } else {
            line += ` ${word}`;
          }
        }
        // Fallback if ctx is not defined.
        else {
          text
            .append('tspan')
            .attr('x', 0)
            .attr('y', y)
            .attr('dy', ++lineNumber * lineHeight + dy + 'em')
            .text(word);
        }
      }
      if (line !== '') {
        text
          .append('tspan')
          .attr('x', 0)
          .attr('y', y)
          .attr('dy', ++lineNumber * lineHeight + dy + 'em')
          .text(line);
      }
    });
  }

  render() {
    let ref = (el: any) => (this.xAxisRef = el);
    let gridRef = (el: any) => (this.gridRef = el);

    let maturityLevels = [
      { name: 'Water-smart', lowerBound: 420 },
      { name: 'Exploratory', lowerBound: 280 },
      { name: 'Linear', lowerBound: 140 },
      { name: 'Untapped', lowerBound: 0 },
    ];

    return (
      <svg
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        preserveAspectRatio="xMinYMid meet"
      >
        <g
          ref={gridRef}
          className="chart-grid"
          transform={`translate(${margin.left + 10}, 0)`}
        />

        {maturityLevels.map((maturity) => (
          <line
            key={maturity.name}
            x1={margin.left}
            y1={this.yScale(maturity.lowerBound)}
            x2={this.xScale.range()[1]}
            y2={this.yScale(maturity.lowerBound)}
            stroke={'#D9D9D9'}
            strokeDasharray="5 5"
            strokeWidth={1}
          />
        ))}
        {maturityLevels.map((maturity) => (
          <text
            key={maturity.name}
            className="maturity-tick"
            x={margin.left}
            // @ts-ignore
            y={this.yScale(maturity.lowerBound) - 3}
          >
            {maturity.name}
          </text>
        ))}

        <rect
          x={this.xScale(barLabels[0])}
          width={this.xScale.bandwidth()}
          y={this.yScale(this.state.companyAverage)}
          // @ts-ignore
          height={this.yScale(0) - this.yScale(this.state.companyAverage)}
          fill="#158BCC"
        />
        <rect
          x={this.xScale(barLabels[1])}
          width={this.xScale.bandwidth()}
          y={this.yScale(this.state.overallAverage)}
          // @ts-ignore
          height={this.yScale(0) - this.yScale(this.state.overallAverage)}
          fill="#49BBEB"
        />

        <line
          x1={margin.left}
          y1={this.yScale.range()[0]}
          x2={this.xScale.range()[1]}
          y2={this.yScale.range()[0]}
          stroke={'#D9D9D9'}
          strokeWidth={1}
        />

        <g
          className="x-axis"
          ref={ref}
          transform={`translate(0, ${height - margin.bottom})`}
        />
      </svg>
    );
  }
}
