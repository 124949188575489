import classnames from 'classnames/bind';
import React, { FC } from 'react';
import { Link as LocalLink } from 'react-router-dom';
import styles from './Pressable.module.css';

const cx = classnames.bind(styles);

export interface PressableProps {
  to: string;
  className?: string;
}

export const Pressable: FC<PressableProps> = ({
  to,
  children,
  className,
  ...props
}) => {
  if (to.includes('http') || to.includes('mailto')) {
    return (
      <a href={to} {...props} className={cx('pressable', className)}>
        {children}
      </a>
    );
  }
  return (
    <LocalLink to={to} {...props} className={cx('pressable', className)}>
      {children}
    </LocalLink>
  );
};

Pressable.displayName = 'Pressable';
