import * as React from 'react';
import Modal from 'react-modal';
import { Layout } from '../Layout';
import { APIService, CallType } from '../../services/APIService';
import { Env } from 'src/constants/env';

interface BatchUploadModalProps {
  isOpen: boolean;
  closeModal: Function;
}

interface State {
  fileIsSelected: boolean;
}

export class BatchUploadModal extends React.Component<
  BatchUploadModalProps,
  State
> {
  api: APIService | null = null;

  constructor(props: BatchUploadModalProps) {
    super(props);

    this.state = {
      fileIsSelected: false,
    };

    this.setFileRef = this.setFileRef.bind(this);
    this.setFiles = this.setFiles.bind(this);
    this.upload = this.upload.bind(this);
  }

  private fileInput: HTMLInputElement | null = null;
  private files: any | null[];

  private setFileRef(fileInput: HTMLInputElement) {
    this.fileInput = fileInput;
  }

  setFiles(files: any | null[]) {
    if (files[0].name.endsWith('.csv')) {
      this.files = files;
      this.setState({ fileIsSelected: true });
    } else {
      this.setState({ fileIsSelected: false });
      Layout.notificationSystem.addNotification({
        message: 'Please select a .csv file.',
        level: 'error',
        position: 'tc',
        autoDismiss: 0,
      });
    }
  }

  upload() {
    const file = this.files[0];
    console.log(file);

    const data = new FormData();
    data.append('file', file);

    this.api &&
      this.api
        .perform(`${Env.waaApiUrl}/fileupload`, CallType.FileUpload, data)
        .then((response) => {
          console.log({ response });
          if (!response.error) {
            this.props.closeModal();
          }
        })
        .catch((error) => {
          // display error
          console.log({ error });
        });
  }

  componentDidMount() {
    this.api = new APIService(Layout.notificationSystem);
  }

  render() {
    return (
      <Modal
        isOpen={this.props.isOpen}
        className="Modal"
        overlayClassName="Overlay"
        contentLabel="Help Modal"
      >
        <div className="modal-content-wrapper col-sm-12">
          <div className="row">
            <div className="col-md-2 pull-right">
              {' '}
              <button className="close" onClick={() => this.props.closeModal()}>
                &times;
              </button>
            </div>
            <div className="col-md-10">
              <h1 className="modal-heading">Upload multiple facilities</h1>
            </div>
          </div>
          <div className="row">
            <p>Enter multiple facilities at once in three easy steps:</p>
            <ol>
              <li>Download the facility information template</li>
              <li>
                Complete the template by entering your facility information
              </li>
              <li>
                Save the Facility Form Questions tab from the template as a .csv
                and upload the file for automatic data entry
              </li>
            </ol>
          </div>
          <div className="row">
            <a
              href="/content/Smart%20Water%20Navigator%20Batch%20Upload%20Template.xlsx"
              className="btn-itb btn-itb-blue"
            >
              Download template
            </a>
          </div>
          <hr />
          <div className="row">
            <p>
              Once you’ve completed the .csv template, select your file to
              complete the batch facility upload.
            </p>
          </div>

          <input
            id="csv-file"
            ref={this.setFileRef}
            type="file"
            accept=".csv"
            onChange={(e) => this.setFiles(e.target.files)}
          />
          <div className="row">
            <button
              onClick={() =>
                null != this.fileInput ? this.fileInput.click() : null
              }
              className="btn-itb btn-itb-blue"
            >
              Choose CSV file
            </button>
            <span className="{this.state.fileIsSelected ? '' : 'hide' }">
              {this.files != null && this.files.length > 0
                ? this.files[0].name
                : ''}
            </span>
          </div>
          <div className="row">
            <button
              onClick={this.upload}
              className="btn-itb"
              disabled={!this.state.fileIsSelected}
            >
              Upload file
            </button>
          </div>
        </div>
      </Modal>
    );
  }
}
