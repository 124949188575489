import React, { FC } from 'react';
import classnames from 'classnames/bind';
import { Redirect, Switch } from 'react-router-dom';
import { Route } from 'src/components/Route';
import { SidebarNav } from 'src/modules/WaterRiskMonetizer/components/SidebarNav';
import { Dashboard } from '../modules/Dashboard';
import { routes, APP_SLUG } from '../routes';
import { LoadingSpinner } from 'src/components/LoadingSpinner';
import { BlockError } from 'src/components/BlockError';
import { AppFooter } from '../components/AppFooter';
import { FilterAndSortProvider } from 'src/hooks/useFilterAndSort';
import { AsyncFormStateProvider } from 'src/hooks/useAsyncFormState';
import styles from './App.module.css';
import { useAuth } from 'src/hooks/useAuth';
import { useQuery } from 'react-query';
import { useThemeManager } from 'src/hooks/useThemeManager';

const cx = classnames.bind(styles);

interface AppProps {}

export const App: FC<AppProps> = () => {
  const themeManager = useThemeManager();
  const { fetchUser, isAuthenticated } = useAuth();

  const { isLoading, data, error } = useQuery(['user', 'current'], fetchUser!, {
    enabled: !!fetchUser && !!isAuthenticated,
  });

  if (isLoading) {
    return <LoadingSpinner block />;
  }

  if (error || data?.status === 'error') {
    return <BlockError />;
  }

  if (data?.status === 'success' && data.value.result.verified !== true) {
    return <Redirect to="/verify-account" />;
  }

  return (
    <>
      <div className={cx('app')}>
        {themeManager.showSidebar ? <SidebarNav /> : null}

        <FilterAndSortProvider>
          <AsyncFormStateProvider>
            <div id="AppContent" className={cx('content')}>
              <Switch>
                {routes.map((route) => {
                  return <Route exact key={route.path} {...route} />;
                })}

                <Route
                  path={APP_SLUG}
                  component={() => <Redirect to={`${APP_SLUG}/dashboard`} />}
                />
                <Route path={`${APP_SLUG}/*`} component={Dashboard} />
              </Switch>
            </div>
          </AsyncFormStateProvider>
        </FilterAndSortProvider>
      </div>
      <AppFooter />
    </>
  );
};
