import React, { FC } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ThemeManagerProvider } from 'src/hooks/useThemeManager';
import { AuthProvider } from '../../hooks/useAuth';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 5, // 5 min native TTL
    },
  },
});

export const AppProviders: FC = ({ children }) => {
  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <ThemeManagerProvider>{children}</ThemeManagerProvider>
      </AuthProvider>
    </QueryClientProvider>
  );
};
