import { ProcessedFacilities } from 'src/types/processedFacility';
import { FilterAndSortContextPropsType } from '.';

const amp = String.fromCharCode(0x0026);
export const SORTING_OPTIONS = [
  {
    value: '1',
    label: 'Facility Rank High to Low',
    prop: 'facilityRank' as const,
    direction: 'desc',
  },
  {
    value: '2',
    label: 'Facility Rank Low to High',
    prop: 'facilityRank' as const,
    direction: 'asc',
  },
  {
    value: '3',
    label: 'Water Risk Premium High to Low',
    prop: 'waterRiskPremium' as const,
    direction: 'desc',
  },
  {
    value: '4',
    label: 'Water Risk Premium Low to High',
    prop: 'waterRiskPremium' as const,
    direction: 'asc',
  },
  {
    value: '5',
    label: 'Revenue At Risk High to Low',
    prop: 'revenueAtRiskPercentage' as const,
    direction: 'desc',
  },
  {
    value: '6',
    label: 'Revenue At Risk Low to High',
    prop: 'revenueAtRiskPercentage' as const,
    direction: 'asc',
  },
  {
    value: '7',
    label: `Total - Incoming ${amp} Outgoing High to Low`,
    prop: 'revenueAtRiskPercentage' as const,
    direction: 'desc',
  },
  {
    value: '8',
    label: `Total - Incoming ${amp} Outgoing Low to High`,
    prop: 'revenueAtRiskPercentage' as const,
    direction: 'asc',
  },
  {
    value: '9',
    label: 'Facility Name (A-Z)',
    prop: 'name' as const,
    direction: 'asc',
  },
  {
    value: '10',
    label: 'Facility Name (Z-A)',
    prop: 'name' as const,
    direction: 'desc',
  },
];

export const filter = (
  facilities: ProcessedFacilities,
  filterState: FilterAndSortContextPropsType
) => {
  const filtered = facilities.filter((f) =>
    [
      !filterState.folderId || filterState.folderId === `${f.folderId}`,
      !filterState.countryId ||
        filterState.countryId === `${f.countryLocationId}`,
      !filterState.label || filterState.label === `${f.label}`,
      !filterState.sectorId || filterState.sectorId === `${f.sectorId}`,
    ].every(Boolean)
  );

  const state = SORTING_OPTIONS.find(
    (f) => f.value === (filterState.sortId ?? '2')
  );

  if (state) {
    filtered.sort((a, b) => {
      const aProp = a[state.prop];
      const bProp = b[state.prop];
      if (aProp && bProp) {
        if (aProp < bProp) {
          return -1;
        }
        if (aProp > bProp) {
          return 1;
        }
      }

      return 0;
    });
  }

  if (state?.direction === 'desc') {
    filtered.reverse();
  }

  return filtered;
};
