import classnames from 'classnames/bind';
import React, { FC } from 'react';
import styles from './AppFooter.module.css';

const cx = classnames.bind(styles);

type AppFooterProps = {};

export const AppFooter: FC<AppFooterProps> = () => {
  return (
    <div className={cx('appFooter')}>
      <section className={cx('section')}>
        Developed by ECOLAB USA INC.&reg;
        <br className={cx('break')} />
        Powered by Microsoft and S&amp;P Global Trucost
      </section>
      <section className={cx('section', 'links')}>
        <a href="/about/disclaimer/" target="_blank" rel="noreferrer noopener">
          disclaimer
        </a>
        <a
          href="https://www.ecolab.com/privacy-policy"
          target="_blank"
          rel="noreferrer noopener"
        >
          privacy policy
        </a>
      </section>
      <section className={cx('section')}>
        &copy; {new Date().getFullYear()} Smart Water Navigator, All Rights
        Reserved
      </section>
    </div>
  );
};

AppFooter.displayName = 'AppFooter';
