import React, { FC } from 'react';
import classnames from 'classnames/bind';

import styles from './LoadingSpinner.module.css';
import { LoaderIcon } from '../Icons';

const cx = classnames.bind(styles);

export interface LoadingSpinnerProps
  extends React.HTMLAttributes<HTMLDivElement> {
  height?: number;
  width?: number;
  block?: boolean;
}

export const LoadingSpinner: FC<LoadingSpinnerProps> = ({
  height = 24,
  width = 24,
  className,
  block = false,
}) => {
  return (
    <div className={cx('loadingSpinner', { block }, className)}>
      <div style={{ height, width }}>
        <LoaderIcon />
      </div>
    </div>
  );
};

LoadingSpinner.displayName = 'LoadingSpinner';
