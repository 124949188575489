import { Env } from 'src/constants/env';
import { CalculatorPdfRequestDataType } from 'src/modules/WaterRiskMonetizer/components/InvestmentCalculator/utils';
import request from '../request';
import { WrappedResponse } from '../types';

export type GetFacilityReportResponseType = WrappedResponse<string>;
export function getFacilityReport(facilityId: number, reportingYear: number) {
  return request.get<GetFacilityReportResponseType>(
    `${Env.apiUrl}/v3.1/reports/pdf/facilities/${facilityId}/year/${reportingYear}`
  );
}

export type GenerateCsvReportForFolderResponseType = WrappedResponse<string>;
export function generateCsvReportForFolder(folderId?: string) {
  return request.get<GenerateCsvReportForFolderResponseType>(
    `${Env.apiUrl}/v3.1/reports/csv${
      folderId && folderId !== '' ? `/${folderId}` : ''
    }?type=combined`
  );
}

export type GeneratePdfReportForFolderResponseType = WrappedResponse<string>;
export type GeneratePdfReportForFolderRequestType = {
  riskAnalysisChart: string;
  benchmarkingChart: string;
};
export function generatePdfReportForFolder(
  folderId: string,
  data: GeneratePdfReportForFolderRequestType
) {
  return request.post<
    GeneratePdfReportForFolderResponseType,
    GeneratePdfReportForFolderRequestType
  >(`${Env.apiUrl}/v3.1/reports/pdf/${folderId}`, data);
}

export type GeneratePdfReportForFacilityResponseType = WrappedResponse<string>;
export type GeneratePdfReportForFacilityRequestType = {
  facilityRank: number;
  incomingSvgChart: string;
  outgoingSvgChart: string;
  revenueSvgChart: string;
  riskFactorSvgChart: string;
  riskSourceSvgChart: string;
  waterWithdrawalSvgChart: string;
};
export function generatePdfReportForFacility(
  facilityId: number,
  reportingYear: number,
  data: GeneratePdfReportForFacilityRequestType
) {
  return request.post<
    GeneratePdfReportForFacilityResponseType,
    GeneratePdfReportForFacilityRequestType
  >(
    `${Env.apiUrl}/v3.1/reports/pdf/facilities/${facilityId}/year/${reportingYear}`,
    data
  );
}

export type GeneratePdfReportForForCalculatorResponseType = WrappedResponse<
  string
>;
export type GeneratePdfReportForForCalculatorRequestType = CalculatorPdfRequestDataType;
export function generatePdfReportForCalculator(
  data: GeneratePdfReportForForCalculatorRequestType
) {
  return request.post<
    GeneratePdfReportForForCalculatorResponseType,
    GeneratePdfReportForForCalculatorRequestType
  >(`${Env.apiUrl}/v3.1/investment/calculator/pdf`, data);
}
