import React, { FC, PropsWithChildren, useEffect } from 'react';
import { useThemeManager } from 'src/hooks/useThemeManager';

type MethodologyProps = Record<string, unknown>;

export const Methodology: FC<PropsWithChildren<MethodologyProps>> = () => {
  const themeManager = useThemeManager();

  useEffect(() => {
    if (themeManager.setThemeProperties) {
      themeManager?.setThemeProperties({
        showSubheader: true,
        subheaderVariant: 'wrm',
      });
    }

    return () => {
      if (themeManager.resetThemeProperties) {
        themeManager.resetThemeProperties();
      }
    };
  }, []);

  return (
    <div className="container" id="main-container">
      <div className="row">
        <div id="primary" className="content-area col-md-8">
          <main id="main" className="site-main" role="main">
            <article
              id="post-3762"
              className="post-3762 page type-page status-publish hentry"
            >
              <header className="entry-header">
                <h1 className="entry-title">Methodology</h1>{' '}
              </header>
              {/* .entry-header */}
              <div className="entry-content">
                <p className="bigintro">
                  Water scarcity has the potential to increase the cost of water
                  which makes operations more expensive and reduces profit
                  margins. Water scarcity also limits availability, making it
                  more difficult to access the water a business needs to operate
                  which can decrease production and result in loss of revenue.
                </p>
                <p>
                  Water is undervalued, particularly in areas where water is
                  most scarce. To date, water has also been easy to access and
                  available. But the reality is changing. Decreasing water
                  quantity and quality poses significant risks to businesses.
                  Businesses need data and insights on water-related risks to
                  better understand and internalize the full value of water at
                  the site and enterprise levels.
                </p>
                <p>
                  The Water Risk Monetizer uses best-in-class local water basin
                  datasets, economic techniques and scientific methodologies
                  developed by Trucost to monetize water-related business risks.
                  The tool provides a comprehensive series of risk metrics to
                  help businesses understand incoming (quantity and quality)
                  risks and outgoing (quality) risks.
                </p>
                <blockquote>
                  <p>
                    <span style={{ color: '#166691' }}>
                      <em>
                        “The Water Risk Monetizer provides companies with a
                        comprehensive tool to deepen their understanding of the
                        financial implications associated with water risks.
                        Tools like this can empower organizations to prioritize
                        and invest in water conservation.”
                      </em>
                      &nbsp;{' '}
                    </span>
                  </p>
                  <h6 style={{ textAlign: 'right' }}>
                    <strong>
                      <span style={{ color: '#166691' }}>
                        –Paul Reig, Bluerisk
                      </span>
                    </strong>
                  </h6>
                </blockquote>
                <p>
                  The tool takes user-provided business information on water
                  use, water prices and production data and calculates incoming
                  and outgoing water risk that could result in increased
                  operating costs. The monetary value assigned to this risk take
                  into account water availability, water quality and competing
                  uses of water within local water basins across a three-, five-
                  and 10-year time horizon. The Water Risk Monetizer also
                  calculates the potential loss in revenue from incoming water
                  quantity risk across the same time horizons.
                </p>
                <p>
                  Alongside each monetary value, the tool also calculates the
                  likelihood of these costs being realized through a number of
                  risk triggers including future water stress, and regulatory
                  and reputational risk factors. The magnitudes and likelihoods
                  are combined into an overall water risk rank so businesses can
                  prioritize facilities for further assessment and start
                  identifying appropriate risk mitigation strategies for each
                  location to reduce their company’s overall risk profile.
                </p>
                <p>
                  <a
                    href="https://ecolab.widen.net/s/5wtj9xxdvb/water-risk-monetizer-methodology"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Download a complete description of the methodology
                  </a>
                  , including data sources and key assumptions and see below for
                  a summary of outputs.
                </p>
                <h3>
                  <span style={{ color: '#6fb7ce' }}>Water risk premiums</span>
                </h3>
                <p>
                  The incoming and outgoing water risk premiums are a monetary
                  estimate of the increased price of water, which may be
                  realized by a business as an increase in its operating costs.
                  The water risk premium is calculated based on the full value
                  of water to a facility, as estimated based on local water
                  availability and local water quality.
                </p>
                <p>
                  <a href="/images/about/Water-Risk-Premiums-1.jpg">
                    <img
                      className="aligncenter wp-image-3211"
                      src="/images/about/Water-Risk-Premiums-1.jpg"
                      alt=""
                      width={712}
                      height={475}
                    />
                  </a>
                </p>
                <hr />
                <h3>
                  <span style={{ color: '#6fb7ce' }}>
                    Water withdrawal risk{' '}
                  </span>
                </h3>
                <p>
                  Water withdrawal targets offer specific, time-bound metrics
                  concerning what volume would be a sustainable allocation for a
                  facility and consider critical contextual factors that are
                  location-specific and informed by best available science and
                  analysis. These contextual factors include the impacts of lack
                  of water on ecosystems, human health, human consumptive needs,
                  and economic and social water needs. A risk threshold of
                  &lt;40% is assumed to indicate a tolerable amount of water
                  stress to a basin as defined by the World Resources Institute
                  (WRI). Thus, operating in a watershed with a baseline water
                  stress of less than 40% does not have water withdrawal risk as
                  it is in a watershed with a sustainable rate of water
                  withdrawal. Even if facility water withdrawal targets are met,
                  that does not ensure the baseline water stress of a watershed
                  is less than 40%. All users in a watershed must do their part
                  to address water risk.
                </p>
                <p>
                  The water withdrawal risk levels are determined by the
                  following parameters.
                </p>
                <p>
                  <img
                    className="aligncenter wp-image-3782"
                    src="/images/about/WWR-Table2-1024x181.png"
                    alt=""
                    width={724}
                    height={128}
                  />
                </p>
                <p>&nbsp;</p>
                <hr />
                <h3>
                  <span style={{ color: '#6fb7ce' }}>
                    Incoming water risk premium{' '}
                  </span>
                </h3>
                <p>
                  The incoming water risk premium is a monetary value of the
                  local environmental, human-health and domestic supply impacts
                  of water depletion and the future costs of incoming water
                  treatment. Local water availability, water quality and
                  population density are all variables that impact the size of
                  the risk premium. Locations that have high levels of water
                  stress (due to quantity or quality-related impacts) and high
                  population densities will usually have a higher risk premium.
                </p>
                <p>
                  The Water Risk Monetizer considers both quantity and
                  quality-related risk in its incoming risk assessment. The
                  Water Risk Monetizer uses the Baseline Water Stress metric
                  from the WRI’s Aqueduct Tool (WRI, 2019) to assess quantity
                  risk and user inputs to assess quality risk.
                </p>
                <p>
                  <a href="/images/about/Incoming_Water-Risk-Premium-Chart.jpg">
                    <img
                      className="aligncenter wp-image-3241"
                      src="/images/about/Incoming_Water-Risk-Premium-Chart-1024x651.jpg"
                      alt=""
                      width={711}
                      height={452}
                    />
                  </a>
                </p>
                <hr />
                <h3>
                  <span style={{ color: '#6fb7ce' }}>
                    Outgoing water risk premium
                  </span>
                </h3>
                <p>
                  The outgoing water risk premium is a monetary value of the
                  local environmental and human-health impacts of water
                  pollution and the future costs of water treatment. Local water
                  stress, water quality and population density are all variables
                  that impact the risk premium. Locations that have high levels
                  of water stress and high population densities will usually
                  have a higher risk premium; however, the type of water
                  pollutant and its treatment method will also influence the
                  value.
                </p>
                <p>
                  <a href="/images/about/Outgoing_Water-Risk-Premium-Chart.jpg">
                    <img
                      className="aligncenter wp-image-3271"
                      src="/images/about/Outgoing_Water-Risk-Premium-Chart.jpg"
                      alt=""
                      width={712}
                      height={467}
                    />
                  </a>
                </p>
                <hr />
                <h3>
                  <span style={{ color: '#6fb7ce' }}>
                    Revenue at risk: a context-based appraisal of water
                    constraints to growth
                  </span>
                </h3>
                <p>
                  Revenue at risk is a comparison of the estimated amount of
                  water a business requires to generate revenue (cubic meters
                  per USD of revenue) to the business’ share of water available
                  in the water basin if water were allocated among water users
                  based on economic activity (contribution to basin-level GDP).
                  If more water is required than the basin share of water
                  allocated, then a proportion of the business’ revenue is
                  potentially at risk. Industry type, local water stress and
                  competition (basin-level economic activity) are all variables
                  that impact the amount of revenue at risk. Water-intensive
                  industries located in areas with high levels of water stress
                  and competition for water resources will usually have a higher
                  amount of revenue at risk.
                </p>
                <p>
                  <a href="/images/about/Revenue-at-Risk.png">
                    <img
                      className="aligncenter wp-image-3281"
                      src="/images/about/Revenue-at-Risk.png"
                      alt=""
                      width={712}
                      height={356}
                    />
                  </a>
                </p>
                <p>
                  <a
                    href="https://ecolab.widen.net/s/5wtj9xxdvb/water-risk-monetizer-methodology"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Download a complete description of the methodology
                  </a>
                  , including data sources and key assumptions.
                </p>
                <p>&nbsp;</p>
              </div>
            </article>
          </main>
        </div>
      </div>
    </div>
  );
};

Methodology.displayName = 'Methodology';
