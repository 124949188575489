import * as NotificationSystem from 'react-notification-system';
import {
  getTokenInStorage,
  clearStorage,
  getAccountIdInStorage,
} from '../../../clients/helpers';
import { Env } from '../../../constants/env';

export enum CallType {
  None,
  Json,
  FileUpload,
}

export class APIService {
  notificationAlwaysAlert: boolean = false;
  notificationSystem: NotificationSystem.System;

  constructor(notificationSystem: NotificationSystem.System) {
    this.notificationSystem = notificationSystem;
  }

  perform(
    uri: string,
    type?: CallType,
    postdata?: any | null | undefined,
    settings?: any
  ) {
    var headers: { [k: string]: any } = {};

    var token = getTokenInStorage();
    if (token && token !== 'expired') {
      headers['Authorization'] = `Bearer ${token}`;
    } else {
      console.log('No token saved');
    }

    var account = getAccountIdInStorage();
    if (account && account !== 'expired') {
      headers['Account'] = `${account}`;
    } else {
      console.log('No account saved');
    }

    if (type === CallType.Json) {
      headers['Content-Type'] = 'application/json';
    }

    var defaultSettings: { [k: string]: any } = {
      headers: headers,
      method: postdata ? 'POST' : 'GET',
    };
    settings = Object.assign(defaultSettings, settings);

    if (postdata) {
      if (type === CallType.FileUpload) {
        settings.body = postdata;
      } else {
        settings.body = JSON.stringify(postdata);
      }
    }

    return fetch(uri, settings)
      .then((response) => {
        if (
          response.status === 401 ||
          response.status === 0 ||
          response.status === 503
        ) {
          let token = getTokenInStorage();
          if (token && token !== 'expired') {
            clearStorage();
          }
          return;
        }
        if (
          response.status !== 200 &&
          response.status !== 201 &&
          response.status !== 202
        ) {
          console.log(response);
        } else {
          return type === CallType.Json || type === CallType.FileUpload
            ? response.json()
            : response.text();
        }
        return;
      })
      .then((result) => {
        var message: string;
        if (!result) {
          return;
        }
        if (result.error) {
          console.log(`API call error: ${Env.waaApiUrl}/${uri}`);
          message = result.message
            ? 'An error occured: ' + result.message
            : 'An unknown error occured.';
          if (this.notificationAlwaysAlert || null == this.notificationSystem) {
            console.log(message);
          } else {
            this.notificationSystem.addNotification({
              message: message,
              level: 'error',
              position: 'tc',
              autoDismiss: 0,
            });
          }
        } else if (result.success && result.message) {
          message = result.message ? result.message : 'Success!';
          if (this.notificationAlwaysAlert || null == this.notificationSystem) {
            console.log(message);
          } else {
            this.notificationSystem.addNotification({
              message: message,
              level: 'success',
              position: 'tc',
              autoDismiss: 0,
            });
          }
        }
        return result;
      })
      .catch((result) => {
        console.log('API call catch error: ' + uri, result);
        var message = 'An error occured attempting to reach our services';

        // If server request fails log user out. This likely means server is down
        if (result === 'TypeError: Failed to fetch') {
          message += ' due to a system outage.';
          if (getTokenInStorage()) {
            clearStorage();
            localStorage.removeItem('viewAll');
            localStorage.removeItem('email');
            message += ' You have been logged out.';
          }
          message += ' Please be patient while we resolve this problem!';
        } else {
          message += '.';
        }

        if (this.notificationAlwaysAlert || null == this.notificationSystem) {
          console.log(message);
        } else {
          this.notificationSystem.addNotification({
            message: message,
            level: 'error',
            position: 'tc',
            autoDismiss: 0,
          });
        }
      });
  }
}
