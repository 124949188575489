import { mdiAccountCircleOutline, mdiClose, mdiMenu } from '@mdi/js';
import { Icon } from '@mdi/react';
import classnames from 'classnames/bind';
import React, { FC, useState } from 'react';
import { Link, NavLink, NavLinkProps, useRouteMatch } from 'react-router-dom';
import { Button } from 'src/components/Button';
import { useAuth } from 'src/hooks/useAuth';
import { useThemeManager } from 'src/hooks/useThemeManager';
import { AccountAvatar } from '../AccountAvatar';
import { SiteSubHeader, SUB_NAV_LINKS } from '../SiteSubHeader';
import styles from './SiteHeader.module.css';

const cx = classnames.bind(styles);

export interface SiteHeaderProps extends React.HTMLAttributes<HTMLDivElement> {}

const LINKS: Record<string, Record<string, string> | string> = {
  About: {
    'Smart Water Navigator': '/journey',
    'Development Partners': '/about/development-partners/',
    FAQ: '/about/faq/',
    Resources: '/about/resources/',
  },
  Explore: {
    'Water Risk Monetizer': '/about',
    'Water Action Assessment': '/waa',
  },
  'My Dashboards': {
    'Water Risk Monetizer': '/wrm/dashboard',
    'Water Action Assessment': '/waa/dashboard',
  },
};

const Navigation: FC<{
  component: FC<NavLinkProps>;
  className?: string;
}> = ({ component: CustomLink, className }) => {
  return (
    <ul className={cx('links', className)}>
      {Object.entries(LINKS).map(([label, value]) => {
        switch (typeof value) {
          case 'string': {
            return (
              <li className={cx('link')} key={label}>
                <CustomLink to={value}>{label}</CustomLink>
              </li>
            );
          }

          case 'object': {
            return (
              <li className={cx('link', 'withSubNav')} key={label}>
                <span>{label}</span>
                <ul className={cx('links')}>
                  {Object.entries(value).map(([subLabel, subValue]) => {
                    return (
                      <li className={cx('link')} key={subLabel}>
                        <CustomLink to={subValue}>{subLabel}</CustomLink>
                      </li>
                    );
                  })}
                </ul>
              </li>
            );
          }

          default: {
            return '';
          }
        }
      })}
    </ul>
  );
};

export const SiteHeader: FC<SiteHeaderProps> = () => {
  const themeManager = useThemeManager();
  const { isAuthenticated, logout } = useAuth();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const match = useRouteMatch(['/wrm', '/waa']);
  const variant = match?.path.split('/').filter(Boolean)[0];

  const HeaderLink: FC<NavLinkProps> = (props) => {
    const isExternal = props.to.includes('http');

    if (isExternal) {
      return <a href={props.to}>{props.children}</a>;
    }

    return (
      <NavLink
        {...props}
        onClick={() => {
          setIsOpen(false);
        }}
      />
    );
  };

  return (
    <>
      <header className={cx('siteHeader')}>
        <div className={cx('brand')}>
          <Link to="/">
            <img
              src="/images/smart-water-navigator.png"
              alt="Water Action Portal"
              className={cx('brandImg')}
            />
          </Link>
        </div>
        <div className={cx('actions', 'hideLg')}>
          <div className={cx('action')}>
            <Icon path={mdiAccountCircleOutline} />
          </div>
          <div className={cx('action')} onClick={() => setIsOpen(!isOpen)}>
            {isOpen ? <Icon path={mdiClose} /> : <Icon path={mdiMenu} />}
          </div>
        </div>
        <Navigation component={HeaderLink} className={cx('hideXs')} />
        <div className={cx('actions', 'hideXs')}>
          <div className={cx('action')}>
            {isAuthenticated ? (
              <AccountAvatar />
            ) : (
              <HeaderLink to="/login">
                <Button>Login</Button>
              </HeaderLink>
            )}
          </div>
        </div>
      </header>
      {!!(variant || themeManager.subheaderVariant) && (
        <SiteSubHeader
          variant={
            (themeManager.subheaderVariant ?? variant) === 'waa' ? 'waa' : 'wrm'
          }
        />
      )}
      {isOpen && (
        <div className={cx('menu', 'fadeInDown', 'animate')}>
          <Navigation component={HeaderLink} />
          <div className={cx('divider')}></div>
          {!!variant && (
            <ul className={cx('links')}>
              {Object.entries(
                SUB_NAV_LINKS[variant === 'wrm' ? 'wrm' : 'waa'].links
              ).map(([label, value]) => (
                <li className={cx('link')} key={label}>
                  <HeaderLink to={value}>{label}</HeaderLink>
                </li>
              ))}

              <div className={cx('divider')}></div>
            </ul>
          )}
          <div className={cx('authActions')}>
            {isAuthenticated ? (
              <Button
                className={cx('authButton')}
                onClick={() => logout && logout()}
              >
                Logout
              </Button>
            ) : (
              <>
                <HeaderLink to="/login">
                  <Button className={cx('authButton')}>Login</Button>
                </HeaderLink>
                <HeaderLink to="/register">
                  <Button className={cx('authButton')} variant="secondary">
                    Signup now
                  </Button>
                </HeaderLink>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

SiteHeader.displayName = 'SiteHeader';
