import * as React from 'react';
import NotificationSystem from 'react-notification-system';
import { APIService } from '../services/APIService';
import { FacilityCreationService } from '../services/FacilityCreationService';
import { WaterStressService } from '../services/WaterStressService';
import { SiteFooter } from 'src/components/SiteFooter';

import '../styles/site.css';

export interface LayoutProps {
  children?: React.ReactNode;
}

export class Layout extends React.Component<
  LayoutProps,
  { loggedIn: boolean; name: string; company: string }
> {
  api: APIService | null = null;
  static facilityCreationService: FacilityCreationService = new FacilityCreationService();
  static notificationSystem: any = null;
  static waterStressService: WaterStressService = new WaterStressService();
  static viewFullCompany: boolean = false;
  static toggleFullCompany: Function = function () {
    Layout.viewFullCompany = !Layout.viewFullCompany;
    localStorage.setItem('viewAll', Layout.viewFullCompany.toString());
  };

  constructor(props: LayoutProps) {
    super(props);

    this.state = {
      loggedIn: false,
      name: '',
      company: '',
    };
  }

  componentDidMount() {
    if (localStorage.getItem('viewAll')) {
      Layout.viewFullCompany = localStorage.getItem('viewAll') === 'true';
    } else {
      localStorage.setItem('viewAll', Layout.viewFullCompany.toString());
    }
  }

  public render() {
    return (
      <div className="row">
        <div className="container-fluid">
          <div className="row">
            <div id="main-content" className="col-sm-12">
              <NotificationSystem
                ref={(elem: any) => {
                  Layout.notificationSystem = elem;
                }}
              />

              {this.props.children}
            </div>
            <SiteFooter />
          </div>
        </div>
      </div>
    );
  }
}
