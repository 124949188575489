import React, { FC } from 'react';
import classnames from 'classnames/bind';

import styles from './FilterAndSort.module.css';
import { SelectInput } from 'src/components/SelectInput';
import { ProcessedFacilities } from 'src/types/processedFacility';
import { useFilterAndSort } from 'src/hooks/useFilterAndSort';
import { Folders } from 'src/types/folder';
import { useDeepCompareMemo } from 'src/hooks/useDeepCompare';
import { SORTING_OPTIONS } from 'src/hooks/useFilterAndSort/utils';

const cx = classnames.bind(styles);

const SORT = (a: FilterOption, b: FilterOption) => (a.label > b.label ? 1 : -1);

type FilterOption = { label: string; value: string };
type FilterData = {
  countries: Array<FilterOption>;
  labels: Array<FilterOption>;
  sectors: Array<FilterOption>;
};

type FilterAndSortProps = {
  facilities: ProcessedFacilities;
  folders: Folders;
};

export const FilterAndSort: FC<FilterAndSortProps> = ({
  facilities,
  folders,
}) => {
  const { setFilterState, ...filterState } = useFilterAndSort();

  const filterData = useDeepCompareMemo(() => {
    const countriesDedupe = new Set();
    const labelsDedupe = new Set();
    const sectorsDedupe = new Set();
    const result = facilities.reduce<FilterData>(
      (acc, f) => {
        if (
          f.countryLocationId &&
          f.countryName &&
          !countriesDedupe.has(`${f.countryLocationId}-${f.countryName}`)
        ) {
          countriesDedupe.add(`${f.countryLocationId}-${f.countryName}`);
          acc.countries.push({
            value: f.countryLocationId,
            label: f.countryName,
          });
        }

        if (f.label && !labelsDedupe.has(f.label)) {
          labelsDedupe.add(f.label);
          acc.labels.push({
            value: f.label,
            label: f.label,
          });
        }

        if (
          f.sectorId &&
          f.sectorName &&
          !sectorsDedupe.has(`${f.sectorId}-${f.sectorName}`)
        ) {
          sectorsDedupe.add(`${f.sectorId}-${f.sectorName}`);
          acc.sectors.push({
            value: `${f.sectorId}`,
            label: f.sectorName,
          });
        }

        return acc;
      },
      {
        countries: [],
        labels: [],
        sectors: [],
      }
    );

    result.countries.sort(SORT);
    result.labels.sort(SORT);
    result.sectors.sort(SORT);

    return result;
  }, [facilities]);

  return (
    <div className={cx('filterAndSort')}>
      <div className={cx('filtering')}>
        <header className={cx('filterAndSortTitle')}>Filter by:</header>
        <div className={cx('options')}>
          <div className={cx('filterOption')}>
            <SelectInput
              items={[
                { label: 'All Folders', value: '' },
                ...folders.map((f) => ({
                  label: f.folderName,
                  value: `${f.folderId}`,
                })),
              ]}
              value={filterState.folderId ?? ''}
              placeholder="&nbsp;"
              onChange={(value) => {
                setFilterState && setFilterState('folderId', value);
              }}
            />
          </div>
          <div className={cx('filterOption')}>
            <SelectInput
              items={[
                { label: 'All Countries', value: '' },
                ...filterData.countries,
              ]}
              value={filterState.countryId ?? ''}
              placeholder="&nbsp;"
              onChange={(value) => {
                setFilterState && setFilterState('countryId', value);
              }}
            />
          </div>
          <div className={cx('filterOption')}>
            <SelectInput
              items={[{ label: 'All Labels', value: '' }, ...filterData.labels]}
              value={filterState.label ?? ''}
              placeholder="&nbsp;"
              onChange={(value) => {
                setFilterState && setFilterState('label', value);
              }}
            />
          </div>
          <div className={cx('filterOption')}>
            <SelectInput
              items={[
                { label: 'All Sectors', value: '' },
                ...filterData.sectors,
              ]}
              value={filterState.sectorId ?? ''}
              placeholder="&nbsp;"
              onChange={(value) => {
                setFilterState && setFilterState('sectorId', value);
              }}
            />
          </div>
        </div>
      </div>
      <div className={cx('sorting')}>
        <header className={cx('filterAndSortTitle')}>Sort by:</header>
        <div className={cx('sortOption')}>
          <SelectInput
            items={[...SORTING_OPTIONS]}
            value={filterState.sortId ?? '2'}
            placeholder="&nbsp;"
            onChange={(value) => {
              setFilterState && setFilterState('sortId', value);
            }}
          />
        </div>
      </div>
    </div>
  );
};

FilterAndSort.displayName = 'FilterAndSort';
