import React, { FC } from 'react';
import classnames from 'classnames/bind';
import { yupResolver } from '@hookform/resolvers/yup';
import { Facility } from 'src/types/facility';
import { ReportingYear } from 'src/types/reportingYear';
import { FacilityTabNavigator } from '../Helpers/FacilityTabNavigator';
import { useTabNavigator } from '../Helpers/FacilityTabNavigator/useTabNavigator';
import { FacilityInformation } from '../Tabs/FacilityInformation';
import { MappedReferenceData } from 'src/types/referenceData';
import { mergeFacilityAndReportingYear } from '../utils';
import { FormProvider, useForm } from 'react-hook-form';
import { FacilityDetails } from '../Tabs/FacilityDetails';
import { useHistory } from 'react-router-dom';
import { useDeepCompareEffect } from 'src/hooks/useDeepCompare';
import { BusinessInformation } from '../Tabs/BusinessInformation';
import { RegulationReputation } from '../Tabs/RegulationReputation';
import { WaterQuality } from '../Tabs/WaterQuality';
import { Pollutants } from '../Tabs/Pollutants';
import { FacilityFormSchema, facilityFormSchema } from '../validation';
import styles from '../FacilityForm.module.css';
import { Alert } from 'src/components/Alert';
import { Copy } from 'src/components/Copy';
import { LoadingSpinner } from 'src/components/LoadingSpinner';

const cx = classnames.bind(styles);

export const TABS = [
  {
    key: 'Facility Information',
    component: (
      <span>
        <span className={cx('required')}>*</span> Facility Information
      </span>
    ),
  },
  {
    key: 'Facility Details',
    component: (
      <span>
        <span className={cx('required')}>*</span> Facility Details
      </span>
    ),
  },
  'Business Information',
  `Regulation ${String.fromCharCode(0x0026)} Reputation`,
  'Water Quality',
  'Pollutants',
];

export type WrmFormProps = {
  facility?: Facility;
  referenceData: MappedReferenceData;
  selectedReportingYear: string;
  reportingYear?: ReportingYear;
  onSave: (values: FacilityFormSchema) => void;
  onDelete?: () => void;
  isLoadingReportingYear?: boolean;
};

export const WrmForm: FC<WrmFormProps> = ({
  facility,
  reportingYear,
  referenceData,
  selectedReportingYear,
  onSave,
  onDelete,
  isLoadingReportingYear,
}) => {
  const history = useHistory();
  const tabNavigator = useTabNavigator(TABS);
  const { activeTabIndex, onNextTab } = tabNavigator;

  const methods = useForm({
    defaultValues: mergeFacilityAndReportingYear(facility, reportingYear),
    resolver: yupResolver(facilityFormSchema),
  });

  useDeepCompareEffect(() => {
    if (facility || reportingYear) {
      methods.reset({
        ...mergeFacilityAndReportingYear(facility, reportingYear),
      });
    }
  }, [selectedReportingYear, facility, reportingYear]);

  const onNext = onNextTab;
  const onCancel = () => history.go(-1);
  const handleSave = methods.handleSubmit(onSave);
  const onSelectYear = (year: string) => {
    history.replace(
      `/wrm/facilities/${facility?.pkFacility ?? 'create'}/wrm/year/${year}`
    );
  };

  const { formState } = methods;

  return (
    <FormProvider {...methods}>
      {formState.isSubmitted && !formState.isSubmitSuccessful ? (
        <div className={cx('alert')}>
          <Alert variant="danger" message="Some form values are invalid." />
        </div>
      ) : null}
      <div className={cx('WrmForm')}>
        <FacilityTabNavigator tabNavigator={tabNavigator} />
        <article className={cx('content')}>
          <Copy className={cx('required', 'requiredExp')}>
            * All information on this tab is required.
          </Copy>
          <FacilityInformation
            onNext={onNext}
            onCancel={onCancel}
            onDelete={onDelete}
            className={cx({ active: activeTabIndex === 0 })}
          />
          {isLoadingReportingYear ? (
            <LoadingSpinner block />
          ) : (
            <>
              <FacilityDetails
                selectedReportingYear={selectedReportingYear}
                referenceData={referenceData}
                onSelectYear={onSelectYear}
                onSave={handleSave}
                onCancel={onCancel}
                className={cx({ active: activeTabIndex === 1 })}
              />
              <BusinessInformation
                referenceData={referenceData}
                reportingYear={reportingYear}
                onSave={handleSave}
                onCancel={onCancel}
                className={cx({ active: activeTabIndex === 2 })}
              />
              <RegulationReputation
                referenceData={referenceData}
                reportingYear={reportingYear}
                onSave={handleSave}
                onCancel={onCancel}
                className={cx({ active: activeTabIndex === 3 })}
              />
              <WaterQuality
                referenceData={referenceData}
                reportingYear={reportingYear}
                onSave={handleSave}
                onCancel={onCancel}
                className={cx({ active: activeTabIndex === 4 })}
              />
              <Pollutants
                reportingYear={reportingYear}
                onSave={handleSave}
                onCancel={onCancel}
                className={cx({ active: activeTabIndex === 5 })}
              />
            </>
          )}
        </article>
      </div>
    </FormProvider>
  );
};

WrmForm.displayName = 'WrmForm';
