import classnames from 'classnames/bind';
import React, { FC } from 'react';
import { Copy } from '../Copy';
import { Pressable } from '../Pressable';
import styles from './SiteFooter.module.css';

const cx = classnames.bind(styles);

export interface SiteFooterProps extends React.HTMLAttributes<HTMLDivElement> {
  solid?: boolean;
}

export const SiteFooter: FC<SiteFooterProps> = ({
  children,
  className,
  solid = false,
  ...props
}) => {
  return (
    <div className={cx('siteFooterGroup', className, { solid })} {...props}>
      {children}

      <div className={cx('siteFooter')}>
        <div className={cx('section')}>
          <Copy color="white">Developed by ECOLAB USA INC.&reg;</Copy>
          <Copy color="white">
            Powered by Microsoft and S&amp;P Global Trucost
          </Copy>
        </div>

        <div className={cx('section')}>
          <Pressable to="/about/disclaimer" className={cx('footerLink')}>
            <Copy>Disclaimer</Copy>
          </Pressable>

          <Pressable
            to="https://www.ecolab.com/privacy-policy"
            className={cx('footerLink')}
          >
            <Copy>Privacy Policy</Copy>
          </Pressable>
        </div>

        <div className={cx('section')}>
          <Copy color="white">
            &copy; {new Date().getFullYear()} Smart Water Navigator, All Rights
            Reserved
          </Copy>
        </div>
      </div>
    </div>
  );
};

SiteFooter.displayName = 'SiteFooter';
