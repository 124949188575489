import React, { FC } from 'react';
import classnames from 'classnames/bind';
import { Icon } from '@mdi/react';
import {
  mdiAccountCircleOutline,
  mdiLogout,
  mdiAccountCogOutline,
  mdiAccountBoxOutline,
} from '@mdi/js';
import styles from './AccountAvatar.module.css';
import { useAuth } from 'src/hooks/useAuth';
import { Link } from 'react-router-dom';
import { useQuery } from 'react-query';

const cx = classnames.bind(styles);

type AccountAvatarProps = {};

export const AccountAvatar: FC<AccountAvatarProps> = () => {
  const { isAuthenticated, logout, fetchUser } = useAuth();

  const { data } = useQuery(['user', 'current'], fetchUser!, {
    enabled: !!isAuthenticated,
  });

  return (
    <div className={cx('accountAvatar')}>
      <button type="button" className={cx('trigger')}>
        <Icon path={mdiAccountCircleOutline} size="32" />
      </button>
      <div className={cx('menu')}>
        <div className={cx('openIcon')}>
          {data?.status === 'success' ? (
            <div className={cx('personals')}>
              <strong>{`${data.value.result.firstName} ${data.value.result.lastName}`}</strong>
              <br />
              <span>{data.value.result.companyName}</span>
            </div>
          ) : null}

          <Icon path={mdiAccountCircleOutline} size="32" />
        </div>
        <ul className={cx('links')}>
          <li className={cx('link')}>
            <Link type="button" className={cx('linkButton')} to="/user">
              <Icon
                path={mdiAccountBoxOutline}
                className={cx('linkButtonIcon')}
                size="18"
              />
              Profile
            </Link>
          </li>
          <li className={cx('link')}>
            <Link type="button" className={cx('linkButton')} to="/account">
              <Icon
                path={mdiAccountCogOutline}
                className={cx('linkButtonIcon')}
                size="18"
              />
              Account
            </Link>
          </li>
          <li className={cx('divider')}></li>
          <li className={cx('link')}>
            <button
              type="button"
              className={cx('linkButton')}
              onClick={() => logout && logout()}
            >
              <Icon
                path={mdiLogout}
                className={cx('linkButtonIcon')}
                size="18"
              />
              Logout
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
};

AccountAvatar.displayName = 'AccountAvatar';
