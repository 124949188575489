import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Link } from './Link';

import '../styles/site-details.css';
import '../styles/about.css';
import { RouteContentScrollable } from 'src/components/RouteContentScrollable';
import { SiteFooter } from 'src/components/SiteFooter';

export class About extends React.Component<RouteComponentProps<{}>, {}> {
  constructor(props: RouteComponentProps<{}>) {
    super(props);
    this.state = { currentCount: 0 };
  }

  scrollTo(target: any, e: any) {
    e.preventDefault();
    let element = document.getElementById(target);

    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  public render() {
    return (
      <RouteContentScrollable className="module-waa">
        <div id="main-content">
          <div className="about">
            <header className="row">
              <div className="col-md-12">
                <h1>What is the Water Action Assessment?</h1>
              </div>
            </header>
            <div className="row intro-waves dark-waves"></div>
            <div className="row scroll-to-section-buttons">
              <button
                className="scroll-button"
                onClick={this.scrollTo.bind(this, 'breakthrough-approach')}
              >
                A breakthrough approach
              </button>
              <button
                className="scroll-button"
                onClick={this.scrollTo.bind(this, 'getting-water-smart')}
              >
                Getting Water-smart
              </button>
              <button
                className="scroll-button"
                onClick={this.scrollTo.bind(this, 'water-maturity-curve')}
              >
                The Water Maturity Curve
              </button>
              <button
                className="scroll-button"
                onClick={this.scrollTo.bind(this, 'measurement_criteria')}
              >
                Measurement Criteria
              </button>
            </div>
            <div className="row">
              <main className="white-background black-text container-fluid">
                <div className="row">
                  <div
                    className="col-lg-8 col-lg-offset-2"
                    id="breakthrough-approach"
                  >
                    <h2>A breakthrough approach</h2>
                    <p>
                      Because water is essential for nearly every human
                      endeavor, all businesses are in the water business. With
                      climate change, economic growth and population growth
                      putting pressure on global water resources, companies are
                      looking for ways to build resilience and manage
                      water-related risk.
                    </p>
                    <p>
                      But, a significant gap persists between intentions and
                      action. To make a difference in the world, action at the
                      local level is crucial. However, many businesses lack the
                      strategies and expertise to translate their corporate
                      targets into tangible change at their facilities. Since
                      every facility is different – some have issues with water
                      quantity, others with water quality, and many with both –
                      local, context-based solutions are needed to make a
                      difference on the ground.
                    </p>
                    <p>
                      Through the Water Action Assessment, the Smart Water
                      Navigator provides a breakthrough approach that enables
                      companies to make the connection between corporate goals
                      and local action, offering a practical roadmap to build
                      circular water management at the site level and enable
                      individual facilities to reduce, re-use and recycle water.
                      Based on a simple, 13-question Water Action Assessment,
                      the tool offers a practical guide to smart, sustainable
                      water practices. Using the guide, you can improve your
                      water management and stewardship practices – operating
                      more efficiently ensuring business success in a world that
                      has enough water for all.
                    </p>
                  </div>
                </div>
                <div className="row" id="getting-water-smart">
                  <div className="col-lg-8 col-lg-offset-2">
                    <h2>Getting Water-smart</h2>
                    <p>
                      When a company starts to assess and understand how it uses
                      water, it progresses along a path of improvement in water
                      management and water efficiency performance. In other
                      words, it gains water maturity.
                    </p>
                    <div className="row">
                      <blockquote className="col-xs-10 col-xs-offset-2 col-sm-8">
                        <p>
                          The Water Maturity Curve illustrates this process,
                          visualizing the state of a company’s water strategy
                          and management plan. A company’s place on the curve is
                          determined by a set of criteria that includes
                          governance and strategy, target setting, water
                          management practices and water stewardship.
                        </p>
                      </blockquote>
                    </div>
                    <p>
                      Companies may score very differently on these criteria.
                      One company may have advanced water management practices,
                      but lack senior leadership support for strong governance
                      and accountability. Another may have strong internal
                      practices for governance and water management, although it
                      hasn’t expanded these principles beyond its own four walls
                      and developed a comprehensive water stewardship approach.
                    </p>
                    <p>
                      To be Water-smart, a company needs to be leading on all
                      criteria. That often requires leadership, investment and
                      stakeholder engagement.
                    </p>
                    <p>
                      The good news is this: Following the assessment and guide
                      offered on this website, companies and individual
                      facilities can determine their water risk, their place on
                      the Water Maturity Curve and the steps necessary to get
                      Water-smart.
                    </p>
                  </div>
                </div>
                <div className="row" id="water-maturity-curve">
                  <div className="col-lg-8 col-lg-offset-2">
                    <h2>The Water Maturity Curve</h2>
                    <p>
                      A facility’s progress is expressed on the Water Maturity
                      Curve. As the facility builds its water management
                      practices and gains water maturity, it moves up the curve
                      through four stages: Untapped, Linear, Exploratory and
                      Water-smart.
                    </p>
                    <br />
                  </div>
                  <div className="col-lg-4 col-lg-offset-2">
                    <div>
                      <p>Water-smart</p>
                      <p>
                        Smart, circular water management is fully embedded in
                        your site’s decision-making and operations. You are
                        actively working on water issues with the surrounding
                        community.
                      </p>
                      <hr />
                    </div>
                    <div>
                      <p>Exploratory</p>
                      <p>
                        You have mastered water conservation. Your site is
                        deploying pilots for circular water management. You are
                        reaching out to other water users in the surrounding
                        community.
                      </p>
                      <hr />
                    </div>
                    <div>
                      <p>Linear</p>
                      <p>
                        Your site is primarily focused on water conservation,
                        with successful water reduction pilots in place.
                      </p>
                      <hr />
                    </div>
                    <div>
                      <p>Untapped</p>
                      <p>
                        Your site has not yet adopted smart water management
                        practices.
                      </p>
                      <hr />
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <img
                      className="water-curve-graph"
                      src="/images/waa/wmc_flat.png"
                      alt=""
                    />
                  </div>
                </div>
                <div className="row" id="measurement_criteria">
                  <div className="col-lg-8 col-lg-offset-2 measurement-criteria--heading">
                    <h2>Measurement criteria</h2>
                    <p>
                      These are the criteria that determine how a company
                      performs on the Water Maturity Curve:
                    </p>
                  </div>
                  <div className="col-12 measurement-criteria">
                    <div className="row">
                      <div className="col-lg-1 col-lg-offset-2">
                        <img
                          className="icon"
                          src="/images/waa/icon_gov.png"
                          alt=""
                        />
                      </div>
                      <div className="col-lg-7">
                        <h2>Site Management</h2>
                        <p>
                          The way a company is managing water issues in its
                          operations, and the practices and processes it has in
                          place to make water a priority and to drive
                          accountability at the local level.
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-1 col-lg-offset-2">
                        <img
                          className="icon"
                          src="/images/waa/icon_water_mgmt.png"
                          alt=""
                        />
                      </div>
                      <div className="col-lg-7">
                        <h2>Water Use Practices</h2>
                        <p>
                          Specific operational practices that occur “within the
                          four walls” of a company related to measuring,
                          managing and optimizing the use of water for
                          production processes or products.
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-1 col-lg-offset-2">
                        <img
                          className="icon"
                          src="/images/waa/icon_target.png"
                          alt=""
                        />
                      </div>
                      <div className="col-lg-7">
                        <h2>Target Setting</h2>
                        <p>
                          The process of understanding baseline business
                          practices and formulating time-based metrics against
                          those baselines. Targets take into account risk
                          avoidance, business water risk, context-specific
                          factors and investments necessary to meet commitments.
                          They should be outcome-focused, quantifiable and
                          designed to drive action at the local level.
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-1 col-lg-offset-2">
                        <img
                          className="icon"
                          src="/images/waa/icon_steward.png"
                          alt=""
                        />
                      </div>
                      <div className="col-lg-7">
                        <h2>Water Stewardship</h2>
                        <p>
                          The water management and actions within a catchment
                          that occur “outside the four walls” of a facility.
                          Water stewardship is achieved through a
                          stakeholder-inclusive process that involves site and
                          catchment-based actions. These efforts are intended to
                          guide water use to be socially equitable and
                          environmentally and economically sustainable.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </main>
            </div>
            <div className="row">
              <div id="facility_score" className="blue-card invite white-text">
                <div className="col-lg-6 col-lg-offset-3">
                  <h2 className="bigger">How does your facility score?</h2>
                </div>
                <div>
                  <div className="col-lg-4 col-lg-offset-3">
                    <p>
                      The Water Action Assessment contains 13 questions to help
                      you discover where your facility scores on the Water
                      Maturity Curve.
                    </p>
                  </div>
                  <div className="col-lg-4">
                    <Link to="/assessment" className="btn-itb">
                      Take the assessment
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <SiteFooter solid style={{ width: '100%' }} />
      </RouteContentScrollable>
    );
  }
}
