import React, { FC } from 'react';
import classnames from 'classnames/bind';

import styles from './Dashboard.module.css';
import { Copy } from 'src/components/Copy';
import { FacilityList } from '../../components/FacilityList';
import { useQuery } from 'react-query';
import { LoadingSpinner } from 'src/components/LoadingSpinner';
import { BlockError } from 'src/components/BlockError';
import { getFacilities } from 'src/clients/api/facilty';
import { useFilterAndSort } from 'src/hooks/useFilterAndSort';
import { getFolders } from 'src/clients/api/folder';
import { FilterAndSort } from '../../components/FilterAndSort';
import { ProcessedFacilities } from 'src/types/processedFacility';
import { Folders } from 'src/types/folder';
import { RouteComponentProps } from 'react-router-dom';

const cx = classnames.bind(styles);

type DashboardProps = {};

const DashboardWrapper: FC<{
  facilities: ProcessedFacilities;
  folders: Folders;
}> = ({ children, facilities, folders }) => (
  <div className={cx('dashboard')}>
    <header className={cx('header')}>
      <Copy as="h2">Facility Dashboard</Copy>
      <br />
      <Copy as="p">
        <strong>Step 1: Identify</strong> | Assess water-related risks by
        analyzing facility-level details on this <em>Facility Dashboard</em> and
        exploring the <em>Enterprise Dashboard</em> from the left-hand
        navigation. Within the <em>Enterprise Dashboard</em>, understand your
        organizational risk profile on the <em>Risk Analysis</em> tab and
        compare the performance of different sites within a portfolio on the{' '}
        <em>Benchmarking tab</em>.
      </Copy>
      <br />
      <Copy as="p">
        <strong>Step 2: Target</strong> | On this <em>Facility Dashboard</em>,
        click on the <em>Set Targets</em> button for any given facility to
        discover what a sustainable annual water withdrawal target would be
        given current water withdrawal and baseline water stress.
      </Copy>
      <br />
      <Copy as="p">
        <strong>Step 3: Implement</strong> | Take the{' '}
        <em>Water Action Assessment</em> to receive a practical,
        industry-specific guide to improve water management, use, target-setting
        and stewardship practices. Once improvement projects have been
        identified, click on the <em>Investment Calculator</em> for any given
        facility on this <em>Facility Dashboard</em> to prioritize water
        initiatives.
      </Copy>
      <br />
      <Copy as="p">
        <strong>Step 4: Track</strong> | For any given facility on this{' '}
        <em>Facility Dashboard</em>, monitor water use over time by inputting
        multiple years of data and clicking on the facility <em>Performance</em>
        .
      </Copy>
    </header>
    <div className={cx('content')}>
      <FilterAndSort facilities={facilities} folders={folders} />
      {children}
    </div>
  </div>
);

export const Dashboard: FC<
  DashboardProps & RouteComponentProps<{ year: string }>
> = () => {
  const { folderId } = useFilterAndSort();

  const { data: folderData, isLoading: isLoadingFolders } = useQuery(
    ['folders'],
    () => getFolders()
  );

  const { isLoading, isFetching, data, error, refetch } = useQuery(
    ['facilities', 'folder', folderId],
    () => getFacilities(folderId),
    {
      enabled: !isLoadingFolders,
    }
  );

  const facilities =
    data?.status === 'success' && data?.value.result ? data?.value.result : [];

  const folders =
    folderData?.status === 'success' && folderData?.value.result
      ? folderData?.value.result
      : [];

  if (isLoading || isFetching || isLoadingFolders) {
    return (
      <DashboardWrapper facilities={facilities} folders={folders}>
        <LoadingSpinner block />
      </DashboardWrapper>
    );
  }

  if (error || !data || data.status === 'error') {
    return (
      <DashboardWrapper facilities={facilities} folders={folders}>
        <BlockError
          retry={refetch}
          isLoading={isFetching}
          title="Error loading facilities"
          message="We were unable to load your facilities successfully"
        />
      </DashboardWrapper>
    );
  }

  return (
    <DashboardWrapper facilities={facilities} folders={folders}>
      <FacilityList facilities={facilities} />
    </DashboardWrapper>
  );
};

Dashboard.displayName = 'Dashboard';
