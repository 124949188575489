import React, { FC } from 'react';
import { VerticalTabContainer } from '../VerticalTabContainer';
import classnames from 'classnames/bind';
import styles from './FacilityTargets.module.css';
import { WaterWithdrawalRisk } from './Tabs/WaterWithdrawalRisk';
import { ProcessedFacility } from 'src/types/processedFacility';
import { TotalRisk } from './Tabs/TotalRisk';
import { IncomingRisk } from './Tabs/IncomingRisk';
import { OutgoingRisk } from './Tabs/OutgoingRisk';
import { RevenueRisk } from './Tabs/RevenueRisk';

export type FacilityTargetsProps = {
  facility: ProcessedFacility;
  className?: string;
};

const TABS = [
  'Water Withdrawal Risk',
  'Total Risk Premium',
  'Incoming Water At Risk',
  'Outgoing Water At Risk',
  'Revenue Risk',
];

const cx = classnames.bind(styles);

export const FacilityTargets: FC<FacilityTargetsProps> = ({
  facility,
  className,
}) => {
  return (
    <VerticalTabContainer
      tabs={TABS}
      className={cx('animate', 'fadeInDown', className)}
    >
      {(activeTabIndex) => (
        <>
          {activeTabIndex === 0 ? (
            <WaterWithdrawalRisk facility={facility} />
          ) : null}
          {activeTabIndex === 1 ? <TotalRisk facility={facility} /> : null}
          {activeTabIndex === 2 ? <IncomingRisk facility={facility} /> : null}
          {activeTabIndex === 3 ? <OutgoingRisk facility={facility} /> : null}
          {activeTabIndex === 4 ? <RevenueRisk facility={facility} /> : null}
        </>
      )}
    </VerticalTabContainer>
  );
};

FacilityTargets.displayName = 'FacilityTargets';
