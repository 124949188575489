import React, { FC } from 'react';
import { Switch } from 'react-router-dom';

import { Route } from 'src/components/Route';
import { Dashboard } from '../components/Dashboard';
import { routes, APP_SLUG } from '../routes';

type WaaApp = {};

export const App: FC<WaaApp> = () => {
  return (
    <Switch>
      {routes.map((route) => {
        return <Route exact key={route.path} {...route} />;
      })}

      <Route path={`${APP_SLUG}/*`} component={Dashboard} />
    </Switch>
  );
};
