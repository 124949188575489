import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from '../Link';
import { HashLink } from 'react-router-hash-link';
import 'isomorphic-fetch';
import '../../styles/site-details.css';
import { ImprovementOpportunity } from '../Cards/Graphs/ImprovementOpportunity';
import { Naic } from '../Facility';
import { SectionToggle } from '../SectionToggle';
import { WaterRiskLevel } from '../WaterRiskLevel';
import { APIService, CallType } from '../../services/APIService';
import { Layout } from '../Layout';
import { Redirect } from '../Redirect';
import { InviteModal } from '../Modals/InviteModal';
import { getTokenInStorage } from 'src/clients/helpers';
import { Env } from 'src/constants/env';
import { RouteContentScrollable } from 'src/components/RouteContentScrollable';
import { SiteFooter } from 'src/components/SiteFooter';

const waterSmart =
  '<p>Congratulations! Based on the Water Action Assessment, your water maturity level is Water-smart.</p><p>You have robust water management practices in place and have embedded circular solutions and processes across your operations. Use your Guide to see how you can continue to stay proactive and sustain your performance level.</p>';
const exploratory =
  '<p>Based on the Water Action Assessment, your water maturity level is Exploratory. You have taken important steps building your water management practices and are on well on your way to being Water-smart.</p><p>Use your Guide to find out how you can continue your water management journey and move toward Water-smart.</p>';
const linear =
  '<p>Based on the Water Action Assessment, your water maturity level is Linear. While you are likely focused on water conservation and might have some successful pilots in place to start addressing your water use, there are still many opportunities to improve the way you manage water.</p><p>Use your Guide to find out how you can continue your water management journey and move toward Water-smart.</p>';
const untapped =
  '<p>Based on the Water Action Assessment, your water maturity level is Untapped. Currently, there are no smart water management practices in place at your facility. There are many opportunities to improve your water management practices.</p><p>Use your Guide to find out you can begin your journey toward Water-smart.</p>';

const actionCopy = {
  monitor:
    'Although you scored low on the water maturity curve, you are currently experiencing low water stress. Water is not an immediate concern for your facility. However, since water stress may increase, you should monitor your situation closely, in case investment becomes necessary.',
  invest:
    'Your facility is experiencing high water stress, but you scored low on the water maturity curve. You should invest in projects that improve your water management practices. As you work your way up the water maturity curve, your water resiliency will improve, benefiting overall operations and business results.',
  sustain:
    'Although you are currently experiencing low water stress, your facility scored high on the water maturity curve. You should sustain your water management practices. If water stress levels increase, you will be able to meet changing conditions with resilient operations and maintain positive business results.',
  optimize:
    'Your facility is experiencing high water stress, but you scored high on the water maturity curve. You are doing what it takes to build water resiliency. To stay ahead and ensure successful operations and positive business results in the future, you should further optimize your water management practices.',
};

const improvementCopy = {
  siteManagement:
    'Your facility’s management team has an important role to play in setting the tone on water management. Consistently prioritizing efficient water use, ensuring clear lines of accountability and providing actionable metrics and internal reporting goes a long way towards changing behavior. You can find additional information on next steps and resources in your Guide.',
  waterManagement:
    'Real-time water metering can identify challenges as they happen as well as opportunities to improve business performance, optimize water and energy use and lower costs at the same time. The technologies to accomplish this are available today. You can find additional information on next steps and resources in your Guide.',
  targetSetting:
    'Targets and metrics are an essential part of improving a facility’s water management performance. Both individual facility managers and the company as a whole can create meaningful, locally appropriate, site-level metrics to monitor progress and inform decisions for driving efficiency. You can find additional information on next steps and resources in your Guide.',
  waterStewardship:
    'Your overall water performance depends on the efforts you make both inside and outside the walls of your facility. Ongoing engagement with external stakeholders such as local communities, governments, industry and non-governmental organizations will improve your awareness of potential water risks and opportunities, and your ability to manage them. You can find additional information on next steps and resources in your Guide.',
};

interface ResultState {
  maturityLevel: string;
  maturityExplanation: string;
  guideUrl: string;
  loading: boolean;
  inviteModalIsOpen: boolean;
  industryCodes: Naic[];
  facility: Facility;
  analysis: Analysis;
  user: string | null;
}

interface Facility {
  id: number;
  name: string;
  label: string;
  country: string;
  state: string;
  city: string;
  industryCode: string;
  uploadedBy: any;
  uploadedByName: string;
  industry: string;
  analysis: Analysis;
}

interface Analysis {
  facilityId: string;
  rawScore: number;
  maturityLevel: string;
  categoryScores: Array<CategoryScore>;
  risk: string;
  action: string;
}

export interface CategoryScore {
  category: string;
  score: number;
  averageScore: number;
}

interface Action {
  name: string;
  copy: string;
  image: string;
}

export class Result extends React.Component<
  RouteComponentProps<{ id: string }>,
  ResultState
> {
  api: APIService | null = null;

  constructor(props: RouteComponentProps<{ id: string }>) {
    super(props);
    this.state = {
      maturityLevel: '',
      maturityExplanation: '',
      guideUrl: '',
      loading: false,
      inviteModalIsOpen: false,
      industryCodes: [],
      facility: {
        id: 0,
        name: '',
        label: '',
        country: '',
        state: '',
        city: '',
        industryCode: '',
        uploadedBy: {},
        uploadedByName: '',
        industry: '',
        analysis: {
          facilityId: '',
          rawScore: 0,
          maturityLevel: '',
          categoryScores: [],
          risk: '',
          action: '',
        },
      },
      analysis: {
        facilityId: '',
        rawScore: 0,
        maturityLevel: '',
        categoryScores: [],
        risk: '',
        action: '',
      },
      user: null,
    };
    this.renderWaterMaturity = this.renderWaterMaturity.bind(this);
    this.renderBenchmark = this.renderBenchmark.bind(this);
    this.renderAction = this.renderAction.bind(this);
    this.getAction = this.getAction.bind(this);
    this.getImprovementCopy = this.getImprovementCopy.bind(this);
    this.openInviteModal = this.openInviteModal.bind(this);
    this.closeModals = this.closeModals.bind(this);
    this.getFacility = this.getFacility.bind(this);
    this.deleteFacility = this.deleteFacility.bind(this);
  }

  componentDidMount() {
    const resultId = this.props.match.params.id;

    this.api = new APIService(Layout.notificationSystem);

    this.api &&
      this.api
        .perform(`${Env.waaApiUrl}/v4/account/getcurrentuser`, CallType.Json)
        .then((userData) => {
          if (userData.success) {
            this.setState({
              user: userData.name,
            });
          }
        });

    this.getFacility(parseInt(resultId));

    this.api &&
      this.api.perform('/content/naics3.json', CallType.Json).then((data) => {
        this.setState({ industryCodes: data });
      });
  }

  componentDidUpdate(
    _prevProps: RouteComponentProps<{ id: string }>,
    prevState: ResultState
  ) {
    if (prevState.facility.id !== this.state.facility.id) {
      this.getFacility(this.state.facility.id);
    }
  }

  openInviteModal() {
    this.setState({ inviteModalIsOpen: true });
  }

  closeModals() {
    this.setState({
      inviteModalIsOpen: false,
    });
  }

  getFacility(facilityId: number) {
    return (
      this.api &&
      this.api
        .perform(`${Env.waaApiUrl}/v4/facility/` + facilityId, CallType.Json)
        .then((response) => {
          const f = response as Facility;
          let facility = { ...this.state.facility };
          facility.id = facilityId;
          facility.name = f.name;
          facility.label = f.label;
          facility.country = f.country;
          facility.state = f.state;
          facility.city = f.city;
          facility.industryCode = f.industryCode;
          facility.industry = f.industry;
          facility.uploadedBy = f.uploadedBy;
          facility.uploadedByName = f.uploadedByName;
          this.setState({ facility });

          let maturityText = this.getMaturityText(f.analysis.maturityLevel);
          this.setState({
            maturityLevel: f.analysis.maturityLevel,
            loading: false,
            analysis: f.analysis,
            maturityExplanation: maturityText,
          });
        })
    );
  }

  deleteFacility() {
    return (
      this.api &&
      this.api
        .perform(
          `${Env.waaApiUrl}/v4/facility/${this.state.facility.id}`,
          CallType.Json,
          {},
          { method: 'DELETE' }
        )
        .then((response) => {
          if (response.id === this.state.facility.id) {
            this.props.history.push('/waa/dashboard');
          }
        })
    );
  }

  getMaturityText(maturityLevel: string) {
    if (maturityLevel === 'Exploratory') {
      return exploratory;
    }

    if (maturityLevel === 'Linear') {
      return linear;
    }

    if (maturityLevel === 'Untapped') {
      return untapped;
    }

    return waterSmart;
  }

  public render() {
    let token = getTokenInStorage();
    if (!token || token === 'expired') {
      return <Redirect to={'/login'}></Redirect>;
    }

    let contents = this.state.loading ? (
      <div className="loading-text">Loading...</div>
    ) : (
      this.renderResults()
    );

    return (
      <RouteContentScrollable className="module-waa">
        <div id="main-content">
          <div>{contents}</div>
        </div>
        <SiteFooter solid style={{ width: '100%' }} />
      </RouteContentScrollable>
    );
  }

  getAction() {
    let result = this.state.analysis.action;
    let copy = 'The correct action could not be identified';
    let image = null;

    if (result === 'Monitor') {
      copy = actionCopy.monitor;
      image = '/images/waa/action_monitor.png';
    } else if (result === 'Invest') {
      copy = actionCopy.invest;
      image = '/images/waa/action_invest.png';
    } else if (result === 'Sustain') {
      copy = actionCopy.sustain;
      image = '/images/waa/action_sustain.png';
    } else if (result === 'Optimize') {
      copy = actionCopy.optimize;
      image = '/images/waa/action_optimize.png';
    }

    return {
      name: result,
      copy: copy,
      image: String(image),
    } as Action;
  }

  getImprovementCopy() {
    let minScore = 500;
    let minCategory = '';

    // find the category with the minimum score.
    this.state.analysis.categoryScores.forEach((category) => {
      if (category.score < minScore) {
        minScore = category.score;
        minCategory = category.category;
      }
    });

    // return improvement copy for category with minimum score.
    switch (minCategory) {
      case 'Site Management': {
        return improvementCopy.siteManagement;
      }
      case 'Target Setting': {
        return improvementCopy.targetSetting;
      }
      case 'Water Management Practices': {
        return improvementCopy.waterManagement;
      }
      case 'Water Stewardship': {
        return improvementCopy.waterStewardship;
      }
    }
    return '';
  }

  renderSiteDetails() {
    let action: string = this.getAction().name;
    let showActions: boolean = !!(
      this.state.user && this.state.user === this.state.facility.uploadedByName
    );

    return (
      <div className="col-md-12 maturity-section">
        <section className="site-details-main row black-text">
          <div className="col-md-4 large-details">
            <h2>Site name</h2>
            <p>{this.state.facility.name}</p>
            <h2>Water maturity</h2>
            <p>
              <Link
                to={
                  '/industryguide/' +
                  this.state.facility.industryCode +
                  '/' +
                  this.state.analysis.maturityLevel +
                  '/' +
                  this.state.facility.id
                }
              >
                {this.state.maturityLevel}
              </Link>
            </p>
            {showActions ? (
              <RouterLink
                to={`/wrm/facilities/${this.state.facility.id}/waa`}
                className="btn-itb btn-shift-down"
              >
                <span
                  className="glyphicon glyphicon-pencil"
                  aria-hidden="true"
                ></span>{' '}
                Update Assessment
              </RouterLink>
            ) : (
              ''
            )}
            {showActions ? (
              <button
                className="btn-itb btn-shift-down"
                onClick={this.deleteFacility}
              >
                <span
                  className="glyphicon glyphicon-trash"
                  aria-hidden="true"
                ></span>{' '}
                Delete Facility
              </button>
            ) : (
              ''
            )}
          </div>
          <div className="col-md-4 small-details">
            <div className="row">
              <div className="block col-md-4">
                <h2>Site label</h2>
                <p>{this.state.facility.label}</p>
              </div>
              <div className="block col-md-8">
                <h2>Industry</h2>
                <p>{this.state.facility.industry}</p>
              </div>
            </div>
            <div className="row">
              <div className="block col-md-4">
                <h2>City</h2>
                <p>{this.state.facility.city}</p>
              </div>
              <div className="block col-md-4">
                <h2>State/province</h2>
                <p>{this.state.facility.state}</p>
              </div>
              <div className="block col-md-4">
                <h2>Country</h2>
                <p>{this.state.facility.country}</p>
              </div>
            </div>
            <div className="row">
              <div className="block col-md-6">
                <WaterRiskLevel level={this.state.analysis.risk} />
              </div>
              <div className="block col-md-6">
                <h2>Uploaded By</h2>
                <p>{this.state.facility.uploadedByName}</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-md-offset-1 large-details">
            <h2>Action</h2>
            <p>
              <HashLink to="#action-anchor">{action}</HashLink>
            </p>
            <Link
              to={
                '/industryguide/' +
                this.state.facility.industryCode +
                '/' +
                this.state.analysis.maturityLevel +
                '/' +
                this.state.facility.id
              }
            >
              <p className="cta black-text btn-shift-down">
                Move your facility up to the next level
                <span className="glyphicon glyphicon-arrow-right gold"></span>
              </p>
            </Link>
          </div>
        </section>
      </div>
    );
  }

  renderWaterMaturity() {
    let icon = '/images/waa/water-mature@3x.png';
    let maturity = this.state.maturityLevel;
    let explanation = this.state.maturityExplanation;
    let wmc = '/images/waa/wmc_watersmart.png';

    if (maturity === 'Exploratory') {
      icon = '/images/waa/icon_exploratory.png';
      wmc = '/images/waa/wmc_exploratory.png';
    }

    if (maturity === 'Linear') {
      icon = '/images/waa/icon_linear.png';
      wmc = '/images/waa/wmc_linear.png';
    }

    if (maturity === 'Untapped') {
      icon = '/images/waa/icon-untapped.png';
      wmc = '/images/waa/wmc_untapped.png';
    }
    return (
      <SectionToggle
        heading="Water maturity curve"
        className="maturity-section"
        isOpen={true}
      >
        <div className="row">
          <div className="col-md-12 black-text">
            <div className="row">
              <div className="col-md-6 result-icon">
                <img src={String(icon)} className="icon" alt="" />
                <h3 className="inline-heading">{maturity}</h3>
                <p className="maturity-explainer center-block">
                  <div dangerouslySetInnerHTML={{ __html: explanation }} />
                </p>
              </div>
              <div className="col-md-6 image-container">
                <img src={String(wmc)} alt="Water maturity curve: {maturity}" />
              </div>
            </div>
          </div>
        </div>
      </SectionToggle>
    );
  }

  renderBenchmark() {
    let categoryScores = this.state.analysis.categoryScores;

    let waterManagement = this.state.analysis.categoryScores.find(
      (category) => category.category === 'Water Management Practices'
    );
    let targetSetting = this.state.analysis.categoryScores.find(
      (category) => category.category === 'Target Setting'
    );
    let biggestPayback = 'None';
    if (waterManagement && targetSetting) {
      if (waterManagement.averageScore > targetSetting.averageScore) {
        biggestPayback = 'Target Setting';
      } else if (targetSetting.averageScore > waterManagement.averageScore) {
        biggestPayback = 'Water Management Practices';
      } else {
        biggestPayback = 'Target Setting & Water Management Practices';
      }
    }

    return (
      <SectionToggle
        heading="Benchmark data"
        className="benchmark-section"
        isOpen={true}
      >
        <div className="row">
          <section className="col-md-12">
            <div className="row">
              <div className="col-md-6">
                <ImprovementOpportunity categories={categoryScores} />
              </div>
              <div className="col-md-6 black-text text-left">
                <h3>Where's your biggest opportunity to improve?</h3>
                <p>{this.getImprovementCopy()}</p>
              </div>
            </div>
          </section>
          <section className="col-md-12">
            <div className="row">
              <div className="col-md-6 payback image-container">
                <img src={'/images/waa/payback.png'} alt="" />
              </div>
              <div className="col-md-6 black-text text-left">
                <h3>Where's your biggest payback?</h3>
                <h2>{biggestPayback}</h2>
                <p>
                  When making the business case for water-related investments,
                  improved water management practices and target setting are
                  crucial focus areas that will ensure the biggest payback. Read
                  the Guide to find tools and resources tailored to your water
                  maturity level.
                </p>
              </div>
            </div>
          </section>
        </div>
      </SectionToggle>
    );
  }

  renderAction() {
    let action: Action = this.getAction();

    return (
      <SectionToggle heading="Action" className="action-section" isOpen={true}>
        <div
          className="row black-text text-left action-main"
          id="action-anchor"
        >
          <div className="col-sm-2 action large-details">
            <p>{action.name}</p>
          </div>
          <div className="col-sm-4 action-copy">
            <p>{action.copy}</p>
          </div>
          <div className="col-sm-3 action-image-container">
            <img src={String(action.image)} className="action-image" alt="" />
          </div>
          <div className="col-sm-3 level-up">
            <Link
              to={
                '/industryguide/' +
                this.state.facility.industryCode +
                '/' +
                this.state.analysis.maturityLevel +
                '/' +
                this.state.facility.id
              }
            >
              <p className="cta white-text btn-shift-down">
                Move your facility up to the next level
                <span className="glyphicon glyphicon-arrow-right gold"></span>
              </p>
            </Link>
          </div>
        </div>
      </SectionToggle>
    );
  }

  renderResults() {
    return (
      <div className="result">
        <header className="row">
          <h1>Site details</h1>
        </header>
        <div className="row intro-waves"></div>
        <div className="row">
          <main className="white-background container-fluid">
            <div className="row">
              <div className="col-lg-10 col-lg-offset-1 text-left">
                <Link to="/dashboard">&lt; back to dashboard</Link>
              </div>
              {this.renderSiteDetails()}
              {this.renderWaterMaturity()}
              {this.renderBenchmark()}
              {this.renderAction()}
            </div>
            <InviteModal
              isOpen={this.state.inviteModalIsOpen}
              closeModal={this.closeModals}
            ></InviteModal>
          </main>
        </div>
      </div>
    );
  }
}
