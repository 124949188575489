import { WrappedResponse } from '../types';
import request, { requestError, requestSuccess } from '../request';
import { ProcessedFacilities } from '../../types/processedFacility';
import { Env } from '../../constants/env';
import { ReportingYear } from 'src/types/reportingYear';
import { Facility, TruncatedFacilities } from 'src/types/facility';
import { getAuthHeaders } from '../request';
import { Answer } from 'src/types/assessment';

export type GetFacilitiesResponseType = WrappedResponse<ProcessedFacilities>;
export function getFacilities(folderId?: string | null) {
  return request.get<GetFacilitiesResponseType>(
    `${Env.apiUrl}/v3.1/facilities${folderId ? `/folder/${folderId}` : ''}`
  );
}

export type GetFacilityResponseType = WrappedResponse<Facility>;
export function getFacility(
  facilityId: string,
  platform: 'wrm' | 'waa' = 'wrm'
) {
  return request.get<GetFacilityResponseType>(
    `${Env.apiUrl}/v4/${platform}/facilities/${facilityId}`
  );
}

export type GetFacilityReportingYearResponseType = WrappedResponse<
  ReportingYear
>;
export function getFacilityReportingYear(
  facilityId: string,
  year: string,
  platform: 'wrm' | 'waa' = 'wrm'
) {
  return request.get<GetFacilityReportingYearResponseType>(
    `${Env.apiUrl}/${
      platform === 'waa' ? `v4/${platform}` : 'v3.1'
    }/facilities/${facilityId}/year/${year}`
  );
}

export type GetFacilityAssessmentResponseType = WrappedResponse<{
  reportingYear: number;
  assessmentAnswers: Array<Answer>;
}>;
export function getFacilityAssessment(facilityId: string) {
  return request.get<GetFacilityAssessmentResponseType>(
    `${Env.apiUrl}/v4/facilities/${facilityId}/assessment`
  );
}

export type CreateWrmFacilityReportingYearRequestPayloadType = ReportingYear;
export function createWrmFacility(data: ReportingYear) {
  return request.post<
    unknown,
    CreateWrmFacilityReportingYearRequestPayloadType
  >(`${Env.apiUrl}/v3.1/facilities`, data);
}

export type UpdateWrmFacilityReportingYearRequestPayloadType = ReportingYear;
export function updateWrmFacility(
  facilityId: string | number,
  data: ReportingYear
) {
  return request.put<unknown, UpdateWrmFacilityReportingYearRequestPayloadType>(
    `${Env.apiUrl}/v3.1/facilities/${facilityId}`,
    data
  );
}

export type CreateWaaFacilityReportingYearRequestPayloadType = Pick<
  ReportingYear,
  | 'pkFacility'
  | 'facilityName'
  | 'reportingYear'
  | 'fkCity'
  | 'fkState'
  | 'fkCountry'
  | 'fkSector'
  | 'label'
  | 'active'
  | 'modelingOnly'
> & {
  questionnaireId: '15e58462-9ac6-4a10-800e-47ffdc484d69';
  assessmentAnswers: Array<{ questionId: string; questionChoiceId: string }>;
};
export function createWaaFacility(
  data: CreateWaaFacilityReportingYearRequestPayloadType,
  facilityId?: string
) {
  return request.post<
    unknown,
    CreateWaaFacilityReportingYearRequestPayloadType
  >(
    `${Env.apiUrl}/v4/waa/facilities${
      facilityId ? `?referrer=${facilityId}` : ''
    }`,
    data
  );
}

export type UpdateWaaFacilityReportingYearRequestPayloadType = CreateWaaFacilityReportingYearRequestPayloadType;
export function updateWaaFacility(
  facilityId: string | number,
  data: UpdateWaaFacilityReportingYearRequestPayloadType
) {
  return request.put<unknown, UpdateWaaFacilityReportingYearRequestPayloadType>(
    `${Env.apiUrl}/v4/waa/facilities/${facilityId}`,
    data
  );
}

export function deleteFacility(facilityId: string | number) {
  return request.del<unknown>(`${Env.apiUrl}/v4/facilities/${facilityId}`);
}

export type GetFacilitiesListResponseType = WrappedResponse<
  TruncatedFacilities
>;
export function getFacilitiesList() {
  return request.get<GetFacilitiesListResponseType>(
    `${Env.apiUrl}/v3.1/facilities?include=list-all`
  );
}

export type GetFacilitiesListExportResponseType = WrappedResponse<string>;
export function getFacilitiesListExport(accountId: string) {
  return request.get<GetFacilitiesListExportResponseType>(
    `${Env.apiUrl}/v4/accounts/${accountId}/facilities/export`
  );
}

export type AddFacilityFolderAssociationResponseType = WrappedResponse<{}>;
export function addFacilityFolderAssociation(
  facilityId: number,
  folderId: number,
  reportingYear: number
) {
  return request.post<RemoveFacilityFolderAssociationResponseType, {}>(
    `${Env.apiUrl}/v3.1/facilities/${facilityId}/folder/${folderId}/year/${reportingYear}`
  );
}

export type RemoveFacilityFolderAssociationResponseType = WrappedResponse<{}>;
export function removeFacilityFolderAssociation(
  facilityId: number,
  folderId: number
) {
  return request.del<RemoveFacilityFolderAssociationResponseType>(
    `${Env.apiUrl}/v3.1/facilities/${facilityId}/folder/${folderId}`
  );
}

export type GetWrmBatchUploadTemplateResponseType = WrappedResponse<string>;
export function getWrmBatchUploadTemplate() {
  return request.get<GetWrmBatchUploadTemplateResponseType>(
    `${Env.apiUrl}/v3.1/facilities/template`
  );
}

export type SaveWrmBatchUploadRequestType = {};
export type SaveWrmBatchUploadResponseType = WrappedResponse<{}>;
export function postWrmBatchUpload(file: File) {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('data', file.name);

  const headers = new Headers({
    ...getAuthHeaders(),
  });
  headers.delete('Content-Type');

  return request
    .raw(`${Env.apiUrl}/v3.1/facilities/upload/data`, {
      method: 'POST',
      headers,
      body: formData,
    })
    .then(requestSuccess)
    .catch(requestError);
}

export type SaveWaaBatchUploadRequestType = {};
export type SaveWaaBatchUploadResponseType = WrappedResponse<{}>;
export function postWaaBatchUpload(file: File) {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('data', file.name);

  const headers = new Headers({
    ...getAuthHeaders(),
  });
  headers.delete('Content-Type');

  return request
    .raw(`${Env.apiUrl}/v4/waa/facilities/upload/data`, {
      method: 'POST',
      headers,
      body: formData,
    })
    .then(requestSuccess)
    .catch(requestError);
}
