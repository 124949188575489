export type EnvType = {
  baseUrl: string;
  apiUrl: string;
  authUrl: string;
  waaApiUrl: string;
  tokenCallbackUrl: string;
};

export function getEnv(): string {
  return process.env.NODE_ENV;
}

const environments: Record<string, EnvType> = {
  development: {
    baseUrl: 'https://mkt-smartwatermonitor-001-d.azureedge.net',
    apiUrl: 'https://api-dev.waterriskmonetizer.com/api',
    authUrl: 'https://api-dev.waterriskmonetizer.com/api',
    waaApiUrl: 'https://api-dev.waterriskmonetizer.com/api',
    tokenCallbackUrl: 'https://api-dev.waterriskmonetizer.com',
  },
  production: {
    baseUrl: 'https://www.smartwaternavigator.com/',
    apiUrl: 'https://api.waterriskmonetizer.com/api',
    authUrl: 'https://api.waterriskmonetizer.com/api',
    waaApiUrl: 'https://api.waterriskmonetizer.com/api',
    tokenCallbackUrl: 'https://eclmktgwrmweb-dev.azurewebsites.net',
  },
};

const isDev = getEnv() === 'development';

export const Env: EnvType = Object.assign(
  {},
  environments[isDev ? 'development' : 'production'],
  {
    ...(process.env.REACT_APP_BASE_URL && {
      baseUrl: process.env.REACT_APP_BASE_URL,
    }),
    ...(process.env.REACT_APP_API_URL && {
      apiUrl: process.env.REACT_APP_API_URL,
    }),
    ...(process.env.REACT_APP_AUTH_API_URL && {
      authUrl: process.env.REACT_APP_AUTH_API_URL,
    }),
    ...(process.env.REACT_APP_WAA_API_URL && {
      waaApiUrl: process.env.REACT_APP_WAA_API_URL,
    }),
    ...(process.env.REACT_APP_TOKEN_CALLBACK_URL && {
      tokenCallbackUrl: process.env.REACT_APP_TOKEN_CALLBACK_URL,
    }),
  }
);
