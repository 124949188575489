import React, { FC } from 'react';
import { Switch } from 'react-router-dom';
import { Route } from 'src/components/Route';
import { Home } from '../modules/Home';
import routes from '../routes';

interface AppProps {}

export const App: FC<AppProps> = () => {
  return (
    <Switch>
      {routes.map((route) => (
        <Route
          exact={route.exact === false ? false : true}
          key={route.path}
          {...route}
        />
      ))}

      <Route path="/" component={Home} />
      <Route path="*" component={Home} />
    </Switch>
  );
};
