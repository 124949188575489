import * as yup from 'yup';

const transformEmptyString = (v: any) => (isNaN(v) ? undefined : v);

export const facilityInformationSchemaProperties = {
  facilityName: yup.string().trim().required('Required'),
  fkCountry: yup.number().transform(transformEmptyString).required('Required'),
  fkState: yup.number().transform(transformEmptyString).required('Required'),
  fkCity: yup.number().transform(transformEmptyString).required('Required'),
  level1SectorId: yup
    .number()
    .transform(transformEmptyString)
    .required('Required'),
  level2SectorId: yup
    .number()
    .transform(transformEmptyString)
    .required('Required'),
  fkSector: yup.string().trim().required('Required'),
  facilityLabel: yup.string().trim(),
  active: yup.bool().required('Required'),
  modelingOnly: yup.bool().required('Required'),
};
export const facilityInformationSchema = yup.object(
  facilityInformationSchemaProperties
);
export type FacilityInformationSchemaForm = yup.InferType<
  typeof facilityInformationSchema
>;

const facilityDetailsSchemaProperties = {
  reportingYear: yup.string().trim().required('Required'),
  incomingWaterTimePeriod: yup.string().trim().required('Required'),
  incomingWaterQuantity: yup
    .number()
    .transform(transformEmptyString)
    .required('Required'),
  incomingWaterQuantityUnit: yup
    .number()
    .transform(transformEmptyString)
    .required('Required'),
  incomingWaterPrice: yup
    .number()
    .transform(transformEmptyString)
    .required('Required'),
  incomingWaterPriceCurrencyCode: yup.string().required('Required'),
  incomingWaterPriceUnit: yup
    .number()
    .transform(transformEmptyString)
    .required('Required'),
  incomingWaterSource: yup.string().trim().required('Required'),
  waterWithdrawalReplenishment: yup.bool().required('Required'),
  waterWithdrawalAnnualizedVolumetricBenefit: yup
    .number()
    .transform(transformEmptyString)
    .when('waterWithdrawalReplenishment', {
      is: true,
      then: yup.number().required('Required'),
      otherwise: yup.number(),
    }),
  waterWithdrawalAnnualizedVolumetricBenefitUOMId: yup
    .number()
    .transform(transformEmptyString)
    .when('waterWithdrawalReplenishment', {
      is: true,
      then: yup.number().required('Required'),
      otherwise: yup.number(),
    }),
  waterWithdrawalSupportingReplenishmentWaterFund: yup
    .string()
    .trim()
    .when('waterWithdrawalReplenishment', {
      is: true,
      then: yup.string().required('Required'),
      otherwise: yup.string(),
    }),
  waterWithdrawalReplenishmentDurationInYears: yup
    .number()
    .transform(transformEmptyString)
    .when('waterWithdrawalReplenishment', {
      is: true,
      then: yup.number().required('Required'),
      otherwise: yup.number(),
    }),
  waterWithdrawalThirdPartyVerified: yup
    .bool()
    .when('waterWithdrawalReplenishment', {
      is: true,
      then: yup.bool().required('Required'),
      otherwise: yup.bool(),
    }),
  waterWithdrawalThirdPartyVerifier: yup
    .string()
    .trim()
    .when('waterWithdrawalThirdPartyVerified', {
      is: true,
      then: yup.string().required('Required'),
      otherwise: yup.string(),
    }),
  outgoingWaterTimePeriod: yup.string().trim().required('Required'),
  outgoingWaterQuantity: yup
    .number()
    .transform(transformEmptyString)
    .required('Required'),
  outgoingWaterQuantityUnit: yup
    .number()
    .transform(transformEmptyString)
    .required('Required'),
  outgoingWaterPrice: yup
    .number()
    .transform(transformEmptyString)
    .required('Required'),
  outgoingWaterPriceCurrencyCode: yup.string().required('Required'),
  outgoingWaterPriceUnit: yup
    .number()
    .transform(transformEmptyString)
    .required('Required'),
  outgoingWaterTreatmentSource: yup.string().required('Required'),
  facilityOutput: yup
    .number()
    .transform(transformEmptyString)
    .typeError('Required')
    .required('Required'),
  facilityOutputUnitsOfMeasure: yup.string().required('Required'),
  projectedFacilityOutputIncrease: yup
    .number()
    .transform(transformEmptyString)
    .required('Required'),
};
export const facilityDetailsSchema = yup.object(
  facilityDetailsSchemaProperties
);
export type FacilityDetailSchemaForm = yup.InferType<
  typeof facilityDetailsSchema
>;

const businessInformationSchemaProperties = {
  droughScenario: yup.bool(),
  projectedIncomingWaterPriceIncrease: yup
    .number()
    .transform(transformEmptyString),
  projectedIncomingWaterQuantityIncrease: yup
    .number()
    .transform(transformEmptyString),
  projectedOutgoingWaterPriceIncrease: yup
    .number()
    .transform(transformEmptyString),
  projectedOutgoingWaterQuantityIncrease: yup
    .number()
    .transform(transformEmptyString),
  revenue: yup.number().transform(transformEmptyString),
  revenueCurrencyCode: yup.string(),
  projectedRevenueIncrease: yup.number().transform(transformEmptyString),
  revenueLostDueToWaterScarcity: yup.string(),
};
export const businessInformationSchema = yup.object(
  businessInformationSchemaProperties
);
export type BusinessInformationSchemaForm = yup.InferType<
  typeof businessInformationSchema
>;

const regulationReputationSchemaProperties = {
  regulationOfWaterInLocalCommunityQuestion: yup.string(),
  facilityEnvironmentalReputationQuestion: yup.string(),
  dependingSameWaterSourceQuestion: yup.string(),
  exemptWaterRestrictionQuestion: yup.string(),
  ngoLocalPressureQuestion: yup.string(),
};
export const regulationReputationSchema = yup.object(
  regulationReputationSchemaProperties
);
export type RegulationReputationSchemaForm = yup.InferType<
  typeof regulationReputationSchema
>;

const waterQualitySchemaProperties = {
  incomingWaterQualityComparison: yup.string(),
  alternativeWaterSourceQuestion: yup.string(),
  outgoingWaterOnsiteTreatmentCapacity: yup.string(),
  outgoingWaterOffsiteTreatmentCapacity: yup.string(),
  incomingWaterImpairmentSalinity: yup.bool(),
  incomingWaterImpairmentOrganics: yup.bool(),
  incomingWaterImpairmentSuspendedSolids: yup.bool(),
  incomingWaterImpairmentTaste: yup.bool(),
  incomingWaterImpairmentOdor: yup.bool(),
};
export const waterQualitySchema = yup.object(waterQualitySchemaProperties);
export type WaterQualitySchemaForm = yup.InferType<typeof waterQualitySchema>;

export const facilityFormSchema = yup.object({
  ...facilityInformationSchemaProperties,
  ...facilityDetailsSchemaProperties,
  ...businessInformationSchemaProperties,
  ...regulationReputationSchemaProperties,
  ...waterQualitySchemaProperties,
});
export type FacilityFormSchema = yup.InferType<typeof facilityFormSchema>;
