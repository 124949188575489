import { yupResolver } from '@hookform/resolvers/yup';
import classnames from 'classnames/bind';
import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import {
  forgotPasswordFormSchema,
  ForgotPasswordSchemaPayload,
} from 'src/clients/auth';
import { Button } from 'src/components/Button';
import { Copy } from 'src/components/Copy';
import { RouteContentScrollable } from 'src/components/RouteContentScrollable';
import { TextInput } from 'src/components/TextInput';
import { useAsyncState } from 'src/hooks/useAsyncState';
import { useAuth } from 'src/hooks/useAuth';
import styles from './ForgotPassword.module.css';

const cx = classnames.bind(styles);

export interface ForgotPasswordProps
  extends React.HTMLAttributes<HTMLDivElement> {}

const RESOLVER = yupResolver<ForgotPasswordSchemaPayload>(
  forgotPasswordFormSchema
);

export const ForgotPassword: FC<ForgotPasswordProps> = () => {
  const { forgotPassword } = useAuth();

  const {
    handleSubmit,
    formState: { errors },
    register,
  } = useForm<ForgotPasswordSchemaPayload>({
    resolver: RESOLVER,
  });

  const [state, setState] = useAsyncState();

  const onSubmit = async ({ email }: ForgotPasswordSchemaPayload) => {
    if (forgotPassword) {
      try {
        setState({
          status: 'loading',
        });

        const result = await forgotPassword({ email });

        if (result.status !== 'success') {
          throw new Error(result.value.errors[0].message);
        }

        setState({
          status: 'success',
          message: 'Password reset email sent successfully.',
        });
      } catch (e) {
        setState({
          status: 'error',
          message: e.message ?? 'There was an error with your request.',
        });
      }
    }
  };

  return (
    <RouteContentScrollable className={cx('scrollable')}>
      <div>
        <article className={cx('forgotPassword')} data-testid="forgotPassword">
          <section>
            <div className={cx('forgotPasswordBox')}>
              <Copy as="h2">Reset Password</Copy>
              {state.status === 'success' ? (
                <div role="alert">
                  <span>
                    Your forgot password request was received. Please check you
                    email for the change password instructions.
                  </span>
                </div>
              ) : (
                <>
                  {state.status === 'error' && (
                    <div role="alert">
                      <span>{state.message}</span>
                    </div>
                  )}
                  <form
                    onSubmit={handleSubmit(onSubmit)}
                    className={cx('forgotPasswordForm')}
                  >
                    <TextInput
                      type="text"
                      placeholder="Email address"
                      autoComplete="email"
                      name="email"
                      error={errors.email}
                      className={cx('input')}
                      ref={register}
                    />

                    <Button
                      type="submit"
                      className={cx('buttonSubmit')}
                      isLoading={state.status === 'loading'}
                    >
                      Reset Password
                    </Button>
                  </form>
                </>
              )}
            </div>
          </section>
        </article>
      </div>
    </RouteContentScrollable>
  );
};

ForgotPassword.displayName = 'ForgotPassword';
