import React, { FC } from 'react';
import classnames from 'classnames/bind';
import { ProcessedFacility } from 'src/types/processedFacility';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import { useDeepCompareMemo } from 'src/hooks/useDeepCompare';
import styles from './Tabs.module.css';
import {
  RISK_COLOR_MAP,
  WITHDRAWAL_RISK_SUGGESTION_MAP,
  waterWithdrawalChartDataGenerator,
} from 'src/utils/chart';
import { Copy } from 'src/components/Copy';
import { STRESS_COLOR_MAP } from 'src/utils/chart/utils';

const cx = classnames.bind(styles);

type WaterWithdrawalRiskProps = {
  facility: ProcessedFacility;
};

export const WaterWithdrawalRisk: FC<WaterWithdrawalRiskProps> = ({
  facility,
}) => {
  const data = facility.charts.sustainableWaterAllocationChart;
  const riskLevel = {
    level: data.attributes['swu-riskRank'],
    color: RISK_COLOR_MAP[data.attributes['swu-riskRank']],
    suggestion: WITHDRAWAL_RISK_SUGGESTION_MAP[data.attributes['swu-riskRank']],
    style: { 'background-color': data.attributes['swu-riskRank'] },
  };
  const stressLevel = {
    level: (facility.wriWaterStressCategory ?? '').toUpperCase(),
    color:
      STRESS_COLOR_MAP[
        (facility.wriWaterStressCategory as keyof typeof STRESS_COLOR_MAP) ??
          'none'
      ],
  };

  const chart = useDeepCompareMemo<Highcharts.Options>(
    () => waterWithdrawalChartDataGenerator(data),
    [facility]
  );

  return (
    <div className={cx('facilityTargetTab')}>
      <div className={cx('header')}>
        <Copy as="h2">Water Withdrawal Risk</Copy>
        <Copy as="p">
          The facility may use more water than what would be a sustainable
          amount of water from its surrounding water sources. If the facility’s
          current water use, subtracting any replenishment volumes, is higher
          than the current and future sustainable water use amounts, the
          facility needs to decrease its water use to avoid impacts to its
          operations from water scarcity. Water intensive industries located in
          areas of high levels of water stress and competition for water
          resources will usually have a higher amount of water use risk.
          Sustainable annual water withdrawal is calculated to achieve a &lt;
          40&#37; risk threshold of baseline water stress to a basin.
        </Copy>
        <Copy as="p">
          <strong>WRI Baseline Water Stress = </strong>{' '}
          <span className={cx('score')} style={{ color: stressLevel.color }}>
            {stressLevel.level}
          </span>
        </Copy>
        <Copy as="p">
          <strong>Water Withdrawal Risk = </strong>{' '}
          <span className={cx('score')} style={{ color: riskLevel.color }}>
            {riskLevel.level}
          </span>
          <span> &mdash; {riskLevel.suggestion}.</span>
        </Copy>
      </div>
      <HighchartsReact highcharts={Highcharts} options={chart} />
      <table className={cx('legendTable', 'waterWithdrawalLegend', 'single')}>
        <tbody>
          <tr>
            <td className={cx('label')}>
              <span className={cx('icon', riskLevel.level)}></span>
              <span className={cx('value')}>
                Current annual water withdrawal
              </span>
            </td>
            <td className={cx('label')}>
              <span
                className={cx('icon')}
                style={{ backgroundColor: '#93c9da' }}
              ></span>
              <span className={cx('value')}>
                Water replenishment
              </span>
            </td>
            <td className={cx('label')}>
              <span
                className={cx('icon')}
                style={{ backgroundColor: '#717171' }}
              ></span>
              <span className={cx('value')}>
                Sustainable annual water withdrawal
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

WaterWithdrawalRisk.displayName = 'WaterWithdrawalRisk';
