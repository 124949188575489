import React, { FC, useEffect, useState } from 'react';
import classnames from 'classnames/bind';

import styles from './VerifyAccount.module.css';
import { LoaderIcon } from 'src/components/Icons';
import { useAuth } from 'src/hooks/useAuth';
import { RouteContentScrollable } from 'src/components/RouteContentScrollable';
import { Alert } from 'src/components/Alert';
import { useHistory, useParams } from 'react-router-dom';
import { Button } from 'src/components/Button';
import { animated, config, useSpring } from 'react-spring';
import { useQueryClient } from 'react-query';

const cx = classnames.bind(styles);

export interface VerifyAccountProps
  extends React.HTMLAttributes<HTMLDivElement> {}

type ComponentState =
  | { type: 'loading' }
  | { type: 'success' }
  | { type: 'error'; value: string };

export const VerifyAccount: FC<VerifyAccountProps> = () => {
  const history = useHistory();
  const queryClient = useQueryClient();
  const [state, setState] = useState<ComponentState>({
    type: 'loading',
  });
  const { verifyAccount } = useAuth();

  const { code } = useParams<{ code: string }>();

  useEffect(() => {
    if (verifyAccount && state.type === 'loading') {
      verifyAccount({ code: `${code}` }).then((result) => {
        if (result.status === 'success') {
          setState({ type: 'success' });
        } else {
          setState({ type: 'error', value: result.value.errors[0].message });
        }
      });
    }
  }, [code, state.type]);

  const animate = useSpring({
    opacity: 1,
    transform: 'translate(0px, 0px)',
    from: { opacity: 0, transform: 'translate(0px, -10px)' },
    config: config.molasses,
  });

  let result;
  switch (state.type) {
    case 'success': {
      result = (
        <animated.div style={animate}>
          <Alert
            variant="success"
            message="Your account has been verified."
            className={cx('success')}
          />

          <Button
            onClick={() => {
              queryClient.refetchQueries(['user', 'current']).then(() => {
                history.push({ pathname: '/wrm/dashboard' });
              });
            }}
          >
            Home
          </Button>
        </animated.div>
      );
      break;
    }

    case 'error': {
      result = (
        <animated.div style={animate}>
          <Alert
            variant="danger"
            message="There was an error verifying your account. Please try again."
          />
        </animated.div>
      );
      break;
    }

    default: {
      result = <LoaderIcon height={32} width={32} className={cx('loader')} />;
      break;
    }
  }

  return (
    <RouteContentScrollable className={cx('scrollable')}>
      <div>
        <article className={cx('verifyAccount')} data-testid="verifyAccount">
          <section>
            <div className={cx('verifyAccountBox')}>
              <h2>Verify Account</h2>
              {result}
            </div>
          </section>
        </article>
      </div>
    </RouteContentScrollable>
  );
};

VerifyAccount.displayName = 'VerifyAccount';
